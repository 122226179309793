import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CheckIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
        >
            <path
                className={fillClass}
                d="M14.354 4.854l-8 8A.5.5 0 0 1 6 13a.5.5 0 0 1-.354-.147l-3.5-3.5a.5.5 0 0 1 0-.707.5.5 0 0 1 .708 0L6 11.793l7.646-7.647a.5.5 0 0 1 .707 0 .5.5 0 0 1 0 .708z"
            />
        </svg>
    )
}

export default CheckIcon