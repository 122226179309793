import React, {FunctionComponent} from "react"
import {formatAmountWithCurrency} from "../../../../../tech/format/amount.util"
import {InvestorDashboardInvestmentType} from "../../dashboard.type"
import {CurrencyOpenApi} from "../../../../../generated"
import styles from "./current-value.module.sass"

type ColoredCurrentValueProps = {
    investment: InvestorDashboardInvestmentType
    targetCurrency: CurrencyOpenApi
}

const ColoredCurrentValue: FunctionComponent<ColoredCurrentValueProps> = ({
    investment,
    targetCurrency
}) => {
    const clx = investment.currentValue === investment.amount ? ""
        : investment.currentValue > investment.amount ? styles.green
            : styles.red;
    return (
        <strong className={`${styles.value} ${clx}`}>
            {formatAmountWithCurrency(investment.currentValue, targetCurrency, 2, true)}
        </strong>
    )
}

export default ColoredCurrentValue