/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StripePaymentIntentStatusOpenApi } from './StripePaymentIntentStatusOpenApi';
import {
    StripePaymentIntentStatusOpenApiFromJSON,
    StripePaymentIntentStatusOpenApiFromJSONTyped,
    StripePaymentIntentStatusOpenApiToJSON,
} from './StripePaymentIntentStatusOpenApi';

/**
 * 
 * @export
 * @interface StripePaymentIntentOpenApi
 */
export interface StripePaymentIntentOpenApi {
    /**
     * 
     * @type {string}
     * @memberof StripePaymentIntentOpenApi
     */
    clientSecret: string;
    /**
     * 
     * @type {StripePaymentIntentStatusOpenApi}
     * @memberof StripePaymentIntentOpenApi
     */
    status: StripePaymentIntentStatusOpenApi;
}

/**
 * Check if a given object implements the StripePaymentIntentOpenApi interface.
 */
export function instanceOfStripePaymentIntentOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientSecret" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function StripePaymentIntentOpenApiFromJSON(json: any): StripePaymentIntentOpenApi {
    return StripePaymentIntentOpenApiFromJSONTyped(json, false);
}

export function StripePaymentIntentOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripePaymentIntentOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientSecret': json['clientSecret'],
        'status': StripePaymentIntentStatusOpenApiFromJSON(json['status']),
    };
}

export function StripePaymentIntentOpenApiToJSON(value?: StripePaymentIntentOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientSecret': value.clientSecret,
        'status': StripePaymentIntentStatusOpenApiToJSON(value.status),
    };
}

