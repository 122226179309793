import React, {FunctionComponent} from "react"
import {FilterOptionsType} from "../options/options.type"
import FilterSection from "../options/section/section.component"

type InvestmentTxypeFilterProps = {
    options: FilterOptionsType
    updateOptions: (options: FilterOptionsType) => void
}

const InvestmentTypeFilter: FunctionComponent<InvestmentTxypeFilterProps> = ({
    options,
    updateOptions
}) => {
    return (
        <FilterSection
            label="Investment Type"
            type="INVESTMENT_TYPE"
            options={options}
            updateOptions={updateOptions}
        />
    )
}

export default InvestmentTypeFilter