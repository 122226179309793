import React, {FunctionComponent, useState} from "react"
import {InvestorOnboardingType} from "../../onboarding.type"
import {KycDocumentUploadNecessaryOnboardingStageType} from "../stage.type"
import Head from "../../../../../tech/head/head.component"
import {PRIVACY_STATEMENT} from "../../../../../paths"
import {KycDocumentUploadStateOpenApi} from "../../../../../generated"
import InvestorOnboardingDescription from "../../description/description.component"
import InvestorOnboardingKycUploadDocument from "./document/document.component"
import {FileType} from "../../../../file/file.type"
import ButtonWrapper from "../../../../../tech/button/wrapper/button-wrapper.component"
import Button from "../../../../../tech/button/button.component"
import {ButtonSize} from "../../../../../tech/button/size.enum"
import {ButtonState} from "../../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../../tech/button/style.enum"
import LoadingDots from "../../../../../tech/loading/dots/dots.component"
import Alert from "../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../tech/alert/type.enum"
import styles from "./kyc-upload.module.sass"
import {useApp} from "../../../../../tech/app/context/app.context"

type InvestorOnboardingKycUploadProps = {
    stage: KycDocumentUploadNecessaryOnboardingStageType
    onboarding: InvestorOnboardingType
    updateOnboarding: (onboarding: InvestorOnboardingType) => void
}

const InvestorOnboardingKycUpload: FunctionComponent<InvestorOnboardingKycUploadProps> = ({
    stage,
    onboarding,
    updateOnboarding
}) => {
    const app = useApp()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR">("NONE")
    const [passportFile, setPassportFile] = useState<FileType>()
    const [proofOfAddressFile, setProofOfAddressFile] = useState<FileType>()
    const [proofOfAiStatusFile, setProofOfAiStatusFile] = useState<FileType>()

    const disabled = (notNoneAndToBeReviewed(stage.passport.state) && passportFile === undefined)
        || (notNoneAndToBeReviewed(stage.proofOfAddress.state) && proofOfAddressFile === undefined)
        || (notNoneAndToBeReviewed(stage.proofOfAiStatus.state) && proofOfAiStatusFile === undefined)

    const onSave = async () => {
        setState("LOADING")
        try {
            updateOnboarding(await app.fetchClient.investorApi.saveOnboardingKyc({
                passportFiles: [passportFile!],
                proofOfAddressFiles: [proofOfAddressFile!],
                proofOfAiStatusFiles: [proofOfAiStatusFile!]
            }))
            setState("NONE")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    const person = onboarding.investorWithDetails?.investor.person!
    const fileIdentifierPrefix = `kyc-${person.firstName.toLowerCase()}-${person.lastName.toLowerCase()}`

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "NONE" && (
                <div className={styles.upload}>
                    <Head title={evaluateTitle(stage)}/>
                    <InvestorOnboardingDescription content={[
                        "We need some documents to complete KYC. KYC stands for Know your Customer and is a legal requirement to verify your eligibility to invest.",
                        <>See our <a href={PRIVACY_STATEMENT} target="_blank" rel="noreferrer">Privacy statement</a> to see on how we handle your data.</>
                    ]}/>
                    <div className={styles.grid}>
                        {notNoneAndToBeReviewed(stage.passport.state) && (
                            <InvestorOnboardingKycUploadDocument
                                label="Passport/ID"
                                description="Passport, or NRIC/FIN for Singapore residents"
                                fileIdentifier={`${fileIdentifierPrefix}-passport`}
                                updateFile={(f: FileType | undefined) => setPassportFile(f)}
                                state={stage.passport.state}
                                rejectionComment={stage.passport.rejectionComment}
                                validTill={stage.passport.validTill}
                            />
                        )}
                        {notNoneAndToBeReviewed(stage.proofOfAddress.state) && (
                            <InvestorOnboardingKycUploadDocument
                                label="Proof of Address"
                                description="Bank Statement, Utilities Bill or Back of NRIC"
                                fileIdentifier={`${fileIdentifierPrefix}-proof-of-address`}
                                updateFile={(f: FileType | undefined) => setProofOfAddressFile(f)}
                                state={stage.proofOfAddress.state}
                                rejectionComment={stage.proofOfAddress.rejectionComment}
                                validTill={stage.proofOfAddress.validTill}
                            />
                        )}
                        {notNoneAndToBeReviewed(stage.proofOfAiStatus.state) && (
                            <InvestorOnboardingKycUploadDocument
                                label="Accredited Investor Status"
                                description="Bank Statement, Real Estate or Pay Slip"
                                fileIdentifier={`${fileIdentifierPrefix}-proof-ai-status`}
                                updateFile={(f: FileType | undefined) => setProofOfAiStatusFile(f)}
                                state={stage.proofOfAiStatus.state}
                                rejectionComment={stage.proofOfAiStatus.rejectionComment}
                                validTill={stage.proofOfAiStatus.validTill}
                            />
                        )}
                    </div>
                    <ButtonWrapper rightOnDesktop={true}>
                        <Button
                            label="Next"
                            type="button"
                            size={ButtonSize.LARGE}
                            state={disabled ? ButtonState.INACTIVE : ButtonState.ACTIVE}
                            style={ButtonStyle.PRIMARY}
                            onClick={onSave}
                            withArrow={true}
                            fullWidth={true}
                            disabled={disabled}
                        />
                    </ButtonWrapper>
                </div>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unexpected error."
                />
            )}
        </>
    )
}

export default InvestorOnboardingKycUpload

function evaluateTitle(stage: KycDocumentUploadNecessaryOnboardingStageType): string {
    const states = [
        stage.passport.state,
        stage.proofOfAddress.state,
        stage.proofOfAiStatus.state
    ]
    if (states.includes(KycDocumentUploadStateOpenApi.Rejected)) {
        return "Some of your documents have been rejected"
    }
    if (states.includes(KycDocumentUploadStateOpenApi.UpdateRequired)) {
        return "We need an update of your KYC documents"
    }
    return "Upload KYC documents"
}

function notNoneAndToBeReviewed(state: KycDocumentUploadStateOpenApi): boolean {
    return state !== "NONE"
        && state !== "TO_BE_REVIEWED"
}