import React, {FunctionComponent} from "react"
import {Link} from "react-router-dom"
import ArrowLeftIcon from "../icon/arrow-left.component"
import CaretRightIcon from "../icon/caret-right.component"
import styles from "./breadcrumb.module.sass"

type BreadcrumbProps = {
    backLink: string | (() => void)
    elements: BreadcrumbElement[]
    maxWidth?: number
}

export type BreadcrumbElement = {
    text: string
    to: string
}

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
    backLink,
    elements,
    maxWidth
}) => {
    if (elements.length < 1) {
        return <></>
    }

    const slicedList = elements.slice(1)

    const firstElement = elements[0]
    return (
        <>
            <div className={styles.spacer}/>
            <div className={styles.breadcrumb}>
                <div style={maxWidth ? { maxWidth: `${maxWidth}px` } : {}}>
                    <div className={styles.elementWrapper}>
                        <div className={styles.arrow}>
                            {typeof backLink === "string" ? (
                                <Link to={backLink}>
                                    <Icon/>
                                </Link>
                            ) : (
                                <div onClick={backLink}>
                                    <Icon/>
                                </div>
                            )}
                        </div>
                        <div>
                            {elements.length > 1 ? (
                                <Link to={firstElement.to}>
                                    {firstElement.text}
                                </Link>
                            ) : (
                                <span>
                                    {firstElement.text}
                                </span>
                            )}
                        </div>
                    </div>
                    {slicedList.map((element, index) => (
                        <div
                            className={styles.elementWrapper}
                            key={`element-${index}`}
                        >
                            <div className={styles.caret}>
                                <CaretRightIcon
                                    width={14}
                                    height={14}
                                    fillClass="fill-icon-inactive"
                                />
                            </div>
                            <div>
                                {index < slicedList.length - 1 ? (
                                    <Link
                                        to={element.to}
                                        className={styles.link}
                                    >
                                        {element.text}
                                    </Link>
                                ) : (
                                    <span className={styles.link}>
                                        {element.text}
                                    </span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Breadcrumb

const Icon: FunctionComponent = () => {
    return (
        <ArrowLeftIcon
            width={20}
            height={20}
            fillClass="fill-icon-theme"
        />
    )
}