import {matchPath} from "react-router-dom"
import {prefetchConfig} from "./prefetch.config"
import {FetchClient} from "../fetch/client"
import {QueryType} from "../query/query.type"
import {QueryClient} from "react-query"

export function assembleQueriesToPrefetch(
    authenticated: boolean,
    fetchClient: FetchClient,
    queryClient: QueryClient,
    pathname: string
): QueryType<any>[] {
    return prefetchConfig
        .filter(config => matchPath({
            path: config.route
        }, pathname) !== null)
        .flatMap(config => config.queries)
        .map(p => "key" in p
            ? p.func.type === "WITH" ? p.func.func(fetchClient, queryClient, p.key) : p.func.func(fetchClient, p.key)
            : p.func.type === "WITH" ? p.func.func(fetchClient, queryClient, p.keyWithParameters) : p.func.func(fetchClient, p.keyWithParameters))
}