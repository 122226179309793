import {
    ApiResponse,
    StripePaymentIntentOpenApi,
    SubscribeResultOpenApi,
    SubscribeResultStripePaymentIntentOpenApi
} from "../../../generated"
import {StripePaymentIntent, SubscribeResultStripePaymentIntentType, SubscribeResultType} from "./subscribe.type"

export function mapSubscribeResponseStatus(
    response: ApiResponse<SubscribeResultOpenApi>,
    result: SubscribeResultOpenApi
): SubscribeResultType {
    if (response.raw.status === 400) return "VALIDATION_FAILED"
    if (response.raw.status === 200) {
        const status = result.stripePaymentIntent.intent?.status
        if (status === "REQUIRES_ACTION") return "STRIPE_REQUIRES_ACTION"
        if (status === "SUCCEEDED") return "SUCCESS"
    }
    return "ERROR"
}

export function mapOpenApiToSubscribeResultStripePaymentIntent(
    subscribeResultStripePaymentIntent: SubscribeResultStripePaymentIntentOpenApi
): SubscribeResultStripePaymentIntentType {
    return {
        errorMessage: subscribeResultStripePaymentIntent.errorMessage,
        intent: subscribeResultStripePaymentIntent.intent && mapOpenApiToStripePaymentIntent(subscribeResultStripePaymentIntent.intent)
    }
}

export function mapOpenApiToStripePaymentIntent(paymentIntent: StripePaymentIntentOpenApi): StripePaymentIntent {
    return {
        clientSecret: paymentIntent.clientSecret,
        status: paymentIntent.status
    }
}