import React, {FunctionComponent} from "react"
import styles from "./description.module.sass"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"

type DealDescriptionProps = {
    deal: DealOnboardingDependentType
}

const DealDescription: FunctionComponent<DealDescriptionProps> = ({ deal }) => {
    return(
        <div className={`${styles.description} ${(hasInvestors(deal) || hasTermsTags(deal)) ? styles.dashedBorder : ""}`}>
            {deal.description}
        </div>
    )
}

export default DealDescription

function hasInvestors(deal: DealOnboardingDependentType) {
    return deal.investors !== undefined
        && deal.investors.length > 0
}

function hasTermsTags(deal: DealOnboardingDependentType): boolean {
    return deal.termsTags !== undefined
        && deal.termsTags.length > 0
}