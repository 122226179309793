import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const XIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M25.708 24.292a1 1 0 0 1 .293.707 1 1 0 0 1-.293.707 1 1 0 0 1-.707.293 1 1 0 0 1-.707-.293L16 17.414l-8.292 8.294a1 1 0 0 1-1.415 0 1 1 0 0 1 0-1.415L14.586 16 6.293 7.707a1 1 0 0 1 0-1.415 1 1 0 0 1 1.415 0L16 14.586l8.293-8.294a1 1 0 0 1 1.415 0 1 1 0 0 1 0 1.415L17.414 16l8.294 8.293z"
            />
        </svg>
    )
}

export default XIcon