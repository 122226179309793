import React, {FunctionComponent} from "react"
import {FieldProps} from "../props.type"
import FormError from "../../error/error.component"
import {ExclamationMarkType} from "../../../exclamation-mark/mark.type"
import {hasError} from "../../error/error.util"
import styles from "./checkbox.module.sass"

type CheckboxProps = FieldProps & {
    id: string
    description?: string
    checked?: boolean
    exclamationMark?: ExclamationMarkType
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
    id,
    label,
    description,
    errors,
    checked,
    exclamationMark,
    reactHookFormRegister
}) => {
    return (
        <div className={styles.checkbox}>
            <div className={styles.wrapper}>
                <div className={styles.input}>
                    <input
                        type="checkbox"
                        id={id}
                        // @ts-ignore
                        defaultValue={checked}
                        className={hasError(reactHookFormRegister.name, errors) ? styles.withError : undefined}
                        {...reactHookFormRegister.register(
                            reactHookFormRegister.name,
                            {
                                ...reactHookFormRegister.options
                            }
                        )}
                    />
                </div>
                <div className={hasError(reactHookFormRegister.name, errors) ? styles.labelWithError : styles.label}>
                    <label htmlFor={id}>
                        {label}
                        {description && (
                            <small className={styles.description}>{description}</small>
                        )}
                    </label>
                    {exclamationMark && (
                        <div className={styles.exclamationMark}>
                            {exclamationMark.mark}
                        </div>
                    )}
                </div>
            </div>
            {exclamationMark?.info}
            <FormError
                field={reactHookFormRegister.name}
                errors={errors}
            />
        </div>
    )
}

export default Checkbox