import React, {FunctionComponent} from "react"
import {DealType} from "../../deal.type"
import initializationStyles from "../initialization.module.sass"
import DealLegalDocuments from "./documents.component"
import styles from "./terms.module.sass"

type DealInvestmentInitializationTermsProps = {
    deal: DealType
}

const DealInvestmentInitializationTerms: FunctionComponent<DealInvestmentInitializationTermsProps> = ({
    deal
}) => {
    return (
        <div className={styles.terms}>
            <div className={initializationStyles.title}>
                Documents / Terms
            </div>
            <DealLegalDocuments dealId={deal.id!}/>
        </div>
    )
}

export default DealInvestmentInitializationTerms