import {InvestorDashboardInvestmentType} from "../../dashboard.type"
import {DonutChartDataPoint} from "../../../../../tech/chart/donut/donut.type"
import {formatPercent} from "../../../../../tech/format/percent.util"

export type GroupedWithSum<T> = {
    identifier: T
    sum: number
}

type GroupedInvestments = { [key: string]: InvestorDashboardInvestmentType[] }

export function groupAndSum<T>(
    investments: InvestorDashboardInvestmentType[],
    filterFunction: (investment: InvestorDashboardInvestmentType) => boolean,
    identifierExtractor: (investment: InvestorDashboardInvestmentType) => T,
    keyExtractor: (investment: InvestorDashboardInvestmentType) => string,
): GroupedWithSum<T>[] {
    const grouped = investments
        .filter(filterFunction)
        .reduce((
            result: GroupedInvestments,
            currentValue: InvestorDashboardInvestmentType,
        ) => {
            const k = keyExtractor(currentValue)
            result[k] = result[k] || []
            result[k].push(currentValue)
            return result
        }, {})
    return Object.keys(grouped)
        .map(key => {
            return {
                identifier: identifierExtractor(grouped[key][0]),
                sum: grouped[key].reduce((r, i) => r + i.currentValue, 0)
            }
        })
}

export function assembleData<T>(
    groupedWithSum: GroupedWithSum<T>[],
    labelExtractor: (identifier: T) => string,
): DonutChartDataPoint[] {
    const overallSum = groupedWithSum.reduce((r, i) => r + i.sum, 0)
    return groupedWithSum
        .map(i => {
            const frequency = i.sum / overallSum
            return {
                label: `${labelExtractor(i.identifier)} (${formatPercent(frequency * 100, 1, 0)})`,
                frequency
            }
        })
        .sort((a, b) => b.frequency - a.frequency)
}

export function findStrongest<T>(
    groupedWithSum: GroupedWithSum<T>[]
): T {
    return groupedWithSum
        .find(x => x.sum === Math.max(...groupedWithSum.map(y => y.sum)))!
        .identifier
}