import React, {FunctionComponent} from "react"
import {InvestorDashboardInvestmentType} from "../../dashboard.type"
import {CurrencyOpenApi} from "../../../../../generated"
import {formatAmountWithCurrency} from "../../../../../tech/format/amount.util"
import styles from "./invested.module.sass"

type InvestorDashboardInvestmentsInvestedOriginalProps = {
    investment: InvestorDashboardInvestmentType
    targetCurrency: CurrencyOpenApi
}

const InvestorDashboardInvestmentsInvestedOriginal: FunctionComponent<InvestorDashboardInvestmentsInvestedOriginalProps> = ({
    investment,
    targetCurrency
}) => {
    const currencyOfInvestment = investment.investment.investment.value.currency!

    if (currencyOfInvestment === targetCurrency) {
        return <></>
    }

    return (
        <span className={styles.original}>
            {" "}
            <small>
                ({formatAmountWithCurrency(investment.investment.getAmountInvested(), currencyOfInvestment, 0)})
            </small>
        </span>
    )
}

export default InvestorDashboardInvestmentsInvestedOriginal