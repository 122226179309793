import React, {FunctionComponent, ReactNode} from "react"
import {Analytics} from "@vercel/analytics/react"
import {SpeedInsights} from "@vercel/speed-insights/react"

type AnalyticsWrapperProps = {
    children: ReactNode
}

const AnalyticsWrapper: FunctionComponent<AnalyticsWrapperProps> = ({ children }) => {
    return (
        <>
            {children}
            {process.env.REACT_APP_VERCEL_ENV === "production" && (
                <>
                    <Analytics/>
                    <SpeedInsights/>
                </>
            )}
        </>
    )
}

export default AnalyticsWrapper