import React, {FunctionComponent, useState} from "react"
import {DashboardType} from "../../dashboard.type"
import InvestorDashboardTile from "../../tile/tile.component"
import InvestorDashboardTabs from "../../tabs/tabs.component"
import InvestorDashboardSummaryDistributionStage from "./stage/stage.component"
import InvestorDashboardSummaryDistributionGeolocation from "./geolocation/geolocation.component"
import InvestorDashboardSummaryDistributionIndustry from "./industry/industry.component"
import styles from "./distribution.module.sass"

type InvestorDashboardSummaryDistributionProps = {
    dashboard: DashboardType
}

type DistributionSelectionType = "STAGE" | "GEOLOCATION" | "INDUSTRY"

const InvestorDashboardSummaryDistribution: FunctionComponent<InvestorDashboardSummaryDistributionProps> = ({
    dashboard
}) => {
    const [selection, setSelection] = useState<DistributionSelectionType>("STAGE")

    const updateSelection = (s: DistributionSelectionType) => setSelection(s)

    const strongest = dashboard.summary.performance.strongest
    if (strongest.geolocation && strongest.industry && strongest.stage) {
        return (
            <InvestorDashboardTile
                title="Portfolio Distribution"
                noLayoutDesktop={false}
            >
                <div className={styles.distribution}>
                    <InvestorDashboardTabs<DistributionSelectionType>
                        currentSelection={selection}
                        tabs={[
                            {
                                title: "Stage",
                                content: <InvestorDashboardSummaryDistributionStage dashboard={dashboard}/>,
                                selection: "STAGE",
                                updateSelection: updateSelection
                            },
                            {
                                title: "Geolocation",
                                content: <InvestorDashboardSummaryDistributionGeolocation dashboard={dashboard}/>,
                                selection: "GEOLOCATION",
                                updateSelection: updateSelection
                            },
                            {
                                title: "Industry",
                                content: <InvestorDashboardSummaryDistributionIndustry dashboard={dashboard}/>,
                                selection: "INDUSTRY",
                                updateSelection: updateSelection
                            },
                        ]}
                    />
                </div>
            </InvestorDashboardTile>
        )
    }

    return <></>
}

export default InvestorDashboardSummaryDistribution