import {InvestorOnboardingType} from "./onboarding.type"
import {KycDocumentUploadNecessaryOnboardingStageType} from "./stage/stage.type"

export function showOnboardingBanner(onboarding: InvestorOnboardingType): boolean {
    return onboarding.stage.type === "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED"
        || onboarding.stage.type === "GENERAL_DATA"
        || onboarding.stage.type === "KYC_DOCUMENT_UPLOAD_NECESSARY"
}

export function needsUpdateOnboardingBanner(onboarding: InvestorOnboardingType): boolean {
    if (onboarding.stage.type === "KYC_DOCUMENT_UPLOAD_NECESSARY") {
        const stage = onboarding.stage as KycDocumentUploadNecessaryOnboardingStageType
        return stage.passport.state === "UPDATE_REQUIRED"
            || stage.proofOfAddress.state === "UPDATE_REQUIRED"
            || stage.proofOfAiStatus.state === "UPDATE_REQUIRED"
    }
    return false
}