import React, {FunctionComponent, ReactNode, useCallback, useEffect, useState} from "react"
import styles from "./tooltip.module.sass"
import InfoCircleIcon from "../../icon/info-circle.component"
import TriangleUpIcon from "../../icon/triangle-up.component"

type TooltipProps = {
    anchor?: React.RefObject<any>
    children: ReactNode
    padding?: {
        x: number
        y: number
    }
    infoIconSize?: number
    showIcon?: boolean
    position?: {
        top: number
        left: number
        shiftLeft?: number
    }
    maxWidth?: number
    halveMaximumWidth?: boolean
}

const Tooltip: FunctionComponent<TooltipProps> = ({
    anchor,
    children,
    padding,
    infoIconSize = 13,
    showIcon = true,
    position,
    maxWidth,
    halveMaximumWidth = false
}) => {
    const [open, setOpen] = useState<boolean>(false)

    let style: any = {}
    let triangleStyle: any = {}
    if (anchor && anchor.current) {
        anchor.current.style.cursor = "pointer"
        if (anchor.current.clientWidth) {
            const positionLeft = anchor.current.getBoundingClientRect().left +  + window.scrollX
            const viewportWidth = window.innerWidth
            const maxWidth = viewportWidth - positionLeft - 32
            style.maxWidth = `min(80vw, ${anchor.current.clientWidth}px, ${halveMaximumWidth ? maxWidth / 2 : maxWidth}px)`
        }
    }

    if (maxWidth) {
        style.maxWidth = `${maxWidth}px`
    }

    if (position) {
        style.paddingTop = position.top
        triangleStyle.top = position.top - 15
        style.left = position.left
        if (position.shiftLeft) {
            style.marginLeft = position.shiftLeft
            triangleStyle.marginLeft = (-1) * position.shiftLeft
        }
    }

    const onClick = useCallback((e: any) => {
        e.stopPropagation()
        setOpen(!open)
    }, [open])

    useEffect(() => {
        if (anchor) {
            const current = anchor.current
            const onMouseEnter = () => setOpen(true)
            const onMouseLeave = () => setOpen(false)
            current.removeEventListener("click", onClick)
            current.removeEventListener("mouseover", onMouseEnter)
            current.removeEventListener("mouseleave", onMouseLeave)
            current.addEventListener("click", onClick)
            current.addEventListener("mouseover", onMouseEnter)
            current.addEventListener("mouseleave", onMouseLeave)
            return () => {
                if (anchor && current) {
                    current.removeEventListener("click", onClick)
                    current.removeEventListener("mouseover", onMouseEnter)
                    current.removeEventListener("mouseleave", onMouseLeave)
                }
            }
        }
    }, [anchor, onClick, open])

    return (
        <div
            className={styles.tooltip}
            onMouseOver={() => !anchor && setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            style={padding && {
                paddingTop: `${padding.y}px`,
                paddingLeft: `${padding.x}px`,
                paddingRight: `${padding.x}px`,
                paddingBottom: `${padding.y}px`
            }}
        >
            {showIcon && (
                <div
                    className={styles.i}
                    onClick={onClick}
                >
                    <InfoCircleIcon
                        width={infoIconSize}
                        height={infoIconSize}
                        fillClass="fill-icon-inactive"
                    />
                </div>
            )}
            {open && (
                <div
                    className={styles.content}
                    onClick={onClick}
                    style={style}
                >
                    <div
                        className={styles.triangle}
                        style={triangleStyle}
                    >
                        <TriangleUpIcon
                            width={20}
                            height={20}
                            fillClass="fill-icon-quaternary"
                        />
                    </div>
                    <div className={styles.contentInner}>
                        {children}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Tooltip