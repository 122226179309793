import React, {FunctionComponent, ReactNode} from "react"
import styles from "./label-text.module.sass"

export type FieldLabelTextProps = {
    label?: string | ReactNode
}

const FieldLabelText: FunctionComponent<FieldLabelTextProps> = ({ label }) => {
    if (label) {
        return (
            <div className={styles.labelText}>
                {label}
            </div>
        )
    }
    return <></>
}

export default FieldLabelText