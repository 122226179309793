import React, {FunctionComponent, ReactNode} from "react"
import XIcon from "../icon/x.component"
import {toggleInfo} from "./mark.component"
import styles from "./info.module.sass"

type ExclamationMarkInfoProps = {
    modalId: string
    title: string
    content: ReactNode
}

const ExclamationMarkInfo: FunctionComponent<ExclamationMarkInfoProps> = ({ modalId, title, content }) => {
    return (
        <div
            className={styles.info}
            style={{ display: 'none' }}
            id={modalId}
        >
            <div>
                <div>
                    <div className={styles.title}>
                        <div className={styles.titleText}>
                            <div>{title}</div>
                        </div>
                        <div
                            className={styles.close}
                            onClick={() => toggleInfo(modalId)}
                        >
                            <XIcon
                                fillClass="fill-icon-theme"
                                width={24}
                                height={24}
                            />
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default ExclamationMarkInfo


