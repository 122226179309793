import React, {FunctionComponent} from "react"
import {useSearchParams} from "react-router-dom"
import {InvestmentType} from "../../../investment.type"
import {isSyncingDocument} from "./sign-documents.util"
import LoadingBar from "../../../../../tech/loading/bar/bar.component"
import commonStyles from "../common.module.sass"
import styles from "./sign-documents.module.sass"

type PendingInvestmentCardSignDocumentsProps = {
    investment: InvestmentType
}

const PendingInvestmentCardSignDocuments: FunctionComponent<PendingInvestmentCardSignDocumentsProps> = ({ investment }) => {
    const [searchParams] = useSearchParams()

    if (isSyncingDocument(searchParams)) {
        return (
            <>
                <div className={commonStyles.title}>
                    Currently syncing your document
                </div>
                <div className={styles.loadingBar}>
                    <LoadingBar
                        fillClass="background-icon-theme"
                        backgroundClass="background-fill-bg-primary"
                    />
                </div>
                <div className={commonStyles.paragraph}>
                    Currently syncing the document you just signed. Might take a few seconds.
                </div>
            </>
        )
    }

    return (
        <>
            <div className={commonStyles.title}>
            Check your Email
            </div>
            <div className={commonStyles.paragraph}>
                Documents for you to sign will be sent to your registered email
                ({investment.investor.person?.contact.email}) shortly.
            </div>
            <div className={commonStyles.paragraph}>
                Do check your email and sign the documents. Further instructions will be provided after the minimum
                amount is reached.
            </div>
            <div className={commonStyles.paragraph}>
                Missing our mail? The mail is sent by a third party provider. You will receive the email
                from <i>documents@paperless.io</i>.
            </div>
        </>
    )
}

export default PendingInvestmentCardSignDocuments