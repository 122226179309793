import React, {FunctionComponent, useState} from "react"
import {AxisBottom, AxisLeft} from "@visx/axis"
import {curveStepAfter} from "@visx/curve"
import {GridRows} from "@visx/grid"
import {Group} from "@visx/group"
import {ParentSize} from "@visx/responsive"
import {coerceNumber, scaleLinear, scaleUtc} from "@visx/scale"
import {LinePath} from "@visx/shape"
import {useTooltip, useTooltipInPortal} from "@visx/tooltip"
import {formatAmountAbbreviatedWithCurrency} from "../../format/amount.util"
import {formatMonthYear} from "../../format/datetime.util"
import LineChartLegend from "./legend/legend.component"
import {LineChartLineType, LineChartLineWithColorAndCoordinatesType} from "./line.type"
import {addColorsAndSort} from "./color.range"
import {CurrencyOpenApi} from "../../../generated"
import {addCoordinates, findNearestDataPoints} from "./position.util"
import styles from "./line.module.sass"
import LineChartTooltip from "./tooltip/tooltip.component"
import {TooltipDataPointType} from "./tooltip/tooltip.type"
import {compareNumbers} from "../../sort/sort.util"

type LineChartProps = {
    currency: CurrencyOpenApi
    height: number
    lines: LineChartLineType[]
}

const LineChart: FunctionComponent<LineChartProps> = ({
    currency,
    height,
    lines
}) => {
    const [line, setLine] = useState<LineType>({ show: false })
    const {
        showTooltip,
        hideTooltip,
        tooltipOpen,
        tooltipData,
        tooltipLeft = 0,
        tooltipTop = 0,
    } = useTooltip<TooltipDataPointType[]>({
        tooltipOpen: false,
        tooltipLeft: 100,
        tooltipTop: 100,
        tooltipData: []
    })

    const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
        detectBounds: true,
        scroll: true,
    })

    const linesWithColor = addColorsAndSort(lines)

    return (
        <>
            <ParentSize
                debounceTime={10}
                style={{ width: "100%" }}
            >
                {({ width}) => {
                    const padding = 12

                    const xAxisHeight = 25
                    const xAxisPaddingAbove = 15
                    const yAxisWidth = 50

                    const xAxisDates = lines
                        .flatMap(l => l.points)
                        .map(p => p.date)
                    const xAxisMinMax = getMinMax(xAxisDates)
                    const xAxisScale = scaleUtc({
                        clamp: false,
                        domain: [xAxisMinMax.min, xAxisMinMax.max],
                        nice: false,
                        range: [yAxisWidth, width - padding],
                    })

                    const yAxisValues = lines
                        .flatMap(l => l.points)
                        .map(p => p.value)
                    const yAxisMinMax = getMinMax(yAxisValues)
                    const yAxisScale = scaleLinear({
                        clamp: false,
                        domain: [0, yAxisMinMax.max],
                        nice: false,
                        range: [0, height - xAxisHeight - padding - xAxisPaddingAbove],
                        round: true,
                        reverse: true,
                    })

                    const linesWithColorAndCoordinates = addCoordinates(linesWithColor, xAxisScale)

                    const handlePointerMove = (event: React.PointerEvent<SVGSVGElement>) => {
                        const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left - 12
                        const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top

                        const isInBoundary = containerX >= yAxisWidth
                            && containerY <= height - xAxisHeight - xAxisPaddingAbove + 5

                        if (isInBoundary) {
                            const nearestDataPoints = findNearestDataPoints(containerX, linesWithColorAndCoordinates)
                            if (nearestDataPoints.length > 0) {
                                showTooltip({
                                    tooltipLeft: containerX,
                                    tooltipTop: containerY,
                                    tooltipData: nearestDataPoints
                                })
                            }
                            else {
                                hideTooltip()
                            }
                            setLine({
                                show: true,
                                left: containerX
                            })
                        }
                        else {
                            hideTooltip()
                            setLine({ show: false })
                        }
                    }

                    return (
                        <>
                            <svg
                                width={width}
                                height={height}
                                className={styles.graph}
                                onPointerMove={handlePointerMove}
                                onMouseLeave={() => {
                                    hideTooltip()
                                    setLine({ show: false })
                                }}
                                ref={containerRef}
                            >
                                <Group
                                    top={padding}
                                    left={padding}
                                >
                                    <AxisLeft
                                        axisClassName={styles.axis}
                                        left={yAxisWidth}
                                        scale={yAxisScale}
                                        strokeWidth={0}
                                        tickFormat={(value) => formatAmountAbbreviatedWithCurrency(value.valueOf(), currency)}
                                        tickValues={assembleAmountTicks(yAxisMinMax.max, 3)}
                                    />
                                    <AxisBottom
                                        axisClassName={styles.axis}
                                        scale={xAxisScale}
                                        strokeWidth={0}
                                        tickFormat={(v, i, vs) => formatMonthYear(v as Date)}
                                        tickValues={assembleDateTicks(xAxisDates, 4)}
                                        top={height - xAxisHeight - padding}
                                    />
                                    <GridRows
                                        width={width - yAxisWidth - padding}
                                        height={height}
                                        left={yAxisWidth}
                                        numTicks={3}
                                        scale={yAxisScale}
                                        stroke="#EEF0F2"
                                        tickValues={assembleAmountTicks(yAxisMinMax.max, 3)}
                                    />
                                    {linesWithColorAndCoordinates
                                        .sort((a, b) => compareNumbers(a.zIndex, b.zIndex, "DESCENDING"))
                                        .map(line => (
                                            <LinePath
                                                width={width - yAxisWidth - padding}
                                                curve={curveStepAfter}
                                                data={assembleLinePath(line)}
                                                stroke={line.color}
                                                strokeWidth={2}
                                                x={d => xAxisScale(d.x)}
                                                y={d => yAxisScale(d.y)}
                                                key={line.id}
                                            />
                                        ))}
                                    {line.show && line.left && (
                                        <line
                                            x1={line.left}
                                            x2={line.left + 1}
                                            y1={0}
                                            y2={height - xAxisHeight - padding - xAxisPaddingAbove}
                                            stroke="#EEF0F2"
                                        />
                                    )}
                                </Group>
                            </svg>
                            {tooltipOpen && tooltipData && tooltipData.length > 0 && (
                                <TooltipInPortal
                                    key={Math.random()}
                                    left={tooltipLeft}
                                    top={tooltipTop}
                                >
                                    <LineChartTooltip
                                        currency={currency}
                                        dataPoints={tooltipData}
                                    />
                                </TooltipInPortal>
                            )}
                        </>
                    )
                }}
            </ParentSize>
            <LineChartLegend
                lines={linesWithColor}
            />
        </>
    )
}

export default LineChart

type NumberValue = number | { valueOf(): number }

type LineType = {
    show: boolean
    left?: number
}

function assembleAmountTicks(max: number, numTicks: number): number[] {
    const min = 0
    const adjustedMax = round(max - (0.1 * max))
    const rangeWidth = (adjustedMax - min) / (numTicks - 1)
    const ticks: number[] = []
    for (let i = 1; i < numTicks - 1; i++) {
        ticks.push(round(min + i * rangeWidth))
    }
    return [min, ...ticks, adjustedMax]
}

function assembleDateTicks(dates: Date[], numTicks: number): Date[] {
    const minDate = dates.reduce((a, b) => a < b ? a : b)
    const maxDate = dates.reduce((a, b) => a > b ? a : b)
    const diff = maxDate.getTime() - minDate.getTime()
    const adjustedMin = Math.abs(minDate.getTime() + (0.1 * diff))
    const adjustedMax = Math.abs(maxDate.getTime() - (0.1 * diff))
    const rangeWidth = (adjustedMax - adjustedMin) / (numTicks - 1)
    const ticks: number[] = []
    for (let i = 1; i < numTicks - 1; i++) {
        ticks.push(adjustedMin + i * rangeWidth)
    }
    return [adjustedMin, ...ticks, adjustedMax]
        .map(t => new Date(t))
}

function getMinMax(values: NumberValue[]): { min: number, max: number } {
    const numericValues = values.map(coerceNumber)
    return {
        min: Math.min(...numericValues),
        max: Math.max(...numericValues)
    }
}

function round(x: number): number {
    const multiple = Math.pow(10, Math.trunc(x).toString().length - 2)
    return Math.round(x / multiple) * multiple
}

function assembleLinePath(line: LineChartLineWithColorAndCoordinatesType): { x: Date, y: number }[] {
    return line.points.map(p => ({
            x: p.date.value,
            y: p.value
        }))
}