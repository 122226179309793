import {FunctionComponent} from "react"
import styles from "./moic.module.sass"

const MoicTooltip: FunctionComponent = () => {
    return (
        <div className={styles.moic}>
            <div>
                <strong>Multiple on Invested Capital (MOIC)</strong> is a financial metric used in investment analysis to evaluate the performance of an investment relative to the amount of capital initially invested.
            </div>
            <div>
                As usual in private equity, this is only a <strong>rough estimate</strong> and not an accurate value.
            </div>
        </div>
    )
}

export default MoicTooltip