import React, {FunctionComponent, useState} from "react"
import LoadingDots from "../../../../../tech/loading/dots/dots.component"
import Alert from "../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../tech/alert/type.enum"
import {InvestorOnboardingType} from "../../onboarding.type"
import Head from "../../../../../tech/head/head.component"
import ButtonWrapper from "../../../../../tech/button/wrapper/button-wrapper.component"
import Button from "../../../../../tech/button/button.component"
import {ButtonSize} from "../../../../../tech/button/size.enum"
import {ButtonState} from "../../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../../tech/button/style.enum"
import CheckCircleIcon from "../../../../../tech/icon/check-circle.component"
import {useApp} from "../../../../../tech/app/context/app.context"
import styles from "./success.module.sass"

type InvestorOnboardingAcknowledgeSuccessProps = {
    updateOnboarding: (onboarding: InvestorOnboardingType) => void
}

const InvestorOnboardingAcknowledgeSuccess: FunctionComponent<InvestorOnboardingAcknowledgeSuccessProps> = ({ updateOnboarding }) => {
    const app = useApp()
    const [state, setState] = useState<"NONE" | "LOADING" | "ERROR">("NONE")

    const start = async () => {
        setState("LOADING")
        try {
            updateOnboarding(await app.fetchClient.investorApi.saveOnboardingStarted(true))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "NONE" && (
                <div className={styles.kyc}>
                    <div className={styles.check}>
                        <CheckCircleIcon
                            width={64}
                            height={64}
                            fillClass="fill-icon-success"
                        />
                    </div>
                    <Head
                        title="Onboarding completed"
                        description={(
                            <>
                                <p>
                                    Congratulations! You have completed the entire onboarding process.
                                </p>
                                <p>
                                    You are now ready to start!
                                </p>
                            </>
                        )}
                    />
                    <ButtonWrapper rightOnDesktop={false}>
                        <Button
                            label="Proceed"
                            type="button"
                            size={ButtonSize.LARGE}
                            state={ButtonState.ACTIVE}
                            style={ButtonStyle.PRIMARY}
                            onClick={start}
                            withArrow={true}
                        />
                    </ButtonWrapper>
                </div>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unexpected error."
                />
            )}
        </>
    )
}

export default InvestorOnboardingAcknowledgeSuccess