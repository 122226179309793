import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const ArrowLeftIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
        >
            <path
                className={fillClass}
                d="M14 8a.5.5 0 0 1-.5.5H3.707l3.647 3.646a.5.5 0 0 1 .147.354.5.5 0 0 1-.147.354.5.5 0 0 1-.354.147.5.5 0 0 1-.354-.147l-4.5-4.5A.5.5 0 0 1 2 8a.5.5 0 0 1 .147-.354l4.5-4.5a.5.5 0 0 1 .708 0 .5.5 0 0 1 0 .708L3.707 7.5H13.5a.5.5 0 0 1 .5.5z"
            />
        </svg>
    )
}

export default ArrowLeftIcon


