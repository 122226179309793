import {FunctionComponent} from "react"
import LegalWrapper from "../legal.component"
import {RouteData} from "../../../tech/routing/route.type"
import {DealOnboardingDependentWithInvestmentType} from "../../deal/onboarding-dependent.type"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import styles from "../legal.module.sass"

type PrivacyStatementProps = {
    onboarding?: InvestorOnboardingType
    openDeals?: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
}

const PrivacyStatement: FunctionComponent<PrivacyStatementProps> = ({
    onboarding,
    openDeals,
    relevantRoutes
}) => {
    return (
        <LegalWrapper
            onboarding={onboarding}
            openDeals={openDeals}
            relevantRoutes={relevantRoutes}
        >
            <div className={styles.content}>
                <center>
                    <h2>NonPublic Pte. Ltd.</h2>
                    <h2>Privacy Policy</h2>
                    <p>(Last updated: 13 September, 2024)</p>
                </center>
                <p>
                    NonPublic Pte. Ltd. (“<strong>NonPublic</strong>”) is a member of the NonPublic Network, comprising
                    NonPublic and its affiliates and related entities (the “<strong>NonPublic Network</strong>” or the “<strong>NonPublic
                    Group</strong>”).
                </p>
                <p>
                    This Privacy Policy sets out the basis which NonPublic (“<strong>we</strong>”, “<strong>us</strong>”,
                    or
                    “<strong>our</strong>”) may collect, use, disclose or otherwise process personal information and
                    data in
                    connection with your use of the NonPublic platform (the “<strong>Website</strong>”) and the services
                    and
                    content provided therein (such services, the “<strong>Services</strong>”, and such content, the
                    “<strong>Content</strong>”).
                </p>
                <p>
                    In this Privacy Policy, unless the context otherwise requires, "personal data" or “personal information”
                    means (a) data, whether true or not, about an individual who can be identified (i) from that data; or
                    (ii)
                    from that data and other information to which we have or are likely to have access, and (b) any other
                    similar definition under any applicable personal data protection laws.
                </p>
                <p>
                    This Privacy Policy applies generally to all users, regardless of location. However, specific sections
                    herein or any supplements to this Privacy Policy may address additional requirements for users in
                    certain jurisdictions.
                </p>
                <p>
                    This Privacy Policy may be supplemented or amended at our sole discretion from time to time. We
                    encourage you to periodically review this Privacy Policy to be informed about how we are protecting
                    your personal information.
                </p>
                <p>
                    By using the Website and the Services, you are agreeing to the collection and use of your personal
                    information as described in this Privacy Policy.
                </p>

                <h3>1. Aggregate Data</h3>
                <p>
                    We generally record certain usage information, such as the number and frequency of visitors to this
                    Website. This information may include the websites that you access immediately before and after your
                    visit to our site, the internet browser you are using, and your IP address. If we use such data at all, it
                    will be on an aggregate basis, and we will not disclose to third parties any usage information that could
                    be used to identify you personally.
                </p>

                <h3>2. Cookies</h3>
                <p>
                    The Website may utilise automated tracking devices or software such as “cookies” to distinguish you
                    from other users of the Website and to help us to provide you with better user experience. By continuing
                    to use the Website and/or the Services, you consent to our use of cookies.
                </p>
                <p>
                    Cookies may be erased when you exit the Website or close the browser. Others are saved on your
                    device for your next visit. You can delete all cookies placed by our Website on your device at any time.
                    You can also set your browser to prevent all cookies from being placed by our Website or to provide
                    you with a warning before a cookie is placed. However, please note that some functionalities of the
                    Website may not work if all cookies are rejected. Please check your browser’s instructions or help
                    screen to learn more about these functions.
                </p>
                <p>
                    Please note that third parties (including, for example, advertising networks and providers of external
                    services like web traffic analysis services) may also use cookies, which we do not have any control
                    over. These cookies are likely to be analytical/performance cookies or targeting cookies.
                </p>

                <h3>3. Collection of Personal Information</h3>
                <p>
                    If you are a visitor, you do not have to submit any personal information in order to use this Website. We
                    only collect personal information that is specifically and voluntarily provided by you for the purpose
                    which such personal information is collected, for example, when you opt to subscribe to our newsletter,
                    register as a user on the Website or register for access to specific content, or request communications
                    about specific areas of interest. Such information collected may consist of, but is not limited to, your
                    name, current job title, company, email address and telephone number. Do not provide personal
                    information to us, unless you consent to our use of that information for our legitimate business purposes.
                </p>
                <p>
                    For users of the Website and/or the Services, we may request and collect additional personal
                    information from you, such as details on your background and business, (a) in connection with our KYC
                    processes, in compliance with applicable laws and legal obligations, particularly in relation to AML/CTF,
                    (b) to facilitate or carry out the activities contemplated by or ancillary to the Services, and/or (c) in
                    connection with any other incidental business purposes related to or in connection with the foregoing.
                </p>
                <p>
                    Generally, we may also collect, store and maintain any personal information that you provide to us
                    through the following ways:
                </p>
                <ol type="a">
                    <li>
                        through application or subscription forms in relation to your participation in any activity conducted
                        by us, such as collaborative, joint venture and project opportunities;
                    </li>
                    <li>
                        by email or other written mechanisms;
                    </li>
                    <li>
                        over a telephone call;
                    </li>
                    <li>
                        in person;
                    </li>
                    <li>
                        through transactions;
                    </li>
                    <li>
                        by technology that is used to support communications between us; and
                    </li>
                    <li>
                        through publicly available information sources (which may include telephone directories, the
                        internet and social media sites).
                    </li>
                </ol>

                <h3>4. Information from Third Parties</h3>
                <p>
                    In addition to the information we collect directly from you, we may also receive personal data and
                    information about you from third parties. This can include information from:
                </p>
                <ol type="a">
                    <li>
                        members of the NonPublic Network, such as special purpose vehicles administered, managed,
                        controlled or operated by the NonPublic Network which you may have previously made an
                        investment in. Personal information and data which we receive pursuant hereto will be used to
                        collate and consolidate, for example, your investment history, to facilitate the Services and
                        enhance your user experience;
                    </li>
                    <li>
                        our business partners and service providers, such as payment processors, corporate services
                        providers, analytics providers, and advertising partners, who may share information with us to
                        facilitate our services, improve our platform, or enhance your user experience;
                    </li>
                    <li>
                        social media or other third-party platforms. For ease, we offer you the option to sign in or create
                        an account on the Website using your social media or other third-party account credentials, such
                        as Apple-ID or Google Sign-In. if you choose to link your social media account(s) or sign in using
                        such social media or other third-party account credentials, we may receive information from the respective social media/third-party platform. This may include your profile information, user ID,
                        and friends list, depending on your settings and permissions;
                    </li>
                    <li>
                        publicly available sources – we may also collect information about you that is publicly available,
                        such as information from news articles, public databases, or other online resources; and
                    </li>
                    <li>
                        other users – we may collect information about you from other users of our Website and the
                        Services, for example, if you are engaged in any dealings via the Website or if such user shares
                        content with you.
                    </li>
                </ol>
                <p>
                    We take steps to ensure that any information received from third parties is treated with the same level
                    of care and security as the information we collect directly from you, in accordance with applicable law.
                </p>

                <h3>5. Use of Personal Information</h3>
                <p>
                    Information we receive, whether from you directly or from a third party, may be used as follows:
                </p>
                <ol type="a">
                    <li>
                        performing obligations in the course of or in connection with our dealings with you;
                    </li>
                    <li>
                        verifying your identity;
                    </li>
                    <li>
                        facilitating transactions, dealings and activities contemplated by or ancillary to the Services;
                    </li>
                    <li>
                        responding to, handling, and processing queries, requests, applications, and feedback from you;
                    </li>
                    <li>
                        managing your relationship with us;
                    </li>
                    <li>
                        marketing purposes, or to send you materials or communications relating to NonPublic that we
                        feel
                        may be of interest to you;
                    </li>
                    <li>
                        complying with any applicable laws, regulations, codes of practice, guidelines, or rules, or to
                        assist in law enforcement and investigations conducted by any governmental and/or regulatory
                        authority;
                    </li>
                    <li>
                        any other purposes for which you have provided the information and consented to the use thereof;
                        and
                    </li>
                    <li>
                        any other incidental business purposes related to or in connection with the above.
                    </li>
                </ol>
                <p>
                    The purposes listed in the above paragraphs may continue to apply even in situations where your
                    relationship with us has been terminated or altered in any way, for a reasonable period thereafter.
                </p>

                <h3>6. Disclosure of Personal Information and Data Transfers</h3>
                <p>
                    We may disclose your personal information to third parties:
                </p>
                <ol type="a">
                    <li>
                        where such disclosure is required for performing obligations in the course of or in connection with
                        our business or dealings with you;
                    </li>
                    <li>
                        where such disclosure is required for facilitating or carrying out the Services and the transactions,
                        dealings and activities contemplated thereby or ancillary thereto. For example, if you participate
                        in an opportunity that is presented or listed on the Website as part of the Services, information
                        about you may be disclosed to, for example, the relevant parties for KYC purposes;
                    </li>
                    <li>
                        to third-party service providers, contractors, advisors, agents, partners, and other organisations
                        we have engaged in connection with our business; and/or
                    </li>
                    <li>
                        to comply with legal or regulatory obligations or requests.
                    </li>
                </ol>
                <p>
                    Additionally, we may provide your personal information to other entities within the NonPublic Network
                    for internal corporate compliance purposes, and/or in order to provide you with information that could
                    be of interest to you and conduct market or other research. Personal information may also be disclosed
                    to other entities within the NonPublic Network and other third parties (a) in order to respond to your requests or inquiries, (b) to share joint venture, project, collaborative and/or investment opportunities
                    and deal flow, or (c) in the course of engaging in activities that are customary in our industry.
                </p>
                <p>
                    All of these disclosures may involve the transfer of personal information across geographical borders
                    and to countries or regions without data protection rules similar to those in effect in your area of
                    residence.
                </p>
                <p>
                    We may also store your personal information in the cloud, in other types of networked or electronic
                    storage or in servers in a jurisdiction outside the country where the purpose is provided or where you
                    are based. These countries/regions may change from time to time, and, if practicable and as qualified
                    by the following sentence, we can provide a current list upon written request. As electronic or networked
                    storage can be accessed from various countries via an internet connection, it’s not always practicable
                    to know in which country your information may be held. Additionally, overseas organisations may be
                    required to disclose information we share with them under a foreign law. In those instances, we will not
                    be responsible for that disclosure.
                </p>
                <p>
                    Whenever we transfer personal information beyond the country/region of origin, we will do so in
                    accordance with applicable laws, and will use reasonably appropriate safeguards for such transfer.
                </p>
                <p>
                    By providing personal information on our Website or through any other channel managed by us, you
                    consent to such transfer and/or storage of personal information across borders, in accordance with the
                    purposes described herein and applicable law.
                </p>

                <h3>7. Retention of Personal Information</h3>
                <p>
                    We will retain your personal information for as long as the information is reasonably required to fulfil the
                    purpose collection of the information or until you request that we delete that information.
                </p>

                <h3>8. Information Security</h3>
                <p>
                    We have in place reasonable commercial standards of technology and operational security for an
                    organisation of our size to protect all information provided by visitors and users via this Website from
                    unauthorised access, disclosure, alteration, or destruction.
                </p>
                <p>
                    In the event that there is a security breach involving your personal data, we will inform you of the incident
                    in accordance with the relevant data protection laws.
                </p>

                <h3>9. Access or Updates to Personal Information</h3>
                <p>
                    If you would like to request access to your personal information, to update your details, or
                    unsubscribe from communications, please contact us at <a
                    href="mailto:info@nonpublic.io">info@nonpublic.io</a>. In all cases, we will treat requests to
                    access information or change information in accordance with applicable legal requirements.
                </p>

                <h3>10. Questions, Restricting Disclosure, Withdrawal of Consent or Complaints</h3>
                <p>
                    If you have any questions regarding this Privacy Policy, or if you would like to: (a) restrict or
                    object to any processing or disclosure of your personal information, (b) withdraw your consent
                    (where we rely on consent as the legal basis for processing at any time), or (c) make a complaint,
                    please contact our Data Protection Officer at <a
                    href="mailto:info@nonpublic.io">info@nonpublic.io</a>.
                </p>
            </div>
        </LegalWrapper>
    )
}

export default PrivacyStatement