import React, {FunctionComponent} from "react"
import {DashboardType, InvestorDashboardInvestmentType} from "../../../dashboard.type"
import DonutChart from "../../../../../../tech/chart/donut/donut.component"
import DashboardInfo from "../../../info/info.component"
import {DealGeographyType} from "../../../../../deal-geography/deal-geography.type"
import {assembleData, groupAndSum} from "../distribution.util"
import styles from "./geolocation.module.sass"
import {InvestmentType} from "../../../../../investment/investment.type"

type InvestorDashboardSummaryDistributionGeolocationProps = {
    dashboard: DashboardType
}

const InvestorDashboardSummaryDistributionGeolocation: FunctionComponent<InvestorDashboardSummaryDistributionGeolocationProps> = ({
    dashboard
}) => {
    const geographiesWithSum = groupAndSum<DealGeographyType>(
        dashboard.investments,
        (investment: InvestorDashboardInvestmentType) => investment.investment.investment.type === "REGULAR"
            && investment.investment.investment.value.deal.geography !== undefined,
        (investment: InvestorDashboardInvestmentType) => (investment.investment.investment.value as InvestmentType).deal.geography!,
        (investment: InvestorDashboardInvestmentType) => (investment.investment.investment.value as InvestmentType).deal.geography!.id!
    )
    const data = assembleData(
        geographiesWithSum,
        (identifier: DealGeographyType) => identifier.name
    )

    return (
        <div className={styles.geolocation}>
            <div className={styles.titleDesktop}>
                Geolocation
            </div>
            <DonutChart
                data={data}
                maxWidth={240}
            />
            {dashboard.summary.performance.strongest.geolocation && (
                <DashboardInfo
                    text={`Strongest performance in ${dashboard.summary.performance.strongest.geolocation.name}`}
                    noBackgroundMobile={false}
                    tooltip={{
                        content: (
                            <>
                                In terms of geolocation your portfolio performs best in <strong>{dashboard.summary.performance.strongest.geolocation.name}</strong> based on IRR.
                            </>
                        )
                    }}
                />
            )}
        </div>
    )
}

export default InvestorDashboardSummaryDistributionGeolocation