import {InvestmentDashboardDesktopSort} from "./sort.type"
import {InvestorDashboardInvestmentType} from "../../../../dashboard.type"
import {compareDates, compareNumbers, compareStrings} from "../../../../../../../tech/sort/sort.util"

export function compare(sort: InvestmentDashboardDesktopSort): (
    a: InvestorDashboardInvestmentType,
    b: InvestorDashboardInvestmentType
) => number {
    switch (sort.type) {
        case "COMPANY":
            return (
                a: InvestorDashboardInvestmentType,
                b: InvestorDashboardInvestmentType
            ) => compareStrings(a.investment.getName(), b.investment.getName(), sort.direction)
        case "CURRENT_VALUE":
            return (
                a: InvestorDashboardInvestmentType,
                b: InvestorDashboardInvestmentType
            ) => compareNumbers(a.currentValue, b.currentValue, sort.direction)
        case "DEAL_STATUS":
            return (
                a: InvestorDashboardInvestmentType,
                b: InvestorDashboardInvestmentType
            ) => compareStrings(
                a.investment.getExitString(),
                b.investment.getExitString(),
                sort.direction
            )
        case "INVESTED":
            return (
                a: InvestorDashboardInvestmentType,
                b: InvestorDashboardInvestmentType
            ) => compareNumbers(a.amount, b.amount, sort.direction)
        case "INVESTMENT_DATE":
            return (
                a: InvestorDashboardInvestmentType,
                b: InvestorDashboardInvestmentType
            ) => compareDates(a.investment.getInvestmentDate(), b.investment.getInvestmentDate(), sort.direction)
        case "IRR":
            return (
                a: InvestorDashboardInvestmentType,
                b: InvestorDashboardInvestmentType
            ) => compareNumbers(a.irr, b.irr, sort.direction)
        case "MOIC":
            return (
                a: InvestorDashboardInvestmentType,
                b: InvestorDashboardInvestmentType
            ) => compareNumbers(a.moic, b.moic, sort.direction)
    }
}