import React, {FunctionComponent, ReactNode, useState} from "react"
import FileUploadMobile from "./mobile/mobile.component"
import {FileType} from "../file.type"
import FileUploadDesktop from "./desktop/desktop.component"
import {FileUploadStateType} from "./state.type"
import PlusIcon from "../../../tech/icon/plus.component"
import styles from "./upload.module.sass"
import LoadingCircle from "../../../tech/loading/circle/circle.component"
import TrashIcon from "../../../tech/icon/trash.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import CheckCircleIcon from "../../../tech/icon/check-circle.component"
import {useApp} from "../../../tech/app/context/app.context"

type FileUploadProps = {
    label: string
    description: string
    fileIdentifier: string
    updateFile: (file?: FileType) => void
    alert?: ReactNode
    check?: boolean
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
    label,
    description,
    fileIdentifier,
    updateFile,
    alert,
    check
}) => {
    const app = useApp()
    const [opened, setOpened] = useState<boolean>(false)
    const [uploadState, setUploadState] = useState<FileUploadStateType>("NONE")

    const uploadFile = async (file: File) => {
        try {
            setUploadState("UPLOADING")
            const uploadedFile = await app.fetchClient.fileApi.upload(file, fileIdentifier)
            updateFile(uploadedFile.file!)
            setUploadState("SUCCESS")
            setOpened(false)
        }
        catch (err) {
            console.warn(err)
            setOpened(false)
            setUploadState("ERROR")
        }
    }

    const removeFile = () => {
        setUploadState("NONE")
        updateFile(undefined)
    }

    return (
        <>
            <div
                className={styles.fileUpload}
                onClick={() => (uploadState === "NONE" || uploadState === "ERROR") && setOpened(true)}
            >
                <div className={styles.textAndIcon}>
                    <div className={styles.text}>
                        <div className={styles.label}>
                            {label}
                        </div>
                        <div className={styles.description}>
                            {description}
                        </div>
                    </div>
                    <div className={styles.icon}>
                        <div>
                            {check ? (
                                <CheckCircleIcon
                                    width={32}
                                    height={32}
                                    fillClass="fill-icon-success"
                                />
                            ) : (uploadState === "NONE" || uploadState === "ERROR") ? (
                                <PlusIcon
                                    width={32}
                                    height={32}
                                    fillClass="fill-icon-theme"
                                />
                            ) : uploadState === "UPLOADING" ? (
                                <LoadingCircle
                                    width={38}
                                    height={38}
                                />
                            ) : (
                                <div
                                    className={styles.trash}
                                    onClick={removeFile}
                                >
                                    <TrashIcon
                                        width={32}
                                        height={32}
                                        fillClass="fill-icon-error"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {uploadState === "ERROR" && (
                    <div className={styles.alert}>
                        <Alert
                            type={AlertType.ERROR}
                            text="Failed to upload file."
                        />
                    </div>
                )}
                {uploadState !== "ERROR" && alert && (
                    <>{alert}</>
                )}
            </div>

            {opened && (
                <>
                    {window.innerWidth >= 1024 ? (
                        <FileUploadDesktop
                            label={label}
                            description={description}
                            close={() => setOpened(false)}
                            uploadState={uploadState}
                            uploadFile={uploadFile}
                        />
                    ) : (
                        <FileUploadMobile
                            close={() => setOpened(false)}
                            uploadFile={uploadFile}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default FileUpload