import React, {FunctionComponent} from "react"
import styles from "./success.module.sass"
import CheckCircleIcon from "../../../tech/icon/check-circle.component"

const WaitlistSuccess: FunctionComponent = () => {
    return (
        <div>
            <div className={styles.icon}>
                <CheckCircleIcon
                    fillClass="fill-icon-success"
                    width={64}
                    height={64}
                />
            </div>
            <div className={styles.title}>
                Thank you for your interest!
            </div>
            <div className={styles.text}>
                NonPublic is referral only. We will notify you as soon as you can access the platform.
            </div>
        </div>
    )
}

export default WaitlistSuccess