import {InvestmentOpenApi} from "../../generated"
import {InvestmentType} from "./investment.type"
import {mapOpenApiToDeal} from "../deal/deal.mapper"
import {mapOpenApiToDocument} from "../document/document.mapper"
import {mapOpenApiToInvestor} from "../investor/investor.mapper"
import {mapOpenApiToInvestmentFees} from "./fee/fees.mapper"
import {mapOpenApiToExchangeRate} from "../exchange-rate/exchange-rate.mapper"

export function mapOpenApiToInvestment(investment: InvestmentOpenApi): InvestmentType {
    return new InvestmentType({
        id: investment.id,
        created: investment.created,
        modified: investment.modified,
        amount: investment.amount,
        amountWithPlacementFee: investment.amountWithPlacementFee,
        amountWithPlacementFeeAndExchangeFee: investment.amountWithPlacementFeeAndExchangeFee,
        amountWithPlacementFeeAndExchangeFeeAtExchangeRate: investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRate,
        currency: investment.currency,
        agreed: investment.agreed,
        signed: investment.signed,
        paid: investment.paid,
        deal: mapOpenApiToDeal(investment.deal),
        documents: investment.documents.map(d => mapOpenApiToDocument(d)),
        investor: mapOpenApiToInvestor(investment.investor),
        fees: mapOpenApiToInvestmentFees(investment.fees),
        exchangeRate: investment.exchangeRate && mapOpenApiToExchangeRate(investment.exchangeRate)
    })
}