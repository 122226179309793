import React, {FunctionComponent, useEffect, useRef, useState} from "react"
import Imgix from "react-imgix"
import Alert from "../alert/alert.component"
import {AlertType} from "../alert/type.enum"
import ArrowLeftIcon from "../icon/arrow-left.component"
import ArrowRightIcon from "../icon/arrow-right.component"
import DownloadIcon from "../icon/download.component"
import ArrowsInIcon from "../icon/arrows-in.component"
import ArrowsOutIcon from "../icon/arrows-out.component"
import styles from "./pdf.module.sass"

type PdfInfo = {
    width: number
    height: number
    numberOfPages: number
}

type PdfViewerProps = {
    url: string
}

const PdfViewer: FunctionComponent<PdfViewerProps> = ({ url }) => {
    const widthRef = useRef<HTMLDivElement>(null)
    const [pdfInfo, setPdfInfo] = useState<PdfInfo>()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [width, setWidth] = useState<number>()
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [fullPage, setFullPage] = useState<boolean>(false)

    useEffect(() => {
        evaluateWidth()
        window.addEventListener("resize", evaluateWidth)
        return () => window.removeEventListener("resize", evaluateWidth)
    }, [])

    useEffect(() => {
        fetch(`${url}?fm=json`)
            .then(res => {
                if (!res.ok) {
                    setState("ERROR")
                }
                else {
                    return res.json()
                }
            })
            .then(json => {
                setPdfInfo({
                    width: json["PixelWidth"],
                    height: json["PixelHeight"],
                    numberOfPages: json["PDF"]["PageCount"]
                })
                setState("LOADED")
            })
            .catch(() => setState("ERROR"))
    }, [url])

    function evaluateWidth() {
        if (widthRef.current) {
            setWidth(widthRef.current.getBoundingClientRect().width)
        }
    }

    return (
        <>
            <div ref={widthRef}/>
            {state === "LOADING" && <>Loading PDF...</>}
            {state === "LOADED" && pdfInfo && width && (
                <div
                    className={`${styles.slideDeck} ${fullPage ? styles.fullPage : ""}`}
                    style={{
                        width: width,
                        height: (width / pdfInfo.width) * pdfInfo.height
                    }}
                >
                    <div className={styles.navigation}>
                        <div>
                            <div>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={() => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1)}
                                >
                                    <ArrowLeftIcon
                                        width={16}
                                        height={16}
                                        fillClass="fill-icon-theme"
                                    />
                                </button>
                            </div>
                            <div>
                                <div className={styles.pages}>
                                    {pageNumber}/{pdfInfo.numberOfPages}
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    disabled={pageNumber >= pdfInfo.numberOfPages}
                                    onClick={() => setPageNumber(pageNumber + 1 >= pdfInfo!.numberOfPages ? pdfInfo!.numberOfPages : pageNumber + 1)}
                                >
                                    <ArrowRightIcon
                                        width={16}
                                        height={16}
                                        fillClass="fill-icon-theme"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <button
                            type="button"
                            disabled={pageNumber >= pdfInfo.numberOfPages}
                            onClick={() => window.open(`${url}?fm=pdf`, "_blank")}
                        >
                            <DownloadIcon
                                width={16}
                                height={16}
                                fillClass="fill-icon-theme"
                            />
                        </button>
                        <button
                            type="button"
                            disabled={pageNumber >= pdfInfo.numberOfPages}
                            onClick={() => setFullPage(!fullPage)}
                        >
                            {fullPage ? (
                                <ArrowsInIcon
                                    width={16}
                                    height={16}
                                    fillClass="fill-icon-theme"
                                />
                            ) : (
                                <ArrowsOutIcon
                                    width={16}
                                    height={16}
                                    fillClass="fill-icon-theme"
                                />
                            )}
                        </button>
                    </div>
                    {[...Array(pdfInfo.numberOfPages)].map((_, i) => i + 1).map(p => (
                        <div
                            className={`${styles.page} ${pageNumber === p ? styles.active : ""}`}
                            key={`slide-deck-page-${p}`}
                        >
                            <Imgix
                                src={`${url}?page=${p}`}
                                width={fullPage ? window.innerWidth : 1000}
                            />
                        </div>
                    ))}
                </div>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load."
                />
            )}
        </>
    )
}

export default PdfViewer