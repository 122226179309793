import {
    CategoryFilterType,
    FilterType,
    GeographyFilterType,
    InvestmentTypeFilterType,
    StageFilterType,
    StatusFilterType,
    WithInvestmentFilterType
} from "./filter.type"

export function isSelected(filterType: FilterType, currentFilterTypes: FilterType[]): boolean {
    if (filterType.type === "CATEGORY") {
        return currentFilterTypes
            .filter(t => t.type === filterType.type)
            .map(f => f as CategoryFilterType)
            .some(f => f.category.id === filterType.category.id)
    }
    if (filterType.type === "GEOGRAPHY") {
        return currentFilterTypes
            .filter(t => t.type === filterType.type)
            .map(f => f as GeographyFilterType)
            .some(f => f.geography.id === filterType.geography.id)
    }
    if (filterType.type === "INVESTMENT_TYPE") {
        return currentFilterTypes
            .filter(t => t.type === filterType.type)
            .map(f => f as InvestmentTypeFilterType)
            .some(f => f.investmentType === filterType.investmentType)
    }
    if (filterType.type === "STAGE") {
        return currentFilterTypes
            .filter(t => t.type === filterType.type)
            .map(f => f as StageFilterType)
            .some(f => f.stage === filterType.stage)
    }
    if (filterType.type === "STATUS") {
        return currentFilterTypes
            .filter(t => t.type === filterType.type)
            .map(f => f as StatusFilterType)
            .some(f => f.status === filterType.status)
    }
    if (filterType.type === "WITH_INVESTMENT") {
        return currentFilterTypes
            .filter(t => t.type === filterType.type)
            .map(f => f as WithInvestmentFilterType)
            .some(f => f.option === filterType.option)
    }
    return false
}

export function getIndexOf(filterType: FilterType, currentFilterTypes: FilterType[]): number {
    if (filterType.type === "CATEGORY") {
        return currentFilterTypes.findIndex(f => f.type === filterType.type && f.category.id === filterType.category.id)
    }
    if (filterType.type === "GEOGRAPHY") {
        return currentFilterTypes.findIndex(f => f.type === filterType.type && f.geography.id === filterType.geography.id)
    }
    if (filterType.type === "INVESTMENT_TYPE") {
        return currentFilterTypes.findIndex(f => f.type === filterType.type && f.investmentType === filterType.investmentType)
    }
    if (filterType.type === "STAGE") {
        return currentFilterTypes.findIndex(f => f.type === filterType.type && f.stage === filterType.stage)
    }
    if (filterType.type === "STATUS") {
        return currentFilterTypes.findIndex(f => f.type === filterType.type && f.status === filterType.status)
    }
    if (filterType.type === "WITH_INVESTMENT") {
        return currentFilterTypes.findIndex(f => f.type === filterType.type && f.option === filterType.option)
    }
    return currentFilterTypes.length
}