import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const UploadIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M30 17v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h6a1 1 0 0 1 1 1 1 1 0 0 1-1 1H4v8h24v-8h-6a1 1 0 0 1-1-1 1 1 0 0 1 1-1h6a2 2 0 0 1 2 2zM10.708 9.707L15 5.414V16a1 1 0 0 0 1 1 1 1 0 0 0 1-1V5.414l4.293 4.294a1 1 0 0 0 1.415 0 1 1 0 0 0 0-1.415l-6-6A1 1 0 0 0 16 1.999a1 1 0 0 0-.707.293l-6 6a1 1 0 0 0 0 1.415 1 1 0 0 0 1.415 0zM25 21a1.5 1.5 0 0 0-.926-1.386 1.5 1.5 0 0 0-1.635.325 1.5 1.5 0 0 0-.325 1.635 1.5 1.5 0 0 0 1.386.926A1.5 1.5 0 0 0 25 21z"
            />
        </svg>
    )
}

export default UploadIcon