import React, {FunctionComponent} from "react"
import InvestorDashboardTile from "../../tile/tile.component"
import InvestorDashboardSummaryOverviewTile from "./tile/tile.component"
import {DashboardType} from "../../dashboard.type"
import {formatAmountWithCurrency} from "../../../../../tech/format/amount.util"
import {formatPercent} from "../../../../../tech/format/percent.util"
import {formatMultiple} from "../../../../../tech/format/multiple.util"
import styles from "./overview.module.sass"
import {InvestorOnboardingType} from "../../../onboarding/onboarding.type"
import {hasAnySubscription} from "../../../../subscription/subscription.util"
import CurrentValueTooltip from "./tooltip/current-value/current-value.component"
import IrrTooltip from "./tooltip/irr/irr.component"
import MoicTooltip from "./tooltip/moic/moic.component"

type InvestorDashboardSummaryOverviewProps = {
    dashboard: DashboardType
    onboarding: InvestorOnboardingType
    withBox?: boolean
    withIrrAndMoic?: boolean
    withTitle?: boolean
}

const InvestorDashboardSummaryOverview: FunctionComponent<InvestorDashboardSummaryOverviewProps> = ({
    dashboard,
    onboarding,
    withBox,
    withIrrAndMoic = true,
    withTitle = false
}) => {
    const inner = (
        <>
            {withTitle && (
                <div className={styles.title}>
                    Summary
                </div>
            )}
            <div className={`${styles.summary} ${hasAnySubscription(onboarding) ? styles.withSubscription : ""}`}>
                <InvestorDashboardSummaryOverviewTile
                    title="Invested Amount"
                    text={formatAmountWithCurrency(
                        dashboard.summary.amount,
                        dashboard.currency,
                        0
                    )}
                    whiteBackground={!withBox}
                />
                <InvestorDashboardSummaryOverviewTile
                    title="Current Value"
                    text={formatAmountWithCurrency(
                        dashboard.summary.currentValue,
                        dashboard.currency,
                        0
                    )}
                    whiteBackground={!withBox}
                    tooltip={{
                        content: <CurrentValueTooltip/>,
                        shiftLeft: -100
                    }}
                />
                {hasAnySubscription(onboarding) && withIrrAndMoic && (
                    <>
                        <InvestorDashboardSummaryOverviewTile
                            title="Total IRR"
                            text={formatPercent(dashboard.summary.irr)}
                            whiteBackground={!withBox}
                            tooltip={{
                                content: <IrrTooltip/>,
                            }}
                        />
                        <InvestorDashboardSummaryOverviewTile
                            title="Total MOIC"
                            text={formatMultiple(dashboard.summary.moic)}
                            whiteBackground={!withBox}
                            tooltip={{
                                content: <MoicTooltip/>,
                                shiftLeft: -100
                            }}
                        />
                    </>
                )}
            </div>
        </>
    )

    return withBox ? (
        <InvestorDashboardTile title="Summary">
            {inner}
        </InvestorDashboardTile>
    ) : inner
}

export default InvestorDashboardSummaryOverview