import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const TriangleDownIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 12 9"
        >
            <path
                className={fillClass}
                d="M6 9l5.196-9H.804L6 9z"
            />
        </svg>
    )
}

export default TriangleDownIcon