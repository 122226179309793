import React, {FunctionComponent} from "react"
import FileUploadMobileUpload from "./upload/upload.component"

type FileUploadMobileProps = {
    close: () => void
    uploadFile: (file: File) => void
}

const FileUploadMobile: FunctionComponent<FileUploadMobileProps> = ({ close, uploadFile }) => {
    const uploadFileInternal = (file: File) => {
        close()
        uploadFile(file)
    }

    return (
        <FileUploadMobileUpload
            close={close}
            uploadFile={uploadFileInternal}
        />
    )
}

export default FileUploadMobile