import {LineChartLineType, LineChartLineWithColorType} from "./line.type"
import {compareDates} from "../../sort/sort.util"

export function addColorsAndSort(lines: LineChartLineType[]): LineChartLineWithColorType[] {
    return lines.map((line, index) => {
        const numColors = colorRange.length
        return {
            ...line,
            color: colorRange[index % numColors],
            points: line.points.sort((a, b) => compareDates(a.date, b.date, "ASCENDING"))
        }
    })
}

const colorRange = [
    "#2F9E44",
    "#B2F2BB",
    "#22B8CF",
]