import React, {FunctionComponent} from "react"
import {InvestorDashboardInvestmentType} from "../../../dashboard.type"
import {useNavigate} from "react-router-dom"
import {CurrencyOpenApi} from "../../../../../../generated"
import {formatAmountWithCurrency} from "../../../../../../tech/format/amount.util"
import {formatDate} from "../../../../../../tech/format/datetime.util"
import ColoredCurrentValue from "../../current-value/current-value.component"
import CaretRightIcon from "../../../../../../tech/icon/caret-right.component"
import {formatPercent} from "../../../../../../tech/format/percent.util"
import {formatMultiple} from "../../../../../../tech/format/multiple.util"
import {calculateNetValueChangePercentage} from "../../percentage/percentage.util"
import Percentage from "../../percentage/percentage.component"
import Triangle from "../../triangle/triangle.component"
import InvestorDashboardInvestmentsInvestedOriginal from "../../invested/invested.component"
import {InvestorOnboardingType} from "../../../../onboarding/onboarding.type"
import {hasAnySubscription} from "../../../../../subscription/subscription.util"
import InvestorDashboardInvestmentsStatus from "../../status/status.component"
import InvestorDashboardInvestmentLogo from "../../logo/logo.component"
import styles from "./row.module.sass"
import {IndividualInvestmentPopupStateType} from "../../../../../investment/individual/popup/popup.type"
import {navigateOnRowClick} from "./row.util"

type InvestorDashboardInvestmentsDesktopRowProps = {
    investment: InvestorDashboardInvestmentType
    targetCurrency: CurrencyOpenApi
    onboarding: InvestorOnboardingType
    updateIndividualInvestmentPopup: (state: IndividualInvestmentPopupStateType) => void
}

const InvestorDashboardInvestmentsDesktopRow: FunctionComponent<InvestorDashboardInvestmentsDesktopRowProps> = ({
    investment,
    targetCurrency,
    onboarding,
    updateIndividualInvestmentPopup
}) => {
    const navigate = useNavigate()
    return (
        <div
            className={styles.row}
            onClick={() => navigateOnRowClick(investment, navigate, updateIndividualInvestmentPopup)}
        >
            <InvestorDashboardInvestmentLogo investment={investment.investment}/>
            <InvestorDashboardInvestmentsStatus investment={investment.investment}/>
            <div className={styles.invested}>
                {formatAmountWithCurrency(investment.amount, targetCurrency, 2, true)}
                <InvestorDashboardInvestmentsInvestedOriginal
                    investment={investment}
                    targetCurrency={targetCurrency}
                />
            </div>
            <div>
                {formatDate(investment.investment.getInvestmentDate())}
            </div>
            <div>
                <div className={styles.netValue}>
                    <ColoredCurrentValue
                        investment={investment}
                        targetCurrency={targetCurrency}
                    />
                    <Percentage value={calculateNetValueChangePercentage(investment)}/>
                </div>
            </div>
            {hasAnySubscription(onboarding) && (
                <>
                    <div>
                        <div className={styles.irr}>
                            <div>
                                {formatPercent(investment.irr, 1, 0)}
                            </div>
                            <div>
                                <Triangle
                                    type="percentage"
                                    value={investment.irr}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.moic}>
                            <div>
                                {formatMultiple(investment.moic, 1, 0)}
                            </div>
                            <div>
                                <Triangle
                                    type="multiple"
                                    value={investment.moic}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className={styles.caret}>
                <CaretRightIcon
                    width={16}
                    height={16}
                    fillClass="fill-icon-theme"
                />
            </div>
        </div>
    )
}

export default InvestorDashboardInvestmentsDesktopRow