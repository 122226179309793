import {matchPath} from "react-router-dom"
import type {Location} from "@remix-run/router"
import {BreadcrumbElement} from "../../../tech/breadcrumb/breadcrumb.component"
import {DEAL, DEALS, PENDING_INVESTMENTS} from "../../../paths"
import {DealOnboardingDependentWithInvestmentType} from "../onboarding-dependent.type"

export function assembleSingleDealBreadcrumb(
    dealWithInvestment: DealOnboardingDependentWithInvestmentType,
    location: Location
): BreadcrumbElement[] {
    return [
        assembleRootElement(location),
        { text: dealWithInvestment.deal.name, to: DEAL(dealWithInvestment.deal.id!) },
    ]
}

function assembleRootElement(location: Location): BreadcrumbElement {
    const previousLocation = location
        && location.state
        && location.state["previousLocationPathname"]
    return previousLocation && matchPath({ path: PENDING_INVESTMENTS }, previousLocation) !== null
        ? { text: "Pending Investments", to: PENDING_INVESTMENTS }
        : { text: "Deals", to: DEALS }
}