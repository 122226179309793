/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactOpenApi } from './ContactOpenApi';
import {
    ContactOpenApiFromJSON,
    ContactOpenApiFromJSONTyped,
    ContactOpenApiToJSON,
} from './ContactOpenApi';

/**
 * 
 * @export
 * @interface WaitlistEntryOpenApi
 */
export interface WaitlistEntryOpenApi {
    /**
     * 
     * @type {string}
     * @memberof WaitlistEntryOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof WaitlistEntryOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof WaitlistEntryOpenApi
     */
    confirmed: boolean;
    /**
     * 
     * @type {string}
     * @memberof WaitlistEntryOpenApi
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof WaitlistEntryOpenApi
     */
    lastName: string;
    /**
     * 
     * @type {ContactOpenApi}
     * @memberof WaitlistEntryOpenApi
     */
    contact: ContactOpenApi;
    /**
     * 
     * @type {boolean}
     * @memberof WaitlistEntryOpenApi
     */
    aiStatusDeclared: boolean;
    /**
     * 
     * @type {string}
     * @memberof WaitlistEntryOpenApi
     */
    referralCode?: string;
}

/**
 * Check if a given object implements the WaitlistEntryOpenApi interface.
 */
export function instanceOfWaitlistEntryOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "confirmed" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "aiStatusDeclared" in value;

    return isInstance;
}

export function WaitlistEntryOpenApiFromJSON(json: any): WaitlistEntryOpenApi {
    return WaitlistEntryOpenApiFromJSONTyped(json, false);
}

export function WaitlistEntryOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaitlistEntryOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'confirmed': json['confirmed'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'contact': ContactOpenApiFromJSON(json['contact']),
        'aiStatusDeclared': json['aiStatusDeclared'],
        'referralCode': !exists(json, 'referralCode') ? undefined : json['referralCode'],
    };
}

export function WaitlistEntryOpenApiToJSON(value?: WaitlistEntryOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'confirmed': value.confirmed,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'contact': ContactOpenApiToJSON(value.contact),
        'aiStatusDeclared': value.aiStatusDeclared,
        'referralCode': value.referralCode,
    };
}

