import {InvestmentWithPaymentDetailsType} from "./investment-with-payment-details.type"
import {InvestmentWithPaymentDetailsOpenApi} from "../../generated"
import {mapOpenApiToInvestment} from "./investment.mapper"
import {mapOpenApiToPaymentDetails} from "./payment-details/payment-details.mapper"

export function mapOpenApiToInvestmentWithPaymentDetails(
    investmentWithPaymentDetails: InvestmentWithPaymentDetailsOpenApi
): InvestmentWithPaymentDetailsType {
    return {
        investment: mapOpenApiToInvestment(investmentWithPaymentDetails.investment),
        paymentDetails: investmentWithPaymentDetails.paymentDetails && mapOpenApiToPaymentDetails(investmentWithPaymentDetails.paymentDetails)
    }
}