import React, {FunctionComponent} from "react"
import {Control, FieldErrors, useFieldArray, UseFormRegister} from "react-hook-form"
import {IndividualInvestmentType} from "../../individual-investment.type"
import IndividualInvestmentFormValuation from "./valuation.component"
import PlusCircleIcon from "../../../../../tech/icon/plus-circle.component"
import styles from "./valuations.module.sass"

type IndividualInvestmentFormValuationsProps = {
    control: Control<IndividualInvestmentType, any>
    errors: FieldErrors<IndividualInvestmentType>
    register: UseFormRegister<IndividualInvestmentType>
}

const IndividualInvestmentFormValuations: FunctionComponent<IndividualInvestmentFormValuationsProps> = ({
    control,
    errors,
    register
}) => {
    const {fields, append, remove} = useFieldArray({name: "valuations", control})

    // @ts-ignore
    const add = () => append({})

    const del = (i: number) => remove(i)

    return (
        <div className={styles.valuations}>
            <div className={styles.head}>
                Valuations
            </div>
            {fields
                .sort((a, b) => sort(a.date, b.date))
                .map((valuation, index) => (
                <IndividualInvestmentFormValuation
                    control={control}
                    errors={errors}
                    register={register}
                    del={del}
                    index={index}
                    key={valuation.id}
                />
            ))}
            <div
                className={styles.add}
                onClick={add}
            >
                <div>
                    <PlusCircleIcon
                        fillClass="fill-icon-caption"
                        width={16}
                        height={16}
                    />
                </div>
                <div>
                    <div>Add valuation</div>
                </div>
            </div>
        </div>
    )
}

export default IndividualInvestmentFormValuations

function sort(a: Date | undefined, b: Date | undefined): number {
    if (a === undefined) return 1
    if (b === undefined) return -1
    if (a < b) return -1
    if (a > b) return 1
    return 0
}