import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const MinusCircleIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
            <path
                className={fillClass}
                d="M13.75 10c0 .166-.066.325-.183.442s-.276.183-.442.183h-6.25c-.166 0-.325-.066-.442-.183S6.25 10.166 6.25 10s.066-.325.183-.442.276-.183.442-.183h6.25c.166 0 .325.066.442.183s.183.276.183.442zm4.375 0c0 1.607-.477 3.178-1.369 4.514s-2.162 2.378-3.646 2.992-3.118.776-4.694.462-3.024-1.087-4.16-2.224-1.91-2.584-2.224-4.16-.153-3.21.462-4.694S4.15 4.137 5.486 3.244 8.393 1.875 10 1.875c2.154.002 4.22.859 5.743 2.382s2.38 3.589 2.382 5.743zm-1.25 0c0-1.36-.403-2.689-1.159-3.82s-1.829-2.012-3.085-2.532-2.639-.656-3.972-.391-2.559.92-3.52 1.882-1.616 2.186-1.882 3.52-.129 2.716.391 3.972 1.402 2.33 2.532 3.085 2.46 1.159 3.82 1.159c1.823-.002 3.57-.727 4.859-2.016s2.014-3.036 2.016-4.859z"
            />
        </svg>
    )
}

export default MinusCircleIcon