import {InvestmentType} from "../../investment.type"
import {InvestorOnboardingType} from "../../../investor/onboarding/onboarding.type"
import {InvestmentStage} from "./stage.type"
import {DealOutcomeOpenApi} from "../../../../generated"

export function evaluateStage(investment: InvestmentType, onboarding: InvestorOnboardingType): InvestmentStage {
    let stage: InvestmentStage
    if (!investment.signed) {
        stage = "SIGN_DOCUMENTS"
    }
    else if (investment.deal.waitingState === "WAITING_FOR_MINIMUM_AMOUNT") {
        stage = "WAIT_FOR_MINIMUM_AMOUNT"
    }
    else if (investment.deal.waitingState === "WAITING_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER") {
        stage = "WAIT_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER"
    }
    else if (investment.deal.waitingState === "WAITING_FOR_PAYMENTS") {
        if (investment.paid) {
            stage = "WAIT_FOR_CLOSING"
        }
        else {
            stage = "MAKE_PAYMENT"
        }
    }
    else if (investment.deal.outcome === DealOutcomeOpenApi.Finalized) {
        stage = "FINALIZED"
    }
    else {
        stage = "CLOSED"
    }
    return stage
}