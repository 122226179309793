import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const ArrowCounterClockwiseIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path
                className={fillClass}
                d="M21 12a9 9 0 0 1-8.879 9H12a8.94 8.94 0 0 1-6.178-2.456.75.75 0 0 1-.234-.524.75.75 0 0 1 .205-.537.75.75 0 0 1 .524-.235.75.75 0 0 1 .536.205 7.5 7.5 0 0 0 8.167 1.408 7.5 7.5 0 0 0 4.478-6.974 7.5 7.5 0 0 0-4.679-6.84 7.5 7.5 0 0 0-8.123 1.645c-.008.008-.016.016-.024.023L4.18 9h2.57a.75.75 0 0 1 .75.75.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 .75-.75.75.75 0 0 1 .75.75v2.794l2.648-2.419a9 9 0 0 1 9.803-1.935A9 9 0 0 1 21 12z"
            />
        </svg>
    )
}

export default ArrowCounterClockwiseIcon