import React, {FunctionComponent, ReactElement} from "react"
import {ButtonStyle} from "./style.enum"
import ArrowRightComponent from "../icon/arrow-right.component"
import {ButtonSize} from "./size.enum"
import {ButtonState} from "./state.enum"
import styles from "./button.module.sass"

type ButtonProps = {
    label: string
    type: "submit" | "button"
    size: ButtonSize
    state: ButtonState
    style: ButtonStyle
    icon?: ReactElement
    iconPosition?: "LEFT" | "RIGHT"
    withArrow?: boolean
    fullWidth?: boolean
    onClick?: (() => void) | ((e: React.MouseEvent<HTMLButtonElement>) => void)
    disabled?: boolean
    withWordWrap?: boolean
}

const Button: FunctionComponent<ButtonProps> = ({
    label,
    type,
    size,
    state,
    style,
    icon,
    iconPosition = "LEFT",
    withArrow = false,
    fullWidth = false,
    onClick,
    disabled = false,
    withWordWrap = false
}) => {
    return (
        <button
            type={type}
            className={determineClasses(size, state, style, fullWidth, iconPosition, disabled, withWordWrap)}
            onClick={onClick}
            disabled={state === ButtonState.DISABLED || disabled}
        >
            {icon && (
                <div className={styles.icon}>
                    <div>
                        {icon}
                    </div>
                </div>
            )}
            <div className={styles.label}>
                <div>
                    {label}
                </div>
            </div>
            {withArrow && (
                <div className={styles.arrow}>
                    <div>
                        <ArrowRightComponent
                            fillClass="fill-icon-white"
                            width={21}
                            height={21}
                        />
                    </div>
                </div>
            )}
        </button>
    )
}

export default Button

function determineClasses(
    size: ButtonSize,
    state: ButtonState,
    style: ButtonStyle,
    fullWidth: boolean,
    iconPosition: "LEFT" | "RIGHT",
    disabled: boolean,
    withWordWrap: boolean
): string {
    const classes = [ styles.button ]
    classes.push(determineSizeClass(size))
    classes.push(determineStateClass(state))
    classes.push(determineStyleClass(style))
    classes.push(determineWidthClass(fullWidth))
    classes.push(determineIconPositionClass(iconPosition))
    classes.push(determineDisabled(disabled))
    classes.push(determineWithWordWrap(withWordWrap))
    return classes.join(" ")
}

function determineSizeClass(size: ButtonSize): string {
    switch (size) {
        case ButtonSize.SMALL:
            return styles.small
        case ButtonSize.MEDIUM:
            return styles.medium
        case ButtonSize.LARGE:
            return styles.large
    }
}

function determineStateClass(state: ButtonState): string {
    switch (state) {
        case ButtonState.ACTIVE:
            return styles.active
        case ButtonState.DISABLED:
            return styles.disabled
        case ButtonState.INACTIVE:
            return styles.inactive
    }
}

function determineStyleClass(style: ButtonStyle): string {
    switch (style) {
        case ButtonStyle.PRIMARY:
            return styles.primary
        case ButtonStyle.SECONDARY:
            return styles.secondary
        case ButtonStyle.TERTIARY:
            return styles.tertiary
    }
}

function determineWidthClass(fullWidth: boolean): string {
    return fullWidth
        ? styles.full
        : styles.hug
}

function determineIconPositionClass(iconPosition: "LEFT" | "RIGHT"): string {
    return iconPosition === "RIGHT"
        ? styles.iconPositionRight
        : ""
}

function determineDisabled(disabled: boolean): string {
    return disabled
        ? styles.disabled
        : ""
}

function determineWithWordWrap(withWordWrap: boolean): string {
    return withWordWrap
        ? styles.withWordWrap
        : ""
}