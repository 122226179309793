import {QUERY_KEY__DEALS, QUERY_KEY_PARAMETERS__DEALS} from "./deal.query"
import {
    QUERY_KEY__INVESTOR_DASHBOARD,
    QUERY_KEY_PARAMETERS__INVESTOR_DASHBOARD
} from "../investor/dashboard/dashboard.query"
import {QueryKeyWithParameters} from "../../tech/query/query.type"
import {DEFAULT_TARGET_CURRENCY} from "../investor/dashboard/dashboard.config"

export const QUERY__DEAL_OVERVIEW__DEALS: QueryKeyWithParameters<QUERY_KEY_PARAMETERS__DEALS> = {
    keyFunction: QUERY_KEY__DEALS,
    parameters: {
        exclusivesOnly: false,
        favouritesOnly: false
    }
}

export const QUERY__DEAL_OVERVIEW__INVESTOR_DASHBOARD: QueryKeyWithParameters<QUERY_KEY_PARAMETERS__INVESTOR_DASHBOARD> = {
    keyFunction: QUERY_KEY__INVESTOR_DASHBOARD,
    parameters: {
        filterTypes: [],
        targetCurrency: DEFAULT_TARGET_CURRENCY
    }
}