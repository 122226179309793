import {ErrorContext, Middleware, ResponseContext} from "../../generated"
import {FetchClient} from "../fetch/client"

export class RequestErrorMiddleware implements Middleware {

    constructor(private fetchClient: FetchClient) {
    }

    async post(context: ResponseContext): Promise<Response | void> {
        if (context.response.status >= 300 && !this.isLoggingUrl(context.url)) {
            await this.sendError(
                undefined,
                context.url,
                context.response?.status
            )
        }
        return context.response
    }

    async onError(context: ErrorContext): Promise<Response | void> {
        if (!this.isLoggingUrl(context.url)) {
            await this.sendError(
                context.error as string,
                context.url,
                context.response?.status
            )
        }
        return context.response
    }

    private async sendError(
        error: string | undefined,
        requestUrl: string,
        responseStatus: number | undefined
    ): Promise<Response | void> {
        try {
            if (responseStatus !== 401 && responseStatus !== 404) {
                await this.fetchClient.loggingApi.sendRequestError(
                    new Date(),
                    error,
                    window.location.pathname,
                    requestUrl,
                    responseStatus
                )
            }
        }
        catch (err) {
            console.error("Error while sending error", err)
        }
    }

    private isLoggingUrl(url: string): boolean {
        return url.includes("/logging/")
    }

}