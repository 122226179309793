import React, {FunctionComponent} from "react"
import InvestmentTimeline from "../../../investment/timeline/timeline.component"
import {determineTimelineItems} from "../../../investment/timeline/timeline.util"
import {InvestorOnboardingType} from "../../../investor/onboarding/onboarding.type"
import styles from "./stages.module.sass"

type DealInvestmentInitializationStagesProps = {
    onboarding: InvestorOnboardingType
}

const DealInvestmentInitializationStages: FunctionComponent<DealInvestmentInitializationStagesProps> = ({
    onboarding
}) => {
    return (
        <div className={styles.stages}>
            <div className={styles.timeline}>
                <InvestmentTimeline items={determineTimelineItems(onboarding)}/>
            </div>
        </div>
    )
}

export default DealInvestmentInitializationStages