/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyEmbeddableOpenApi } from './CompanyEmbeddableOpenApi';
import {
    CompanyEmbeddableOpenApiFromJSON,
    CompanyEmbeddableOpenApiFromJSONTyped,
    CompanyEmbeddableOpenApiToJSON,
} from './CompanyEmbeddableOpenApi';
import type { DealCategoryOpenApi } from './DealCategoryOpenApi';
import {
    DealCategoryOpenApiFromJSON,
    DealCategoryOpenApiFromJSONTyped,
    DealCategoryOpenApiToJSON,
} from './DealCategoryOpenApi';
import type { DealExitTypeOpenApi } from './DealExitTypeOpenApi';
import {
    DealExitTypeOpenApiFromJSON,
    DealExitTypeOpenApiFromJSONTyped,
    DealExitTypeOpenApiToJSON,
} from './DealExitTypeOpenApi';
import type { DealGeographyOpenApi } from './DealGeographyOpenApi';
import {
    DealGeographyOpenApiFromJSON,
    DealGeographyOpenApiFromJSONTyped,
    DealGeographyOpenApiToJSON,
} from './DealGeographyOpenApi';
import type { DealGroupOpenApi } from './DealGroupOpenApi';
import {
    DealGroupOpenApiFromJSON,
    DealGroupOpenApiFromJSONTyped,
    DealGroupOpenApiToJSON,
} from './DealGroupOpenApi';
import type { DealIndustryOpenApi } from './DealIndustryOpenApi';
import {
    DealIndustryOpenApiFromJSON,
    DealIndustryOpenApiFromJSONTyped,
    DealIndustryOpenApiToJSON,
} from './DealIndustryOpenApi';
import type { DealInvestorGroupOpenApi } from './DealInvestorGroupOpenApi';
import {
    DealInvestorGroupOpenApiFromJSON,
    DealInvestorGroupOpenApiFromJSONTyped,
    DealInvestorGroupOpenApiToJSON,
} from './DealInvestorGroupOpenApi';
import type { DealManualReconciliationBankAccountOpenApi } from './DealManualReconciliationBankAccountOpenApi';
import {
    DealManualReconciliationBankAccountOpenApiFromJSON,
    DealManualReconciliationBankAccountOpenApiFromJSONTyped,
    DealManualReconciliationBankAccountOpenApiToJSON,
} from './DealManualReconciliationBankAccountOpenApi';
import type { DealOutcomeOpenApi } from './DealOutcomeOpenApi';
import {
    DealOutcomeOpenApiFromJSON,
    DealOutcomeOpenApiFromJSONTyped,
    DealOutcomeOpenApiToJSON,
} from './DealOutcomeOpenApi';
import type { DealPaymentInstructionsTriggerOpenApi } from './DealPaymentInstructionsTriggerOpenApi';
import {
    DealPaymentInstructionsTriggerOpenApiFromJSON,
    DealPaymentInstructionsTriggerOpenApiFromJSONTyped,
    DealPaymentInstructionsTriggerOpenApiToJSON,
} from './DealPaymentInstructionsTriggerOpenApi';
import type { DealPaymentProcessingTypeOpenApi } from './DealPaymentProcessingTypeOpenApi';
import {
    DealPaymentProcessingTypeOpenApiFromJSON,
    DealPaymentProcessingTypeOpenApiFromJSONTyped,
    DealPaymentProcessingTypeOpenApiToJSON,
} from './DealPaymentProcessingTypeOpenApi';
import type { DealPubliclyAvailableDataPointOpenApi } from './DealPubliclyAvailableDataPointOpenApi';
import {
    DealPubliclyAvailableDataPointOpenApiFromJSON,
    DealPubliclyAvailableDataPointOpenApiFromJSONTyped,
    DealPubliclyAvailableDataPointOpenApiToJSON,
} from './DealPubliclyAvailableDataPointOpenApi';
import type { DealStageOpenApi } from './DealStageOpenApi';
import {
    DealStageOpenApiFromJSON,
    DealStageOpenApiFromJSONTyped,
    DealStageOpenApiToJSON,
} from './DealStageOpenApi';
import type { DealWaitingStateOpenApi } from './DealWaitingStateOpenApi';
import {
    DealWaitingStateOpenApiFromJSON,
    DealWaitingStateOpenApiFromJSONTyped,
    DealWaitingStateOpenApiToJSON,
} from './DealWaitingStateOpenApi';
import type { ImageOpenApi } from './ImageOpenApi';
import {
    ImageOpenApiFromJSON,
    ImageOpenApiFromJSONTyped,
    ImageOpenApiToJSON,
} from './ImageOpenApi';

/**
 * 
 * @export
 * @interface DealIncompleteOnboardingOpenApi
 */
export interface DealIncompleteOnboardingOpenApi {
    /**
     * 
     * @type {string}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    nameIncompleteOnboarding: string;
    /**
     * 
     * @type {string}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    descriptionIncompleteOnboarding: string;
    /**
     * 
     * @type {CompanyEmbeddableOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    company?: CompanyEmbeddableOpenApi;
    /**
     * 
     * @type {string}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    companyId?: string;
    /**
     * 
     * @type {Array<DealCategoryOpenApi>}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    categories: Array<DealCategoryOpenApi>;
    /**
     * 
     * @type {DealGeographyOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    geography?: DealGeographyOpenApi;
    /**
     * 
     * @type {ImageOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    image?: ImageOpenApi;
    /**
     * 
     * @type {DealStageOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    stage: DealStageOpenApi;
    /**
     * 
     * @type {DealOutcomeOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    outcome: DealOutcomeOpenApi;
    /**
     * 
     * @type {DealWaitingStateOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    waitingState?: DealWaitingStateOpenApi;
    /**
     * 
     * @type {DealIndustryOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    industry: DealIndustryOpenApi;
    /**
     * 
     * @type {Date}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    finalDeadline: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    exclusive: boolean;
    /**
     * 
     * @type {Array<DealGroupOpenApi>}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    dealGroups: Array<DealGroupOpenApi>;
    /**
     * 
     * @type {Array<DealInvestorGroupOpenApi>}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    investorGroups?: Array<DealInvestorGroupOpenApi>;
    /**
     * 
     * @type {Array<DealManualReconciliationBankAccountOpenApi>}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    manualReconciliationBankAccounts: Array<DealManualReconciliationBankAccountOpenApi>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    documentTemplateIds: Array<number>;
    /**
     * 
     * @type {DealPaymentProcessingTypeOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    paymentProcessingType: DealPaymentProcessingTypeOpenApi;
    /**
     * 
     * @type {DealPaymentInstructionsTriggerOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    paymentInstructionsTrigger: DealPaymentInstructionsTriggerOpenApi;
    /**
     * 
     * @type {Date}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    paymentInstructionsManuallyTriggered?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    exited?: Date;
    /**
     * 
     * @type {DealExitTypeOpenApi}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    exitType?: DealExitTypeOpenApi;
    /**
     * 
     * @type {boolean}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    published: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    imported: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    publiclyAvailable: boolean;
    /**
     * 
     * @type {Array<DealPubliclyAvailableDataPointOpenApi>}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    publiclyAvailableDataPoints?: Array<DealPubliclyAvailableDataPointOpenApi>;
    /**
     * 
     * @type {boolean}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    showCompletelyWithoutOnboarding: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    favourite: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealIncompleteOnboardingOpenApi
     */
    objectType: string;
}

/**
 * Check if a given object implements the DealIncompleteOnboardingOpenApi interface.
 */
export function instanceOfDealIncompleteOnboardingOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nameIncompleteOnboarding" in value;
    isInstance = isInstance && "descriptionIncompleteOnboarding" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "stage" in value;
    isInstance = isInstance && "outcome" in value;
    isInstance = isInstance && "industry" in value;
    isInstance = isInstance && "finalDeadline" in value;
    isInstance = isInstance && "exclusive" in value;
    isInstance = isInstance && "dealGroups" in value;
    isInstance = isInstance && "manualReconciliationBankAccounts" in value;
    isInstance = isInstance && "documentTemplateIds" in value;
    isInstance = isInstance && "paymentProcessingType" in value;
    isInstance = isInstance && "paymentInstructionsTrigger" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "imported" in value;
    isInstance = isInstance && "publiclyAvailable" in value;
    isInstance = isInstance && "showCompletelyWithoutOnboarding" in value;
    isInstance = isInstance && "favourite" in value;
    isInstance = isInstance && "objectType" in value;

    return isInstance;
}

export function DealIncompleteOnboardingOpenApiFromJSON(json: any): DealIncompleteOnboardingOpenApi {
    return DealIncompleteOnboardingOpenApiFromJSONTyped(json, false);
}

export function DealIncompleteOnboardingOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealIncompleteOnboardingOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'nameIncompleteOnboarding': json['nameIncompleteOnboarding'],
        'descriptionIncompleteOnboarding': json['descriptionIncompleteOnboarding'],
        'company': !exists(json, 'company') ? undefined : CompanyEmbeddableOpenApiFromJSON(json['company']),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'categories': ((json['categories'] as Array<any>).map(DealCategoryOpenApiFromJSON)),
        'geography': !exists(json, 'geography') ? undefined : DealGeographyOpenApiFromJSON(json['geography']),
        'image': !exists(json, 'image') ? undefined : ImageOpenApiFromJSON(json['image']),
        'stage': DealStageOpenApiFromJSON(json['stage']),
        'outcome': DealOutcomeOpenApiFromJSON(json['outcome']),
        'waitingState': !exists(json, 'waitingState') ? undefined : DealWaitingStateOpenApiFromJSON(json['waitingState']),
        'industry': DealIndustryOpenApiFromJSON(json['industry']),
        'finalDeadline': (new Date(json['finalDeadline'])),
        'exclusive': json['exclusive'],
        'dealGroups': ((json['dealGroups'] as Array<any>).map(DealGroupOpenApiFromJSON)),
        'investorGroups': !exists(json, 'investorGroups') ? undefined : ((json['investorGroups'] as Array<any>).map(DealInvestorGroupOpenApiFromJSON)),
        'manualReconciliationBankAccounts': ((json['manualReconciliationBankAccounts'] as Array<any>).map(DealManualReconciliationBankAccountOpenApiFromJSON)),
        'documentTemplateIds': json['documentTemplateIds'],
        'paymentProcessingType': DealPaymentProcessingTypeOpenApiFromJSON(json['paymentProcessingType']),
        'paymentInstructionsTrigger': DealPaymentInstructionsTriggerOpenApiFromJSON(json['paymentInstructionsTrigger']),
        'paymentInstructionsManuallyTriggered': !exists(json, 'paymentInstructionsManuallyTriggered') ? undefined : (new Date(json['paymentInstructionsManuallyTriggered'])),
        'exited': !exists(json, 'exited') ? undefined : (new Date(json['exited'])),
        'exitType': !exists(json, 'exitType') ? undefined : DealExitTypeOpenApiFromJSON(json['exitType']),
        'published': json['published'],
        'imported': json['imported'],
        'publiclyAvailable': json['publiclyAvailable'],
        'publiclyAvailableDataPoints': !exists(json, 'publiclyAvailableDataPoints') ? undefined : ((json['publiclyAvailableDataPoints'] as Array<any>).map(DealPubliclyAvailableDataPointOpenApiFromJSON)),
        'showCompletelyWithoutOnboarding': json['showCompletelyWithoutOnboarding'],
        'favourite': json['favourite'],
        'objectType': json['objectType'],
    };
}

export function DealIncompleteOnboardingOpenApiToJSON(value?: DealIncompleteOnboardingOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'nameIncompleteOnboarding': value.nameIncompleteOnboarding,
        'descriptionIncompleteOnboarding': value.descriptionIncompleteOnboarding,
        'company': CompanyEmbeddableOpenApiToJSON(value.company),
        'companyId': value.companyId,
        'categories': ((value.categories as Array<any>).map(DealCategoryOpenApiToJSON)),
        'geography': DealGeographyOpenApiToJSON(value.geography),
        'image': ImageOpenApiToJSON(value.image),
        'stage': DealStageOpenApiToJSON(value.stage),
        'outcome': DealOutcomeOpenApiToJSON(value.outcome),
        'waitingState': DealWaitingStateOpenApiToJSON(value.waitingState),
        'industry': DealIndustryOpenApiToJSON(value.industry),
        'finalDeadline': (value.finalDeadline.toISOString().substr(0,10)),
        'exclusive': value.exclusive,
        'dealGroups': ((value.dealGroups as Array<any>).map(DealGroupOpenApiToJSON)),
        'investorGroups': value.investorGroups === undefined ? undefined : ((value.investorGroups as Array<any>).map(DealInvestorGroupOpenApiToJSON)),
        'manualReconciliationBankAccounts': ((value.manualReconciliationBankAccounts as Array<any>).map(DealManualReconciliationBankAccountOpenApiToJSON)),
        'documentTemplateIds': value.documentTemplateIds,
        'paymentProcessingType': DealPaymentProcessingTypeOpenApiToJSON(value.paymentProcessingType),
        'paymentInstructionsTrigger': DealPaymentInstructionsTriggerOpenApiToJSON(value.paymentInstructionsTrigger),
        'paymentInstructionsManuallyTriggered': value.paymentInstructionsManuallyTriggered === undefined ? undefined : (value.paymentInstructionsManuallyTriggered.toISOString()),
        'exited': value.exited === undefined ? undefined : (value.exited.toISOString().substr(0,10)),
        'exitType': DealExitTypeOpenApiToJSON(value.exitType),
        'published': value.published,
        'imported': value.imported,
        'publiclyAvailable': value.publiclyAvailable,
        'publiclyAvailableDataPoints': value.publiclyAvailableDataPoints === undefined ? undefined : ((value.publiclyAvailableDataPoints as Array<any>).map(DealPubliclyAvailableDataPointOpenApiToJSON)),
        'showCompletelyWithoutOnboarding': value.showCompletelyWithoutOnboarding,
        'favourite': value.favourite,
        'objectType': value.objectType,
    };
}

