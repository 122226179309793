import {round} from "./round.util"

export function formatPercent(
    value: number,
    maximumFractionDigits: number = 1,
    minimumFractionDigits: number = 1
): string {
    return round(value, maximumFractionDigits).toLocaleString("en", {
        style: 'decimal',
        maximumFractionDigits,
        minimumFractionDigits
    }) + "%"
}