import {FetchClient} from "../../fetch/client"

export class AppContextState {
    private _currentUser: CurrentUser | undefined = undefined
    private _fetchClient: FetchClient = new FetchClient(undefined)
    private _steps: AppContextSteps = {
        currentUserFetched: false,
        preflightDone: false,
        onboardingAndOpenDealsFetched: false
    }
    
    private _authenticated: boolean = false
    private _loading: boolean = true

    public withCurrentUser(currentUser: CurrentUser | undefined): AppContextState {
        this._currentUser = currentUser
        return this.updateInternalStateAndCopy()
    }

    public withCurrentUserFetched(currentUserFetched: boolean): AppContextState {
        this._steps.currentUserFetched = currentUserFetched
        return this.updateInternalStateAndCopy()
    }

    public withFetchClient(fetchClient: FetchClient): AppContextState {
        this._fetchClient = fetchClient
        return this.updateInternalStateAndCopy()
    }

    public withOnboardingAndOpenDealsFetched(onboardingAndOpenDealsFetched: boolean): AppContextState {
        this._steps.onboardingAndOpenDealsFetched = onboardingAndOpenDealsFetched
        return this.updateInternalStateAndCopy()
    }

    public withPreflightDone(preflightDone: boolean): AppContextState {
        this.steps.preflightDone = preflightDone
        return this.updateInternalStateAndCopy()
    }

    public get authenticated() {
        return this._authenticated
    }

    public get currentUser() {
        return this._currentUser
    }

    public get fetchClient() {
        return this._fetchClient
    }

    public get loading() {
        return this._loading
    }

    public get steps() {
        return this._steps
    }

    private copy(): AppContextState {
        // @ts-ignore
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this)
    }
    
    private updateInternalStateAndCopy() {
        this._authenticated = this.evaluateAuthenticated()
        this._loading = this.evaluateLoading()
        return this.copy()
    }

    private evaluateAuthenticated(): boolean {
        return !this.evaluateLoading()
            && this._currentUser !== undefined
    }

    private evaluateLoading(): boolean {
        if (this._currentUser === undefined && this._steps.currentUserFetched) {
            return false
        }
        return !this._steps.currentUserFetched
            || !this._steps.preflightDone
            || !this._steps.onboardingAndOpenDealsFetched
    }

    public static reset(): AppContextState {
        return (new AppContextState()).withCurrentUserFetched(true)
    }

}

export type CurrentUser = {
    email: string
    username: string
}

type AppContextSteps = {
    currentUserFetched: boolean
    preflightDone: boolean
    onboardingAndOpenDealsFetched: boolean
}