import {CurrencyOpenApi} from "../../generated"
import {round} from "./round.util"

export function formatAmountWithCurrency(
    value: number,
    currency: CurrencyOpenApi,
    maximumFractionDigits: number = 2,
    hideFractionDigitsIfPossible: boolean = false
): string {
    const rounded = round(value, maximumFractionDigits)
    return getNumberFormat(
        rounded,
        currency,
        maximumFractionDigits,
        hideFractionDigitsIfPossible
    ).format(rounded)
}

export function formatAmountWithoutCurrency(
    value: number,
    maximumFractionDigits: number = 2,
    hideFractionDigitsIfPossible: boolean = false
) {
    const rounded = round(value, maximumFractionDigits)
    const currencyFractionDigits = getNumberFormat(
        rounded,
        CurrencyOpenApi.Usd,
        maximumFractionDigits,
        hideFractionDigitsIfPossible
    ).resolvedOptions().maximumFractionDigits
    return rounded.toLocaleString("en", {
        maximumFractionDigits: currencyFractionDigits
    })
}

export function formatAmountAbbreviatedWithCurrency(
    value: number,
    currency: CurrencyOpenApi
): string {
    let abbreviatedNumber = value.toString()
    if (Math.abs(value) >= 1e6) {
        abbreviatedNumber = (value / 1e6).toFixed(0) + 'M';
    } else if (Math.abs(value) >= 1e3) {
        abbreviatedNumber = (value / 1e3).toFixed(0) + 'K';
    }
    return getNumberFormat(value, currency, 0).formatToParts(1)[0].value
        + abbreviatedNumber
}

function getNumberFormat(
    value: number,
    currency: CurrencyOpenApi,
    maximumFractionDigits: number = 2,
    hideFractionDigitsIfPossible: boolean = false
) {
    return hideFractionDigitsIfPossible && value % 1 === 0
        ? new Intl.NumberFormat("en", {
            currency: currency,
            style: "currency",
            maximumFractionDigits: 0
        })
        : new Intl.NumberFormat("en", {
            currency: currency,
            style: "currency",
            maximumFractionDigits
        })
}