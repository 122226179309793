import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CheckCircleIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 64 64"
        >
            <path
                className={fillClass}
                d="M43.415 24.585A2 2 0 0 1 44.002 26a2 2 0 0 1-.587 1.415l-14 14a2 2 0 0 1-1.415.587 2 2 0 0 1-1.415-.587l-6-6a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0L28 37.173l12.585-12.587A2 2 0 0 1 42 23.998a2 2 0 0 1 1.415.587zM58 32a26 26 0 0 1-16.05 24.021 26 26 0 0 1-28.335-5.636A26 26 0 0 1 7.979 22.05 26 26 0 0 1 32 6c6.893.007 13.502 2.749 18.377 7.623S57.993 25.107 58 32zm-4 0a22 22 0 0 0-13.581-20.325 22 22 0 0 0-23.975 4.769 22 22 0 0 0-4.769 23.975A22 22 0 0 0 32 54c5.833-.007 11.425-2.327 15.549-6.451S53.993 37.833 54 32z"
            />
        </svg>
    )
}

export default CheckCircleIcon