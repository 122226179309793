import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CopyIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path
                className={fillClass}
                d="M20.25 3h-12a.75.75 0 0 0-.75.75V7.5H3.75a.75.75 0 0 0-.75.75v12a.75.75 0 0 0 .75.75h12a.75.75 0 0 0 .75-.75V16.5h3.75a.75.75 0 0 0 .75-.75v-12a.75.75 0 0 0-.75-.75zM15 19.5H4.5V9H15v10.5zm4.5-4.5h-3V8.25a.75.75 0 0 0-.75-.75H9v-3h10.5V15z"
            />
        </svg>
    )
}

export default CopyIcon