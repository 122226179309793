import React, {FunctionComponent} from "react"
import DealTile from "./tile.component"
import {DealTileType} from "../../tile/tile.type"

type DealTilesProps = {
    tiles: DealTileType[] | undefined
}

const DealTiles: FunctionComponent<DealTilesProps> = ({ tiles }) => {
    return tiles ? (
        <>
            {tiles
                .sort((a, b) => a.order - b.order)
                .map(tile => (
                    <DealTile
                        tile={tile}
                        key={tile.id}
                    />
            ))}
        </>
    ) : <></>
}

export default DealTiles