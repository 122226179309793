import {matchPath, NavigateFunction} from "react-router-dom"
import {PROFILE, SIGN_IN, SIGN_UP} from "../../paths"
import {RouteData} from "../routing/route.type"

const PATH_BEFORE_SIGN_IN = "PATH_BEFORE_SIGN_IN"

export function redirectToSignInAndStoreCurrentUrl(
    pathname: string,
    navigate: NavigateFunction
) {
    if (pathname !== PROFILE) {
        window.localStorage.setItem(PATH_BEFORE_SIGN_IN, pathname)
    }
    navigate(SIGN_IN)
}

export function redirectToSignUpAndStoreCurrentUrl(
    pathname: string,
    navigate: NavigateFunction
) {
    if (pathname !== PROFILE) {
        window.localStorage.setItem(PATH_BEFORE_SIGN_IN, pathname)
    }
    navigate(SIGN_UP)
}

export function redirectToPreviousPathAfterSignInIfNecessary(
    navigate: NavigateFunction
) {
    const previousPath = getPreviousPath()
    if (previousPath !== null) {
        navigate(previousPath)
    }
}

export function removePreviousPath() {
    window.localStorage.removeItem(PATH_BEFORE_SIGN_IN)
}

export function shouldRedirectToPreviousPathAfterSignIn(relevantRoutes: RouteData[]): boolean {
    const previousPath = getPreviousPath()
    return previousPath !== null
        && relevantRoutes.some(route => matchPath(route.path, previousPath) !== null)
}

export function shouldRemovePreviousPath(pathname: string): boolean {
    const previousPath = getPreviousPath()
    return previousPath !== null
        &&  matchPath({ path: previousPath }, pathname) !== null
}

export function getPreviousPath(): string | null {
    return window.localStorage.getItem(PATH_BEFORE_SIGN_IN)
}