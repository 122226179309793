import React, {FunctionComponent} from "react"
import {DashboardType, InvestorDashboardInvestmentType} from "../../../dashboard.type"
import DonutChart from "../../../../../../tech/chart/donut/donut.component"
import DashboardInfo from "../../../info/info.component"
import {mapDealStageToString} from "../../../../../deal/deal.type"
import {assembleData, groupAndSum} from "../distribution.util"
import {DealStageOpenApi} from "../../../../../../generated"
import styles from "./stage.module.sass"
import {InvestmentType} from "../../../../../investment/investment.type"

type InvestorDashboardSummaryDistributionStageProps = {
    dashboard: DashboardType
}

const InvestorDashboardSummaryDistributionStage: FunctionComponent<InvestorDashboardSummaryDistributionStageProps> = ({
    dashboard
}) => {
    const stagesWithSum = groupAndSum<DealStageOpenApi>(
        dashboard.investments,
        (investment: InvestorDashboardInvestmentType) => investment.investment.investment.type === "REGULAR",
        (investment: InvestorDashboardInvestmentType) => (investment.investment.investment.value as InvestmentType).deal._stage,
        (investment: InvestorDashboardInvestmentType) => (investment.investment.investment.value as InvestmentType).deal._stage
    )
    const data = assembleData(
        stagesWithSum,
        (identifier: DealStageOpenApi) => mapDealStageToString(identifier)
    )

    return (
        <div className={styles.stage}>
            <div className={styles.titleDesktop}>
                Stage
            </div>
            <DonutChart
                data={data}
                maxWidth={240}
            />
            {dashboard.summary.performance.strongest.stage && (
                <DashboardInfo
                    text={`Strongest performance in ${mapDealStageToString(dashboard.summary.performance.strongest.stage)}`}
                    noBackgroundMobile={false}
                    tooltip={{
                        content: (
                            <>
                                In terms of stage your portfolio performs best in <strong>{mapDealStageToString(dashboard.summary.performance.strongest.stage)}</strong> based on IRR.
                            </>
                        )
                    }}
                />
            )}
        </div>
    )
}

export default InvestorDashboardSummaryDistributionStage