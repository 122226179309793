import React, {FunctionComponent, ReactNode} from "react"
import {AlertType} from "./type.enum"
import CheckCircleIcon from "../icon/check-circle.component"
import ExclamationMarkIcon from "../icon/exclamation-mark.component"
import InfoCircleIcon from "../icon/info-circle.component"
import styles from "./alert.module.sass"

type AlertProps = {
    type: AlertType
    text: string | ReactNode
    size?: "SMALL" | "NORMAL"
}

const Alert: FunctionComponent<AlertProps> = ({
    type,
    text,
    size = "NORMAL"
}) => {
    return (
        <div className={`${getAlertClass(type)} ${size === "SMALL" ? styles.small : ""}`}>
            <div className={styles.icon}>
                {{
                    "INFO": (
                        <InfoCircleIcon
                            width={18}
                            height={18}
                            fillClass="fill-icon-inactive"
                        />
                    ),
                    "SUCCESS": (
                        <CheckCircleIcon
                            width={18}
                            height={18}
                            fillClass="fill-icon-theme-light"
                        />
                    ),
                    "WARNING": (
                        <ExclamationMarkIcon
                            width={18}
                            height={18}
                            fillClass="fill-icon-theme-light"
                        />
                    ),
                    "ERROR": (
                        <ExclamationMarkIcon
                            width={18}
                            height={18}
                            fillClass="fill-icon-theme-light"
                        />
                    )
                }[type]}
            </div>
            <div className={styles.text}>
                <div>
                    {text}
                </div>
            </div>
        </div>
    )
}

export default Alert

const getAlertClass = (alertType: AlertType) => {
    switch (alertType) {
        case AlertType.INFO:
            return styles.alertInfo
        case AlertType.SUCCESS:
            return styles.alertSuccess
        case AlertType.WARNING:
            return styles.alertWarning
        case AlertType.ERROR:
            return styles.alertError
    }
}