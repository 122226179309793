export type RouteType = string

export const CHANGE_PASSWORD: RouteType = "/change-password/"

export const DEALS: RouteType = "/"
export const DEAL_BASE_PATH: RouteType = "/deal/"
export const DEAL = (id: string) => `/deal/${id}/`
export const DEAL_INVEST = (id: string) => `/deal/${id}/invest/`

export const EXCLUSIVE_DEALS: RouteType = "/exclusive-deals/"

export const FAVOURITE_DEALS: RouteType = "/favourite-deals/"

export const HELP: RouteType = "/help/"

export const HELP_CENTER: RouteType = "/help-center/"

export const INVESTOR_DASHBOARD: RouteType = "/investor-dashboard/"

export const ONBOARDING: RouteType = "/onboarding/"

export const PENDING_INVESTMENTS: RouteType = "/pending-investment/"

export const PREMIUM_MEMBERSHIP: RouteType = "/premium-membership/"
export const PREMIUM_MEMBERSHIP_CANCEL: RouteType = "/premium-membership/cancel/"
export const PREMIUM_MEMBERSHIP_REACTIVATE: RouteType = "/premium-membership/reactivate/"

export const PRIVACY_STATEMENT: RouteType = "/privacy-statement/"

export const PROFILE: RouteType = "/profile/"

export const RESET_PASSWORD: RouteType = "/reset-password/"

export const SIGN_IN: RouteType = "/sign-in/"

export const SIGN_UP: RouteType = "/sign-up/"

export const SYNDICATION: RouteType = "/syndication/"
export const SYNDICATE = (id: string) => `/syndicate/${id}/`

export const TERMS_AND_CONDITIONS = "/terms-and-conditions/"

export const VERIFY_EMAIL: RouteType = "/verify-email/"

export const WAITLIST: RouteType = "/waitlist/"


export const NOT_AUTHENTICATED_PATHS = [
    CHANGE_PASSWORD,
    HELP_CENTER,
    PRIVACY_STATEMENT,
    RESET_PASSWORD,
    SIGN_IN,
    SIGN_UP,
    TERMS_AND_CONDITIONS,
    VERIFY_EMAIL,
    WAITLIST,
]

export const AUTHENTICATED_AND_NOT_AUTHENTICATED_PATHS = [
    PRIVACY_STATEMENT,
    TERMS_AND_CONDITIONS,
]