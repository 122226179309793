import {CurrencyOpenApi} from "../../../../generated"

export function format(amount: number, currency: CurrencyOpenApi): string {
    const amountInThousands = amount / 1000
    const amountInMillions = amount / 1000000

    const withCurrency = (amountInMillions < 1 ? amountInThousands : amountInMillions).toLocaleString("en", {
        currency: currency,
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    })

    return withCurrency + (amountInMillions < 1 ? "K" : "M")
}