import React, {FunctionComponent} from "react"
import {LegendOrdinal} from "@visx/legend"
import {scaleOrdinal} from "@visx/scale"
import {LineChartLineWithColorType} from "../line.type"
import styles from "./legend.module.sass"

type LineChartLegendProps = {
    lines: LineChartLineWithColorType[]
}

const LineChartLegend: FunctionComponent<LineChartLegendProps> = ({
    lines
}) => {
    const getColor = scaleOrdinal({
        domain: lines.map(l => l.label),
        range: lines.map(l => l.color)
    })
    return (
        <div className={styles.legend}>
            <LegendOrdinal
                scale={getColor}
                shape="circle"
                direction="row"
                shapeWidth={12}
                shapeHeight={12}
                className={styles.legend}
            />
        </div>
    )
}

export default LineChartLegend