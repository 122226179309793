import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CaretDownIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
        >
            <path
                className={fillClass}
                d="M7.648 10.854L2.647 5.855a.5.5 0 0 1 0-.708.5.5 0 0 1 .708.001l4.647 4.645 4.645-4.648a.5.5 0 0 1 .707.707l-4.999 5.001a.5.5 0 0 1-.707 0v.001z"
            />
        </svg>
    )
}

export default CaretDownIcon