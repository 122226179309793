export const INVITATION_CODE_PARAMETER = "invitationCode"
export const INVITATION_CODE_LOCAL_STORAGE_KEY = "invitationCode"

export function getInvitationCodeQueryParameter(searchParams: URLSearchParams): string | undefined {
    return searchParams.get(INVITATION_CODE_PARAMETER) || undefined
}

export function buildUserAttributes(invitationCode: string | undefined) {
    return {
        userAttributes: {
            "custom:invitation_code": invitationCode
        }
    }
}