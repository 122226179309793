import React, {createRef, FunctionComponent, useState} from "react"
import {InvestmentWithPaymentDetailsType} from "../../../../investment-with-payment-details.type"
import PendingInvestmentCardPaymentTable from "../table/table.component"
import commonStyles from "../../common.module.sass"
import styles from "./payment-details.module.sass"
import {PaymentDetailsAccountType} from "../../../../payment-details/payment-details.type"
import PendingInvestmentCardPaymentDetailsAccount from "./account.component"

type PendingInvestmentCardPaymentDetailsProps = {
    investmentWithPaymentDetails: InvestmentWithPaymentDetailsType
}

const PendingInvestmentCardPaymentDetails: FunctionComponent<PendingInvestmentCardPaymentDetailsProps> = ({
    investmentWithPaymentDetails
}) => {
    const selectRef = createRef<HTMLSelectElement>()
    const [selectedAccount, setSelectedAccount] = useState<PaymentDetailsAccountType>(
        investmentWithPaymentDetails.paymentDetails!.accounts[0]
    )

    return (
        <div>
            <div className={styles.amountToPay}>
                {investmentWithPaymentDetails.paymentDetails?.manualReconciliation && (
                    <>
                        <div className={styles.title}>
                            Transaction Information
                        </div>
                        <div className={styles.explanation}>
                            Depending on if you transfer from <strong>within or outside the US</strong> you need to
                            choose the right transfer account.
                        </div>
                        <div className={styles.selectTransferOrigin}>
                            <div>
                                <div className={commonStyles.subtitle}>
                                    Choose your transfer origin
                                </div>
                            </div>
                            <div>
                                <div>
                                    <select
                                        ref={selectRef}
                                        value={selectedAccount.number}
                                        onChange={(e) => {
                                            const account = investmentWithPaymentDetails.paymentDetails!.accounts
                                                .find(a => a.number === e.target.value)
                                            setSelectedAccount(account!)
                                        }}
                                    >
                                        {investmentWithPaymentDetails.paymentDetails!.accounts.map(account => (
                                            <option
                                                value={account.number}
                                                key={`account-select-option-${account.number}`}
                                            >
                                                {account.manualReconciliation?.transferOrigin === "WITHIN_US"
                                                    ? "Transfer from within the US"
                                                    : "Transfer from outside the US"}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className={`${commonStyles.subtitle} ${styles.amountToPayTitle}`}>
                    Amount To Pay
                </div>
                <PendingInvestmentCardPaymentTable input={{
                    type: "INVESTMENT_WITH_PAYMENT_DETAILS",
                    investmentWithPaymentDetails
                }}/>
                <div className={styles.alertExactAmount}>
                    You need to transfer the <strong>exact amount</strong> (Total Amount) as indicated in the cost breakup and add <strong>your
                    name</strong> in the transfer reference.
                </div>
            </div>
            <div className={styles.accountDetails}>
                <div>
                    <div className={commonStyles.subtitle}>
                        Transfer Account Details
                    </div>
                </div>
                {selectedAccount.manualReconciliation?.transferOrigin === "WITHIN_US" && (
                    <div className={styles.redNotice}>
                        <div>
                            ONLY works for transactions from US bank accounts
                        </div>
                    </div>
                )}
            </div>
            <PendingInvestmentCardPaymentDetailsAccount
                investment={investmentWithPaymentDetails.investment}
                paymentDetailsAccount={selectedAccount}
            />
        </div>
    )
}

export default PendingInvestmentCardPaymentDetails