import React, {FunctionComponent} from "react"
import TextField, {TextFieldProps} from "../text/text.component"

const PhoneField: FunctionComponent<TextFieldProps> = ({
    placeholder,
    errors,
    prefill,
    reactHookFormRegister,
    label
}) => {
    const reactHookFormRegisterExtended = {
        name: reactHookFormRegister.name,
        register: reactHookFormRegister.register,
        options: {
            pattern: {
                value: /^(\+|00)[1-9][0-9 \-().]{7,32}$/,
                message: "Please enter a valid phone number. According to international format it should start with a plus sign (+)."
            }
        }
    }
    return (
        <TextField
            placeholder={placeholder}
            errors={errors}
            prefill={prefill}
            reactHookFormRegister={reactHookFormRegisterExtended}
            label={label}
        />
    )
}

export default PhoneField