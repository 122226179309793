import React, {FunctionComponent} from "react"
import styles from "./status.module.sass"
import {InvestorDashboardUnderlyingInvestmentType} from "../investment.type"

type InvestorDashboardInvestmentsStatusProps = {
    investment: InvestorDashboardUnderlyingInvestmentType
}

const InvestorDashboardInvestmentsStatus: FunctionComponent<InvestorDashboardInvestmentsStatusProps> = ({ investment }) => {
    return (
        <div className={styles.status}>
            <div>
                <div className={`${styles.badge} ${evaluateBadgeTypeClass(investment)}`}>
                    {investment.getExitString()}
                </div>
            </div>
        </div>
    )
}

export default InvestorDashboardInvestmentsStatus

function evaluateBadgeTypeClass(investment: InvestorDashboardUnderlyingInvestmentType): string {
    if (investment.investment.type === "INDIVIDUAL") {
        return investment.investment.value.exited
            ? styles.exit
            : styles.active
    }
    else {
        const deal = investment.investment.value.deal
        if (deal.exited === undefined || deal.exitType === undefined) return styles.active
        switch (deal.exitType) {
            case "DEAD":
                return styles.dead
            case "EXIT":
                return styles.exit
        }
    }
}