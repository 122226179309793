import React, {FunctionComponent, ReactNode} from "react"
import styles from "./layout-wrapper.module.sass"

type AuthLayoutWrapperProps = {
    children: ReactNode
    withBackgroundImage?: boolean
}

const AuthLayoutWrapper: FunctionComponent<AuthLayoutWrapperProps> = ({
    children,
    withBackgroundImage = true
}) => {
    return (
        <div className={withBackgroundImage ? styles.background : ""}>
            {withBackgroundImage && (
                <div className={styles.backgroundGradient}/>
            )}
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default AuthLayoutWrapper