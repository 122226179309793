import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const TriangleUpIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 12 12"
            fill="none"
        >
            <path
                className={fillClass}
                d="M6 0l5.196 9H.804L6 0z"
            />
        </svg>
    )
}

export default TriangleUpIcon