import React, {createRef, FunctionComponent, useEffect, useState} from "react"
import {useQueryClient} from "react-query"
import {InvestmentWithPaymentDetailsType} from "../../../../investment-with-payment-details.type"
import {InvestmentExchangePreviewType} from "../../../../exchange-rate/exchange-preview.type"
import {CurrencyOpenApi} from "../../../../../../generated"
import {needsToSelectCurrency} from "../payment.util"
import LoadingDots from "../../../../../../tech/loading/dots/dots.component"
import Alert from "../../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../../tech/alert/type.enum"
import Button from "../../../../../../tech/button/button.component"
import {ButtonSize} from "../../../../../../tech/button/size.enum"
import {ButtonState} from "../../../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../../../tech/button/style.enum"
import LockIcon from "../../../../../../tech/icon/lock.component"
import PendingInvestmentCardPaymentTable from "../table/table.component"
import commonStyles from "../../common.module.sass"
import {QUERY_KEY__DEAL_WITH_INVESTMENT} from "../../../../../deal/deal.query"
import styles from "./select-currency.module.sass"
import {useApp} from "../../../../../../tech/app/context/app.context"

type PendingInvestmentCardPaymentSelectCurrencyProps = {
    investmentWithPaymentDetails: InvestmentWithPaymentDetailsType
}

const PendingInvestmentCardPaymentSelectCurrency: FunctionComponent<PendingInvestmentCardPaymentSelectCurrencyProps> = ({
    investmentWithPaymentDetails,
}) => {
    const app = useApp()
    const queryClient = useQueryClient()
    const selectRef = createRef<HTMLSelectElement>()
    const investment = investmentWithPaymentDetails.investment
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR" | "ERROR_GONE">("LOADING")
    const [previews, setPreviews] = useState<InvestmentExchangePreviewType[]>()
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyOpenApi>(
        needsToSelectCurrency(investment) ? investment.deal._currency : investment.currency!
    )

    const relevantPreview = previews?.find(preview => preview.currency === selectedCurrency)

    useEffect(() => {
        if (needsToSelectCurrency(investment)) {
            const fetch = async () => {
                try {
                    setPreviews(await app.fetchClient.investmentApi.getExchangeRatePreviews(investment.id))
                    setState("LOADED")
                } catch (err) {
                    console.error(err)
                    setState("ERROR")
                }
            }
            fetch()
        }
        else {
            setState("LOADED")
        }
    }, [app.fetchClient, investment])


    const selectCurrency = async (currency: CurrencyOpenApi, exchangePreviewId?: string) => {
        try {
            setState("LOADING")
            const result = await app.fetchClient.investmentApi.selectCurrency(
                investmentWithPaymentDetails.investment.id,
                currency,
                exchangePreviewId
            )
            if (result === "GONE") {
                setState("ERROR_GONE")
            }
            else if (result === "ERROR") {
                setState("ERROR")
            }
            else {
                queryClient.setQueryData(
                    QUERY_KEY__DEAL_WITH_INVESTMENT({ id: investment.deal.id! }),
                    () => result
                )
                setState("LOADED")
            }
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && previews && (
                <>
                    <div className={styles.head}>
                        <div>
                            <div className={commonStyles.subtitle}>
                                Choose the currency
                            </div>
                        </div>
                        <div>
                            <select
                                ref={selectRef}
                                value={selectedCurrency}
                                onChange={(e) => setSelectedCurrency(e.target.value as CurrencyOpenApi)}
                            >
                                {Object.entries(CurrencyOpenApi).map((c, index) => (
                                    <option
                                        value={c[1]}
                                        key={`currency-${index}`}
                                    >
                                        {c[1]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <PendingInvestmentCardPaymentTable input={{
                        type: "FROM_CURRENCY_SELECTION",
                        investment: investmentWithPaymentDetails.investment,
                        relevantPreview,
                        selectedCurrency
                    }}
                    />
                    <div className={styles.lock}>
                        <Button
                            label={"Lock in currency"}
                            type="button"
                            size={ButtonSize.MEDIUM}
                            state={ButtonState.ACTIVE}
                            style={ButtonStyle.PRIMARY}
                            fullWidth={true}
                            icon={(
                                <LockIcon
                                    width={24}
                                    height={24}
                                    fillClass="fill-icon-white"
                                />
                            )}
                            disabled={!needsToSelectCurrency(investment)}
                            onClick={() => selectCurrency(selectedCurrency, relevantPreview?.exchangePreviewId)}
                        />
                    </div>
                </>
            )}
            {state === "ERROR_GONE" && (
                <div className={styles.error}>
                    <Alert
                        type={AlertType.ERROR}
                        text="The underlying exchange rate is not any longer available. Please referesh the page and try again."
                    />
                </div>
            )}
            {state === "ERROR" && (
                <div className={styles.error}>
                    <Alert
                        type={AlertType.ERROR}
                        text="Unexpected error. Please try again by refreshing the page."
                    />
                </div>
            )}
        </>
    )
}

export default PendingInvestmentCardPaymentSelectCurrency