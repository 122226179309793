import {InvestorDashboardInvestmentTypeOpenApi} from "../../../../generated"

export function mapInvestorDashboardInvestmentTypeToString(
    investmentType: InvestorDashboardInvestmentTypeOpenApi
): string {
    switch (investmentType) {
        case "INDIVIDUAL_INVESTMENTS":
            return "Individual Investments"
        case "REGULAR_INVESTMENTS":
            return "Regular Investments"
    }
}