import React, {FunctionComponent} from "react"
import {DealSortType} from "../../sort.type"
import {getStringRepresentation} from "../../sort.util"
import styles from "./option.module.sass"
import RadioIcon from "../../../../../tech/icon/radio.component"

type DealSortOptionProps = {
    sortType: DealSortType
    currentSortType?: DealSortType
    updateSortType: (type: DealSortType) => void
}

const DealSortOption: FunctionComponent<DealSortOptionProps> = ({ sortType, currentSortType, updateSortType }) => {
    const selected = currentSortType === sortType
    return (
        <button
            onClick={() => updateSortType(sortType)}
            className={styles.option}
        >
            <div>
                <div className={styles.radio}>
                    <RadioIcon
                        size={24}
                        active={selected}
                    />
                </div>
                <div>
                    <div>
                        {getStringRepresentation(sortType)}
                    </div>
                </div>
            </div>
        </button>
    )
}

export default DealSortOption