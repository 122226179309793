import React, {FunctionComponent, ReactNode} from "react"
import Tooltip from "../../../../../../tech/layout/tooltip/tooltip.component"
import styles from "./tile.module.sass"

type InvestorDashboardSummaryOverviewTileProps = {
    title: string
    text: string
    whiteBackground: boolean
    tooltip?: {
        content: ReactNode
        shiftLeft?: number
    }
}

const InvestorDashboardSummaryOverviewTile: FunctionComponent<InvestorDashboardSummaryOverviewTileProps> = ({
    title,
    text,
    whiteBackground,
    tooltip
}) => {
    return (
        <div className={`${styles.tile} ${whiteBackground ? styles.whiteBackground : ""}`}>
            <div className={styles.title}>
                <div>
                    {title}
                </div>
                {tooltip && (
                    <Tooltip
                        padding={{ x: 5, y: 1 }}
                        position={{
                            top: 14,
                            left: -42,
                            shiftLeft: tooltip.shiftLeft
                        }}
                        maxWidth={200}
                    >
                        {tooltip.content}
                    </Tooltip>
                )}
            </div>
            <div className={styles.text}>
                {text}
            </div>
        </div>
    )
}

export default InvestorDashboardSummaryOverviewTile