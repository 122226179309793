import {InvestmentType} from "../../../investment.type"

const PARAMETER_NAME = "signed"

export function isSyncingDocument(searchParams: URLSearchParams) {
    return searchParams.has(PARAMETER_NAME)
}

export function removeSyncingDocumentParameterIfNecessary(
    investment: InvestmentType | undefined,
    searchParams: URLSearchParams,
    updateSearchParams: (params: URLSearchParams) => void
) {
    if (isSyncingDocument(searchParams) && investment !== undefined && investment.signed) {
        const newSearchParams = searchParams
        newSearchParams.delete(PARAMETER_NAME)
        updateSearchParams(newSearchParams)
    }
}