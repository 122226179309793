import React, {FunctionComponent} from "react"
import DealLegalDocuments from "./documents.component"
import styles from "./documents-tile.module.sass"

type DealTileLegalDocumentsProps = {
    dealId: string
}

const DealTileLegalDocuments: FunctionComponent<DealTileLegalDocumentsProps> = ({ dealId }) => {
    return (
        <div className={styles.tile}>
            <h4>Terms / Documents</h4>
            <DealLegalDocuments dealId={dealId}/>
        </div>
    )
}

export default DealTileLegalDocuments