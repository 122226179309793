import {FunctionComponent} from "react"
import Modal from "../../../../tech/layout/modal/modal.component"
import IndividualInvestmentForm from "../form/form.component"
import {IndividualInvestmentPopupStateType} from "./popup.type"
import styles from "./popup.module.sass"
import IndividualInvestmentDelete from "../delete/delete.component"

type IndividualInvestmentPopupProps = {
    state: IndividualInvestmentPopupStateType
    updateState: (newState: IndividualInvestmentPopupStateType) => void
    invalidateDashboardQuery: () => void
}

const IndividualInvestmentPopup: FunctionComponent<IndividualInvestmentPopupProps> = ({
    state,
    updateState,
    invalidateDashboardQuery
}) => {
    if (state.type === "CLOSED") {
        return <></>
    }
    return (
        <Modal close={() => updateState({ type: "CLOSED" })}>
            <div className={styles.popup}>
                {state.type === "DELETE" ? (
                    <IndividualInvestmentDelete
                        investment={state.investment}
                        invalidateDashboardQuery={invalidateDashboardQuery}
                        updatePopupState={updateState}
                    />
                ): (
                    <IndividualInvestmentForm
                        investment={state.type === "EDIT" ? state.investment : undefined}
                        invalidateDashboardQuery={invalidateDashboardQuery}
                        updatePopupState={updateState}
                    />
                )}
            </div>
        </Modal>
    )
}

export default IndividualInvestmentPopup