import {
    InvestorOnboardingStageTypeOpenApi,
    InvestorOnboardingStageWrapperOpenApi,
    type KycDocumentUploadOpenApi
} from "../../../../generated"
import {InvestorOnboardingStageType, KycDocumentUploadType} from "./stage.type"

export function mapOpenApiToInvestorOnboardingStageType(wrapper: InvestorOnboardingStageWrapperOpenApi): InvestorOnboardingStageType {
    if (wrapper.type === "KYC_DOCUMENT_UPLOAD_NECESSARY" && wrapper.stage.objectType === "KycDocumentUploadNecessary") {
        return {
            type: InvestorOnboardingStageTypeOpenApi.KycDocumentUploadNecessary,
            passport: mapOpenApiToKycDocumentUploadType(wrapper.stage.passport),
            proofOfAddress: mapOpenApiToKycDocumentUploadType(wrapper.stage.proofOfAddress),
            proofOfAiStatus: mapOpenApiToKycDocumentUploadType(wrapper.stage.proofOfAiStatus)
        }
    }
    return {
        type: wrapper.type
    }
}

export function mapOpenApiToKycDocumentUploadType(upload: KycDocumentUploadOpenApi): KycDocumentUploadType {
    return {
        state: upload.state,
        rejectionComment: upload.rejectionComment,
        validTill: upload.validTill
    }
}