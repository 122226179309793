import React, {FunctionComponent, useEffect, useState} from "react"
import {FetchClient} from "../../../tech/fetch/client"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import HelpFaqEntry from "./entry/faq-entry.component"
import styles from "./faq.module.sass"
import {FaqEntryType} from "./entry/faq-entry.type"
import {FaqEntryVisibilityOpenApi} from "../../../generated"
import {useApp} from "../../../tech/app/context/app.context"

type HelpFaqProps = {
    authenticated: boolean
}

const HelpFaq: FunctionComponent<HelpFaqProps> = ({ authenticated }) => {
    const app = useApp()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [faqEntries, setFaqEntries] = useState<FaqEntryType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                if (authenticated) {
                    const filteredFaqEntries = (await app.fetchClient.faqEntryApi.getAll())
                        .filter(entry => entry.visibility === FaqEntryVisibilityOpenApi.All || entry.visibility === FaqEntryVisibilityOpenApi.AuthorizedOnly)
                    setFaqEntries(filteredFaqEntries)
                }
                else {
                    setFaqEntries(await (new FetchClient(undefined)).faqEntryApi.getAllPublic())
                }
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [authenticated, app.fetchClient])

    const groupedFaqEntries = groupByCategory(faqEntries)

    return (
        <div className={styles.faq}>
            <>
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && Object.keys(groupedFaqEntries).map((category: string, index) => (
                    <div
                        className={styles.category}
                        key={`category-${index}`}
                    >
                        <div className={styles.categoryTitle}>
                            {category}
                        </div>
                        <div className={styles.entries}>
                            {groupedFaqEntries[category].map(entry => (
                                <HelpFaqEntry
                                    faqEntry={entry}
                                    key={entry.id}
                                />
                            ))}
                        </div>
                    </div>
                ))}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load FAQ."
                    />
                )}
            </>
        </div>
    )
}

export default HelpFaq

type GroupedEntries = {
    [key: string]: FaqEntryType[]
}

const groupByCategory = (entries: FaqEntryType[]): GroupedEntries => {
    return entries.reduce((result, currentEntry) => {
        const keyValue = currentEntry.category
        if (!result[keyValue]) {
            result[keyValue] = []
        }
        result[keyValue].push(currentEntry)
        return result
    }, {} as Record<string, FaqEntryType[]>)
}