import React, {FunctionComponent} from "react"
import {DealSlideDeckType} from "../../deal.type"
import styles from "./slide-deck.module.sass"
import PdfViewer from "../../../../tech/pdf/pdf.component"

type DealSlideDeckProps = {
    slideDeck: DealSlideDeckType | undefined
}

const DealSlideDeck: FunctionComponent<DealSlideDeckProps> = ({ slideDeck }) => {
    return slideDeck && slideDeck.url ? (
        <div className={styles.deck}>
            <PdfViewer url={slideDeck.url}/>
        </div>
    ) : (
        <></>
    )
}

export default DealSlideDeck