import {FetchClient} from "../fetch/client"

export async function sendJavaScriptError(error: unknown, fetchClient: FetchClient) {
    try {
        await fetchClient.loggingApi.sendError(
            new Date(),
            error as string,
            window.location.pathname
        )
    }
    catch (err) {
        console.error("Error while sending error", err)
    }
}