import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const BankIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path
                className={fillClass}
                d="M2.25 9.75H4.5v6H3a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h18a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75h-1.5v-6h2.25a.75.75 0 0 0 .721-.546.75.75 0 0 0-.328-.843l-9.75-6a.75.75 0 0 0-.786 0l-9.75 6a.75.75 0 0 0-.328.843.75.75 0 0 0 .721.546zm3.75 0h3v6H6v-6zm7.5 0v6h-3v-6h3zm4.5 6h-3v-6h3v6zM12 3.88l7.101 4.37H4.899L12 3.88zM23.25 19.5a.75.75 0 0 1-.75.75h-21a.75.75 0 0 1-.75-.75.75.75 0 0 1 .75-.75h21a.75.75 0 0 1 .75.75z"
            />
        </svg>
    )
}

export default BankIcon