import React, {FunctionComponent} from "react"
import {InvestmentDashboardDesktopSort} from "./sort/sort.type"
import InvestorDashboardInvestmentsDesktopTableHeadSort from "./sort/sort.component"
import {InvestorOnboardingType} from "../../../../onboarding/onboarding.type"
import {hasAnySubscription} from "../../../../../subscription/subscription.util"
import CurrentValueTooltip from "../../../summary/overview/tooltip/current-value/current-value.component"
import styles from "./head.module.sass"
import IrrTooltip from "../../../summary/overview/tooltip/irr/irr.component"
import MoicTooltip from "../../../summary/overview/tooltip/moic/moic.component"

type InvestorDashboardInvestmentsDesktopTableHeadProps = {
    sort: InvestmentDashboardDesktopSort
    updateSort: (sort: InvestmentDashboardDesktopSort) => void
    onboarding: InvestorOnboardingType
}

const InvestorDashboardInvestmentsDesktopTableHead: FunctionComponent<InvestorDashboardInvestmentsDesktopTableHeadProps> = ({
    sort,
    updateSort,
    onboarding
}) => {
    return (
        <div className={styles.head}>
            <div>
                <InvestorDashboardInvestmentsDesktopTableHeadSort
                    title="Company"
                    sort={sort}
                    sortType="COMPANY"
                    updateSort={updateSort}
                />
            </div>
            <div>
                <InvestorDashboardInvestmentsDesktopTableHeadSort
                    title="Status"
                    sort={sort}
                    sortType="DEAL_STATUS"
                    updateSort={updateSort}
                />
            </div>
            <div>
                <InvestorDashboardInvestmentsDesktopTableHeadSort
                    title="Invested"
                    sort={sort}
                    sortType="INVESTED"
                    updateSort={updateSort}
                />
            </div>
            <div>
                <InvestorDashboardInvestmentsDesktopTableHeadSort
                    title="Invest. Date"
                    sort={sort}
                    sortType="INVESTMENT_DATE"
                    updateSort={updateSort}
                />
            </div>
            <div className={styles.currentValue}>
                <InvestorDashboardInvestmentsDesktopTableHeadSort
                    title="Current Value"
                    sort={sort}
                    sortType="CURRENT_VALUE"
                    updateSort={updateSort}
                    tooltip={{
                        content: <CurrentValueTooltip/>,
                        maxWidth: 250,
                        shiftLeft: -150
                    }}
                />
            </div>
            {hasAnySubscription(onboarding) && (
                <>
                    <div>
                        <InvestorDashboardInvestmentsDesktopTableHeadSort
                            title="IRR"
                            sort={sort}
                            sortType="IRR"
                            updateSort={updateSort}
                            tooltip={{
                                content: <IrrTooltip/>,
                                maxWidth: 250,
                                shiftLeft: -150
                            }}
                        />
                    </div>
                    <div>
                        <InvestorDashboardInvestmentsDesktopTableHeadSort
                            title="MOIC"
                            sort={sort}
                            sortType="MOIC"
                            updateSort={updateSort}
                            tooltip={{
                                content: <MoicTooltip/>,
                                maxWidth: 250,
                                shiftLeft: -150
                            }}
                        />
                    </div>
                </>
            )}
            <div/>
        </div>
    )
}

export default InvestorDashboardInvestmentsDesktopTableHead