import {PreflightApiClient} from "../../generated"

export class PreflightApi {

    constructor(private apiClient: PreflightApiClient) {
    }

    public async get(): Promise<"SUCCESS" | "WAITING_FOR_INVESTOR_CREATION"> {
        const result = await this.apiClient.preflightGetRaw()
        return result.raw.status === 202
            ? "WAITING_FOR_INVESTOR_CREATION"
            : "SUCCESS"
    }

}