import {DealTileOpenApi} from "../../../generated"
import {DealTileType} from "./tile.type"

export function mapOpenApiToDealTile(tile: DealTileOpenApi): DealTileType {
    return {
        id: tile.id,
        title: tile.title,
        text: tile.text,
        order: tile.order
    }
}