import React, {FunctionComponent} from "react"
import styles from "./bar.module.sass"

type LoadingBarProps = {
    fillClass: string
    backgroundClass: string
}

const LoadingBar: FunctionComponent<LoadingBarProps> = ({
    fillClass,
    backgroundClass
}) => {
    return (
        <div className={`${styles.loadingBar} ${backgroundClass}`}>
            <div className={fillClass}/>
        </div>
    )
}

export default LoadingBar