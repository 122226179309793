import React, {FunctionComponent, ReactNode, useEffect, useRef, useState} from "react"
import styles from "./fixed.module.sass"

type FixedContainerProps = {
    children: ReactNode
    top: number
}

const FixedContainer: FunctionComponent<FixedContainerProps> = ({
    children,
    top
}) => {
    const placementDiv = useRef<HTMLDivElement>(null)
    const [windowWidth, setWindowWidth] = useState<number>()

    useEffect(() => {
        evaluateWidth()
        window.addEventListener("resize", evaluateWidth)
        return () => window.removeEventListener("resize", evaluateWidth)
    }, [])

    function evaluateWidth() {
        setWindowWidth(window.innerWidth)
    }

    const rect = placementDiv.current?.getBoundingClientRect()
    const left = rect?.left
    const right = rect?.right
    const width = right && left && (right - left)

    return (
        <>
            <div ref={placementDiv}/>
            <div
                className={styles.fixed}
                style={windowWidth && windowWidth >= 1024 && top && left && width ? {
                    position: "fixed",
                    top: `${top}px`,
                    left: `${left}px`,
                    width: `${width}px`
                } : {}}
            >
                {children}
            </div>
        </>
    )
}

export default FixedContainer