import React, {FunctionComponent} from "react"
import TagLine from "../../../../tech/tag-line/tag-line.component"
import NotchesIcon from "../../../../tech/icon/notches.component"
import styles from "./tags.module.sass"

type DealTagsProps = {
    termsTags: string[] | undefined
}

const DealTags: FunctionComponent<DealTagsProps> = ({ termsTags }) => {
    if (termsTags && termsTags.length > 0) {
        return (
            <div className={styles.tags}>
                <div>
                    <NotchesIcon
                        width={16}
                        height={16}
                        fillClass="fill-icon-theme"
                    />
                </div>
                <div>
                    <TagLine tags={termsTags.map(t => ({ name: t }))}/>
                </div>
            </div>
        )
    }
    return <div/>
}

export default DealTags