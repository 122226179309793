import React, {FunctionComponent} from "react"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"

type IndividualInvestmentFormErrorProps = {
    validationErrors: string[]
}

const IndividualInvestmentFormError: FunctionComponent<IndividualInvestmentFormErrorProps> = ({ validationErrors }) => {
    return (
        <Alert
            type={AlertType.ERROR}
            text={(
                <>
                    {validationErrors.length > 0 ? (
                        <>
                            Saving failed with following errors:
                            <ul>
                                {validationErrors.map((error, index) => (
                                    <li key={`individual-investment-validation-error-${index}`}>
                                        {error}
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <>Failed to save.</>
                    )}
                </>
            )}
        />
    )
}

export default IndividualInvestmentFormError