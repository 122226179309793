import {InputFieldProps} from "../props.type"
import React, {FunctionComponent} from "react"
import FormError from "../../error/error.component"
import FieldLabelText from "../label-text.component"
import styles from "./number.module.sass"
import {Control, Controller} from "react-hook-form"

export type NumberFieldProps = InputFieldProps & {
    control: Control<any, any>
}

const NumberField: FunctionComponent<NumberFieldProps> = ({
    label,
    placeholder,
    errors,
    control,
    reactHookFormRegister,
    disabled = false
}) => {
    function calculateNewCursorPosition (rawValue: string, formattedValue: string, cursorPosition: number) {
        let rawIndex = 0
        let formattedIndex = 0
        while (rawIndex < cursorPosition) {
            if (rawValue[rawIndex] !== ',') {
                formattedIndex++
            }
            rawIndex++
        }
        return formattedIndex + (formattedValue.match(/,/g) || []).length
    }

    return (
        <label className={styles.label}>
            <FieldLabelText label={label}/>
            <Controller
                name={reactHookFormRegister.name}
                control={control}
                render={({field: {onChange, value}}) => {
                    return (
                        <input
                            type="text"
                            value={formatNumber(value)}
                            placeholder={placeholder}
                            disabled={disabled}
                            onChange={(e) => {
                                const input = e.target
                                const rawValue = input.value
                                if (rawValue === undefined || rawValue === "") {
                                    onChange(0)
                                }
                                else {
                                    const cursorPosition = input.selectionStart
                                    const rawNumericValue = unformatNumber(rawValue)
                                    const formattedValue = formatNumber(rawNumericValue)
                                    const newCursorPosition = calculateNewCursorPosition(rawValue, formattedValue, cursorPosition || 0)
                                    onChange(rawNumericValue)
                                    setTimeout(() => {
                                        input.setSelectionRange(newCursorPosition, newCursorPosition)
                                    }, 0)
                                }
                            }}
                        />
                    )
                }}
                rules={{
                    ...reactHookFormRegister.options
                }}
            />
            <FormError
                field={reactHookFormRegister.name}
                errors={errors}
            />
        </label>
    )
}

export default NumberField

function formatNumber(value: number | undefined): string {
    if (!value) return ""
    const cleanedValue = value.toString().replace(/[^0-9]/g, '')
    return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function unformatNumber(formattedValue: string): number | undefined {
    if (!formattedValue) return undefined
    return parseInt(formattedValue.replace(/,/g, ''))
}