import React, {FunctionComponent} from "react"
import {useQuery} from "react-query"
import {QUERY__INVESTMENTS_PENDING} from "../investment.query"
import {isErroneous, showLoadingAnimation} from "../../../tech/query/query.util"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Head from "../../../tech/head/head.component"
import EmptyState from "../../../tech/layout/empty/empty.component"
import DealCard from "../../deal/card/card.component"
import {QUERY__PENDING_INVESTMENT_OVERVIEW__PENDING_INVESTMENTS} from "./overview.config"
import {useApp} from "../../../tech/app/context/app.context"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import styles from "./overview.module.sass"

type PendingInvestmentsOverviewProps = {
    onboarding: InvestorOnboardingType
}

const PendingInvestmentsOverview: FunctionComponent<PendingInvestmentsOverviewProps> = ({ onboarding }) => {
    const app = useApp()

    const resultPendingInvestments = useQuery(QUERY__INVESTMENTS_PENDING(
        app.fetchClient,
        QUERY__PENDING_INVESTMENT_OVERVIEW__PENDING_INVESTMENTS
    ))

    return (
        <>
            {showLoadingAnimation(resultPendingInvestments) && <LoadingDots/>}
            {resultPendingInvestments.data && (
                <div className={styles.overview}>
                    <div className={styles.head}>
                        <Head
                            title="Pending Investments"
                            description="Find all Investments that have a commitment from you. You can check the status on each and perform the required actions."
                        />
                    </div>
                    {resultPendingInvestments.data.length < 1 ? (
                        <EmptyState
                            imageSrc="/empty-state/empty-state-pending-investments.svg"
                            textLine1="No pending investments available"
                            textLine2="Start browsing investments"
                        />
                    ) : resultPendingInvestments.data.map((investment) => (
                        <DealCard
                            deal={investment.deal}
                            investment={investment}
                            onboarding={onboarding}
                            pendingInvestment
                            key={investment.id}
                        />
                    ))}
                </div>
            )}
            {isErroneous(resultPendingInvestments) && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load pending investments."
                />
            )}
        </>
    )
}

export default PendingInvestmentsOverview