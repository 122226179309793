export type State = "LOADING"
    | "NONE"
    | "TO_BE_CONFIRMED"
    | ErrorState

export type ErrorState = "ERROR"
    | "ERROR_EMAIL_NOT_ON_WAITLIST"
    | "ERROR_USER_WITH_SAME_EMAIL_ALREADY_EXISTS"

export function isErrorState(state: State): boolean {
    return state === "ERROR"
        || state === "ERROR_EMAIL_NOT_ON_WAITLIST"
        || state === "ERROR_USER_WITH_SAME_EMAIL_ALREADY_EXISTS"
}