/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaitlistFiltersOpenApi
 */
export interface WaitlistFiltersOpenApi {
    /**
     * 
     * @type {string}
     * @memberof WaitlistFiltersOpenApi
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WaitlistFiltersOpenApi
     */
    aiStatusDeclared?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WaitlistFiltersOpenApi
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WaitlistFiltersOpenApi
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof WaitlistFiltersOpenApi
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof WaitlistFiltersOpenApi
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof WaitlistFiltersOpenApi
     */
    referralCode?: string;
}

/**
 * Check if a given object implements the WaitlistFiltersOpenApi interface.
 */
export function instanceOfWaitlistFiltersOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaitlistFiltersOpenApiFromJSON(json: any): WaitlistFiltersOpenApi {
    return WaitlistFiltersOpenApiFromJSONTyped(json, false);
}

export function WaitlistFiltersOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaitlistFiltersOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'aiStatusDeclared': !exists(json, 'aiStatusDeclared') ? undefined : json['aiStatusDeclared'],
        'confirmed': !exists(json, 'confirmed') ? undefined : json['confirmed'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'referralCode': !exists(json, 'referralCode') ? undefined : json['referralCode'],
    };
}

export function WaitlistFiltersOpenApiToJSON(value?: WaitlistFiltersOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'aiStatusDeclared': value.aiStatusDeclared,
        'confirmed': value.confirmed,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'referralCode': value.referralCode,
    };
}

