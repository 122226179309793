import React, {FunctionComponent} from "react"
import {InvestmentWithPaymentDetailsType} from "../../../investment-with-payment-details.type"
import PendingInvestmentCardPaymentSelectCurrency from "./select-currency/select-currency.component"
import PendingInvestmentCardPaymentDetails from "./payment-details/payment-details.component"
import {needsToSelectCurrency} from "./payment.util"
import commonStyles from "../common.module.sass"
import styles from "./payment.module.sass"

type PendingInvestmentCardPaymentProps = {
    investmentWithPaymentDetails: InvestmentWithPaymentDetailsType
}

const PendingInvestmentCardPayment: FunctionComponent<PendingInvestmentCardPaymentProps> = ({ investmentWithPaymentDetails }) => {
    return (
        <>
            <div className={commonStyles.title}>
                Complete payment
            </div>
            <div className={commonStyles.paragraph}>
                {needsToSelectCurrency(investmentWithPaymentDetails.investment)
                    ? "After you have chosen the currency you want to pay in we show you the payment details."
                    : "You need to transact the exact amount in the account number shown below."}
            </div>
            <div className={styles.whiteBackground}>
                {needsToSelectCurrency(investmentWithPaymentDetails.investment) ? (
                    <PendingInvestmentCardPaymentSelectCurrency investmentWithPaymentDetails={investmentWithPaymentDetails}/>
                ) : (
                    <PendingInvestmentCardPaymentDetails investmentWithPaymentDetails={investmentWithPaymentDetails}/>
                )}
            </div>
        </>
    )
}

export default PendingInvestmentCardPayment