import {FetchClient} from "../../tech/fetch/client"
import {
    QUERY_KEY_PART__LIST,
    QUERY_KEY_PART__SINGLE,
    QueryKey,
    QueryKeyWithParameters,
    QueryType
} from "../../tech/query/query.type"
import {DealOnboardingDependentWithInvestmentType} from "./onboarding-dependent.type"
import {QueryClient} from "react-query"

export const QUERY_KEY__DEAL_BASE = "deal"
const QUERY_KEY__DEAL_WITH_INVESTMENT_BASE = "deal-with-investment"

export function QUERY_KEY__DEAL_WITH_INVESTMENT(parameters: QUERY_KEY_PARAMETERS__DEAL_WITH_INVESTMENT): QueryKey {
    return [
        QUERY_KEY__DEAL_WITH_INVESTMENT_BASE,
        QUERY_KEY_PART__SINGLE,
        parameters.id
    ]
}

export type QUERY_KEY_PARAMETERS__DEAL_WITH_INVESTMENT = {
    id: string
}

export function QUERY__DEAL_WITH_INVESTMENT(
    fetchClient: FetchClient,
    keyWithParameters: QueryKeyWithParameters<QUERY_KEY_PARAMETERS__DEAL_WITH_INVESTMENT>,
    enabled: boolean = true,
    refetchIntervalInMilliseconds?: number
): QueryType<DealOnboardingDependentWithInvestmentType> {
    return {
        queryKey: keyWithParameters.keyFunction(keyWithParameters.parameters),
        queryFn: () => fetchClient.dealApi.getWithInvestment(keyWithParameters.parameters.id),
        enabled,
        refetchInterval: refetchIntervalInMilliseconds ? refetchIntervalInMilliseconds : false
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function QUERY_KEY__DEALS_BASE(): QueryKey {
    return [
        QUERY_KEY__DEAL_BASE,
        QUERY_KEY_PART__LIST
    ]
}

export function QUERY_KEY__DEALS(parameters: QUERY_KEY_PARAMETERS__DEALS): QueryKey {
    return [
        ...QUERY_KEY__DEALS_BASE(),
        {
            exclusivesOnly: parameters.exclusivesOnly,
            favouritesOnly: parameters.favouritesOnly
        }
    ]
}

export type QUERY_KEY_PARAMETERS__DEALS = {
    exclusivesOnly: boolean
    favouritesOnly: boolean
}

export function QUERY__DEALS(
    fetchClient: FetchClient,
    queryClient: QueryClient,
    keyWithParameters: QueryKeyWithParameters<QUERY_KEY_PARAMETERS__DEALS>,
    enabled: boolean = true
): QueryType<DealOnboardingDependentWithInvestmentType[]> {
    return {
        queryKey: keyWithParameters.keyFunction(keyWithParameters.parameters),
        queryFn: () => fetchClient.dealApi.getAllOpen(
            keyWithParameters.parameters.exclusivesOnly,
            keyWithParameters.parameters.favouritesOnly
        ),
        enabled,
        onSuccess: (data) => updateSingleDealWithInvestmentCache(queryClient, data)
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function updateSingleDealWithInvestmentCache(
    queryClient: QueryClient,
    deals: DealOnboardingDependentWithInvestmentType[]
) {
    for (const dealOnboardingDependentWithWithInvestment of deals) {
        queryClient.setQueryData(
            QUERY_KEY__DEAL_WITH_INVESTMENT({ id: dealOnboardingDependentWithWithInvestment.deal.id! }),
            dealOnboardingDependentWithWithInvestment
        )
    }
}