import {DEAL} from "../../../../../../paths"
import {NavigateFunction} from "react-router-dom"
import {InvestorDashboardInvestmentType} from "../../../dashboard.type"
import {IndividualInvestmentPopupStateType} from "../../../../../investment/individual/popup/popup.type"

export function navigateOnRowClick(
    investment: InvestorDashboardInvestmentType,
    navigate: NavigateFunction,
    updateIndividualInvestmentPopup: (state: IndividualInvestmentPopupStateType) => void
) {
    if (investment.investment.investment.type === "REGULAR") {
        navigate(DEAL((investment.investment.investment.value.deal.id!)))
    }
    else {
        updateIndividualInvestmentPopup({
            type: "EDIT",
            investment: investment.investment.investment.value
        })
    }
}