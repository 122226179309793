import React, {FunctionComponent, ReactNode} from "react"
import {buildICalUrl, isSafariMobile} from "./ical.util"
import {CalendarEvent} from "./event.type"

type AddToCalendarProps = {
    calendarEvent: CalendarEvent
    children: ReactNode
}

const AddToCalendar: FunctionComponent<AddToCalendarProps> = ({ calendarEvent, children }) => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        e.stopPropagation()

        const url: string = buildICalUrl(calendarEvent, isSafariMobile())
        const blob: Blob = new Blob([url], {
            type: "text/calendar;charset=utf-8"
        })

        if (isSafariMobile()) {
            window.open(url, "_blank")
        }
        else {
            const linkElement = document.createElement("a")
            linkElement.href = window.URL.createObjectURL(blob)
            linkElement.setAttribute("download", `${calendarEvent.title}.ics`)
            document.body.appendChild(linkElement)
            linkElement.click()
            document.body.removeChild(linkElement)
        }
    }

    return (
        <a onClick={onClick} href={window.location.href}>
            {children}
        </a>
    )
}

export default AddToCalendar