import {InvestorOnboardingType} from "../investor/onboarding/onboarding.type"
import {SyndicateType} from "./syndicate.type"
import {DealOnboardingDependentType} from "../deal/onboarding-dependent.type"
import {DealType} from "../deal/deal.type"

export function getSyndicateOfDeal(
    deal: DealOnboardingDependentType,
    onboarding: InvestorOnboardingType
): SyndicateType | undefined {
    if (deal instanceof DealType) {
        return (onboarding.investorWithDetails?.syndication.memberships || [])
            .find(syndicate => syndicate.investorGroupIds.some(syndicateInvestorGroupId => deal.investorGroupIds.includes(syndicateInvestorGroupId)))
    }
    return undefined
}