import React, {FunctionComponent} from "react"
import AddToCalendar from "../../../../tech/add-to-calendar/add-to-calendar.component"
import styles from "./event.module.sass"
import CalendarIcon from "../../../../tech/icon/calendar.component"
import {formatDateTime} from "../../../../tech/format/datetime.util"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import {DealType} from "../../deal.type"

type DealEventProps = {
    deal: DealOnboardingDependentType
}

const DealEvent: FunctionComponent<DealEventProps> = ({ deal }) => {
    return deal instanceof DealType && deal.event && deal.event.start >= new Date() ? (
        <div className={styles.event}>
            <div>
                <div>
                    <strong>Join founders call</strong>
                    <div>
                        {formatDateTime(deal.event.start)}
                    </div>
                </div>
                <button className={styles.addToCalendar}>
                    <AddToCalendar
                        calendarEvent={{
                            title: deal.event.title,
                            start: deal.event.start,
                            end: deal.event.end ? deal.event.end : addOneHour(deal.event.start),
                            location: deal.event.location,
                            url: deal.event.url
                        }}
                    >
                        <div>
                            <CalendarIcon
                                width={16}
                                height={16}
                                fillClass="fill-icon-theme"
                            />
                        </div>
                        <div>
                            Add to calendar
                        </div>
                    </AddToCalendar>
                </button>
            </div>
        </div>
    ) : (
        <></>
    )
}

export default DealEvent

function addOneHour(date: Date) {
    date.setHours(date.getHours() + 1)
    return date
}