import {IndividualInvestmentApiClient, ResponseError, ValidationResponseOpenApiFromJSON} from "../../../generated"
import {IndividualInvestmentSaveResponse} from "./response.type"
import {IndividualInvestmentType} from "./individual-investment.type"
import {mapIndividualInvestmentToOpenApi, mapOpenApiToIndividualInvestment} from "./individual-investment.mapper"

export class IndividualInvestmentApi {

    constructor(private apiClient: IndividualInvestmentApiClient) {
    }

    public async create(investment: IndividualInvestmentType): Promise<IndividualInvestmentSaveResponse> {
        try {
            return {
                type: "SUCCESS",
                investment: mapOpenApiToIndividualInvestment(await this.apiClient.individualInvestmentCreate({
                    individualInvestmentOpenApi: mapIndividualInvestmentToOpenApi(investment)
                }))
            }
        }
        catch (err) {
            if ((err as ResponseError)?.response?.status === 400) {
                return {
                    type: "VALIDATION_ERROR",
                    errors: ValidationResponseOpenApiFromJSON(await (err as ResponseError).response.json()).errors?.map(e => e.message) ?? []
                }
            }
            return { type: "ERROR" }
        }
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.individualInvestmentDelete({ id })
    }

    public async update(
        id: string,
        investment: IndividualInvestmentType
    ): Promise<IndividualInvestmentSaveResponse> {
        try {
            return {
                type: "SUCCESS",
                investment: mapOpenApiToIndividualInvestment(await this.apiClient.individualInvestmentUpdate({
                    id,
                    individualInvestmentOpenApi: mapIndividualInvestmentToOpenApi(investment)
                }))
            }
        }
        catch (err) {
            if ((err as ResponseError)?.response?.status === 400) {
                return {
                    type: "VALIDATION_ERROR",
                    errors: ValidationResponseOpenApiFromJSON(await (err as ResponseError).response.json()).errors?.map(e => e.message) ?? []
                }
            }
            return { type: "ERROR" }
        }
    }

}