import React, {FunctionComponent, ReactNode, useEffect} from "react"
import Overlay from "../overlay/overlay.component"
import styles from "./modal.module.sass"
import {disableBodyScroll, enableBodyScroll} from "../layout.util"
import XIcon from "../../icon/x.component"

type ModalProps = {
    children: ReactNode
    close?: () => void
}

const Modal: FunctionComponent<ModalProps> = ({
    children,
    close
}) => {
    useEffect(() => {
        disableBodyScroll()

        return () => {
            enableBodyScroll()
        }
    }, [])

    return (
        <>
            {close && (
                <div
                    className={styles.close}
                    onClick={close}
                >
                    <XIcon
                        width={24}
                        height={24}
                        fillClass="fill-icon-theme"
                    />
                </div>
            )}
            <div className={styles.wrapper}>
                <div>
                    {children}
                </div>
            </div>
            <Overlay/>
        </>
    )
}

export default Modal