import {
    CategoryFilterType,
    FilterType,
    GeographyFilterType,
    InvestmentTypeFilterType,
    StageFilterType,
    StatusFilterType,
    WithInvestmentFilterType,
    WithInvestmentOptions
} from "./filter.type"
import {DealStageOpenApi, DealStatusOpenApi, InvestorDashboardInvestmentTypeOpenApi} from "../../generated"
import {DealOnboardingDependentWithInvestmentType} from "../../domain/deal/onboarding-dependent.type"
import {FilterOptions} from "./options/options.type"

export function filterByFilterTypes(deal: DealOnboardingDependentWithInvestmentType, filterTypes: FilterType[]): boolean {
    const groups = filterTypes
        .reduce((groups, filterType) => {
            if (!groups.has(filterType.type)) {
                groups.set(filterType.type, [])
            }
            groups.get(filterType.type)!.push(filterType)
            return groups
        }, new Map<FilterOptions, FilterType[]>())
    return Array.from(groups)
        .every(([key, value]) => filterByFilterTypeGroup(deal, value))
}

function filterByFilterTypeGroup(
    deal: DealOnboardingDependentWithInvestmentType,
    filterTypes: FilterType[]
): boolean {
    return filterTypes.some(f => filterByFilterType(deal, f))
}

function filterByFilterType(dealWithInvestment: DealOnboardingDependentWithInvestmentType, filterType: FilterType): boolean {
    if (filterType.type === "CATEGORY") {
        return dealWithInvestment.deal.categories
            .map(c => c.id)
            .includes(filterType.category.id)
    }
    if (filterType.type === "GEOGRAPHY") {
        return dealWithInvestment.deal.geography?.id === filterType.geography.id
    }
    if (filterType.type === "STAGE") {
        return dealWithInvestment.deal.stage === filterType.stage
    }
    if (filterType.type === "STATUS") {
        return ((filterType.status === "EXITED" && dealWithInvestment.deal.exited !== undefined)
            || (filterType.status === "LIVE" && dealWithInvestment.deal.exited === undefined))
    }
    if (filterType.type === "WITH_INVESTMENT") {
        return (filterType.option === "WITH" && dealWithInvestment.investmentWithPaymentDetails !== undefined)
            || (filterType.option === "WITHOUT" && dealWithInvestment.investmentWithPaymentDetails === undefined)
    }
    return true
}

export function getCategoryIdsFromFilterTypes(filterTypes: FilterType[]): string[] {
    return filterTypes
        .filter(f => f.type === "CATEGORY")
        .map(f => (f as CategoryFilterType).category.id!)
}

export function getGeographyIdsFromFilterTypes(filterTypes: FilterType[]): string[] {
    return filterTypes
        .filter(f => f.type === "GEOGRAPHY")
        .map(f => (f as GeographyFilterType).geography.id!)
}

export function getInvestmentTypesFromFilterTypes(filterTypes: FilterType[]): InvestorDashboardInvestmentTypeOpenApi[] {
    return filterTypes
        .filter(f => f.type === "INVESTMENT_TYPE")
        .map(f => (f as InvestmentTypeFilterType).investmentType)
}

export function getStageFromFilterTypes(filterTypes: FilterType[]): DealStageOpenApi[] {
    return filterTypes
        .filter(f => f.type === "STAGE")
        .map(f => (f as StageFilterType).stage)
}

export function getStatusFromFilterTypes(filterTypes: FilterType[]): DealStatusOpenApi[] {
    return filterTypes
        .filter(f => f.type === "STATUS")
        .map(f => (f as StatusFilterType).status)
}

export function getWithInvestmentFilterTypes(filterTypes: FilterType[]): WithInvestmentOptions[] {
    return filterTypes
        .filter(f => f.type === "WITH_INVESTMENT")
        .map(f => (f as WithInvestmentFilterType).option)
}