import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CaretRightIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
        >
            <path
                className={fillClass}
                d="M11.354 8.354l-5 5a.5.5 0 0 1-.354.147.5.5 0 0 1-.354-.147A.5.5 0 0 1 5.5 13a.5.5 0 0 1 .147-.354L10.293 8 5.646 3.354a.5.5 0 0 1 0-.707.5.5 0 0 1 .707 0l5 5A.5.5 0 0 1 11.5 8a.5.5 0 0 1-.147.354z"
            />
        </svg>
    )
}

export default CaretRightIcon