import React, {useEffect, useState} from "react"
import {confirmSignUp} from "aws-amplify/auth"
import LoadingDots from "../../loading/dots/dots.component"
import Alert from "../../alert/alert.component"
import {AlertType} from "../../alert/type.enum"
import {sendJavaScriptError} from "../../logging/error-logger"
import AuthHead from "../head/head.component"
import styles from "./verify-email.module.sass"
import {useNavigate, useSearchParams} from "react-router-dom"
import {addQueryParameters} from "../../routing/parameters.util"
import {SIGN_IN} from "../../../paths"
import {useApp} from "../../app/context/app.context"

const VerifyEmail = () => {
    const app = useApp()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [state, setState] = useState<"LOADING" | "ERROR">("LOADING")
    const code = searchParams.get("c") || undefined
    const email = searchParams.get("email") || undefined
    const username = searchParams.get("username") || undefined

    useEffect(() => {
        const verify = async () => {
            try {
                await confirmSignUp({
                    username: username!,
                    confirmationCode: code!
                })
                navigate(buildRedirectUrl(email))
            }
            catch (err) {
                // @ts-ignore
                if (err["message"] !== undefined && err["message"] === "User cannot be confirmed. Current status is CONFIRMED") {
                    navigate(buildRedirectUrl(email))
                }
                else {
                    console.error(err)
                    await sendJavaScriptError(err, app.fetchClient)
                    setState("ERROR")
                }
            }
        }

        if (code === undefined || username === undefined) {
            setState("ERROR")
        }
        else {
            verify()
        }
    }, [code, email, username, app.fetchClient, navigate])

    return (
        <>
            <AuthHead title="Verify Email"/>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to verify."
                    />
                </div>
            )}
        </>
    )
}

export default VerifyEmail

function buildRedirectUrl(email?: string): string {
    const parameters = [{ key: "confirmed", value: "true" }]
    if (email) {
        parameters.push({
            key: "email",
            value: email.trim().replaceAll(" ", "+")
        })
    }
    return addQueryParameters(
        SIGN_IN,
        parameters
    )
}