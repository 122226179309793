import React, {FunctionComponent, ReactNode} from "react"
import {InvestorOnboardingType} from "../../../investor/onboarding/onboarding.type"
import {InvestmentWithPaymentDetailsType} from "../../investment-with-payment-details.type"
import {evaluateStage} from "../stage/stage.util"
import PendingInvestmentCardSignDocuments from "./sign-documents/sign-documents.component"
import PendingInvestmentCardWaitForMinimumAmount from "./wait-minimum-amount/wait-minimum-amount.component"
import PendingInvestmentCardPayment from "./payment/payment.component"
import styles from "./card.module.sass"
import PendingInvestmentCardWaitForDealClosing from "./wait-for-closing/wait-for-closing.component"
import PendingInvestmentCardWaitForManualPaymentInstructionsTrigger
    from "./wait-manual-payment-trigger/wait-manual-payment-trigger.component"

type PositionType = "MOBILE" | "DESKTOP"

type PendingInvestmentCardProps = {
    investmentWithPaymentDetails?: InvestmentWithPaymentDetailsType
    onboarding: InvestorOnboardingType
    position: PositionType
}

const PendingInvestmentCard: FunctionComponent<PendingInvestmentCardProps> = ({
    investmentWithPaymentDetails,
    onboarding,
    position
}) => {
    if (!investmentWithPaymentDetails) return <></>
    return (
        <>
            {{
                "SIGN_DOCUMENTS": (
                    <CardInner position={position}>
                        <PendingInvestmentCardSignDocuments investment={investmentWithPaymentDetails.investment}/>
                    </CardInner>
                ),
                "WAIT_FOR_MINIMUM_AMOUNT": (
                    <CardInner position={position}>
                        <PendingInvestmentCardWaitForMinimumAmount/>
                    </CardInner>
                ),
                "WAIT_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER": (
                    <CardInner position={position}>
                        <PendingInvestmentCardWaitForManualPaymentInstructionsTrigger/>
                    </CardInner>
                ),
                "MAKE_PAYMENT": (
                    <CardInner position={position}>
                        <PendingInvestmentCardPayment investmentWithPaymentDetails={investmentWithPaymentDetails}/>
                    </CardInner>
                ),
                "WAIT_FOR_CLOSING": (
                    <CardInner position={position}>
                        <PendingInvestmentCardWaitForDealClosing/>
                    </CardInner>
                ),
                "CLOSED": <></>,
                "FINALIZED": <></>
            }[evaluateStage(investmentWithPaymentDetails.investment, onboarding)]}
        </>
    )
}

export default PendingInvestmentCard

const CardInner: FunctionComponent<{
    children: ReactNode
    position: PositionType
}> = ({
    children,
    position
}) => {
    return (
        <div className={`${styles.card} ${position === "MOBILE" ? styles.mobile : ""} ${position === "DESKTOP" ? styles.desktop : ""}`}>
            {children}
        </div>
    )
}