import {DealOnboardingDependentWithInvestmentType} from "../../domain/deal/onboarding-dependent.type"
import {InvestorOnboardingType} from "../../domain/investor/onboarding/onboarding.type"
import {evaluateRelevantRoutes} from "../routing/routing.util"
import {DEALS} from "../../paths"

export function showBreadcrumb(
    openDeals: DealOnboardingDependentWithInvestmentType[],
    onboarding: InvestorOnboardingType
): boolean {
    return evaluateRelevantRoutes(openDeals, onboarding)
        .map(r => r.path)
        .includes(DEALS)
}