import React, {FunctionComponent} from "react"
import PendingInvestmentCardPaymentCopy from "../copy/copy.component"
import styles from "./payment-details.module.sass"

type PaymentDetailFieldProps = {
    label: string
    text: string
    value: number | string
}

const PaymentDetailField: FunctionComponent<PaymentDetailFieldProps> = ({
    label,
    text,
    value
}) => {
    return (
        <div className={styles.field}>
            <div className={styles.label}>
                {label}
            </div>
            <PendingInvestmentCardPaymentCopy
                text={text}
                value={value}
            />
        </div>
    )
}

export default PaymentDetailField