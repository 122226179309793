import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CalendarIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M13 2h-1.5v-.5A.5.5 0 0 0 11 1a.5.5 0 0 0-.5.5V2h-5v-.5A.5.5 0 0 0 5 1a.5.5 0 0 0-.5.5V2H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM4.5 3v.5A.5.5 0 0 0 5 4a.5.5 0 0 0 .5-.5V3h5v.5a.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5V3H13v2H3V3h1.5zM13 13H3V6h10v7zM7 7.5v4a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V8.309l-.276.139a.5.5 0 0 1-.671-.224.5.5 0 0 1 .224-.671l1-.5a.5.5 0 0 1 .487.022A.5.5 0 0 1 7 7.5zm3.698 1.903L9.5 11h1a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.447-.276.5.5 0 0 1 .047-.524l1.799-2.398a.5.5 0 0 0 .093-.385.5.5 0 0 0-.216-.332.5.5 0 0 0-.39-.07.5.5 0 0 0-.319.235.5.5 0 0 1-.304.243.5.5 0 0 1-.386-.048.5.5 0 0 1-.235-.31.5.5 0 0 1 .058-.384 1.5 1.5 0 0 1 1.687-.698A1.5 1.5 0 0 1 11 8.5c.001.326-.105.643-.303.903z"
            />
        </svg>
    )
}

export default CalendarIcon