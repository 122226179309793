import {
    LineChartDataPointType,
    LineChartDataPointWithXCoordinateType,
    LineChartLineWithColorAndCoordinatesType,
    LineChartLineWithColorType
} from "./line.type"
import {compareDates} from "../../sort/sort.util"
import {TooltipDataPointType} from "./tooltip/tooltip.type"

export function addCoordinates(
    lines: LineChartLineWithColorType[],
    xAxisScale: (value: Date) => number
): LineChartLineWithColorAndCoordinatesType[] {
    return lines.map(line => ({
        ...line,
        points: addCoordinatesAndSort(line.points, xAxisScale)
    }))
}

function addCoordinatesAndSort(
    points: LineChartDataPointType[],
    xAxisScale: (value: Date) => number
): LineChartDataPointWithXCoordinateType[] {
    return points
        .sort((a, b) => compareDates(a.date, b.date, "ASCENDING"))
        .map(p => ({
            date: {
                value: p.date,
                x: xAxisScale(p.date)
            },
            description: p.description,
            value: p.value,
            preventHover: p.preventHover
        }))
}

export function findNearestDataPoints(
    x: number,
    lines: LineChartLineWithColorAndCoordinatesType[]
): TooltipDataPointType[] {
    return lines
        .map(line => {
            let minDistance = Infinity
            let nearestPoint: TooltipDataPointType | undefined
            line.points
                .filter(p => !p.preventHover)
                .forEach(point => {
                    const distance = Math.abs(point.date.x - x)
                    if (distance < minDistance ) {
                        minDistance = distance
                        nearestPoint = {
                            label: line.label,
                            color: line.color,
                            dataPoint: point
                        }
                    }
                }
            )

            return nearestPoint && Math.abs(nearestPoint.dataPoint.date.x - x) <= 20
                ? nearestPoint
                : undefined
        })
        .filter(p => p !== undefined) as TooltipDataPointType[]
}
