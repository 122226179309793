import {InvestmentFeesOpenApi, InvestmentFeeWithAmountOpenApi} from "../../../generated"
import {InvestmentFeesType, InvestmentFeeWithAmountType} from "./fees.type"

export function mapOpenApiToInvestmentFees(fees: InvestmentFeesOpenApi): InvestmentFeesType {
    return {
        placement: mapOpenApiToInvestmentFeeWithAmount(fees.placement),
        management: fees.management,
        carry: fees.carry,
        exchange: fees.exchange && mapOpenApiToInvestmentFeeWithAmount(fees.exchange)
    }
}

export function mapOpenApiToInvestmentFeeWithAmount(feeWithAmount: InvestmentFeeWithAmountOpenApi): InvestmentFeeWithAmountType {
    return new InvestmentFeeWithAmountType({
        amount: feeWithAmount.amount,
        percent: feeWithAmount.percent
    })
}