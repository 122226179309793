import {CurrencyOpenApi} from "../../../generated"
import {QUERY_KEY__INVESTOR_DASHBOARD, QUERY_KEY_PARAMETERS__INVESTOR_DASHBOARD} from "./dashboard.query"
import {QueryKeyWithParameters} from "../../../tech/query/query.type"

export const DEFAULT_TARGET_CURRENCY = CurrencyOpenApi.Usd

export const QUERY__INVESTOR_DASHBOARD__INVESTOR_DASHBOARD: QueryKeyWithParameters<QUERY_KEY_PARAMETERS__INVESTOR_DASHBOARD> = {
    keyFunction: QUERY_KEY__INVESTOR_DASHBOARD,
    parameters: {
        filterTypes: [],
        targetCurrency: DEFAULT_TARGET_CURRENCY
    }
}