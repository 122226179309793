import React, {FunctionComponent, ReactNode, useEffect, useState} from "react"
import {useQueryClient} from "react-query"
import {fetchAuthSession, updateUserAttributes} from "aws-amplify/auth"
import {Hub} from "aws-amplify/utils"
import {APP_CONTEXT} from "./app.context"
import {AppContextState} from "./context.state"
import {getCurrentUser} from "./get-current-user"
import {FetchClient} from "../../fetch/client"
import {DealOnboardingDependentWithInvestmentType} from "../../../domain/deal/onboarding-dependent.type"
import {InvestorOnboardingType} from "../../../domain/investor/onboarding/onboarding.type"
import {QUERY_KEY__INVESTOR_ONBOARDING} from "../../../domain/investor/onboarding/onboarding.query"
import {QUERY__DEAL_OVERVIEW__DEALS} from "../../../domain/deal/overview.config"
import {updateSingleDealWithInvestmentCache} from "../../../domain/deal/deal.query"
import {buildUserAttributes, INVITATION_CODE_LOCAL_STORAGE_KEY} from "../../../domain/syndicate/invitation-code.util"
import {removePreviousPath} from "../../redirect/redirect.util"

type AppContextProviderProps = {
    children: ReactNode
}

const AppContextProvider: FunctionComponent<AppContextProviderProps> = ({ children }) => {
    const app = useProvideApp()
    return (
        <APP_CONTEXT.Provider value={app}>
            {children}
        </APP_CONTEXT.Provider>
    )
}

const useProvideApp = (): AppContextState => {
    const queryClient = useQueryClient()
    const [state, setState] = useState<AppContextState>(new AppContextState())

    useEffect(() => {
        Hub.listen("auth", (data) => {
            switch (data.payload.event) {
                case "signedIn":
                    setUp()
                    break;
                case "signedOut":
                    removePreviousPath()
                    setState(AppContextState.reset())
                    queryClient.clear()
                    break;
                case "tokenRefresh":
                    fetchAuthSession()
                        .then(session => setState(currentState => currentState.withFetchClient(new FetchClient(session.tokens))))
                    break;
            }
        })

        async function preflight(fetchClient: FetchClient) {
            try {
                if ((await fetchClient.preflightApi.get()) === "SUCCESS") {
                    setState(currentState => currentState.withPreflightDone(true))
                }
            }
            catch (err) {
                // ignore
            }
        }

        async function setUp() {
            const currentUser = await getCurrentUser()
            setState(currentState => currentState
                .withCurrentUser(currentUser)
                .withCurrentUserFetched(true))
            if (currentUser) {
                const fetchClient = new FetchClient((await fetchAuthSession()).tokens)
                const invitationCode = localStorage.getItem(INVITATION_CODE_LOCAL_STORAGE_KEY)
                if (invitationCode) {
                    await updateUserAttributes(buildUserAttributes(invitationCode))
                    localStorage.removeItem(INVITATION_CODE_LOCAL_STORAGE_KEY)
                }
                setState(currentState => currentState.withFetchClient(fetchClient))
                await preflight(fetchClient)
            }
        }
        setUp()
    }, [queryClient])

    useEffect(() => {
        async function fetch() {
            const onboardingAndOpenDeals = await fetchOnboardingAndOpenDeals(state.fetchClient)
            queryClient.setQueryData(
                QUERY__DEAL_OVERVIEW__DEALS.keyFunction(QUERY__DEAL_OVERVIEW__DEALS.parameters),
                () => onboardingAndOpenDeals.openDeals
            )
            queryClient.setQueryData(
                QUERY_KEY__INVESTOR_ONBOARDING(),
                () => onboardingAndOpenDeals.onboarding
            )
            updateSingleDealWithInvestmentCache(
                queryClient,
                onboardingAndOpenDeals.openDeals!
            )
            setState(currentState => currentState.withOnboardingAndOpenDealsFetched(true))
        }

        if (state.steps.currentUserFetched && state.steps.preflightDone) {
            fetch()
        }
    }, [
        queryClient,
        state.authenticated,
        state.fetchClient,
        state.steps.currentUserFetched,
        state.steps.preflightDone
    ])

    return state
}

export default AppContextProvider

async function fetchOnboardingAndOpenDeals(fetchClient: FetchClient): Promise<{
    onboarding: InvestorOnboardingType,
    openDeals: DealOnboardingDependentWithInvestmentType[]
}> {
    const [onboarding, openDeals] = await Promise.all([
        fetchClient.investorApi.getOnboarding(),
        fetchClient.dealApi.getAllOpen(
            QUERY__DEAL_OVERVIEW__DEALS.parameters.exclusivesOnly,
            QUERY__DEAL_OVERVIEW__DEALS.parameters.favouritesOnly
        )
    ])
    return {
        onboarding,
        openDeals
    }
}