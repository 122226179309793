import React, {FunctionComponent} from "react"
import {format} from "../format.util"
import {CurrencyOpenApi} from "../../../../../generated"
import styles from "./amount.module.sass"

type DealCommitmentAmountProps = {
    dealCurrency: CurrencyOpenApi
    investedAmount: number
    minTarget: number
    maxTarget: number
}

const DealCommitmentAmount: FunctionComponent<DealCommitmentAmountProps> = ({
    dealCurrency,
    investedAmount,
    minTarget,
    maxTarget
}) => {
    const committedOfMaxPercent = investedAmount / maxTarget
    const minTargetMaxTargetRatio = minTarget / maxTarget
    const live = investedAmount >= minTarget
    const width = committedOfMaxPercent * 100 > 100 ? 100 : committedOfMaxPercent * 100
    return (
        <div className={styles.amount}>
            <div className={live ? styles.barLive : styles.bar}>
                <div
                    className={styles.fill}
                    style={{ width: width + "%" }}
                />
            </div>
            {!live && (
                <div className={styles.texts}>
                    {minTarget === maxTarget ? (
                        <>
                            {format(maxTarget, dealCurrency)} Target
                        </>
                    ) : minTargetMaxTargetRatio > 0.70 ? (
                        <>
                            {format(minTarget, dealCurrency)} Min /{" "}
                            {format(maxTarget, dealCurrency)} Max
                        </>
                    ) : (
                        <>
                            <div
                                className={styles.minBar}
                                style={{ left: minTargetMaxTargetRatio * 100 + "%" }}
                            >
                                <div>
                                </div>
                            </div>
                            <div
                                className={`${styles.minText} ${minTargetMaxTargetRatio < 0.1 ? styles.leftAligned : ""}`}
                                style={{ left: (minTargetMaxTargetRatio < 0.1 ? 0 : minTargetMaxTargetRatio * 100) + "%" }}
                            >
                                {format(minTarget, dealCurrency)} Min
                            </div>
                            <div className={styles.max}>
                                {format(maxTarget, dealCurrency)} Max
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default DealCommitmentAmount