import {InvestorOnboardingType} from "../../domain/investor/onboarding/onboarding.type"
import {DEAL, DEALS} from "../../paths"
import {RouteAvailability, RouteData} from "./route.type"
import {DealOnboardingDependentWithInvestmentType} from "../../domain/deal/onboarding-dependent.type"
import {routes} from "./routes"

export function evaluateEntryPath(
    openDeals: DealOnboardingDependentWithInvestmentType[],
    onboarding: InvestorOnboardingType
): string {
    const routeAvailability = evaluateRouteAvailability(onboarding, openDeals.length)
    const shouldRedirectToDeal = routeAvailability.type === "SYNDICATE_ONLY_INVESTORS"
        && routeAvailability.investorsWithSingleDeal
        && openDeals.length === 1
    if (shouldRedirectToDeal) {
        return DEAL(openDeals[0].deal.id!)
    }
    return DEALS
}

export function evaluateRelevantRoutes(
    openDeals: DealOnboardingDependentWithInvestmentType[],
    onboarding: InvestorOnboardingType
): RouteData[] {
    const routeAvailability = evaluateRouteAvailability(onboarding, openDeals.length)
    return routes.filter(r => {
        if (routeAvailability.type === "FULL_PLATFORM_ACCESS") {
            return true
        }
        if (r.availability.type === "FULL_PLATFORM_ACCESS") {
            return false
        }
        return (routeAvailability.investorsWithNoDeals && r.availability.investorsWithNoDeals)
            || (routeAvailability.investorsWithSingleDeal && r.availability.investorsWithSingleDeal)
            || (routeAvailability.investorsWithMultipleDeals && r.availability.investorsWithMultipleDeals)
            || (routeAvailability.investorsWithMultipleSyndicates && r.availability.investorsWithMultipleSyndicates)
    })
}

function evaluateRouteAvailability(
    onboarding: InvestorOnboardingType,
    numberOfOpenDeals: number
): RouteAvailability {
    const investorWithDetails = onboarding.investorWithDetails
    if (investorWithDetails === undefined || !investorWithDetails.syndication.syndicateOnlyInvestor) {
        return {
            type: "FULL_PLATFORM_ACCESS"
        }
    }
    return {
        type: "SYNDICATE_ONLY_INVESTORS",
        investorsWithNoDeals: numberOfOpenDeals === 0,
        investorsWithSingleDeal: numberOfOpenDeals === 1,
        investorsWithMultipleDeals: numberOfOpenDeals > 1,
        investorsWithMultipleSyndicates: investorWithDetails.syndication.memberships.length > 1
    }
}