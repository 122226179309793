import React, {FunctionComponent} from "react"
import styles from "./logo.module.sass"
import {InvestorDashboardUnderlyingInvestmentType} from "../investment.type"
import Imgix from "react-imgix"

type InvestorDashboardInvestmentLogoProps = {
    investment: InvestorDashboardUnderlyingInvestmentType
}

const InvestorDashboardInvestmentLogo: FunctionComponent<InvestorDashboardInvestmentLogoProps> = ({ investment }) => {
    const deal = investment.investment.type === "REGULAR"
        ? investment.investment.value.deal
        : undefined
    return (
        <div>
            <div className={styles.logoAndTitle}>
                {deal?.logos?.normal?.url && (
                    <div className={styles.logo}>
                        <Imgix
                            src={deal.logos.normal.url}
                            height={28}
                            htmlAttributes={{ alt: deal.name }}
                        />
                    </div>
                )}
                <div className={styles.title}>
                    <div>
                        {investment.getName()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestorDashboardInvestmentLogo