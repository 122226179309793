import React from "react"
import {Link} from "react-router-dom"
import {RESET_PASSWORD} from "../../../../paths"
import styles from "./link.module.sass"

const ResetPasswordLink = () => {
    return (
        <div className={styles.link}>
            <Link to={RESET_PASSWORD}>
                Forgot password?
            </Link>
        </div>
    )
}

export default ResetPasswordLink