/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorOnboardingOpenApi } from './InvestorOnboardingOpenApi';
import {
    InvestorOnboardingOpenApiFromJSON,
    InvestorOnboardingOpenApiFromJSONTyped,
    InvestorOnboardingOpenApiToJSON,
} from './InvestorOnboardingOpenApi';
import type { SubscribeResultStripePaymentIntentOpenApi } from './SubscribeResultStripePaymentIntentOpenApi';
import {
    SubscribeResultStripePaymentIntentOpenApiFromJSON,
    SubscribeResultStripePaymentIntentOpenApiFromJSONTyped,
    SubscribeResultStripePaymentIntentOpenApiToJSON,
} from './SubscribeResultStripePaymentIntentOpenApi';

/**
 * 
 * @export
 * @interface SubscribeResultOpenApi
 */
export interface SubscribeResultOpenApi {
    /**
     * 
     * @type {InvestorOnboardingOpenApi}
     * @memberof SubscribeResultOpenApi
     */
    onboarding?: InvestorOnboardingOpenApi;
    /**
     * 
     * @type {SubscribeResultStripePaymentIntentOpenApi}
     * @memberof SubscribeResultOpenApi
     */
    stripePaymentIntent: SubscribeResultStripePaymentIntentOpenApi;
}

/**
 * Check if a given object implements the SubscribeResultOpenApi interface.
 */
export function instanceOfSubscribeResultOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stripePaymentIntent" in value;

    return isInstance;
}

export function SubscribeResultOpenApiFromJSON(json: any): SubscribeResultOpenApi {
    return SubscribeResultOpenApiFromJSONTyped(json, false);
}

export function SubscribeResultOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscribeResultOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onboarding': !exists(json, 'onboarding') ? undefined : InvestorOnboardingOpenApiFromJSON(json['onboarding']),
        'stripePaymentIntent': SubscribeResultStripePaymentIntentOpenApiFromJSON(json['stripePaymentIntent']),
    };
}

export function SubscribeResultOpenApiToJSON(value?: SubscribeResultOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onboarding': InvestorOnboardingOpenApiToJSON(value.onboarding),
        'stripePaymentIntent': SubscribeResultStripePaymentIntentOpenApiToJSON(value.stripePaymentIntent),
    };
}

