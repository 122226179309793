import React, {FunctionComponent} from "react"
import {DashboardType, InvestorDashboardInvestmentType} from "../../../dashboard.type"
import DonutChart from "../../../../../../tech/chart/donut/donut.component"
import DashboardInfo from "../../../info/info.component"
import {assembleData, groupAndSum} from "../distribution.util"
import {DealIndustryOpenApi} from "../../../../../../generated"
import {mapDealIndustryToString} from "../../../../../deal/deal.type"
import styles from "./industry.module.sass"
import {InvestmentType} from "../../../../../investment/investment.type"

type InvestorDashboardSummaryDistributionIndustryProps = {
    dashboard: DashboardType
}

const InvestorDashboardSummaryDistributionIndustry: FunctionComponent<InvestorDashboardSummaryDistributionIndustryProps> = ({
    dashboard
}) => {
    const industriesWithSum = groupAndSum<DealIndustryOpenApi>(
        dashboard.investments,
        (investment: InvestorDashboardInvestmentType) => investment.investment.investment.type === "REGULAR",
        (investment: InvestorDashboardInvestmentType) => (investment.investment.investment.value as InvestmentType).deal._industry,
        (investment: InvestorDashboardInvestmentType) => (investment.investment.investment.value as InvestmentType).deal._industry
    )
    const data = assembleData(
        industriesWithSum,
        (identifier: DealIndustryOpenApi) => mapDealIndustryToString(identifier)
    )

    return (
        <div className={styles.industry}>
            <div className={styles.titleDesktop}>
                Industry
            </div>
            <DonutChart
                data={data}
                maxWidth={240}
            />
            {dashboard.summary.performance.strongest.industry && (
                <DashboardInfo
                    text={`Strongest performance in ${mapDealIndustryToString(dashboard.summary.performance.strongest.industry)}`}
                    noBackgroundMobile={false}
                    tooltip={{
                        content: (
                            <>
                                In terms of industry your portfolio performs best in <strong>{mapDealIndustryToString(dashboard.summary.performance.strongest.industry)}</strong> based on IRR.
                            </>
                        )
                    }}
                />
            )}
        </div>
    )
}

export default InvestorDashboardSummaryDistributionIndustry