/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorDashboardPerformanceDataPointOpenApi } from './InvestorDashboardPerformanceDataPointOpenApi';
import {
    InvestorDashboardPerformanceDataPointOpenApiFromJSON,
    InvestorDashboardPerformanceDataPointOpenApiFromJSONTyped,
    InvestorDashboardPerformanceDataPointOpenApiToJSON,
} from './InvestorDashboardPerformanceDataPointOpenApi';
import type { InvestorDashboardPerformanceStrongestOpenApi } from './InvestorDashboardPerformanceStrongestOpenApi';
import {
    InvestorDashboardPerformanceStrongestOpenApiFromJSON,
    InvestorDashboardPerformanceStrongestOpenApiFromJSONTyped,
    InvestorDashboardPerformanceStrongestOpenApiToJSON,
} from './InvestorDashboardPerformanceStrongestOpenApi';

/**
 * 
 * @export
 * @interface InvestorDashboardPerformanceOpenApi
 */
export interface InvestorDashboardPerformanceOpenApi {
    /**
     * 
     * @type {Array<InvestorDashboardPerformanceDataPointOpenApi>}
     * @memberof InvestorDashboardPerformanceOpenApi
     */
    invested: Array<InvestorDashboardPerformanceDataPointOpenApi>;
    /**
     * 
     * @type {Array<InvestorDashboardPerformanceDataPointOpenApi>}
     * @memberof InvestorDashboardPerformanceOpenApi
     */
    realized?: Array<InvestorDashboardPerformanceDataPointOpenApi>;
    /**
     * 
     * @type {Array<InvestorDashboardPerformanceDataPointOpenApi>}
     * @memberof InvestorDashboardPerformanceOpenApi
     */
    unrealized: Array<InvestorDashboardPerformanceDataPointOpenApi>;
    /**
     * 
     * @type {InvestorDashboardPerformanceStrongestOpenApi}
     * @memberof InvestorDashboardPerformanceOpenApi
     */
    strongest: InvestorDashboardPerformanceStrongestOpenApi;
}

/**
 * Check if a given object implements the InvestorDashboardPerformanceOpenApi interface.
 */
export function instanceOfInvestorDashboardPerformanceOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "invested" in value;
    isInstance = isInstance && "unrealized" in value;
    isInstance = isInstance && "strongest" in value;

    return isInstance;
}

export function InvestorDashboardPerformanceOpenApiFromJSON(json: any): InvestorDashboardPerformanceOpenApi {
    return InvestorDashboardPerformanceOpenApiFromJSONTyped(json, false);
}

export function InvestorDashboardPerformanceOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorDashboardPerformanceOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invested': ((json['invested'] as Array<any>).map(InvestorDashboardPerformanceDataPointOpenApiFromJSON)),
        'realized': !exists(json, 'realized') ? undefined : ((json['realized'] as Array<any>).map(InvestorDashboardPerformanceDataPointOpenApiFromJSON)),
        'unrealized': ((json['unrealized'] as Array<any>).map(InvestorDashboardPerformanceDataPointOpenApiFromJSON)),
        'strongest': InvestorDashboardPerformanceStrongestOpenApiFromJSON(json['strongest']),
    };
}

export function InvestorDashboardPerformanceOpenApiToJSON(value?: InvestorDashboardPerformanceOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invested': ((value.invested as Array<any>).map(InvestorDashboardPerformanceDataPointOpenApiToJSON)),
        'realized': value.realized === undefined ? undefined : ((value.realized as Array<any>).map(InvestorDashboardPerformanceDataPointOpenApiToJSON)),
        'unrealized': ((value.unrealized as Array<any>).map(InvestorDashboardPerformanceDataPointOpenApiToJSON)),
        'strongest': InvestorDashboardPerformanceStrongestOpenApiToJSON(value.strongest),
    };
}

