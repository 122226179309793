import React, {FunctionComponent} from "react"
import styles from "./title.module.sass"
import FavouriteBookmark from "../../favourite/bookmark.component"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"

type DealTitleProps = {
    deal: DealOnboardingDependentType
    showBookmark?: boolean
}

const DealTitle: FunctionComponent<DealTitleProps> = ({ deal, showBookmark = true }) => {
    return (
        <div className={styles.title}>
            <div>
                <div>{deal.name}</div>
            </div>
            {showBookmark && (
                <div className={styles.bookmark}>
                    <div>
                        <FavouriteBookmark deal={deal}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DealTitle