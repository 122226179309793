/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestorDashboardUnderlyingInvestmentOpenApi } from './InvestorDashboardUnderlyingInvestmentOpenApi';
import {
    InvestorDashboardUnderlyingInvestmentOpenApiFromJSON,
    InvestorDashboardUnderlyingInvestmentOpenApiFromJSONTyped,
    InvestorDashboardUnderlyingInvestmentOpenApiToJSON,
} from './InvestorDashboardUnderlyingInvestmentOpenApi';

/**
 * 
 * @export
 * @interface InvestorDashboardInvestmentOpenApi
 */
export interface InvestorDashboardInvestmentOpenApi {
    /**
     * 
     * @type {number}
     * @memberof InvestorDashboardInvestmentOpenApi
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof InvestorDashboardInvestmentOpenApi
     */
    currentValue: number;
    /**
     * 
     * @type {InvestorDashboardUnderlyingInvestmentOpenApi}
     * @memberof InvestorDashboardInvestmentOpenApi
     */
    investment: InvestorDashboardUnderlyingInvestmentOpenApi;
    /**
     * 
     * @type {number}
     * @memberof InvestorDashboardInvestmentOpenApi
     */
    irr: number;
    /**
     * 
     * @type {Date}
     * @memberof InvestorDashboardInvestmentOpenApi
     */
    lastUpdated: Date;
    /**
     * 
     * @type {number}
     * @memberof InvestorDashboardInvestmentOpenApi
     */
    moic: number;
}

/**
 * Check if a given object implements the InvestorDashboardInvestmentOpenApi interface.
 */
export function instanceOfInvestorDashboardInvestmentOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currentValue" in value;
    isInstance = isInstance && "investment" in value;
    isInstance = isInstance && "irr" in value;
    isInstance = isInstance && "lastUpdated" in value;
    isInstance = isInstance && "moic" in value;

    return isInstance;
}

export function InvestorDashboardInvestmentOpenApiFromJSON(json: any): InvestorDashboardInvestmentOpenApi {
    return InvestorDashboardInvestmentOpenApiFromJSONTyped(json, false);
}

export function InvestorDashboardInvestmentOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorDashboardInvestmentOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currentValue': json['currentValue'],
        'investment': InvestorDashboardUnderlyingInvestmentOpenApiFromJSON(json['investment']),
        'irr': json['irr'],
        'lastUpdated': (new Date(json['lastUpdated'])),
        'moic': json['moic'],
    };
}

export function InvestorDashboardInvestmentOpenApiToJSON(value?: InvestorDashboardInvestmentOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currentValue': value.currentValue,
        'investment': InvestorDashboardUnderlyingInvestmentOpenApiToJSON(value.investment),
        'irr': value.irr,
        'lastUpdated': (value.lastUpdated.toISOString().substr(0,10)),
        'moic': value.moic,
    };
}

