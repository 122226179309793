import React, {FunctionComponent} from "react"
import commonStyles from "../common.module.sass"

const PendingInvestmentCardWaitForMinimumAmount: FunctionComponent = () => {
    return (
        <>
            <div className={commonStyles.title}>
                Waiting for minimum amount
            </div>
            <div className={commonStyles.paragraph}>
                We are currently waiting for the minimum amount for this deal to be reached. When the minimum is reached, payment instructions will be displayed here.
            </div>
        </>
    )
}

export default PendingInvestmentCardWaitForMinimumAmount