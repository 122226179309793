import React, {FunctionComponent} from "react"
import {Tag} from "./tag.type"
import styles from "./tag-line.module.sass"

type TagLineProps = {
    tags: Tag[]
    bright?: boolean
}

const TagLine: FunctionComponent<TagLineProps> = ({
    tags,
    bright = false
}) => {
    return (
        <div className={styles.line}>
            {tags.map((tag, index) => {
                const randomKeyPrefix = Math.random()
                return (
                    <Element
                        tag={tag}
                        bright={bright}
                        index={index}
                        numberOfTags={tags.length}
                        randomKeyPrefix={randomKeyPrefix}
                        key={`${randomKeyPrefix}-${index}-element`}
                    />
                )
            })}
        </div>
    )
}

export default TagLine

type ElementType = {
    tag: Tag
    bright: boolean,
    index: number
    numberOfTags: number
    randomKeyPrefix: number
}

const Element: FunctionComponent<ElementType> = ({
    tag,
    bright,
    index,
    numberOfTags,
    randomKeyPrefix
}) => {
    return (
        <>
            <div
                className={`${tag.onClick ? styles.tagWithLink : styles.tag} ${bright && styles.bright}`}
                key={`${randomKeyPrefix}-${index}-tag`}
                onClick={tag.onClick}
            >
                {tag.name}
            </div>
            {index < numberOfTags - 1 && (
                <div
                    className={`${styles.separator}  ${bright && styles.bright}`}
                    key={`${randomKeyPrefix}-${index}-separator`}
                >
                    <div/>
                </div>
            )}
        </>
    )
}