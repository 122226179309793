import {DealEventOpenApi, DealLogosOpenApi, DealOpenApi, DealSlideDeckOpenApi} from "../../generated"
import {DealEventType, DealLogosType, DealSlideDeckType, DealType} from "./deal.type"
import {mapOpenApiToDealGroup} from "../deal-group/deal-group.mapper"
import {mapOpenApiToDealGeography} from "../deal-geography/deal-geography.mapper"
import {mapOpenApiToDealCategory} from "../deal-category/deal-category.mapper"
import {mapOpenApiToDealTile} from "./tile/tile.mapper"
import {mapOpenApiToSinglePurposeVehicle} from "../single-purpose-vehicle/single-purpose-vehicle.mapper"
import {mapOpenApiToImage} from "../../tech/image/image.mapper"
import {mapOpenApiToCompany} from "../company/company.mapper"

export function mapOpenApiToDeal(deal: DealOpenApi): DealType {
    return new DealType({
        id: deal.id,
        nameCompleteOnboarding: deal.nameCompleteOnboarding,
        nameIncompleteOnboarding: deal.nameIncompleteOnboarding,
        descriptionCompleteOnboarding: deal.descriptionCompleteOnboarding,
        descriptionIncompleteOnboarding: deal.descriptionIncompleteOnboarding,
        company: deal.company && mapOpenApiToCompany(deal.company),
        categories: deal.categories.map(mapOpenApiToDealCategory),
        geography: deal.geography && mapOpenApiToDealGeography(deal.geography),
        logos: deal.logos && mapOpenApiToDealLogos(deal.logos),
        image: deal.image && mapOpenApiToImage(deal.image),
        stage: deal.stage,
        outcome: deal.outcome,
        waitingState: deal.waitingState,
        industry: deal.industry,
        minTarget: deal.minTarget,
        maxTarget: deal.maxTarget,
        minTicket: deal.minTicket,
        finalDeadline: deal.finalDeadline,
        currency: deal.currency,
        exclusive: deal.exclusive,
        investedAmount: deal.investedAmount,
        dealGroups: deal.dealGroups ? deal.dealGroups.map(mapOpenApiToDealGroup) : [],
        investorGroupIds: deal.investorGroups
            ? deal.investorGroups.map(ig => ig.id!)
            : [],
        investors: deal.investors,
        termsTags: deal.termsTags,
        tiles: deal.tiles.map(mapOpenApiToDealTile),
        slideDeck: deal.slideDeck && mapOpenApiToDealSlideDeck(deal.slideDeck),
        event: deal.event && mapOpenApiToDealEvent(deal.event),
        singlePurposeVehicle: mapOpenApiToSinglePurposeVehicle(deal.singlePurposeVehicle),
        exited: deal.exited,
        exitType: deal.exitType,
        published: deal.published,
        imported: deal.imported,
        favourite: deal.favourite,
        objectType: "Deal"
    })
}

export function mapOpenApiToDealEvent(event: DealEventOpenApi): DealEventType {
    return {
        title: event.title,
        start: event.start,
        end: event.end,
        location: event.location,
        url: event.url
    }
}

export function mapOpenApiToDealLogos(logos: DealLogosOpenApi): DealLogosType {
    return {
        normal: logos.normal && mapOpenApiToImage(logos.normal),
        light: logos.light && mapOpenApiToImage(logos.light)
    }
}

export function mapOpenApiToDealSlideDeck(slideDeck: DealSlideDeckOpenApi): DealSlideDeckType {
    return {
        fileId: slideDeck.fileId,
        url: slideDeck.url
    }
}