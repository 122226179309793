import {fetchUserAttributes, getCurrentUser as amplifyGetCurrentUser} from "aws-amplify/auth"
import {CurrentUser} from "./context.state"

export async function getCurrentUser(): Promise<CurrentUser | undefined> {
    try {
        const [
            currentUser,
            userAttributes
        ] = await Promise.all([
            amplifyGetCurrentUser(),
            fetchUserAttributes()
        ])
        return {
            email: userAttributes.email!,
            username: currentUser.username
        }
    }
    catch (err) {
        return undefined
    }
}