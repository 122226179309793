import {QueryKey, QueryKeyWithParameters, QueryType} from "../../../tech/query/query.type"
import {FetchClient} from "../../../tech/fetch/client"
import {DashboardType} from "./dashboard.type"
import {
    getCategoryIdsFromFilterTypes,
    getGeographyIdsFromFilterTypes,
    getInvestmentTypesFromFilterTypes,
    getStageFromFilterTypes,
    getStatusFromFilterTypes
} from "../../../tech/filter/filter.util"
import {CurrencyOpenApi} from "../../../generated"
import {FilterType} from "../../../tech/filter/filter.type"

const QUERY_KEY__BASE = "investorDashboard"

export function QUERY_KEY__INVESTOR_DASHBOARD(parameters: QUERY_KEY_PARAMETERS__INVESTOR_DASHBOARD): QueryKey {
    return [
        QUERY_KEY__BASE,
        parameters.filterTypes,
        parameters.targetCurrency
    ]
}

export type QUERY_KEY_PARAMETERS__INVESTOR_DASHBOARD = {
    filterTypes: FilterType[]
    targetCurrency: CurrencyOpenApi
}

export function QUERY__INVESTOR_DASHBOARD(
    fetchClient: FetchClient,
    keyWithParameters: QueryKeyWithParameters<QUERY_KEY_PARAMETERS__INVESTOR_DASHBOARD>,
    enabled: boolean = true
): QueryType<DashboardType> {
    return {
        queryKey: keyWithParameters.keyFunction(keyWithParameters.parameters),
        queryFn: () => fetchClient.investorDashboardApi.get(
            getCategoryIdsFromFilterTypes(keyWithParameters.parameters.filterTypes),
            getGeographyIdsFromFilterTypes(keyWithParameters.parameters.filterTypes),
            getInvestmentTypesFromFilterTypes(keyWithParameters.parameters.filterTypes),
            getStageFromFilterTypes(keyWithParameters.parameters.filterTypes),
            getStatusFromFilterTypes(keyWithParameters.parameters.filterTypes),
            keyWithParameters.parameters.targetCurrency
        ),
        enabled
    }
}