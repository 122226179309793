import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const ArrowsOutIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M13.5 3v3a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V4.207L9.854 6.854a.5.5 0 0 1-.707 0 .5.5 0 0 1 0-.707L11.793 3.5H10a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5zM6.146 9.146L3.5 11.793V10a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5H4.207l2.647-2.646a.5.5 0 0 0 0-.707.5.5 0 0 0-.707 0zM13 9.5a.5.5 0 0 0-.5.5v1.793L9.854 9.146a.5.5 0 0 0-.707 0 .5.5 0 0 0 0 .707l2.647 2.646H10a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm-8.793-6H6a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5V4.207l2.646 2.647a.5.5 0 0 0 .707 0 .5.5 0 0 0 0-.707L4.207 3.5z"
            />
        </svg>
    )
}

export default ArrowsOutIcon