import React, {FunctionComponent} from "react"
import styles from "./empty.module.sass"

export type EmptyStateStateProps = {
    imageSrc: string
    textLine1: string
    textLine2: string
}

const EmptyState: FunctionComponent<EmptyStateStateProps> = ({
    imageSrc,
    textLine1,
    textLine2
}) => {
    return (
        <div className={styles.empty}>
            <img
                src={imageSrc}
                alt={textLine1}
            />
            <div>
                {textLine1}
            </div>
            {textLine2}
        </div>
    )
}

export default EmptyState