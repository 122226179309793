import React, {createRef, FunctionComponent, useEffect, useState} from "react"
import XIcon from "../../../../../../tech/icon/x.component"
import LoadingDots from "../../../../../../tech/loading/dots/dots.component"
import styles from "./take-picture.module.sass"

type TakePictureProps = {
    close: () => void
    uploadFile: (file: File) => void
}

const TakePicture: FunctionComponent<TakePictureProps> = ({ close, uploadFile }) => {
    const [permission, setPermission] = useState<"REQUESTING"|"GRANTED"|"ERROR">("REQUESTING")
    const [stream, setStream] = useState<MediaStream>()
    const [takingPhotoError, setTakingPhotoError] = useState<boolean>(false)
    const videoRef = createRef<HTMLVideoElement>()
    const canvasRef = createRef<HTMLCanvasElement>()

    useEffect(() => {
        const request = async () => {
            try {
                !stream && setStream(await requestPermission())
                setPermission("GRANTED")
            }
            catch (err) {
                console.warn(err)
                setPermission("ERROR")
            }
        }
        request()

        return () => {
            // Anything in here is fired on component unmount.
            stop(stream)
        }
    }, [stream])

    useEffect(() => {
        if (stream && videoRef.current) {
            videoRef.current.srcObject = stream
        }
    }, [stream, videoRef])

    const takePicture = () => {
        const canvas = canvasRef.current
        const video = videoRef.current
        if (!canvas || !video) {
            setTakingPhotoError(true)
            return
        }

        let canvasWidth = video.videoWidth
        let canvasHeight = video.videoHeight

        canvas.width = canvasWidth
        canvas.height = canvasHeight

        const context = canvas.getContext("2d")
        if (context) {
            context.drawImage(video, 0,  0, canvasWidth, canvasHeight)
            canvas.toBlob(
                async (blob) => {
                    try {
                        const fileName = Math.floor(Math.random() * 99999) + ".jpg"
                        uploadFile(new File([blob!], fileName, { type: "image/jpeg" }))
                    }
                    catch (err) {
                        console.warn(err)
                        setTakingPhotoError(true)
                    }
                },
                "image/jpeg",
                0.92
            )
        }
        else {
            setTakingPhotoError(true)
        }
    }

    return (
        <div className={styles.takePicture}>
            <div
                className={styles.close}
                onClick={close}
            >
                <XIcon
                    fillClass="fill-white"
                    width={24}
                    height={24}
                />
            </div>
            {permission === "REQUESTING" && (
                <LoadingDots/>
            )}
            {permission === "GRANTED" && (
                <>
                    {takingPhotoError ? (
                        <div className={styles.error}>
                            Error taking photo.
                        </div>
                    ) : (
                        <div className={styles.video}>
                            <video
                                autoPlay
                                src={videoRef.current?.src}
                                muted={true}
                                playsInline
                                ref={videoRef}
                            />
                            <button
                                className={styles.trigger}
                                onClick={takePicture}
                            />
                            <canvas ref={canvasRef}/>
                        </div>
                    )}
                </>
            )}
            {permission === "ERROR" && (
                <div className={styles.error}>
                    Failed to access camera.
                </div>
            )}
        </div>
    )
}

export default TakePicture

async function requestPermission(): Promise<MediaStream> {
    return await navigator.mediaDevices.getUserMedia({
        video: {
            facingMode: {
                exact: "environment"
            }
        }
    })
}

function stop(stream?: MediaStream): void {
    stream?.getTracks().forEach(track => track.stop())
}