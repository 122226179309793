import React from "react"
import {TabsType} from "./tabs.type"
import styles from "./tabs.module.sass"

function InvestorDashboardTabs<T>(props: TabsType<T>) {
    return (
        <>
            <div className={styles.tabs}>
                {props.tabs.map(tab => (
                    <div
                        className={`${styles.tab} ${props.currentSelection === tab.selection ? styles.active : ""}`}
                        onClick={() => tab.updateSelection(tab.selection)}
                        key={`tab-${tab.selection}`}
                    >
                        {tab.title}
                    </div>
                ))}
            </div>
            {props.tabs.map(tab => (
                <div
                    className={`${styles.content} ${props.currentSelection === tab.selection ? styles.active : ""}`}
                    key={`tab-content-${tab.selection}`}
                >
                    {tab.content}
                </div>
            ))}
        </>
    )
}

export default InvestorDashboardTabs