import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const TrashIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M27 6h-5V5a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v1H5a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1v18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8h1a1 1 0 0 0 1-1 1 1 0 0 0-1-1zM12 5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v1h-8V5zm12 21H8V8h16v18zM14 13v8a1 1 0 0 1-1 1 1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1 1 1 0 0 1 1 1zm6 0v8a1 1 0 0 1-1 1 1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1 1 1 0 0 1 1 1z"
            />
        </svg>
    )
}

export default TrashIcon