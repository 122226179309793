import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const NotchesIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M13.354 8.354l-5 5A.5.5 0 0 1 8 13.5a.5.5 0 0 1-.354-.146A.5.5 0 0 1 7.5 13a.5.5 0 0 1 .147-.354l5-5A.5.5 0 0 1 13 7.5a.5.5 0 0 1 .354.147A.5.5 0 0 1 13.5 8a.5.5 0 0 1-.146.354zm-1-6.208A.5.5 0 0 0 12 2a.5.5 0 0 0-.354.147l-9.5 9.5a.5.5 0 0 0 0 .707.5.5 0 0 0 .707 0l9.5-9.5A.5.5 0 0 0 12.5 2.5a.5.5 0 0 0-.147-.354z"
            />
        </svg>
    )
}

export default NotchesIcon