import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const ImagesIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M27 5H9a2 2 0 0 0-2 2v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2v-2h2a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2zM9 7h18v7.844l-1.259-1.258A2 2 0 0 0 24.327 13a2 2 0 0 0-1.414.586l-2.5 2.5-5.5-5.5a2 2 0 0 0-2.827 0L9 13.671V7zm14 18H5V11h2v10a2 2 0 0 0 2 2h14v2zm4-4H9v-4.5l4.5-4.5 6.208 6.208a1 1 0 0 0 1.414 0L24.329 15 27 17.673V21zm-7-10.5a1.5 1.5 0 0 1 .926-1.386 1.5 1.5 0 0 1 1.635.325 1.5 1.5 0 0 1 .325 1.635A1.5 1.5 0 0 1 21.5 12a1.5 1.5 0 0 1-1.5-1.5z"
            />
        </svg>
    )
}

export default ImagesIcon