import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CameraIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M26 7h-3.465l-1.704-2.555A1 1 0 0 0 20 4h-8a1 1 0 0 0-.831.445L9.464 7H6a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h20a3 3 0 0 0 3-3V10a3 3 0 0 0-3-3zm1 17a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h4a1 1 0 0 0 .832-.445L12.535 6h6.929l1.704 2.555A1 1 0 0 0 22 9h4a1 1 0 0 1 1 1v14zM16 11a5.5 5.5 0 0 0-5.081 3.395 5.5 5.5 0 0 0 1.192 5.994 5.5 5.5 0 0 0 5.994 1.192A5.5 5.5 0 0 0 21.5 16.5c-.002-1.458-.582-2.856-1.613-3.887S17.458 11.002 16 11zm0 9a3.5 3.5 0 0 1-3.234-2.161 3.5 3.5 0 0 1 .759-3.814 3.5 3.5 0 0 1 3.814-.759A3.5 3.5 0 0 1 19.5 16.5 3.5 3.5 0 0 1 16 20z"
            />
        </svg>
    )
}

export default CameraIcon