import React, {FunctionComponent, useEffect} from "react"
import {Outlet, useNavigate} from "react-router-dom"
import {useApp} from "../../app/context/app.context"
import AuthLayout from "../../auth/layout.component"
import {evaluateEntryPath} from "../routing.util"
import {InvestorOnboardingType} from "../../../domain/investor/onboarding/onboarding.type"
import {DealOnboardingDependentWithInvestmentType} from "../../../domain/deal/onboarding-dependent.type"

type NotAuthenticatedRouteProps = {
    onboarding: InvestorOnboardingType | undefined
    openDeals: DealOnboardingDependentWithInvestmentType[] | undefined
}

const NotAuthenticatedRoute: FunctionComponent<NotAuthenticatedRouteProps> = ({
    onboarding,
    openDeals
}) => {
    const app = useApp()
    const navigate = useNavigate()

    useEffect(() => {
        if (app.authenticated && onboarding && openDeals) {
            navigate(evaluateEntryPath(
                openDeals,
                onboarding
            ))
        }
    }, [app.authenticated, onboarding, openDeals, navigate])

    return (
        <AuthLayout>
            <Outlet/>
        </AuthLayout>
    )
}

export default NotAuthenticatedRoute