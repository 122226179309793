import React, {FunctionComponent} from "react"
import {InvestorDashboardInvestmentType} from "../../../dashboard.type"
import InvestorDashboardInvestmentMobileHead from "./head/head.component"
import InvestorDashboardInvestmentMobileInvested from "./invested/invested.component"
import {CurrencyOpenApi} from "../../../../../../generated"
import InvestorDashboardInvestmentLastUpdated from "../../last-updated/last-updated.component"
import InvestorDashboardInvestmentMobileStats from "./stats/stats.component"
import {useNavigate} from "react-router-dom"
import {InvestorOnboardingType} from "../../../../onboarding/onboarding.type"
import {IndividualInvestmentPopupStateType} from "../../../../../investment/individual/popup/popup.type"
import {navigateOnRowClick} from "../../desktop/row/row.util"
import styles from "./investment.module.sass"

type InvestorDashboardInvestmentMobileProps = {
    investment: InvestorDashboardInvestmentType
    targetCurrency: CurrencyOpenApi
    onboarding: InvestorOnboardingType
    updateIndividualInvestmentPopup: (state: IndividualInvestmentPopupStateType) => void
}

const InvestorDashboardInvestmentMobile: FunctionComponent<InvestorDashboardInvestmentMobileProps> = ({
    investment,
    targetCurrency,
    onboarding,
    updateIndividualInvestmentPopup
}) => {
    const navigate = useNavigate()
    return (
        <div
            className={styles.investment}
            onClick={() => navigateOnRowClick(investment, navigate, updateIndividualInvestmentPopup)}
        >
            <InvestorDashboardInvestmentMobileHead investment={investment.investment}/>
            <InvestorDashboardInvestmentMobileInvested
                investment={investment}
                targetCurrency={targetCurrency}
            />
            <InvestorDashboardInvestmentMobileStats
                investment={investment}
                targetCurrency={targetCurrency}
                onboarding={onboarding}
            />
            <InvestorDashboardInvestmentLastUpdated lastUpdated={investment.lastUpdated}/>
        </div>
    )
}

export default InvestorDashboardInvestmentMobile