import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const PlusIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M28 16a1 1 0 0 1-1 1H17v10a1 1 0 0 1-1 1 1 1 0 0 1-1-1V17H5a1 1 0 0 1-1-1 1 1 0 0 1 1-1h10V5a1 1 0 0 1 1-1 1 1 0 0 1 1 1v10h10a1 1 0 0 1 1 1z"
            />
        </svg>
    )
}

export default PlusIcon