import React, {FunctionComponent, useState} from "react"
import BookmarkIcon from "../../../tech/icon/bookmark.component"
import BookmarkedIcon from "../../../tech/icon/bookmarked.component"
import styles from "./bookmark.module.sass"
import {DealOnboardingDependentType} from "../onboarding-dependent.type"
import {useQueryClient} from "react-query"
import {QUERY_KEY__DEAL_WITH_INVESTMENT, QUERY_KEY__DEALS_BASE} from "../deal.query"
import {useApp} from "../../../tech/app/context/app.context"

type FavouriteBookmarkProps = {
    deal: DealOnboardingDependentType
    bright?: boolean
}

const FavouriteBookmark: FunctionComponent<FavouriteBookmarkProps> = ({
    deal,
    bright = false
}) => {
    const app = useApp()
    const queryClient = useQueryClient()
    const [bookmarked, setBookmarked] = useState<boolean>(deal.favourite)

    const toggle = async (e: React.MouseEvent) => {
        e.stopPropagation()
        try {
            setBookmarked(!bookmarked)
            await app.fetchClient.dealApi.toggleFavourite(deal.id!)
            await queryClient.invalidateQueries(QUERY_KEY__DEALS_BASE(), {
                refetchActive: true,
                refetchInactive: true,
            })
            await queryClient.invalidateQueries(
                QUERY_KEY__DEAL_WITH_INVESTMENT({ id: deal.id! }),
                {
                    refetchActive: true,
                    refetchInactive: true,
                },
                {}
            )
        }
        catch (err) {
            console.error(err)
            setBookmarked(!bookmarked)
        }
    }

    return (
        <div
            className={styles.bookmark}
            onClick={toggle}
        >
            {bookmarked ? (
                <BookmarkedIcon
                    fillClass={bright ? "fill-icon-white" : "fill-icon-black"}
                    width={16}
                    height={16}
                />
            ) : (
                <BookmarkIcon
                    fillClass={bright ? "fill-icon-white" : "fill-icon-black"}
                    width={16}
                    height={16}
                />
            )}
        </div>
    )
}

export default FavouriteBookmark