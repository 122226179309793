import {ErrorState} from "./state.type"

export function mapErrorToString(state: ErrorState): string {
    switch (state) {
        case "ERROR_TOO_MANY_FAILED_ATTEMPTS":
            return "Too many failed attempts. Please try again."
        case "ERROR_USER_NOT_CONFIRMED":
            return "Before you can sign in, you must confirm your account first."
        case "ERROR_USER_DOES_NOT_EXIST":
            return "User with that email does not exist."
        case "ERROR_INCORRECT_USERNAME_OR_PASSWORD":
            return "Incorrect username or password."
        case "ERROR":
        default:
            return "Something went wrong. Please try again."
    }
}

export function evaluateError(err: unknown): ErrorState {
    const errorString = err ? err.toString() : ""
    if (errorString.includes("NotAuthorizedException")) {
        return "ERROR_INCORRECT_USERNAME_OR_PASSWORD"
    }
    if (errorString.includes("UserNotFoundException")) {
        return "ERROR_USER_DOES_NOT_EXIST"
    }
    if (errorString.includes("TooManyFailedAttemptsException")) {
        return "ERROR_TOO_MANY_FAILED_ATTEMPTS"
    }
    if (errorString.includes("UserNotConfirmedException")) {
        return "ERROR_USER_NOT_CONFIRMED"
    }
    return "ERROR"
}

type AmplifySignInStep = "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
    | "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
    | "CONTINUE_SIGN_IN_WITH_MFA_SELECTION"
    | "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
    | "CONFIRM_SIGN_IN_WITH_SMS_CODE"
    | "CONTINUE_SIGN_IN_WITH_TOTP_SETUP"
    | "CONFIRM_SIGN_UP"
    | "RESET_PASSWORD"
    | "DONE"

export function evaluateErrorFromNextSignInStep(signInStep: AmplifySignInStep): ErrorState {
    return signInStep === "RESET_PASSWORD"
        ? "ERROR_PASSWORD_RESET_REQUIRED"
        : "ERROR_USER_NOT_CONFIRMED"
}