/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealFeeStructureOpenApi
 */
export interface DealFeeStructureOpenApi {
    /**
     * 
     * @type {number}
     * @memberof DealFeeStructureOpenApi
     */
    placementFee: number;
    /**
     * 
     * @type {number}
     * @memberof DealFeeStructureOpenApi
     */
    managementFee: number;
    /**
     * 
     * @type {number}
     * @memberof DealFeeStructureOpenApi
     */
    carry: number;
}

/**
 * Check if a given object implements the DealFeeStructureOpenApi interface.
 */
export function instanceOfDealFeeStructureOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "placementFee" in value;
    isInstance = isInstance && "managementFee" in value;
    isInstance = isInstance && "carry" in value;

    return isInstance;
}

export function DealFeeStructureOpenApiFromJSON(json: any): DealFeeStructureOpenApi {
    return DealFeeStructureOpenApiFromJSONTyped(json, false);
}

export function DealFeeStructureOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealFeeStructureOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'placementFee': json['placementFee'],
        'managementFee': json['managementFee'],
        'carry': json['carry'],
    };
}

export function DealFeeStructureOpenApiToJSON(value?: DealFeeStructureOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'placementFee': value.placementFee,
        'managementFee': value.managementFee,
        'carry': value.carry,
    };
}

