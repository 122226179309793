import React, {FunctionComponent} from "react"
import {FieldProps} from "../props.type"
import FormError from "../../error/error.component"
import styles from "./select.module.sass"
import CaretDownIcon from "../../../icon/caret-down.component"
import FieldLabelText from "../label-text.component"

type SelectFieldProps = FieldProps & {
    options: { label: string, value: string }[]
    prefillValue?: string
}

const SelectField: FunctionComponent<SelectFieldProps> = ({
    label,
    options,
    prefillValue,
    errors,
    reactHookFormRegister
}) => {
    return (
        <label className={styles.label}>
            <FieldLabelText label={label}/>
            <div className={styles.select}>
                <select
                    defaultValue={prefillValue}
                    {...reactHookFormRegister.register(
                        reactHookFormRegister.name,
                        reactHookFormRegister.options
                    )}
                >
                    {options.map((o, i) => (
                        <option
                            value={o.value}
                            key={`${o.value}-${i}`}
                        >
                            {o.label}
                        </option>
                    ))}
                </select>
                <CaretDownIcon
                    width={16}
                    height={16}
                    fillClass="fill-icon-black"
                />
            </div>
            <FormError
                field={reactHookFormRegister.name}
                errors={errors}
            />
        </label>
    )
}

export default SelectField