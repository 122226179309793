import React, {FunctionComponent, useEffect, useState} from "react"
import {DealFeeStructureType} from "../../../fee-structure/deal-fee-structure.type"
import LoadingDots from "../../../../../tech/loading/dots/dots.component"
import Alert from "../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../tech/alert/type.enum"
import {DealOnboardingDependentType} from "../../../onboarding-dependent.type"
import {DealType} from "../../../deal.type"
import Tooltip from "../../../../../tech/layout/tooltip/tooltip.component"
import {InvestmentType} from "../../../../investment/investment.type"
import styles from "./fees.module.sass"
import {useApp} from "../../../../../tech/app/context/app.context"

type DealFeesProps = {
    deal: DealOnboardingDependentType
    investment?: InvestmentType
}

const DealFees: FunctionComponent<DealFeesProps> = ({ deal, investment }) => {
    const app = useApp()
    const [state, setState] = useState<"HIDE" |"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [dealFeeStructure, setDealFeeStructure] = useState<DealFeeStructureType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setDealFeeStructure(await app.fetchClient.dealApi.getDealFeeStructure(deal.id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        if (investment !== undefined) {
            setDealFeeStructure({
                placementFee: investment.fees.placement.percent,
                managementFee: investment.fees.management,
                carry: investment.fees.carry
            })
            setState("LOADED")
        }
        else if (deal instanceof DealType) {
            fetch()
        }
        else {
            setState("HIDE")
        }
    }, [deal, app.fetchClient, investment])

    if (state === "HIDE") return <></>

    return (
        <div className={styles.fees}>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && dealFeeStructure && (
                <>
                    <div className={styles.head}>
                        <div className={styles.title}>
                            <div>
                                Fee Structure
                            </div>
                        </div>
                        <div>
                            <Tooltip
                                infoIconSize={20}
                                maxWidth={280}
                                position={{
                                    top: 16,
                                    left: -44,
                                    shiftLeft: -100
                                }}
                            >
                                <div className={styles.tooltip}>
                                    <div>
                                        <strong>Setup fee:</strong> A one-time fee.
                                    </div>
                                    <div>
                                        <strong>Carry (Carried Interest):</strong> This is a performance-based fee that only takes effects when profits materialise.
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={styles.table}>
                        <div>
                            <div>
                                Setup fee
                            </div>
                            <div>
                                {dealFeeStructure.placementFee} %
                            </div>
                        </div>
                        <div>
                            <div>
                                Carry
                            </div>
                            <div>
                                {dealFeeStructure.carry} %
                            </div>
                        </div>
                    </div>
                </>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load fee structure."
                />
            )}
        </div>
    )
}

export default DealFees