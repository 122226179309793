import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const LockIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M26 10h-4V7a6 6 0 0 0-6-6 6 6 0 0 0-6 6v3H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2zM12 7a4 4 0 0 1 4-4 4 4 0 0 1 4 4v3h-8V7zm14 19H6V12h20v14zm-8.5-7a1.5 1.5 0 0 1-.926 1.386 1.5 1.5 0 0 1-1.635-.325 1.5 1.5 0 0 1-.325-1.635A1.5 1.5 0 0 1 16 17.5a1.5 1.5 0 0 1 1.5 1.5z"
            />
        </svg>
    )
}

export default LockIcon