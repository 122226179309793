/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageOpenApi } from './ImageOpenApi';
import {
    ImageOpenApiFromJSON,
    ImageOpenApiFromJSONTyped,
    ImageOpenApiToJSON,
} from './ImageOpenApi';

/**
 * 
 * @export
 * @interface SyndicateForPresentationOpenApi
 */
export interface SyndicateForPresentationOpenApi {
    /**
     * 
     * @type {string}
     * @memberof SyndicateForPresentationOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SyndicateForPresentationOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyndicateForPresentationOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {string}
     * @memberof SyndicateForPresentationOpenApi
     */
    name: string;
    /**
     * 
     * @type {ImageOpenApi}
     * @memberof SyndicateForPresentationOpenApi
     */
    logo: ImageOpenApi;
    /**
     * 
     * @type {Array<string>}
     * @memberof SyndicateForPresentationOpenApi
     */
    investorGroupIds: Array<string>;
}

/**
 * Check if a given object implements the SyndicateForPresentationOpenApi interface.
 */
export function instanceOfSyndicateForPresentationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "investorGroupIds" in value;

    return isInstance;
}

export function SyndicateForPresentationOpenApiFromJSON(json: any): SyndicateForPresentationOpenApi {
    return SyndicateForPresentationOpenApiFromJSONTyped(json, false);
}

export function SyndicateForPresentationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyndicateForPresentationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'name': json['name'],
        'logo': ImageOpenApiFromJSON(json['logo']),
        'investorGroupIds': json['investorGroupIds'],
    };
}

export function SyndicateForPresentationOpenApiToJSON(value?: SyndicateForPresentationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'name': value.name,
        'logo': ImageOpenApiToJSON(value.logo),
        'investorGroupIds': value.investorGroupIds,
    };
}

