import React, {FunctionComponent, useEffect, useState} from "react"
import {FilterType} from "../filter.type"
import Button from "../../button/button.component"
import {ButtonSize} from "../../button/size.enum"
import {ButtonState} from "../../button/state.enum"
import {ButtonStyle} from "../../button/style.enum"
import StatusFilter from "../filters/status.component"
import CategoryFilter from "../filters/category.component"
import GeographyFilter from "../filters/geography.component"
import StageFilter from "../filters/stage.component"
import {
    FilterOptionInvestmentTypeType,
    FilterOptionStatusType,
    FilterOptionsType,
    FilterOptionWithInvestmentType
} from "./options.type"
import {initializeFilterOptions, sortFilterOptions} from "./options.util"
import ArrowCounterClockwiseIcon from "../../icon/arrow-counter-clockwise.component"
import {DealOnboardingDependentType} from "../../../domain/deal/onboarding-dependent.type"
import WithInvestmentFilter from "../filters/with-investment.component"
import InvestmentTypeFilter from "../filters/investment-type.component"
import styles from "./options.module.sass"

type FilterOptionsProps = {
    deals: DealOnboardingDependentType[]
    filterTypes: FilterType[]
    updateFilterTypes: (updatedFilterType: FilterType[]) => void
    showInvestmentTypeFilter: boolean
    showStatusFilter: boolean
    showWithInvestmentFilter: boolean,
    opened: boolean
    close: () => void
}

const FilterOptions: FunctionComponent<FilterOptionsProps> = ({
    deals,
    filterTypes,
    updateFilterTypes,
    showInvestmentTypeFilter,
    showStatusFilter,
    showWithInvestmentFilter,
    opened,
    close
}) => {
    const [options, setOptions] = useState<FilterOptionsType>(initializeFilterOptions(
        deals,
        filterTypes,
        showInvestmentTypeFilter,
        showStatusFilter,
        showWithInvestmentFilter
    ))

    useEffect(() => {
        setOptions(initializeFilterOptions(
            deals,
            filterTypes,
            showInvestmentTypeFilter,
            showStatusFilter,
            showWithInvestmentFilter
        ))
    }, [deals, filterTypes, showInvestmentTypeFilter, showStatusFilter, showWithInvestmentFilter])

    const updateOptions = (updatedOptions: FilterOptionsType) => setOptions(sortFilterOptions(updatedOptions))

    const apply = () => {
        close()
        updateFilterTypes(mapOptionsToFilterTypes(options))
    }

    const reset = () => {
        updateFilterTypes([])
    }

    const numberOfColumns = countNumberOfColumns(
        options,
        showInvestmentTypeFilter,
        showStatusFilter,
        showWithInvestmentFilter
    )
    const width = numberOfColumns * (calculateContainerWidth() / 5)

    return (
        <>
            <div className={`${styles.options} ${opened && styles.opened}`}>
                <div
                    className={styles.head}
                    onClick={close}
                    onTouchMove={close}
                >
                    Filter by
                </div>

                <div className={styles.filters}>
                    <div
                        className={styles.filtersInner}
                        style={{
                            gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
                            width
                        }}
                    >
                        <StageFilter
                            options={options}
                            updateOptions={updateOptions}
                        />

                        <CategoryFilter
                            options={options}
                            updateOptions={updateOptions}
                        />

                        <GeographyFilter
                            options={options}
                            updateOptions={updateOptions}
                        />

                        {showStatusFilter && (
                            <StatusFilter
                                options={options}
                                updateOptions={updateOptions}
                            />
                        )}

                        {showInvestmentTypeFilter && (
                            <InvestmentTypeFilter
                                options={options}
                                updateOptions={updateOptions}
                            />
                        )}

                        {showWithInvestmentFilter && (
                            <WithInvestmentFilter
                                options={options}
                                updateOptions={updateOptions}
                            />
                        )}
                    </div>
                </div>

                <div className={styles.actions}>
                    <div className={styles.reset}>
                        <Button
                            label="Reset"
                            type="button"
                            size={ButtonSize.LARGE}
                            state={ButtonState.ACTIVE}
                            style={ButtonStyle.TERTIARY}
                            icon={(
                                <ArrowCounterClockwiseIcon
                                    width={24}
                                    height={24}
                                    fillClass="fill-icon-theme"
                                />
                            )}
                            withArrow={false}
                            onClick={reset}
                        />
                    </div>
                    <div className={styles.applyAndCancel}>
                        <div className={styles.cancel}>
                            <Button
                                label="Cancel"
                                type="button"
                                size={ButtonSize.LARGE}
                                state={ButtonState.ACTIVE}
                                style={ButtonStyle.SECONDARY}
                                withArrow={false}
                                fullWidth={true}
                                onClick={close}
                            />
                        </div>
                        <div className={styles.apply}>
                            <Button
                                label="Apply Filters"
                                type="button"
                                size={ButtonSize.LARGE}
                                state={ButtonState.ACTIVE}
                                style={ButtonStyle.PRIMARY}
                                withArrow={false}
                                fullWidth={true}
                                onClick={apply}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {opened && (
                <div
                    className={styles.overlay}
                    onClick={close}
                />
            )}
        </>
    )
}

export default FilterOptions

function calculateContainerWidth(): number {
    const innerWidthWithoutPadding = window.innerWidth - 64
    return Math.min(innerWidthWithoutPadding, 1360 - 48)
}

function countNumberOfColumns(
    options: FilterOptionsType,
    showInvestmentTypeFilter: boolean,
    showStatusFilter: boolean,
    showWithInvestmentFilter: boolean
): number {
    let numberOfColumns = 0
    if (options.stages.length > 0) numberOfColumns += 1
    if (options.categories.length > 0) numberOfColumns += 1
    if (options.geographies.length > 0) numberOfColumns += 1
    if (showStatusFilter) numberOfColumns += 1
    if (showInvestmentTypeFilter) numberOfColumns += 1
    if (showWithInvestmentFilter) numberOfColumns += 1
    return numberOfColumns
}

function mapOptionsToFilterTypes(options: FilterOptionsType): FilterType[] {
    return [
        ...options.categories.filter(o => o.selected).map(o => ({
            type: "CATEGORY",
            category: o.value,
            applied: o.selected
        })),
        ...options.geographies.filter(o => o.selected).map(o => ({
            type: "GEOGRAPHY",
            geography: o.value,
            applied: o.selected
        })),
        ...mapOptionsToFilterOptionInvestmentTypeType(options.investmentTypes),
        ...options.stages.filter(o => o.selected).map(o => ({
            type: "STAGE",
            stage: o.value,
            applied: o.selected
        })),
        ...mapOptionsToFilterOptionStatusType(options.statuses),
        ...mapOptionsToFilterOptionWithInvestmentType(options.withInvestmentOptions),
    ] as FilterType[]
}

function mapOptionsToFilterOptionInvestmentTypeType(investmentTypes: FilterOptionInvestmentTypeType[] | undefined): FilterType[] {
    if (investmentTypes) {
        return investmentTypes.filter(o => o.selected).map(o => ({
            type: "INVESTMENT_TYPE",
            investmentType: o.value,
            applied: o.selected
        }))
    }
    return []
}

function mapOptionsToFilterOptionStatusType(statuses: FilterOptionStatusType[] | undefined): FilterType[] {
    if (statuses) {
        return statuses.filter(o => o.selected).map(o => ({
            type: "STATUS",
            status: o.value,
            applied: o.selected
        }))
    }
    return []
}

function mapOptionsToFilterOptionWithInvestmentType(options: FilterOptionWithInvestmentType[] | undefined): FilterType[] {
    if (options) {
        return options.filter(o => o.selected).map(o => ({
            type: "WITH_INVESTMENT",
            option: o.value,
            applied: o.selected
        }))
    }
    return []
}