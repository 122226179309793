/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvestorDashboardInvestmentTypeOpenApi = {
    IndividualInvestments: 'INDIVIDUAL_INVESTMENTS',
    RegularInvestments: 'REGULAR_INVESTMENTS'
} as const;
export type InvestorDashboardInvestmentTypeOpenApi = typeof InvestorDashboardInvestmentTypeOpenApi[keyof typeof InvestorDashboardInvestmentTypeOpenApi];


export function InvestorDashboardInvestmentTypeOpenApiFromJSON(json: any): InvestorDashboardInvestmentTypeOpenApi {
    return InvestorDashboardInvestmentTypeOpenApiFromJSONTyped(json, false);
}

export function InvestorDashboardInvestmentTypeOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorDashboardInvestmentTypeOpenApi {
    return json as InvestorDashboardInvestmentTypeOpenApi;
}

export function InvestorDashboardInvestmentTypeOpenApiToJSON(value?: InvestorDashboardInvestmentTypeOpenApi | null): any {
    return value as any;
}

