/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealGeographyOpenApi } from './DealGeographyOpenApi';
import {
    DealGeographyOpenApiFromJSON,
    DealGeographyOpenApiFromJSONTyped,
    DealGeographyOpenApiToJSON,
} from './DealGeographyOpenApi';
import type { DealIndustryOpenApi } from './DealIndustryOpenApi';
import {
    DealIndustryOpenApiFromJSON,
    DealIndustryOpenApiFromJSONTyped,
    DealIndustryOpenApiToJSON,
} from './DealIndustryOpenApi';
import type { DealStageOpenApi } from './DealStageOpenApi';
import {
    DealStageOpenApiFromJSON,
    DealStageOpenApiFromJSONTyped,
    DealStageOpenApiToJSON,
} from './DealStageOpenApi';

/**
 * 
 * @export
 * @interface InvestorDashboardPerformanceStrongestOpenApi
 */
export interface InvestorDashboardPerformanceStrongestOpenApi {
    /**
     * 
     * @type {DealGeographyOpenApi}
     * @memberof InvestorDashboardPerformanceStrongestOpenApi
     */
    geolocation?: DealGeographyOpenApi;
    /**
     * 
     * @type {DealIndustryOpenApi}
     * @memberof InvestorDashboardPerformanceStrongestOpenApi
     */
    industry?: DealIndustryOpenApi;
    /**
     * 
     * @type {DealStageOpenApi}
     * @memberof InvestorDashboardPerformanceStrongestOpenApi
     */
    stage?: DealStageOpenApi;
}

/**
 * Check if a given object implements the InvestorDashboardPerformanceStrongestOpenApi interface.
 */
export function instanceOfInvestorDashboardPerformanceStrongestOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvestorDashboardPerformanceStrongestOpenApiFromJSON(json: any): InvestorDashboardPerformanceStrongestOpenApi {
    return InvestorDashboardPerformanceStrongestOpenApiFromJSONTyped(json, false);
}

export function InvestorDashboardPerformanceStrongestOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorDashboardPerformanceStrongestOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geolocation': !exists(json, 'geolocation') ? undefined : DealGeographyOpenApiFromJSON(json['geolocation']),
        'industry': !exists(json, 'industry') ? undefined : DealIndustryOpenApiFromJSON(json['industry']),
        'stage': !exists(json, 'stage') ? undefined : DealStageOpenApiFromJSON(json['stage']),
    };
}

export function InvestorDashboardPerformanceStrongestOpenApiToJSON(value?: InvestorDashboardPerformanceStrongestOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geolocation': DealGeographyOpenApiToJSON(value.geolocation),
        'industry': DealIndustryOpenApiToJSON(value.industry),
        'stage': DealStageOpenApiToJSON(value.stage),
    };
}

