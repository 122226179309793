import React, {FunctionComponent} from "react"
import {InvestorOnboardingType} from "./onboarding.type"
import {Navigate, useNavigate} from "react-router-dom"
import {DEALS, ONBOARDING} from "../../../paths"
import Breadcrumb from "../../../tech/breadcrumb/breadcrumb.component"
import {InvestorOnboardingStageTypeOpenApi} from "../../../generated"
import InvestorOnboardingAiStatusDeclaration from "./stage/ai-status-declaration/ai-status-declaration.component"
import InvestorOnboardingAcknowledgeSuccess from "./stage/success/success.component"
import InvestorOnboardingGeneralData from "./stage/general-data/general-data.component"
import styles from "./onboarding.module.sass"
import InvestorOnboardingKycWaiting from "./stage/kyc-waiting/kyc-waiting.component"
import InvestorOnboardingKycUpload from "./stage/kyc-upload/kyc-upload.component"
import {KycDocumentUploadNecessaryOnboardingStageType} from "./stage/stage.type"

type InvestorOnboardingProps = {
    onboarding: InvestorOnboardingType
    updateOnboarding: (onboarding: InvestorOnboardingType) => void
}

const InvestorOnboarding: FunctionComponent<InvestorOnboardingProps> = ({
    onboarding,
    updateOnboarding
}) => {
    const navigate = useNavigate()

    const stageType = onboarding ? onboarding.stage.type : "NONE"

    if (stageType === "NONE" || !onboarding) {
        return <Navigate to={DEALS}/>
    }

    return (
        <div className={styles.investorOnboarding}>
            <Breadcrumb
                backLink={() => navigate(-1)}
                elements={[
                    { text: getBreadcrumbText(stageType), to: ONBOARDING }
                ]}
            />
            {{
                "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED": <InvestorOnboardingAiStatusDeclaration updateOnboarding={updateOnboarding}/>,
                "GENERAL_DATA": <InvestorOnboardingGeneralData updateOnboarding={updateOnboarding} />,
                "KYC_DOCUMENT_UPLOAD_NECESSARY": (
                    <InvestorOnboardingKycUpload
                        stage={onboarding.stage as KycDocumentUploadNecessaryOnboardingStageType}
                        onboarding={onboarding}
                        updateOnboarding={updateOnboarding}
                    />
                ),
                "KYC_WAITING_FOR_REVIEW": <InvestorOnboardingKycWaiting/>,
                "ACKNOWLEDGE_SUCCESS": <InvestorOnboardingAcknowledgeSuccess updateOnboarding={updateOnboarding}/>,
                "NONE": <></>
            }[stageType]}
        </div>
    )
}

export default InvestorOnboarding

function getBreadcrumbText(stageType: InvestorOnboardingStageTypeOpenApi): string {
    switch (stageType) {
        case "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED":
            return "AI Status Declaration"
        case "GENERAL_DATA":
            return "Personal Data"
        case "KYC_DOCUMENT_UPLOAD_NECESSARY":
        case "KYC_WAITING_FOR_REVIEW":
            return "KYC"
        case "ACKNOWLEDGE_SUCCESS":
        case "NONE":
            return "Onboarding"
    }
}