import {InvestorApiClient} from "../../generated"
import {InvestorOnboardingType} from "./onboarding/onboarding.type"
import {mapOpenApiToInvestorOnboarding} from "./onboarding/onboarding.mapper"
import {PersonType} from "../person/person.type"
import {mapPersonToOpenApi} from "../person/person.mapper"
import {InvestorKycDocumentsType} from "./onboarding/kyc/kyc.type"
import {mapInvestorKycDocumentsToOpenApi} from "./onboarding/kyc/kyc.mapper"
import {mapStartToOpenApi} from "./onboarding/stage/success/success.mapper"
import {AiStatusDeclarationType} from "./onboarding/stage/ai-status-declaration/ai-status-declaration.type"
import {mapAiStatusDeclarationToOpenApi} from "./onboarding/stage/ai-status-declaration/ai-status-declaration.mapper"

export class InvestorApi {

    constructor(private apiClient: InvestorApiClient) {
    }

    public async getOnboarding(): Promise<InvestorOnboardingType> {
        const investorOnboardingOpenApi = await this.apiClient.investorOnboardingGet()
        return mapOpenApiToInvestorOnboarding(investorOnboardingOpenApi)
    }

    public async saveOnboardingGeneralData(person: PersonType): Promise<InvestorOnboardingType> {
        const investorOnboardingOpenApi = await this.apiClient.investorOnboardingSaveGeneralData({ personOpenApi: mapPersonToOpenApi(person) })
        return mapOpenApiToInvestorOnboarding(investorOnboardingOpenApi)
    }

    public async updateOnboardingAiDeclaration(aiStatusDeclaration: AiStatusDeclarationType): Promise<InvestorOnboardingType> {
        const investorOnboardingOpenApi = await this.apiClient.investorOnboardingUpdateAccreditedInvestorStatus({
            investorAiDeclarationOpenApi: mapAiStatusDeclarationToOpenApi(aiStatusDeclaration)
        })
        return mapOpenApiToInvestorOnboarding(investorOnboardingOpenApi)
    }

    public async saveOnboardingKyc(investorKycDocuments: InvestorKycDocumentsType): Promise<InvestorOnboardingType> {
        const investorOnboardingOpenApi = await this.apiClient.investorOnboardingSaveKycDocuments({
            investorKycDocumentsOpenApi: mapInvestorKycDocumentsToOpenApi(investorKycDocuments)
        })
        return mapOpenApiToInvestorOnboarding(investorOnboardingOpenApi)
    }

    public async saveOnboardingStarted(started: boolean): Promise<InvestorOnboardingType> {
        const investorOnboardingOpenApi = await this.apiClient.investorOnboardingStart({
            investorStartOpenApi: mapStartToOpenApi(started)
        })
        return mapOpenApiToInvestorOnboarding(investorOnboardingOpenApi)
    }

}