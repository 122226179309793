import {SubscriptionApiClient, SubscriptionPaymentIntervalOpenApi, SubscriptionTypeOpenApi} from "../../generated"
import {SubscribeResult} from "./subscribe/subscribe.type"
import {mapOpenApiToSubscribeResultStripePaymentIntent, mapSubscribeResponseStatus} from "./subscribe/subscribe.mapper"
import {mapOpenApiToInvestorOnboarding} from "../investor/onboarding/onboarding.mapper"
import {InvestorOnboardingType} from "../investor/onboarding/onboarding.type"

export class SubscriptionApi {

    constructor(private apiClient: SubscriptionApiClient) {
    }

    public async cancel(reason: string): Promise<InvestorOnboardingType> {
        return mapOpenApiToInvestorOnboarding(await this.apiClient.subscriptionCancel({
            subscriptionCancellationRequestOpenApi: { reason }
        }))
    }

    public async createCustomerPortalSession(redirectUrl: string): Promise<string> {
        return (await this.apiClient.subscriptionCustomerPortalSession({
            customerPortalSessionCreationRequestOpenApi: {
                redirectUrl
            }
        })).url
    }

    public async previewCancellation(): Promise<Date> {
        return (await this.apiClient.subscriptionPreviewCancellation()).terminationDate
    }

    public async reactivate(): Promise<InvestorOnboardingType> {
        return mapOpenApiToInvestorOnboarding(await this.apiClient.subscriptionReactivate())
    }

    public async subscribe(
        subscriptionType: SubscriptionTypeOpenApi,
        paymentInterval: SubscriptionPaymentIntervalOpenApi,
        promotedPrice: number,
        stripePaymentMethodId: string
    ): Promise<SubscribeResult> {
        const response = await this.apiClient.subscriptionSubscribeRaw({
            subscribeRequestOpenApi: {
                type: subscriptionType,
                paymentInterval,
                promotedPrice,
                stripePaymentMethodId
            }
        })
        const result = await response.value()
        const resultType = mapSubscribeResponseStatus(response, result)
        if (resultType !== "SUCCESS" && resultType !== "STRIPE_REQUIRES_ACTION") {
            return {
                result: resultType
            }
        }
        return {
            result: resultType,
            onboarding: result.onboarding && mapOpenApiToInvestorOnboarding(result.onboarding),
            stripePaymentIntent: result.stripePaymentIntent
                && mapOpenApiToSubscribeResultStripePaymentIntent(result.stripePaymentIntent)
        }
    }

}