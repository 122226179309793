import {FunctionComponent} from "react"
import styles from "./irr.module.sass"

const IrrTooltip: FunctionComponent = () => {
    return (
        <div className={styles.irr}>
            <div>
                The <strong>internal rate of return (IRR)</strong> is a financial metric employed for evaluating the profitability of investments. It represents the discount rate at which the net current value of all cash flows becomes zero.
            </div>
            <div>
                As usual in private equity, this is only a <strong>rough estimate</strong> and not an accurate value.
            </div>
        </div>
    )
}

export default IrrTooltip