import {FunctionComponent} from "react"
import {InvestorOnboardingType} from "../onboarding.type"
import {useNavigate} from "react-router-dom"
import {ONBOARDING} from "../../../../paths"
import ArrowRightIcon from "../../../../tech/icon/arrow-right.component"
import {needsUpdateOnboardingBanner, showOnboardingBanner} from "../onboarding.util"
import styles from "./banner.module.sass"

type OnboardingBannerProps = {
    onboarding: InvestorOnboardingType
}

const OnboardingBanner: FunctionComponent<OnboardingBannerProps> = ({ onboarding }) => {
    const navigate = useNavigate()

    if (showOnboardingBanner(onboarding)) {
        const needsUpdate = needsUpdateOnboardingBanner(onboarding)
        return (
            <div
                className={styles.kycBanner}
                onClick={() => navigate(ONBOARDING)}
            >
                <div className={styles.text}>
                    <div className={styles.textHead}>
                        {needsUpdate ? "KYC document update required" : "Onboarding incomplete"}
                    </div>
                    <div className={styles.textBody}>
                        {needsUpdate
                            ? "Please update your KYC documents. We recommend to do it right away."
                            : "You need to complete our onboarding process to make an investment. We recommend to do it right away."}
                    </div>
                </div>
                <div className={styles.go}>
                    <div className={styles.goText}>
                        Complete Onboarding now
                    </div>
                    <div className={styles.goArrow}>
                        <ArrowRightIcon
                            fillClass="fill-black"
                            width={16}
                            height={16}
                        />
                    </div>
                </div>
            </div>
        )
    }
    return <></>
}

export default OnboardingBanner