import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const BookmarkedIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path
                className={fillClass}
                d="M17.25 3H6.75a1.5 1.5 0 0 0-1.5 1.5V21a.75.75 0 0 0 .387.656.75.75 0 0 0 .761-.02L12 18.134l5.603 3.502a.75.75 0 0 0 .76.019.75.75 0 0 0 .386-.655V4.5a1.5 1.5 0 0 0-1.5-1.5zm-4.853 13.614a.75.75 0 0 0-.795 0L6.75 19.647v-2.732L12 13.634l5.25 3.281v2.732l-4.853-3.033z"
            />
        </svg>
    )
}

export default BookmarkedIcon