import React, {FunctionComponent} from "react"
import Head from "../../../../tech/head/head.component"
import DealFilter, {FilterProps} from "../../../../tech/filter/filter.component"
import FilterSelection from "../../../../tech/filter/selection/selection.component"
import {FilterType} from "../../../../tech/filter/filter.type"
import {FilterTypeUpdateSource} from "../dashboard.component"
import Tooltip from "../../../../tech/layout/tooltip/tooltip.component"
import InvestorDashboardIndividualInvestmentButton from "../investments/individual/button/button.component"
import {IndividualInvestmentPopupStateType} from "../../../investment/individual/popup/popup.type"
import styles from "./head.module.sass"

type InvestorDashboardHeadProps = {
    title: string
    filter: FilterProps
    updateIndividualInvestmentPopup: (state: IndividualInvestmentPopupStateType) => void
    updateFilterTypesAndSource: (updatedFilterTypes: FilterType[], source: FilterTypeUpdateSource) => void
}

const InvestorDashboardHead: FunctionComponent<InvestorDashboardHeadProps> = ({
    title,
    filter,
    updateIndividualInvestmentPopup,
    updateFilterTypesAndSource
}) => {
    const updateFilterTypes = (updatedFilterTypes: FilterType[]) => updateFilterTypesAndSource(updatedFilterTypes, "HEAD")

    return (
        <>
            <div className={styles.head}>
                <div>
                    <div className={styles.title}>
                        <div className={styles.headElement}>
                            <Head title={title}/>
                        </div>
                        <div className={styles.tooltip}>
                            <Tooltip
                                infoIconSize={20}
                                padding={{
                                    x: 12,
                                    y: 2
                                }}
                                position={{
                                    top: 14,
                                    left: -32,
                                    shiftLeft: -120
                                }}
                            >
                                <div>
                                    In order to make the investments with different currencies comparable, all amounts are <strong>standardised to USD</strong> at the current exchange rate.
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={styles.actions}>
                    <div>
                        <InvestorDashboardIndividualInvestmentButton openPopup={() => updateIndividualInvestmentPopup({ type: "NEW" })}/>
                        <DealFilter {...{
                            ...filter,
                            updateFilterTypes
                        }}/>
                        <FilterSelection
                            filterTypes={filter.filterTypes}
                            updateFilterTypes={updateFilterTypes}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvestorDashboardHead