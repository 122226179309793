import React, {FunctionComponent, useState} from "react"
import ReactMarkdown from "react-markdown"
import {DealTileType} from "../../tile/tile.type"
import CaretCircleDownIcon from "../../../../tech/icon/caret-circle-down.component"
import CaretCircleUpIcon from "../../../../tech/icon/caret-circle-up.component"
import styles from "./tile.module.sass"

type DealTileProps = {
    tile: DealTileType
}

const DealTile: FunctionComponent<DealTileProps> = ({ tile }) => {
    const [opened, setOpened] = useState<boolean>(false)
    return (
        <div className={opened ? styles.tileOpened : styles.tile}>
            <div
                className={styles.head}
                onClick={() => setOpened(!opened)}
            >
                <div>
                    <div>{tile.title}</div>
                </div>
                <div className={styles.toggle}>
                    <div>
                        {opened ? (
                            <CaretCircleUpIcon
                                width={24}
                                height={24}
                                fillClass="fill-icon-theme"
                            />
                        ) : (
                            <CaretCircleDownIcon
                                width={24}
                                height={24}
                                fillClass="fill-icon-theme"
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.inner}>
                    <div>
                        <ReactMarkdown>{tile.text}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DealTile