/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IndividualInvestmentOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    IndividualInvestmentOpenApiFromJSON,
    IndividualInvestmentOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface IndividualInvestmentCreateRequest {
    individualInvestmentOpenApi?: IndividualInvestmentOpenApi;
}

export interface IndividualInvestmentDeleteRequest {
    id: string;
}

export interface IndividualInvestmentUpdateRequest {
    id: string;
    individualInvestmentOpenApi?: IndividualInvestmentOpenApi;
}

/**
 * 
 */
export class IndividualInvestmentApiClient extends runtime.BaseAPI {

    /**
     * Create new individual investment
     */
    async individualInvestmentCreateRaw(requestParameters: IndividualInvestmentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IndividualInvestmentOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/individual-investment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IndividualInvestmentOpenApiToJSON(requestParameters.individualInvestmentOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IndividualInvestmentOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new individual investment
     */
    async individualInvestmentCreate(requestParameters: IndividualInvestmentCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IndividualInvestmentOpenApi> {
        const response = await this.individualInvestmentCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete individual investment by id
     */
    async individualInvestmentDeleteRaw(requestParameters: IndividualInvestmentDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling individualInvestmentDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/individual-investment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete individual investment by id
     */
    async individualInvestmentDelete(requestParameters: IndividualInvestmentDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.individualInvestmentDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Update individual investment
     */
    async individualInvestmentUpdateRaw(requestParameters: IndividualInvestmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IndividualInvestmentOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling individualInvestmentUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/individual-investment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IndividualInvestmentOpenApiToJSON(requestParameters.individualInvestmentOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IndividualInvestmentOpenApiFromJSON(jsonValue));
    }

    /**
     * Update individual investment
     */
    async individualInvestmentUpdate(requestParameters: IndividualInvestmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IndividualInvestmentOpenApi> {
        const response = await this.individualInvestmentUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
