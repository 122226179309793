import React, {FunctionComponent, ReactNode} from "react"
import styles from "./tile.module.sass"

type InvestorDashboardTileProps = {
    title: string
    noLayoutMobile?: boolean
    noLayoutDesktop?: boolean
    hideTitleDesktop?: boolean
    tooltip?: ReactNode
    children: ReactNode
}

const InvestorDashboardTile: FunctionComponent<InvestorDashboardTileProps> = ({
    title,
    noLayoutMobile = false,
    noLayoutDesktop = false,
    hideTitleDesktop = false,
    tooltip,
    children
}) => {
    return (
        <div className={`
            ${styles.tile}
            ${noLayoutMobile ? styles.noLayoutMobile : ""}
            ${noLayoutDesktop ? styles.noLayoutDesktop : ""}
            ${hideTitleDesktop ? styles.hideTitleDesktop : ""}
        `}>
            <div className={styles.title}>
                <div>
                    {title}
                </div>
                {tooltip && (
                    <div className={styles.tooltip}>
                        <div>
                            {tooltip}
                        </div>
                    </div>
                )}
            </div>
            {children}
        </div>
    )
}

export default InvestorDashboardTile