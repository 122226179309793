import React, {FunctionComponent} from "react"
import {FilterType} from "../filter.type"
import {mapDealStageToString} from "../../../domain/deal/deal.type"
import {getIndexOf} from "../removal.util"
import Button from "../../button/button.component"
import {ButtonSize} from "../../button/size.enum"
import {ButtonState} from "../../button/state.enum"
import {ButtonStyle} from "../../button/style.enum"
import CrossCircleIcon from "../../icon/cross-circle.component"
import styles from "./selection.module.sass"
import {
    mapInvestorDashboardInvestmentTypeToString
} from "../../../domain/investor/dashboard/investments/investment-type.util"

type FilterSelectionProps = {
    filterTypes: FilterType[]
    updateFilterTypes: (updatedFilterTypes: FilterType[]) => void
    onRemoval?: (updatedFilterTypes: FilterType[]) => void
}

const FilterSelection: FunctionComponent<FilterSelectionProps> = ({ filterTypes, updateFilterTypes, onRemoval }) => {
    const remove = (filterType: FilterType) => {
        const updatedFilterTypes = [...filterTypes]
        updatedFilterTypes.splice(getIndexOf(filterType, updatedFilterTypes), 1)
        updateFilterTypes(updatedFilterTypes)
        onRemoval && onRemoval(updatedFilterTypes)
    }

    return (
        <>
            {filterTypes.reverse().map(filterType => (
                <div
                    className={styles.selectionElement}
                    key={getKey(filterType)}
                >
                    <Button
                        label={getLabel(filterType)}
                        type="button"
                        size={ButtonSize.SMALL}
                        state={ButtonState.ACTIVE}
                        style={ButtonStyle.SECONDARY}
                        icon={
                            <CrossCircleIcon
                                fillClass="fill-icon-theme"
                                width={16}
                                height={16}
                            />
                        }
                        iconPosition="RIGHT"
                        onClick={() => remove(filterType)}
                    />
                </div>
            ))}
        </>
    )
}

export default FilterSelection

function getKey(filterType: FilterType): string {
    return `filter-selection-${getLabel(filterType)}`
}

function getLabel(filterType: FilterType): string {
    switch (filterType.type) {
        case "CATEGORY":
            return filterType.category.name
        case "GEOGRAPHY":
            return filterType.geography.name
        case "INVESTMENT_TYPE":
            return mapInvestorDashboardInvestmentTypeToString(filterType.investmentType)
        case "STAGE":
            return mapDealStageToString(filterType.stage)
        case "STATUS":
            return filterType.status === "LIVE" ? "Live Deals" : "Exited Deals"
        case "WITH_INVESTMENT":
            return filterType.option === "WITH" ? "With" : "Without"
    }
}