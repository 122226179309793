import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const BagIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M13.5 4H11a3 3 0 0 0-3-3 3 3 0 0 0-3 3H2.5a1 1 0 0 0-1 1v7.5a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zM8 2a2 2 0 0 1 2 2H6a2 2 0 0 1 2-2zm5.5 10.5h-11V5H5v1a.5.5 0 0 0 .5.5A.5.5 0 0 0 6 6V5h4v1a.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5V5h2.5v7.5z"
            />
        </svg>
    )
}

export default BagIcon