import React, {FunctionComponent} from "react"
import {FilterType} from "./filter.type"
import Button from "../button/button.component"
import {ButtonStyle} from "../button/style.enum"
import FunnelIcon from "../icon/funnel.component"
import {ButtonSize} from "../button/size.enum"
import {ButtonState} from "../button/state.enum"
import FilterOptions from "./options/options.component"
import styles from "./filter.module.sass"
import {DealOnboardingDependentType} from "../../domain/deal/onboarding-dependent.type"

export type FilterProps = {
    deals: DealOnboardingDependentType[]
    filterTypes: FilterType[]
    updateFilterTypes: (updatedFilterType: FilterType[]) => void
    showExitedLiveFilter?: boolean
    showInvestmentTypeFilter?: boolean
    showWithInvestmentFilter?: boolean
    opened: boolean
    updateOpened: (opened: boolean) => void
    closeSort?: () => void
}

const DealFilter: FunctionComponent<FilterProps> = ({
    deals,
    filterTypes,
    updateFilterTypes,
    showExitedLiveFilter = false,
    showInvestmentTypeFilter = false,
    showWithInvestmentFilter = false,
    opened,
    updateOpened,
    closeSort,
}) => {
    const toggleOpened = () => {
        if (!opened && closeSort) closeSort()
        updateOpened(!opened)
    }

    return (
        <div className={styles.filter} key={Math.random()}>
            <Button
                label="Filter"
                type="button"
                size={ButtonSize.SMALL}
                state={filterTypes.length > 0 ? ButtonState.ACTIVE : ButtonState.INACTIVE}
                style={filterTypes.length > 0 ? ButtonStyle.PRIMARY : ButtonStyle.SECONDARY}
                icon={
                    <FunnelIcon
                        width={16}
                        height={16}
                        fillClass="fill-icon-inactive"
                    />
                }
                onClick={toggleOpened}
            />
            <FilterOptions
                deals={deals}
                filterTypes={filterTypes}
                updateFilterTypes={updateFilterTypes}
                showInvestmentTypeFilter={showInvestmentTypeFilter}
                showStatusFilter={showExitedLiveFilter}
                showWithInvestmentFilter={showWithInvestmentFilter}
                opened={opened}
                close={() => updateOpened(false)}
                key={Math.random()}
            />
        </div>
    )
}

export default DealFilter