import {CurrencyOpenApi} from "../../../generated"
import {InvestmentFeeWithAmountType} from "../fee/fees.type"

export class InvestmentExchangePreviewType {
    public amountAtExchangeRate: number
    public amountWithPlacementFeeAndExchangeFee: number
    public amountWithPlacementFeeAndExchangeFeeAtExchangeRate: number
    public currency: CurrencyOpenApi
    public exchangePreviewId: string
    public fee: InvestmentFeeWithAmountType

    constructor({
        amountAtExchangeRate,
        amountWithPlacementFeeAndExchangeFee,
        amountWithPlacementFeeAndExchangeFeeAtExchangeRate,
        currency,
        exchangePreviewId,
        fee
    } : {
        amountAtExchangeRate: number,
        amountWithPlacementFeeAndExchangeFee: number
        amountWithPlacementFeeAndExchangeFeeAtExchangeRate: number
        currency: CurrencyOpenApi
        exchangePreviewId: string
        fee: InvestmentFeeWithAmountType
    }) {
        this.amountAtExchangeRate = amountAtExchangeRate
        this.amountWithPlacementFeeAndExchangeFee = amountWithPlacementFeeAndExchangeFee
        this.amountWithPlacementFeeAndExchangeFeeAtExchangeRate = amountWithPlacementFeeAndExchangeFeeAtExchangeRate
        this.currency = currency
        this.exchangePreviewId = exchangePreviewId
        this.fee = fee
    }

    public getAmountAtExchangeRateFormatted(): string {
        return this.amountAtExchangeRate.toLocaleString("en", {
            currency: this.currency,
            style: 'currency'
        })
    }

    public getAmountWithPlacementFeeAndExchangeFeeFormatted(): string {
        return this.amountWithPlacementFeeAndExchangeFee.toLocaleString("en", {
            currency: this.currency,
            style: 'currency'
        })
    }

    public getAmountWithPlacementFeeAndExchangeFeeAtExchangeRateFormatted(): string {
        return this.amountWithPlacementFeeAndExchangeFeeAtExchangeRate.toLocaleString("en", {
            currency: this.currency,
            style: 'currency'
        })
    }
}