import React, {FunctionComponent} from "react"
import {localPoint} from "@visx/event"
import {Group} from "@visx/group"
import {scaleOrdinal} from "@visx/scale"
import {Pie} from "@visx/shape"
import {LegendOrdinal} from "@visx/legend"
import {DonutChartDataPoint} from "./donut.type"
import {ParentSize} from "@visx/responsive"
import styles from "./donut.module.sass"
import {useTooltip, useTooltipInPortal} from "@visx/tooltip"

type DonutChartProps = {
    data: DonutChartDataPoint[]
    maxWidth: number
}

const DonutChart: FunctionComponent<DonutChartProps> = ({
    data,
    maxWidth
}) => {
    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip<DonutChartDataPoint>();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        detectBounds: true,
        scroll: true,
    })

    const handleMouseOver = (event: React.MouseEvent<SVGGElement, MouseEvent>, point: DonutChartDataPoint) => {
        const coords = localPoint(event.currentTarget, event)
        showTooltip({
            tooltipLeft: coords!.x,
            tooltipTop: coords!.y,
            tooltipData: point
        })
    }

    const margin = { top: 16, right: 16, bottom: 16, left: 16 }

    const getFrequencyColor = scaleOrdinal({
        domain: data.map((p) => p.label),
        range: [
            "#A155B9",
            "#165BAA",
            "#F765A3",
            "#16BFD6",
            "rgba(161, 85, 185, 0.6)",
            "rgba(22, 91, 170, 0.6)",
            "rgba(247, 101, 163, 0.6)",
            "rgba(22, 191, 214, 0.6)",
            "#FFA8A8",
            "#91A7FF",
            "#66D9E8",
            "#8CE99A",
            "#FFC078",
        ]
    })

    return (
        <div className={styles.donut}>
            <div className={styles.chart}>
                <ParentSize
                    debounceTime={10}
                    style={{ width: "100%", maxWidth }}
                >
                    {({ width}) => {
                        const height = width
                        const innerWidth = width - margin.left - margin.right
                        const innerHeight = height - margin.top - margin.bottom
                        const radius = Math.min(innerWidth, innerHeight) / 2
                        const centerY = innerHeight / 2
                        const centerX = innerWidth / 2
                        const top = centerY + margin.top
                        const left = centerX + margin.left
                        return (
                            <>
                                <svg
                                    width={width}
                                    height={height}
                                    ref={containerRef}
                                >
                                    <Group
                                        top={top}
                                        left={left}
                                    >
                                        <Pie
                                            data={data}
                                            pieValue={(d) => d.frequency}
                                            pieSortValues={(a: number, b: number) => b - a}
                                            outerRadius={radius}
                                            innerRadius={radius / 1.8}
                                        >
                                            {(pie) => {
                                                return pie.arcs.map((arc, index) => {
                                                    const { label } = arc.data
                                                    const arcPath = pie.path(arc)!
                                                    const arcFill = getFrequencyColor(label)
                                                    return (
                                                        <g
                                                            key={`arc-${label}-${index}`}
                                                            onMouseOver={(e) => handleMouseOver(e, arc.data)}
                                                            onMouseOut={hideTooltip}
                                                        >
                                                            <path d={arcPath} fill={arcFill} />
                                                        </g>
                                                    );
                                                });
                                            }}
                                        </Pie>
                                    </Group>
                                </svg>
                                {tooltipOpen && (
                                    <TooltipInPortal
                                        key={Math.random()}
                                        top={tooltipTop}
                                        left={tooltipLeft}
                                    >
                                        <strong>{tooltipData?.label}</strong>
                                    </TooltipInPortal>
                                )}
                            </>
                        )
                    }}
                </ParentSize>
            </div>
            <div className={styles.legendWrapper}>
                <div style={{ maxWidth: maxWidth + 40 }}>
                    <LegendOrdinal
                        scale={getFrequencyColor}
                        shape="circle"
                        direction="row"
                        shapeWidth={12}
                        shapeHeight={12}
                        className={styles.legend}
                    />
                </div>
            </div>
        </div>
    )
}

export default DonutChart