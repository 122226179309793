import React, {FunctionComponent, ReactNode} from "react"
import Imgix from "react-imgix"
import FavouriteBookmark from "../../favourite/bookmark.component"
import {DealInterface} from "../../deal.interface"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import styles from "./head.module.sass"

type DealHeadProps = {
    deal: DealInterface
    showBookmark?: boolean
}

const DealHead: FunctionComponent<DealHeadProps> = ({
    deal,
    showBookmark = true
}) => {
    return (
        <>
            <div className={styles.head}>
                <div className={styles.logo}>
                    <div>
                        {deal.name && deal.logos ? (
                            <LogoWithTitle
                                name={deal.name}
                                logo={deal.logos?.normal?.url && (
                                    <Imgix
                                        src={deal.logos.normal.url}
                                        height={56}
                                        htmlAttributes={{ alt: deal.name }}
                                    />
                                )}
                            />
                        ) : (
                            <LogoWithTitle name={deal.name}/>
                        )}
                    </div>
                </div>
                {showBookmark && !deal.isPublic() && (
                    <div>
                        <FavouriteBookmark deal={deal as DealOnboardingDependentType}/>
                    </div>
                )}
            </div>
        </>
    )
}

export default DealHead

const LogoWithTitle: FunctionComponent<{ name?: string, logo?: ReactNode }> = ({ name, logo }) => {
    return (
        <div>
            {logo && name ? (
                <div className={styles.logoWithTitle}>
                    <div>
                        {logo}
                    </div>
                    <div className={`${styles.title} ${styles.titleAndLogo}`}>
                        <div>
                            {name}
                        </div>
                    </div>
                </div>
            ) : name ? (
                <div className={styles.title}>
                    {name}
                </div>
            ) : <></>}
        </div>
    )
}