import {InvestmentExchangePreviewOpenApi} from "../../../generated"
import {InvestmentExchangePreviewType} from "./exchange-preview.type"
import {mapOpenApiToInvestmentFeeWithAmount} from "../fee/fees.mapper"

export function mapOpenApiToInvestmentExchangePreview(preview: InvestmentExchangePreviewOpenApi): InvestmentExchangePreviewType {
    return new InvestmentExchangePreviewType({
        amountAtExchangeRate: preview.amountAtExchangeRate,
        amountWithPlacementFeeAndExchangeFee: preview.amountWithPlacementFeeAndExchangeFee,
        amountWithPlacementFeeAndExchangeFeeAtExchangeRate: preview.amountWithPlacementFeeAndExchangeFeeAtExchangeRate,
        currency: preview.currency,
        exchangePreviewId: preview.exchangePreviewId,
        fee: mapOpenApiToInvestmentFeeWithAmount(preview.fee)
    })
}