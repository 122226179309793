import {InputFieldProps} from "../props.type"
import React, {FunctionComponent} from "react"
import FormError from "../../error/error.component"
import {hasError} from "../../error/error.util"
import styles from "./text.module.sass"
import FieldLabelText from "../label-text.component"

export type TextFieldProps = InputFieldProps & {
    prefill?: string
}

const TextField: FunctionComponent<TextFieldProps> = ({
    placeholder,
    errors,
    prefill,
    reactHookFormRegister,
    label,
    disabled = false
}) => {
    return (
        <label className={styles.label}>
            <FieldLabelText label={label}/>
            <input
                type="text"
                placeholder={placeholder}
                defaultValue={prefill}
                disabled={disabled}
                className={hasError(reactHookFormRegister.name, errors) ? styles.withError : undefined}
                {...reactHookFormRegister.register(
                    reactHookFormRegister.name,
                    reactHookFormRegister.options
                )}
            />
            <FormError
                field={reactHookFormRegister.name}
                errors={errors}
            />
        </label>
    )
}

export default TextField