import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import {PlanType} from "./plan.type"
import {CurrencyOpenApi, SubscriptionPaymentIntervalOpenApi} from "../../../generated"

export function getPricePerInterval(
    type: PlanType,
    paymentInterval: SubscriptionPaymentIntervalOpenApi
): number {
    switch (type) {
        case "BASIC":
            return 0
        case "PRO":
            return paymentInterval === SubscriptionPaymentIntervalOpenApi.Monthly
                ? 65
                : 702
        case "ELITE":
            return paymentInterval === SubscriptionPaymentIntervalOpenApi.Monthly
                ? 400
                : 4300
    }
}

export function getPriceStringRepresentation(
    type: PlanType,
    paymentInterval: SubscriptionPaymentIntervalOpenApi
): string {
    const pricePerInterval = getPricePerInterval(type, paymentInterval)

    if (pricePerInterval === 0) {
        return "Free"
    }

    const pricePerIntervalString = pricePerInterval.toLocaleString("en", {
        currency: CurrencyOpenApi.Sgd,
        style: 'currency',
        maximumFractionDigits: 0
    })
    return `${pricePerIntervalString} / ${getPaymentIntervalAsString(paymentInterval)}`
}

export function shouldSeePremiumMembership(onboarding: InvestorOnboardingType): boolean {
    const email = onboarding.investorWithDetails?.investor.person?.contact.email || "-"
    const hasEligibleMail = [
        "benni@nonpublic.io",
        "sascha@nonpublic.io"
    ].includes(email)
    return hasEligibleMail
        && onboarding.stage.type !== "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED"
        && onboarding.stage.type !== "GENERAL_DATA"
}

function getPaymentIntervalAsString(paymentInterval: SubscriptionPaymentIntervalOpenApi): string {
    switch (paymentInterval) {
        case "MONTHLY":
            return "month"
        case "YEARLY":
            return "year"
    }
}
