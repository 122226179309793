import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import {SignInType} from "./sign-in.type"
import Alert from "../../alert/alert.component"
import {AlertType} from "../../alert/type.enum"
import SubmitButton from "../../form/submit/submit.component"
import FormRow from "../../form/row/row.component"
import EmailField from "../../form/fields/email/email.component"
import PasswordField from "../../form/fields/password/password.component"
import formStyles from "../../form/form.module.sass"
import styles from "./form.module.sass"
import {useSearchParams} from "react-router-dom"

type SignInFormProps = {
    onSubmit: SubmitHandler<SignInType>
}

const SignInForm: FunctionComponent<SignInFormProps> = ({ onSubmit }) => {
    const [searchParams] = useSearchParams()
    const email = searchParams.get("email") || undefined
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<SignInType>()

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            {searchParams.get("confirmed") && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.SUCCESS}
                        text="Your email was verified successfully. You can sign in now."
                    />
                </div>
            )}
            {searchParams.get("changedPassword") && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.SUCCESS}
                        text="Your password was changed successfully."
                    />
                </div>
            )}
            <FormRow content={{
                type: "one",
                element: (
                    <EmailField
                        placeholder="Your Email"
                        autoComplete="email"
                        errors={errors}
                        prefill={email}
                        reactHookFormRegister={{
                            name: "email",
                            register,
                            options: {
                                required: "Email is required."
                            }
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <PasswordField
                        placeholder="Password"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "password",
                            register,
                            options: {
                                required: "Password is required."
                            }
                        }}
                        autoComplete="current-password"
                    />
                )
            }}/>
            <SubmitButton label="Proceed"/>
        </form>
    )
}

export default SignInForm