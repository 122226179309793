import React, {FunctionComponent, useState} from "react"
import initializationStyles from "../initialization.module.sass"
import ButtonWrapper from "../../../../tech/button/wrapper/button-wrapper.component"
import Button from "../../../../tech/button/button.component"
import {ButtonState} from "../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import {ButtonSize} from "../../../../tech/button/size.enum"
import CheckboxOutsideForm from "../../../../tech/form/fields/checkbox/checkbox-outside-form.component"
import {CurrencyOpenApi} from "../../../../generated"
import ConfirmationModal from "../../../../tech/layout/modal/confirmation/confirmation.component"
import styles from "./action.module.sass"

type DealInvestmentInitializationActionProps = {
    amount: number
    hasAmountErrors: boolean
    currency: CurrencyOpenApi
    agreedToTerms: boolean
    updateAgreedToTerms: (agreedToTerms: boolean) => void
    invest: () => void
}

const DealInvestmentInitializationAction: FunctionComponent<DealInvestmentInitializationActionProps> = ({
    amount,
    hasAmountErrors,
    currency,
    agreedToTerms,
    updateAgreedToTerms,
    invest
}) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

    const amountFormatted = (parseFloat(String(amount))).toLocaleString("en", {
        currency: currency,
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })

    return (
        <div className={styles.action}>
            {showConfirmationModal && (
                <ConfirmationModal
                    close={() => setShowConfirmationModal(false)}
                    confirm={(
                        <Button
                            label="Accept"
                            type="button"
                            size={ButtonSize.SMALL}
                            state={ButtonState.ACTIVE}
                            style={ButtonStyle.PRIMARY}
                            onClick={() => {
                                setShowConfirmationModal(false)
                                invest()
                            }}
                        />
                    )}
                    decline={(
                        <Button
                            label="Decline"
                            type="button"
                            size={ButtonSize.SMALL}
                            state={ButtonState.ACTIVE}
                            style={ButtonStyle.SECONDARY}
                            onClick={() => setShowConfirmationModal(false)}
                        />
                    )}
                >
                    <>
                        <div className={styles.modalTitle}>
                            Investment Confirmation
                        </div>
                        <div className={styles.modalDescription}>
                            Are you sure you want to invest <strong>{amountFormatted}</strong>?
                        </div>
                        <div className={styles.modalNotice}>
                            Your confirmation will be final. You will not be able to make changes to your submission after pressing Accept.
                        </div>
                    </>
                </ConfirmationModal>
            )}
            <div className={`${styles.title} ${initializationStyles.title}`}>
                Review documents and terms
            </div>
            <div className={styles.button}>
                <ButtonWrapper
                    rightOnDesktop={false}
                    hideSpacer={true}
                >
                    <div className={styles.buttonAndCheckbox}>
                        <div className={styles.checkbox}>
                            <CheckboxOutsideForm
                                label="I have read the terms, provisions and timeline stated."
                                id="terms"
                                checked={agreedToTerms}
                                onClick={() => updateAgreedToTerms(!agreedToTerms)}
                            />
                        </div>
                        <Button
                            label="Invest"
                            type="button"
                            onClick={() => setShowConfirmationModal(true)}
                            size={ButtonSize.LARGE}
                            state={ButtonState.ACTIVE}
                            style={ButtonStyle.PRIMARY}
                            fullWidth={true}
                            withArrow={true}
                            disabled={!agreedToTerms || hasAmountErrors}
                        />
                    </div>
                </ButtonWrapper>
            </div>
        </div>
    )
}

export default DealInvestmentInitializationAction
