import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import {InvestmentType} from "../investment.type"
import {InvestmentTimelineItemType} from "./timeline.type"
import {evaluateStage} from "../pending/stage/stage.util"
import {InvestmentStage} from "../pending/stage/stage.type"
import {TimelineItemStatusType, TimelineItemType} from "../../../tech/layout/timeline/item/item.type"

export function determineTimelineItems(
    onboarding: InvestorOnboardingType,
    investment?: InvestmentType
): TimelineItemType<InvestmentTimelineItemType>[] {
    return [
        "SIGN_DOCUMENTS",
        "WAIT_FOR_MINIMUM_AMOUNT_OR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER",
        "COMPLETE_PAYMENT",
        "DEAL_CLOSING"
    ].map(t => determineItem(t as InvestmentTimelineItemType, onboarding, investment))
}

function determineItem(
    type: InvestmentTimelineItemType,
    onboarding: InvestorOnboardingType,
    investment?: InvestmentType
): TimelineItemType<InvestmentTimelineItemType> {
    const stage = investment && evaluateStage(investment, onboarding)
    switch (type) {
        case "SIGN_DOCUMENTS":
            return {
                text: "Sign Documents",
                type: "SIGN_DOCUMENTS",
                status: determineStateForSignDocuments(stage)
            }
        case "WAIT_FOR_MINIMUM_AMOUNT_OR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER":
            return investment?.deal.waitingState === "WAITING_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER"
                ? {
                    text: "Preparing payment instructions",
                    type: "WAIT_FOR_MINIMUM_AMOUNT_OR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER",
                    status: determineStateForWaitForMinimumAmountOrManualPaymentInstructions(stage)
                } : {
                    text: "Wait for minimum amount to be reached",
                    type: "WAIT_FOR_MINIMUM_AMOUNT_OR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER",
                    status: determineStateForWaitForMinimumAmountOrManualPaymentInstructions(stage)
                }
        case "COMPLETE_PAYMENT":
            return {
                text: "Complete Payment",
                type: "COMPLETE_PAYMENT",
                status: determineStateForCompletePayment(investment, stage)
            }
        case "DEAL_CLOSING":
            return {
                text: "Deal Closing",
                type: "DEAL_CLOSING",
                status: determineStateForDealClosing(stage)
            }
    }
}

function determineStateForSignDocuments(stage?: InvestmentStage): TimelineItemStatusType {
    return !stage || stage === "SIGN_DOCUMENTS"
        ? "ACTIVE"
        : "DONE"
}

function determineStateForWaitForMinimumAmountOrManualPaymentInstructions(stage?: InvestmentStage): TimelineItemStatusType {
    if (!stage || stage === "SIGN_DOCUMENTS") return "INACTIVE"
    if (stage === "WAIT_FOR_MINIMUM_AMOUNT") return "ACTIVE"
    if (stage === "WAIT_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER") return "ACTIVE"
    return "DONE"
}

function determineStateForCompletePayment(investment?: InvestmentType, stage?: InvestmentStage): TimelineItemStatusType {
    const previousStages: InvestmentStage[] = [
        "SIGN_DOCUMENTS",
        "WAIT_FOR_MINIMUM_AMOUNT",
        "WAIT_FOR_MANUAL_PAYMENT_INSTRUCTIONS_TRIGGER",
    ]
    if (investment?.paid) return "DONE"
    if (!stage || previousStages.includes(stage)) return "INACTIVE"
    if (stage === "MAKE_PAYMENT") return "ACTIVE"
    return "DONE"
}

function determineStateForDealClosing(stage?: InvestmentStage): TimelineItemStatusType {
    if (stage && (stage === "CLOSED" || stage === "FINALIZED")) return "DONE"
    if (stage && stage === "WAIT_FOR_CLOSING") return "ACTIVE"
    return "INACTIVE"
}