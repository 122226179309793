import React, {useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {SubmitHandler} from "react-hook-form"
import {WaitlistRequestType} from "./waitlist.type"
import {normalizeValues} from "../../tech/form/form.util"
import AuthHead from "../../tech/auth/head/head.component"
import WaitlistForm from "./form/form.component"
import WaitlistSuccess from "./success/success.component"
import ActionLine from "../../tech/auth/action-line.component"
import {WaitlistEnterResponseValidationErrors} from "./response.type"
import {useApp} from "../../tech/app/context/app.context"
import styles from "./waitlist.module.sass"
import {SIGN_IN} from "../../paths"

const Waitlist = () => {
    const app = useApp()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR" | "SUCCESS" | "ALREADY_ON_LIST" | "VALIDATION_ERROR">("NONE")
    const [validationErrors, setValidationErrors] = useState<string[]>([])

    const onSubmit: SubmitHandler<WaitlistRequestType> = async (waitlistRequest) => {
        try {
            setState("LOADING")
            const response = await app.fetchClient.waitlistApi.enter(normalizeValues(waitlistRequest))
            setState(response.type)
            if (response.type === "VALIDATION_ERROR") {
                setValidationErrors((response as WaitlistEnterResponseValidationErrors).errors)
            }
            else {
                setValidationErrors([])
            }
        }
        catch (err) {
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.ERROR}
                        text="Unexpected error."
                    />
                </div>
            )}
            {state === "VALIDATION_ERROR" && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.ERROR}
                        text={assembleValidationErrorText(validationErrors)}
                    />
                </div>
            )}
            {state === "ALREADY_ON_LIST" && (
                <Alert
                    type={AlertType.WARNING}
                    text="You are already on the waitlist."
                />
            )}
            {(state === "NONE" || state === "ERROR" || state === "VALIDATION_ERROR" || state === "ALREADY_ON_LIST") && (
                <div className={styles.waitlist}>
                    <AuthHead title="Launching Soon"/>
                    <WaitlistForm onSubmit={onSubmit}/>
                    <ActionLine
                        intro="Already a member?"
                        linkText="Sign in"
                        route={SIGN_IN}
                    />
                </div>
            )}
            {state === "SUCCESS" && (
                <WaitlistSuccess/>
            )}
        </>
    )
}

export default Waitlist

function assembleValidationErrorText(validationErrors: string[]) {
    return (
        <>
            Validation failed.
            {validationErrors.length > 0 && (
                <ul>
                    {validationErrors.map((e, index) => (
                        <li key={`validation-error-${index}`}>
                            {e}
                        </li>
                    ))}
                </ul>
            )}
        </>
    )
}