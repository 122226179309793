import React, {FunctionComponent} from "react"
import {FieldErrors} from "react-hook-form"
import {access} from "./error.util"
import formStyles from "../form.module.sass"

type FormErrorProps = {
    field: string
    errors: FieldErrors
}

const FormError: FunctionComponent<FormErrorProps> = ({ field, errors }) => {
    const error = access(field, errors)
    if (error) {
        return (
            <div className={formStyles.error}>
                {error?.message || "Invalid."}
            </div>
        )
    }
    return <></>
}

export default FormError