import {
    InvestorDashboardInvestmentOpenApi,
    InvestorDashboardOpenApi,
    InvestorDashboardPerformanceDataPointOpenApi,
    InvestorDashboardPerformanceOpenApi,
    InvestorDashboardPerformanceStrongestOpenApi,
    InvestorDashboardSummaryOpenApi,
    type InvestorDashboardUnderlyingInvestmentOpenApi
} from "../../../generated"
import {
    DashboardType,
    InvestorDashboardInvestmentType,
    InvestorDashboardPerformanceDataPoint,
    InvestorDashboardPerformanceStrongest,
    InvestorDashboardPerformanceType,
    InvestorDashboardSummaryType
} from "./dashboard.type"
import {mapOpenApiToInvestment} from "../../investment/investment.mapper"
import {mapOpenApiToDeal} from "../../deal/deal.mapper"
import {mapOpenApiToDealGeography} from "../../deal-geography/deal-geography.mapper"
import {mapOpenApiToIndividualInvestment} from "../../investment/individual/individual-investment.mapper"
import {InvestorDashboardUnderlyingInvestmentType} from "./investments/investment.type"

export function mapOpenApiToInvestorDashboard(dashboard: InvestorDashboardOpenApi): DashboardType {
    return {
        currency: dashboard.currency,
        investments: dashboard.investments.map((i: InvestorDashboardInvestmentOpenApi) => mapOpenApiToInvestorDashboardInvestment(i)),
        summary: mapOpenApiToInvestorDashboardSummary(dashboard.summary),
        unfilteredDeals: dashboard.unfilteredDeals.map(d => mapOpenApiToDeal(d)),
        unfilteredInvestmentsCount: dashboard.unfilteredInvestmentsCount
    }
}

export function mapOpenApiToInvestorDashboardInvestment(investment: InvestorDashboardInvestmentOpenApi): InvestorDashboardInvestmentType {
    return {
        amount: investment.amount,
        currentValue: investment.currentValue,
        investment: mapInvestorDashboardUnderlyingInvestmentOpenApi(investment.investment),
        irr: investment.irr,
        lastUpdated: investment.lastUpdated,
        moic: investment.moic
    }
}

export function mapOpenApiToInvestorDashboardSummary(summary: InvestorDashboardSummaryOpenApi): InvestorDashboardSummaryType {
    return {
        amount: summary.amount,
        currentValue: summary.currentValue,
        irr: summary.irr,
        moic: summary.moic,
        performance: mapOpenApiToInvestorDashboardPerformance(summary.performance)
    }
}

export function mapOpenApiToInvestorDashboardPerformance(performance: InvestorDashboardPerformanceOpenApi): InvestorDashboardPerformanceType {
    return {
        invested: performance.invested.map(mapOpenApiToInvestorDashboardPerformanceDataPoint),
        realized: performance.realized && performance.realized.map(mapOpenApiToInvestorDashboardPerformanceDataPoint),
        unrealized: performance.unrealized.map(mapOpenApiToInvestorDashboardPerformanceDataPoint),
        strongest: mapOpenApiToInvestorDashboardPerformanceStrongest(performance.strongest)
    }
}

export function mapOpenApiToInvestorDashboardPerformanceDataPoint(
    dataPoint: InvestorDashboardPerformanceDataPointOpenApi
): InvestorDashboardPerformanceDataPoint {
    return {
        artificial: dataPoint.artificial,
        date: dataPoint.date,
        description: dataPoint.description,
        value: dataPoint.value
    }
}

export function mapOpenApiToInvestorDashboardPerformanceStrongest(
    strongest: InvestorDashboardPerformanceStrongestOpenApi
): InvestorDashboardPerformanceStrongest {
    return {
        geolocation: strongest.geolocation && mapOpenApiToDealGeography(strongest.geolocation),
        industry: strongest.industry,
        stage: strongest.stage
    }
}

export function mapInvestorDashboardUnderlyingInvestmentOpenApi(
    underlyingInvestment: InvestorDashboardUnderlyingInvestmentOpenApi
): InvestorDashboardUnderlyingInvestmentType {
    if ("valuations" in underlyingInvestment) {
        return new InvestorDashboardUnderlyingInvestmentType({
            type: "INDIVIDUAL",
            value: mapOpenApiToIndividualInvestment(underlyingInvestment)
        })
    }
    return new InvestorDashboardUnderlyingInvestmentType({
        type: "REGULAR",
        value: mapOpenApiToInvestment(underlyingInvestment)
    })
}