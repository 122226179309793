import {
    ResponseError,
    ValidationResponseOpenApiFromJSON,
    WaitlistApiClient,
    WaitlistRequestOpenApi
} from "../../generated"
import {mapWaitlistRequestToOpenApi} from "./waitlist.mapper"
import {WaitlistEnterResponse} from "./response.type"

export class WaitlistApi {

    constructor(private apiClient: WaitlistApiClient) {
    }

    public async enter(waitlistRequest: WaitlistRequestOpenApi): Promise<WaitlistEnterResponse> {
        try {
           await this.apiClient.waitlistEnter({ waitlistRequestOpenApi: mapWaitlistRequestToOpenApi(waitlistRequest) })
        }
        catch (err) {
            if ((err as ResponseError)?.response?.status === 409) {
                return { type: "ALREADY_ON_LIST" }
            }
            else if ((err as ResponseError)?.response?.status === 400) {
                return {
                    type: "VALIDATION_ERROR",
                    errors: ValidationResponseOpenApiFromJSON(await (err as ResponseError).response.json()).errors?.map(e => e.message) ?? []
                }
            }
            else {
                return { type: "ERROR" }
            }
        }
        return { type: "SUCCESS" }
    }

}