import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const QuestionCircleIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path
                className={fillClass}
                d="M13.125 16.875c0 .223-.066.44-.19.625s-.299.329-.505.414-.432.107-.65.064-.419-.151-.576-.308-.264-.358-.308-.576-.021-.445.064-.65.229-.381.414-.505.402-.19.625-.19c.298 0 .585.118.796.329s.329.497.329.796zM12 6.75c-2.068 0-3.75 1.514-3.75 3.375v.375a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-.375c0-1.031 1.01-1.875 2.25-1.875s2.25.844 2.25 1.875S13.24 12 12 12a.75.75 0 0 0-.75.75v.75a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-.068c1.71-.314 3-1.678 3-3.307 0-1.861-1.682-3.375-3.75-3.375zM21.75 12a9.75 9.75 0 0 1-6.019 9.008 9.75 9.75 0 0 1-10.625-2.113A9.75 9.75 0 0 1 2.992 8.269 9.75 9.75 0 0 1 12 2.25 9.76 9.76 0 0 1 21.75 12zm-1.5 0a8.25 8.25 0 0 0-5.093-7.622 8.25 8.25 0 0 0-8.991 1.788 8.25 8.25 0 0 0-1.788 8.991A8.25 8.25 0 0 0 12 20.25 8.26 8.26 0 0 0 20.25 12z"
            />
        </svg>
    )
}

export default QuestionCircleIcon