import React, {useState} from "react"
import LoadingDots from "../loading/dots/dots.component"
import {useApp} from "./context/app.context"
import AppNotLoading from "./AppNotLoading"

const App = () => {
    const app = useApp()
    const [alreadyRedirected, setAlreadyRedirected] = useState<boolean>(false)

    if (app.loading) {
        return <LoadingDots/>
    }

    return (
        <AppNotLoading
            alreadyRedirected={alreadyRedirected}
            updateAlreadyRedirected={setAlreadyRedirected}
        />
    )
}

export default App