import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

type SortIconProps = IconPropsType & {
    mode: "ASCENDING" | "DESCENDING" | "NONE"
    fillClassActive: string
}

const SortIcon: FunctionComponent<SortIconProps> = ({
    width,
    height,
    fillClass,
    fillClassActive,
    mode
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={mode === "DESCENDING" ? fillClassActive : fillClass}
                d="M7.354 10.646A.5.5 0 0 1 7.5 11a.5.5 0 0 1-.147.354l-2 2A.5.5 0 0 1 5 13.5a.5.5 0 0 1-.354-.147l-2-2A.5.5 0 0 1 2.5 11a.5.5 0 0 1 .147-.354.5.5 0 0 1 .545-.108.5.5 0 0 1 .162.108L4.5 11.793V3a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v8.793l1.146-1.147A.5.5 0 0 1 7 10.5a.5.5 0 0 1 .354.147z"
            />
            <path
                className={mode === "ASCENDING" ? fillClassActive : fillClass}
                d="M11.354 2.647l2 2a.5.5 0 0 1 0 .708.5.5 0 0 1-.707 0L11.5 4.207V13a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V4.207L9.354 5.354a.5.5 0 0 1-.707 0 .5.5 0 0 1 0-.708l2-2A.5.5 0 0 1 11 2.5a.5.5 0 0 1 .354.147z"
            />
        </svg>
    )
}

export default SortIcon