import React, {FunctionComponent, useState} from "react"
import FileUploadMobileFunction from "../../function.component"
import TakePicture from "./take-picture.component"
import CameraIcon from "../../../../../../tech/icon/camera.component"

type TakePictureFunctionProps = {
    close: () => void
    uploadFile: (file: File) => void
}

const TakePictureFunction: FunctionComponent<TakePictureFunctionProps> = ({ close, uploadFile }) => {
    const [opened, setOpened] = useState<boolean>(false)

    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        return (
            <>
                <FileUploadMobileFunction
                    label="Take picture"
                    icon={
                        <CameraIcon
                            width={24}
                            height={24}
                            fillClass="fill-icon-theme"
                        />
                    }
                    onClick={() => setOpened(true)}
                />
                {opened && (
                    <TakePicture
                        close={close}
                        uploadFile={uploadFile}
                    />
                )}
            </>
        )
    }

    return <></>
}

export default TakePictureFunction