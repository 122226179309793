import React, {FunctionComponent} from "react"
import FilterSectionHead from "../head/head.component"
import styles from "./section.module.sass"
import CheckboxOutsideForm from "../../../form/fields/checkbox/checkbox-outside-form.component"
import {FilterOptions, FilterOptionsType, FilterOptionType} from "../options.type"
import FilterOption from "../option/option.component"

type FilterSectionProps = {
    label: string
    type: FilterOptions
    options: FilterOptionsType
    updateOptions: (options: FilterOptionsType) => void
}

const FilterSection: FunctionComponent<FilterSectionProps> = ({
    label,
    type,
    options,
    updateOptions
}) => {
    const relevantOptions = getRelevantOptions(options, type)
    const allSelected = relevantOptions.every(o => o.selected)
    return (
        <div className={styles.section}>
            <FilterSectionHead label={label}/>
            {allSelected && relevantOptions.length > 1 && (
                <div className={styles.deSelectAll}>
                    <CheckboxOutsideForm
                        id={`${label}-deselect-all`}
                        label={allSelected ? "Deselect all" : "Select all"}
                        checked={allSelected}
                        onClick={() => updateOptions(setForAll(
                            !allSelected,
                            options,
                            type
                        ))}
                    />
                </div>
            )}
            {relevantOptions.map(option => (
                <FilterOption
                    option={option}
                    options={options}
                    updateOptions={updateOptions}
                    key={`option-${option.id}`}
                />
            ))}
        </div>
    )
}

export default FilterSection

function getRelevantOptions(
    options: FilterOptionsType,
    type: FilterOptions
): FilterOptionType<any>[] {
    switch (type) {
        case "CATEGORY":
            return options.categories
        case "GEOGRAPHY":
            return options.geographies
        case "INVESTMENT_TYPE":
            return options.investmentTypes ? options.investmentTypes : []
        case "STAGE":
            return options.stages
        case "STATUS":
            return options.statuses ? options.statuses : []
        case "WITH_INVESTMENT":
            return options.withInvestmentOptions ? options.withInvestmentOptions : []
    }
}

function setForAll(
    desiredState: boolean,
    options: FilterOptionsType,
    type: FilterOptions
): FilterOptionsType {
    return {
        ...options,
        categories: type === "CATEGORY"
            ? options.categories.map(o => ({ ...o, selected: desiredState }))
            : options.categories,
        geographies: type === "GEOGRAPHY"
            ? options.geographies.map(o => ({ ...o, selected: desiredState }))
            : options.geographies,
        investmentTypes: type === "INVESTMENT_TYPE"
            ? options.investmentTypes?.map(o => ({ ...o, selected: desiredState }))
            : options.investmentTypes,
        stages: type === "STAGE"
            ? options.stages.map(o => ({ ...o, selected: desiredState }))
            : options.stages,
        statuses: type === "STATUS"
            ? options.statuses?.map(o => ({ ...o, selected: desiredState }))
            : options.statuses,
        withInvestmentOptions: type === "WITH_INVESTMENT"
            ? options.withInvestmentOptions?.map(o => ({ ...o, selected: desiredState }))
            : options.withInvestmentOptions
    }
}