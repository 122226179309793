import React, {FunctionComponent} from "react"
import Head from "../../../../../tech/head/head.component"
import ButtonWrapper from "../../../../../tech/button/wrapper/button-wrapper.component"
import Button from "../../../../../tech/button/button.component"
import {ButtonSize} from "../../../../../tech/button/size.enum"
import {ButtonState} from "../../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../../tech/button/style.enum"
import {DEALS} from "../../../../../paths"
import {useNavigate} from "react-router-dom"

const InvestorOnboardingKycWaiting: FunctionComponent = () => {
    const navigate = useNavigate()
    return (
        <>
            <Head
                title="Your KYC documents are being reviewed"
                description={(
                    <>
                        <p>
                            Your KYC documents were submitted successfully and are currently being reviewed. You will be notified of your KYC status by email.
                        </p>
                        <p>
                            Meanwhile, you can check your KYC status in your Profile.
                        </p>
                    </>
                )}
            />
            <ButtonWrapper rightOnDesktop={false}>
                <Button
                    label="Continue"
                    type="button"
                    size={ButtonSize.LARGE}
                    state={ButtonState.ACTIVE}
                    style={ButtonStyle.PRIMARY}
                    onClick={() => navigate(DEALS)}
                    withArrow={true}
                />
            </ButtonWrapper>

        </>
    )
}

export default InvestorOnboardingKycWaiting