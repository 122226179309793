/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    IndividualInvestmentOpenApi,
    instanceOfIndividualInvestmentOpenApi,
    IndividualInvestmentOpenApiFromJSON,
    IndividualInvestmentOpenApiFromJSONTyped,
    IndividualInvestmentOpenApiToJSON,
} from './IndividualInvestmentOpenApi';
import {
    InvestmentOpenApi,
    instanceOfInvestmentOpenApi,
    InvestmentOpenApiFromJSON,
    InvestmentOpenApiFromJSONTyped,
    InvestmentOpenApiToJSON,
} from './InvestmentOpenApi';

/**
 * @type InvestorDashboardUnderlyingInvestmentOpenApi
 * 
 * @export
 */
export type InvestorDashboardUnderlyingInvestmentOpenApi = { objectType: 'IndividualInvestment' } & IndividualInvestmentOpenApi | { objectType: 'Investment' } & InvestmentOpenApi;

export function InvestorDashboardUnderlyingInvestmentOpenApiFromJSON(json: any): InvestorDashboardUnderlyingInvestmentOpenApi {
    return InvestorDashboardUnderlyingInvestmentOpenApiFromJSONTyped(json, false);
}

export function InvestorDashboardUnderlyingInvestmentOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorDashboardUnderlyingInvestmentOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['objectType']) {
        case 'IndividualInvestment':
            return {...IndividualInvestmentOpenApiFromJSONTyped(json, true), objectType: 'IndividualInvestment'};
        case 'Investment':
            return {...InvestmentOpenApiFromJSONTyped(json, true), objectType: 'Investment'};
        default:
            throw new Error(`No variant of InvestorDashboardUnderlyingInvestmentOpenApi exists with 'objectType=${json['objectType']}'`);
    }
}

export function InvestorDashboardUnderlyingInvestmentOpenApiToJSON(value?: InvestorDashboardUnderlyingInvestmentOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['objectType']) {
        case 'IndividualInvestment':
            return IndividualInvestmentOpenApiToJSON(value);
        case 'Investment':
            return InvestmentOpenApiToJSON(value);
        default:
            throw new Error(`No variant of InvestorDashboardUnderlyingInvestmentOpenApi exists with 'objectType=${value['objectType']}'`);
    }

}

