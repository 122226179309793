import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CaretCircleDownIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M16 3a13 13 0 0 0-12.01 8.025 13 13 0 0 0 2.818 14.167 13 13 0 0 0 14.167 2.818A13 13 0 0 0 29 16c-.004-3.447-1.374-6.751-3.812-9.188S19.447 3.004 16 3zm0 24A11 11 0 0 1 5.837 20.21 11 11 0 0 1 8.222 8.222 11 11 0 0 1 20.21 5.837 11 11 0 0 1 27 16a11.01 11.01 0 0 1-11 11zm5.708-13.707a1 1 0 0 1 .293.707 1 1 0 0 1-.293.707l-5 5a1 1 0 0 1-.707.293 1 1 0 0 1-.707-.293l-5-5a1 1 0 0 1 0-1.415 1 1 0 0 1 1.415 0L16 17.586l4.293-4.294a1 1 0 0 1 .707-.293 1 1 0 0 1 .707.293z"
            />
        </svg>
    )
}

export default CaretCircleDownIcon