import {
    CurrencyOpenApi,
    DealStageOpenApi,
    DealStatusOpenApi,
    InvestorDashboardApiClient,
    InvestorDashboardInvestmentTypeOpenApi
} from "../../../generated"
import {DashboardType} from "./dashboard.type"
import {mapOpenApiToInvestorDashboard} from "./dashboard.mapper"

export class DashboardApi {

    constructor(private apiClient: InvestorDashboardApiClient) {
    }

    public async get(
        categoryIds: string[],
        geographyIds: string[],
        investmentTypes: InvestorDashboardInvestmentTypeOpenApi[],
        stages: DealStageOpenApi[],
        status: DealStatusOpenApi[],
        targetCurrency: CurrencyOpenApi
    ): Promise<DashboardType> {
        const investorDashboardOpenApi = await this.apiClient.investorDashboardGet({
            categoryIds,
            geographyIds,
            investmentTypes,
            stages,
            status,
            targetCurrency
        })
        return mapOpenApiToInvestorDashboard(investorDashboardOpenApi)
    }

}