import React, {FunctionComponent, ReactNode} from "react"
import {Link, matchPath, useLocation, useNavigate} from "react-router-dom"
import Button from "../button/button.component"
import {ButtonSize} from "../button/size.enum"
import {ButtonState} from "../button/state.enum"
import {ButtonStyle} from "../button/style.enum"
import InfoCircleIcon from "../icon/info-circle.component"
import {HELP_CENTER, SIGN_IN} from "../../paths"
import styles from "./layout.module.sass"
import AuthLayoutWrapper from "./layout-wrapper.component"

const AuthLayout: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <AuthLayoutWrapper>
            <div className={styles.authLayout}>
                <div className={styles.logo}>
                    <div>
                        <Link
                            to={SIGN_IN}
                            className={styles.logoImage}
                        >
                            <img src="/nonpublic-logo.svg" alt="NonPublic"/>
                        </Link>
                        <div className={styles.tagLine}>
                            Private markets, unlocked.
                        </div>
                        <div className={styles.description}>
                            The exclusive platform for curated deal flow, syndication mechanics and liquidity.
                        </div>
                        <div className={styles.learnMore}>
                            {matchPath({path: HELP_CENTER}, location.pathname) !== null ? (
                                <Button
                                    label="Go to sign in"
                                    type="button"
                                    size={ButtonSize.MEDIUM}
                                    state={ButtonState.ACTIVE}
                                    style={ButtonStyle.SECONDARY}
                                    withArrow={true}
                                    onClick={() => navigate(SIGN_IN)}
                                />
                            ) : (
                                <Button
                                    label="Learn more"
                                    type="button"
                                    size={ButtonSize.MEDIUM}
                                    state={ButtonState.ACTIVE}
                                    style={ButtonStyle.SECONDARY}
                                    icon={(
                                        <InfoCircleIcon
                                            width={24}
                                            height={24}
                                            fillClass="fill-icon-white"
                                        />
                                    )}
                                    onClick={() => navigate(HELP_CENTER)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.contentInner}>
                        <div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayoutWrapper>
    )
}

export default AuthLayout