/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestorDashboardPerformanceDataPointOpenApi
 */
export interface InvestorDashboardPerformanceDataPointOpenApi {
    /**
     * 
     * @type {boolean}
     * @memberof InvestorDashboardPerformanceDataPointOpenApi
     */
    artificial: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InvestorDashboardPerformanceDataPointOpenApi
     */
    date: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvestorDashboardPerformanceDataPointOpenApi
     */
    description: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof InvestorDashboardPerformanceDataPointOpenApi
     */
    value: number;
}

/**
 * Check if a given object implements the InvestorDashboardPerformanceDataPointOpenApi interface.
 */
export function instanceOfInvestorDashboardPerformanceDataPointOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "artificial" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function InvestorDashboardPerformanceDataPointOpenApiFromJSON(json: any): InvestorDashboardPerformanceDataPointOpenApi {
    return InvestorDashboardPerformanceDataPointOpenApiFromJSONTyped(json, false);
}

export function InvestorDashboardPerformanceDataPointOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorDashboardPerformanceDataPointOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artificial': json['artificial'],
        'date': (new Date(json['date'])),
        'description': json['description'],
        'value': json['value'],
    };
}

export function InvestorDashboardPerformanceDataPointOpenApiToJSON(value?: InvestorDashboardPerformanceDataPointOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artificial': value.artificial,
        'date': (value.date.toISOString().substr(0,10)),
        'description': value.description,
        'value': value.value,
    };
}

