/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SalutationOpenApi = {
    None: 'NONE',
    Mr: 'MR',
    Mrs: 'MRS',
    Ms: 'MS',
    Dr: 'DR',
    Prof: 'PROF'
} as const;
export type SalutationOpenApi = typeof SalutationOpenApi[keyof typeof SalutationOpenApi];


export function SalutationOpenApiFromJSON(json: any): SalutationOpenApi {
    return SalutationOpenApiFromJSONTyped(json, false);
}

export function SalutationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalutationOpenApi {
    return json as SalutationOpenApi;
}

export function SalutationOpenApiToJSON(value?: SalutationOpenApi | null): any {
    return value as any;
}

