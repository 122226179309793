import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const CrossCircleIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
        >
            <path
                className={fillClass}
                d="M10.354 6.354L8.707 8l1.647 1.646A.5.5 0 0 1 10.5 10a.5.5 0 0 1-.146.354.5.5 0 0 1-.354.146.5.5 0 0 1-.354-.146L8 8.707l-1.646 1.647A.5.5 0 0 1 6 10.5a.5.5 0 0 1-.354-.146A.5.5 0 0 1 5.5 10a.5.5 0 0 1 .147-.354L7.293 8 5.646 6.354a.5.5 0 0 1 0-.707.5.5 0 0 1 .707 0L8 7.293l1.646-1.647A.5.5 0 0 1 10 5.5a.5.5 0 0 1 .354.147A.5.5 0 0 1 10.5 6a.5.5 0 0 1-.146.354zM14.5 8a6.5 6.5 0 0 1-4.013 6.005 6.5 6.5 0 0 1-7.084-1.409 6.5 6.5 0 0 1-1.409-7.084A6.5 6.5 0 0 1 8 1.5c1.723.002 3.376.687 4.594 1.906S14.498 6.277 14.5 8zm-1 0a5.5 5.5 0 0 0-3.395-5.081 5.5 5.5 0 0 0-5.994 1.192 5.5 5.5 0 0 0-1.192 5.994A5.5 5.5 0 0 0 8 13.5c1.458-.002 2.856-.582 3.887-1.613S13.498 9.458 13.5 8z"
            />
        </svg>
    )
}

export default CrossCircleIcon