import React, {FunctionComponent} from "react"
import styles from "./person-icon.module.sass"
import ProfileIcon from "../../../icon/profile.component"

const MenuPersonIcon: FunctionComponent = () => {
    return (
        <div className={styles.icon}>
            <ProfileIcon
                width={18}
                height={18}
                strokeColor="#33005B"
            />
        </div>
    )
}

export default MenuPersonIcon