import React, {FunctionComponent} from "react"
import styles from "./about.module.sass"

type DealAboutProps = {
    description: string
}

const DealAbout: FunctionComponent<DealAboutProps> = ({ description }) => {
    return (
        <div className={styles.about}>
            <h4>About Company</h4>
            {description}
        </div>
    )
}

export default DealAbout