import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const ArrowRightIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path
                className={fillClass}
                d="M20.781 12.531l-6.75 6.75a.75.75 0 0 1-1.061 0 .75.75 0 0 1 0-1.061l5.47-5.469H3.75A.75.75 0 0 1 3 12a.75.75 0 0 1 .75-.75h14.69l-5.47-5.469a.75.75 0 0 1 0-1.061.75.75 0 0 1 1.061 0l6.75 6.75c.07.07.125.152.163.243a.75.75 0 0 1 0 .574.75.75 0 0 1-.163.243z"
            />
        </svg>
    )
}

export default ArrowRightIcon