import React, {FunctionComponent} from "react"
import ImagesIcon from "../../../../../tech/icon/images.component"
import TakePictureFunction from "./take-picture/function.component"
import UploadIcon from "../../../../../tech/icon/upload.component"
import FileUploadInput from "./input/input.component"
import styles from "./upload.module.sass"

type FileUploadMobileUploadProps = {
    close: () => void
    uploadFile: (file: File) => void
}

const FileUploadMobileUpload: FunctionComponent<FileUploadMobileUploadProps> = ({ close, uploadFile }) => {
    return (
        <>
            <div className={styles.functions}>
                <TakePictureFunction
                    close={close}
                    uploadFile={uploadFile}
                />
                <FileUploadInput
                    label="Choose picture from Gallery"
                    icon={
                        <ImagesIcon
                            width={24}
                            height={24}
                            fillClass="fill-icon-theme"
                        />
                    }
                    type="IMAGE"
                    uploadFile={uploadFile}
                />
                <FileUploadInput
                    label="Upload file"
                    icon={
                        <UploadIcon
                            width={24}
                            height={24}
                            fillClass="fill-icon-theme"
                        />
                    }
                    type="FILE"
                    uploadFile={uploadFile}
                />
            </div>
            <div
                className={styles.overlay}
                onClick={close}
            />
        </>
    )
}

export default FileUploadMobileUpload