import React, {FunctionComponent, ReactNode, useRef} from "react"
import ExclamationMarkIcon from "../../../../tech/icon/exclamation-mark.component"
import styles from "./info.module.sass"
import Tooltip from "../../../../tech/layout/tooltip/tooltip.component"

type DashboardInfoProps = {
    text: string
    noBackgroundMobile: boolean
    tooltip: {
        content: ReactNode
        halveMaximumWidth?: boolean
    }
}

const DashboardInfo: FunctionComponent<DashboardInfoProps> = ({
    text,
    noBackgroundMobile,
    tooltip
}) => {
    const tooltipHandlerRef = useRef<HTMLDivElement>(null)

    return (
        <div
            className={`${styles.info} ${noBackgroundMobile ? styles.noBackgroundMobile : ""}`}
            ref={tooltipHandlerRef}
        >
            <Tooltip
                anchor={tooltipHandlerRef}
                showIcon={false}
                position={{
                    top: 18,
                    left: -41
                }}
                halveMaximumWidth={tooltip.halveMaximumWidth}
            >
                {tooltip.content}
            </Tooltip>
            <div className={styles.icon}>
                <div>
                    <ExclamationMarkIcon
                        width={24}
                        height={24}
                        fillClass="fill-icon-inactive"
                    />
                </div>
            </div>
            <div className={styles.text}>
                <div>
                    {text}
                </div>
            </div>
        </div>
    )
}

export default DashboardInfo