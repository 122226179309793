import {FunctionComponent} from "react"
import styles from "./footer.module.sass"
import {Link} from "react-router-dom"
import {HELP, PRIVACY_STATEMENT, TERMS_AND_CONDITIONS} from "../../paths"

const Footer: FunctionComponent = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <Link to={HELP}>
                    Contact
                </Link>
                <Link to={TERMS_AND_CONDITIONS}>
                    Terms of Use
                </Link>
                <Link to={PRIVACY_STATEMENT}>
                    Privacy Policy
                </Link>
            </div>
        </footer>
    )
}

export default Footer