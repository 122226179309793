import React, {FunctionComponent} from "react"
import commonStyles from "../common.module.sass"

const PendingInvestmentCardWaitForManualPaymentInstructionsTrigger: FunctionComponent = () => {
    return (
        <>
            <div className={commonStyles.title}>
                Minimum amount reached
            </div>
            <div className={commonStyles.paragraph}>
                The minimum amount for this deal has been reached. We are currently preparing the next steps and will shortly send you the payment instructions. They will also be shown to you here.
            </div>
        </>
    )
}

export default PendingInvestmentCardWaitForManualPaymentInstructionsTrigger