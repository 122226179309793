import React, {FunctionComponent} from "react"
import InvestorDashboardInvestmentMobile from "./investment/investment.component"
import {DashboardType} from "../../dashboard.type"
import styles from "./mobile.module.sass"
import {InvestorOnboardingType} from "../../../onboarding/onboarding.type"
import InvestorDashboardSummaryOverview from "../../summary/overview/overview.component"
import {IndividualInvestmentPopupStateType} from "../../../../investment/individual/popup/popup.type"

type InvestorDashboardInvestmentsMobileProps = {
    dashboard: DashboardType
    onboarding: InvestorOnboardingType
    updateIndividualInvestmentPopup: (state: IndividualInvestmentPopupStateType) => void
}

const InvestorDashboardInvestmentsMobile: FunctionComponent<InvestorDashboardInvestmentsMobileProps> = ({
    dashboard,
    onboarding,
    updateIndividualInvestmentPopup
}) => {
    return (
        <div className={styles.mobile}>
            <div className={styles.summary}>
                <InvestorDashboardSummaryOverview
                    dashboard={dashboard}
                    onboarding={onboarding}
                    withBox
                    withIrrAndMoic={false}
                    withTitle
                />
            </div>
            {dashboard.investments.length > 0 ? (
                <>
                    {dashboard.investments.map(investment => (
                        <InvestorDashboardInvestmentMobile
                            investment={investment}
                            targetCurrency={dashboard.currency}
                            key={investment.investment.investment.value.id}
                            onboarding={onboarding}
                            updateIndividualInvestmentPopup={updateIndividualInvestmentPopup}
                        />
                    ))}
                </>
            ) : (
                <>No investments found.</>
            )}
        </div>
    )
}

export default InvestorDashboardInvestmentsMobile