import {
    DealIncompleteOnboardingOpenApi,
    DealOnboardingDependentOpenApi,
    DealOnboardingDependentWithInvestmentOpenApi
} from "../../generated"
import {mapOpenApiToDeal} from "./deal.mapper"
import {
    DealIncompleteOnboardingType,
    DealOnboardingDependentType,
    DealOnboardingDependentWithInvestmentType
} from "./onboarding-dependent.type"
import {mapOpenApiToCompany} from "../company/company.mapper"
import {mapOpenApiToDealCategory} from "../deal-category/deal-category.mapper"
import {mapOpenApiToDealGeography} from "../deal-geography/deal-geography.mapper"
import {mapOpenApiToImage} from "../../tech/image/image.mapper"
import {mapOpenApiToDealGroup} from "../deal-group/deal-group.mapper"
import {mapOpenApiToInvestmentWithPaymentDetails} from "../investment/investment-with-payment-details.mapper"

export function mapOpenApiToDealOnboardingDependentWithInvestment(dealWithInvestment: DealOnboardingDependentWithInvestmentOpenApi): DealOnboardingDependentWithInvestmentType {
    return {
        deal: mapOpenApiToDealOnboardingDependent(dealWithInvestment.deal),
        investmentWithPaymentDetails: dealWithInvestment.investmentWithPaymentDetails
            && mapOpenApiToInvestmentWithPaymentDetails(dealWithInvestment.investmentWithPaymentDetails)
    }
}

export function mapOpenApiToDealOnboardingDependent(deal: DealOnboardingDependentOpenApi): DealOnboardingDependentType {
    return "investedAmount" in deal
        ? mapOpenApiToDeal(deal)
        : mapOpenApiToDealOnboardingDependentIncompleteOnboarding(deal)
}

function mapOpenApiToDealOnboardingDependentIncompleteOnboarding(deal: DealIncompleteOnboardingOpenApi): DealIncompleteOnboardingType {
    return new DealIncompleteOnboardingType({
        id: deal.id,
        nameIncompleteOnboarding: deal.nameIncompleteOnboarding,
        descriptionIncompleteOnboarding: deal.descriptionIncompleteOnboarding,
        company: deal.company && mapOpenApiToCompany(deal.company),
        categories: deal.categories.map(c => mapOpenApiToDealCategory(c)),
        geography: deal.geography && mapOpenApiToDealGeography(deal.geography),
        image: deal.image && mapOpenApiToImage(deal.image),
        stage: deal.stage,
        outcome: deal.outcome,
        waitingState: deal.waitingState,
        industry: deal.industry,
        finalDeadline: deal.finalDeadline,
        exclusive: deal.exclusive,
        dealGroups: deal.dealGroups ? deal.dealGroups.map(dealGroup => mapOpenApiToDealGroup(dealGroup)) : [],
        investorGroupIds: deal.investorGroups
            ? deal.investorGroups.map(ig => ig.id!)
            : [],
        exited: deal.exited,
        exitType: deal.exitType,
        published: deal.published,
        imported: deal.imported,
        favourite: deal.favourite,
        objectType: "DealIncompleteOnboarding"
    })
}