import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const DownloadIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M15 8.5v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h2.5A.5.5 0 0 1 5 8a.5.5 0 0 1-.5.5H2v4h12v-4h-2.5A.5.5 0 0 1 11 8a.5.5 0 0 1 .5-.5H14a1 1 0 0 1 1 1zm-7.354-.146A.5.5 0 0 0 8 8.5a.5.5 0 0 0 .354-.147l3-3a.5.5 0 0 0 0-.707.5.5 0 0 0-.708 0L8.5 6.793V1.5A.5.5 0 0 0 8 1a.5.5 0 0 0-.5.5v5.293L5.354 4.646a.5.5 0 0 0-.707 0 .5.5 0 0 0 0 .707l3 3zM12.5 10.5a.75.75 0 0 0-.463-.693.75.75 0 0 0-.817.163.75.75 0 0 0-.163.817.75.75 0 0 0 .693.463.75.75 0 0 0 .75-.75z"
            />
        </svg>
    )
}

export default DownloadIcon