import React, {FunctionComponent} from "react"

type PropsIconProps  = {
    width: number,
    height: number,
    strokeColor: string
}

const ProfileIcon: FunctionComponent<PropsIconProps> = ({
    width,
    height,
    strokeColor
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g
                clipPath="url(#A)"
                stroke={strokeColor}
                strokeWidth="2"
                className="person-stroke"
            >
                <path
                    d="M4 18a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4h0a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2h0z"
                    strokeLinejoin="round"
                />
                <circle cx="12" cy="7" r="3"/>
            </g>
            <defs>
                <clipPath id="A">
                    <path
                        fill="#fff"
                        d="M0 0h24v24H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ProfileIcon