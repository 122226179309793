import React, {FunctionComponent} from "react"
import {InvestorOnboardingType} from "../../../onboarding/onboarding.type"
import Timeline from "../../../../../tech/layout/timeline/timeline.component"
import {TimelineItemType} from "../../../../../tech/layout/timeline/item/item.type"
import {ProfileOnboardingChecklistItemType} from "./checklist.type"
import {evaluateAiStatusDeclarationStatus, evaluateKycStatus, evaluatePersonalDataStatus} from "./checklist.util"
import styles from "./checklist.module.sass"

type ProfileOnboardingChecklistProps = {
    onboarding: InvestorOnboardingType
}

const ProfileOnboardingChecklist: FunctionComponent<ProfileOnboardingChecklistProps> = ({ onboarding }) => {
    return (
        <div className={styles.checklist}>
            <Timeline items={assembleItems(onboarding)}/>
        </div>
    )
}

export default ProfileOnboardingChecklist

function assembleItems(onboarding: InvestorOnboardingType): TimelineItemType<ProfileOnboardingChecklistItemType>[] {
    return [
        {
            text: "AI Status Verification",
            type: "AI_STATUS_VERIFICATION",
            status: evaluateAiStatusDeclarationStatus(onboarding)
        },
        {
            text: "Personal Data",
            type: "GENERAL_DATA",
            status: evaluatePersonalDataStatus(onboarding)
        },
        {
            text: "KYC",
            type: "KYC",
            status: evaluateKycStatus(onboarding)
        },
    ]
}