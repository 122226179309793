import React, {FunctionComponent} from "react"
import DealSortOption from "./option/option.component"
import {DealSortType} from "../sort.type"
import styles from "./options.module.sass"

type DealSortOptionsProps = {
    currentSortType?: DealSortType
    updateSortType: (updatedSortType: DealSortType) => void
    opened: boolean
    close: () => void
}

const DealSortOptions: FunctionComponent<DealSortOptionsProps> = ({
    currentSortType,
    updateSortType,
    opened,
    close
}) => {
    return (
        <>
            <div
                className={`${styles.options} ${opened && styles.opened}`}
                onClick={close}
                onTouchMove={close}
            >
                <div className={styles.head}>
                    Sort by
                </div>
                <div className={styles.list}>
                    <DealSortOption
                        sortType="CATEGORY"
                        currentSortType={currentSortType}
                        updateSortType={updateSortType}
                    />
                    <DealSortOption
                        sortType="GEOGRAPHY"
                        currentSortType={currentSortType}
                        updateSortType={updateSortType}
                    />
                    <DealSortOption
                        sortType="FINAL_DEADLINE"
                        currentSortType={currentSortType}
                        updateSortType={updateSortType}
                    />
                    <DealSortOption
                        sortType="NAME"
                        currentSortType={currentSortType}
                        updateSortType={updateSortType}
                    />
                    <DealSortOption
                        sortType="STAGE"
                        currentSortType={currentSortType}
                        updateSortType={updateSortType}
                    />
                </div>
            </div>
            {opened && (
                <div
                    className={styles.overlay}
                    onClick={close}
                />
            )}
        </>
    )
}

export default DealSortOptions