import React, {FunctionComponent} from "react"
import {TimelineItemStatusType, TimelineItemType} from "./item.type"
import styles from "./item.module.sass"

type TimelineItemProps<T> = {
    item: TimelineItemType<T>
}

const TimelineItem: FunctionComponent<TimelineItemProps<any>> = ({ item }) => {
    return (
        <div className={`${styles.item} ${evaluateClass(item.status)}`}>
            <div>
                <div className={styles.circle}/>
            </div>
            <div>
                <div className={styles.text}>
                    {item.text}
                </div>
            </div>
        </div>
    )
}

export default TimelineItem

function evaluateClass(status: TimelineItemStatusType): string {
    switch (status) {
        case "ACTIVE":
            return styles.active
        case "INACTIVE":
            return styles.inactive
        case "DONE":
            return styles.done
    }
}