/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubscriptionPaymentIntervalOpenApi } from './SubscriptionPaymentIntervalOpenApi';
import {
    SubscriptionPaymentIntervalOpenApiFromJSON,
    SubscriptionPaymentIntervalOpenApiFromJSONTyped,
    SubscriptionPaymentIntervalOpenApiToJSON,
} from './SubscriptionPaymentIntervalOpenApi';
import type { SubscriptionTypeOpenApi } from './SubscriptionTypeOpenApi';
import {
    SubscriptionTypeOpenApiFromJSON,
    SubscriptionTypeOpenApiFromJSONTyped,
    SubscriptionTypeOpenApiToJSON,
} from './SubscriptionTypeOpenApi';

/**
 * 
 * @export
 * @interface SubscribeRequestOpenApi
 */
export interface SubscribeRequestOpenApi {
    /**
     * 
     * @type {SubscriptionPaymentIntervalOpenApi}
     * @memberof SubscribeRequestOpenApi
     */
    paymentInterval: SubscriptionPaymentIntervalOpenApi;
    /**
     * 
     * @type {number}
     * @memberof SubscribeRequestOpenApi
     */
    promotedPrice: number;
    /**
     * 
     * @type {string}
     * @memberof SubscribeRequestOpenApi
     */
    stripePaymentMethodId: string;
    /**
     * 
     * @type {SubscriptionTypeOpenApi}
     * @memberof SubscribeRequestOpenApi
     */
    type: SubscriptionTypeOpenApi;
}

/**
 * Check if a given object implements the SubscribeRequestOpenApi interface.
 */
export function instanceOfSubscribeRequestOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentInterval" in value;
    isInstance = isInstance && "promotedPrice" in value;
    isInstance = isInstance && "stripePaymentMethodId" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function SubscribeRequestOpenApiFromJSON(json: any): SubscribeRequestOpenApi {
    return SubscribeRequestOpenApiFromJSONTyped(json, false);
}

export function SubscribeRequestOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscribeRequestOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentInterval': SubscriptionPaymentIntervalOpenApiFromJSON(json['paymentInterval']),
        'promotedPrice': json['promotedPrice'],
        'stripePaymentMethodId': json['stripePaymentMethodId'],
        'type': SubscriptionTypeOpenApiFromJSON(json['type']),
    };
}

export function SubscribeRequestOpenApiToJSON(value?: SubscribeRequestOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentInterval': SubscriptionPaymentIntervalOpenApiToJSON(value.paymentInterval),
        'promotedPrice': value.promotedPrice,
        'stripePaymentMethodId': value.stripePaymentMethodId,
        'type': SubscriptionTypeOpenApiToJSON(value.type),
    };
}

