/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { DealOpenApi } from './DealOpenApi';
import {
    DealOpenApiFromJSON,
    DealOpenApiFromJSONTyped,
    DealOpenApiToJSON,
} from './DealOpenApi';
import type { InvestorDashboardInvestmentOpenApi } from './InvestorDashboardInvestmentOpenApi';
import {
    InvestorDashboardInvestmentOpenApiFromJSON,
    InvestorDashboardInvestmentOpenApiFromJSONTyped,
    InvestorDashboardInvestmentOpenApiToJSON,
} from './InvestorDashboardInvestmentOpenApi';
import type { InvestorDashboardSummaryOpenApi } from './InvestorDashboardSummaryOpenApi';
import {
    InvestorDashboardSummaryOpenApiFromJSON,
    InvestorDashboardSummaryOpenApiFromJSONTyped,
    InvestorDashboardSummaryOpenApiToJSON,
} from './InvestorDashboardSummaryOpenApi';

/**
 * 
 * @export
 * @interface InvestorDashboardOpenApi
 */
export interface InvestorDashboardOpenApi {
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof InvestorDashboardOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {Array<InvestorDashboardInvestmentOpenApi>}
     * @memberof InvestorDashboardOpenApi
     */
    investments: Array<InvestorDashboardInvestmentOpenApi>;
    /**
     * 
     * @type {InvestorDashboardSummaryOpenApi}
     * @memberof InvestorDashboardOpenApi
     */
    summary: InvestorDashboardSummaryOpenApi;
    /**
     * 
     * @type {Array<DealOpenApi>}
     * @memberof InvestorDashboardOpenApi
     */
    unfilteredDeals: Array<DealOpenApi>;
    /**
     * 
     * @type {number}
     * @memberof InvestorDashboardOpenApi
     */
    unfilteredInvestmentsCount: number;
}

/**
 * Check if a given object implements the InvestorDashboardOpenApi interface.
 */
export function instanceOfInvestorDashboardOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "investments" in value;
    isInstance = isInstance && "summary" in value;
    isInstance = isInstance && "unfilteredDeals" in value;
    isInstance = isInstance && "unfilteredInvestmentsCount" in value;

    return isInstance;
}

export function InvestorDashboardOpenApiFromJSON(json: any): InvestorDashboardOpenApi {
    return InvestorDashboardOpenApiFromJSONTyped(json, false);
}

export function InvestorDashboardOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorDashboardOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'investments': ((json['investments'] as Array<any>).map(InvestorDashboardInvestmentOpenApiFromJSON)),
        'summary': InvestorDashboardSummaryOpenApiFromJSON(json['summary']),
        'unfilteredDeals': ((json['unfilteredDeals'] as Array<any>).map(DealOpenApiFromJSON)),
        'unfilteredInvestmentsCount': json['unfilteredInvestmentsCount'],
    };
}

export function InvestorDashboardOpenApiToJSON(value?: InvestorDashboardOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': CurrencyOpenApiToJSON(value.currency),
        'investments': ((value.investments as Array<any>).map(InvestorDashboardInvestmentOpenApiToJSON)),
        'summary': InvestorDashboardSummaryOpenApiToJSON(value.summary),
        'unfilteredDeals': ((value.unfilteredDeals as Array<any>).map(DealOpenApiToJSON)),
        'unfilteredInvestmentsCount': value.unfilteredInvestmentsCount,
    };
}

