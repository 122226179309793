import {FileType} from "./file.type"
import {FFileOpenApi} from "../../generated"

export function mapOpenApiToFile(file: FFileOpenApi): FileType {
    return {
        id: file.id,
        created: file.created,
        modified: file.modified,
        path: file.path,
        type: file.type,
        mimeType: file.mimeType
    }
}

export function mapFileToOpenApi(file: FileType): FFileOpenApi {
    return {
        id: file.id,
        created: file.created,
        modified: file.modified,
        path: file.path,
        type: file.type,
        mimeType: file.mimeType
    }
}