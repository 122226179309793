import {DashboardType} from "../dashboard.type"
import React, {FunctionComponent} from "react"
import InvestorDashboardInvestmentsMobile from "./mobile/mobile.component"
import InvestorDashboardInvestmentsDesktop from "./desktop/desktop.component"
import InvestorDashboardTile from "../tile/tile.component"
import {InvestorOnboardingType} from "../../onboarding/onboarding.type"
import {FilterTypeUpdateSource} from "../dashboard.component"
import {FilterProps} from "../../../../tech/filter/filter.component"
import {FilterType} from "../../../../tech/filter/filter.type"
import {IndividualInvestmentPopupStateType} from "../../../investment/individual/popup/popup.type"

type InvestorDashboardInvestmentsProps = {
    dashboard: DashboardType
    filter: FilterProps
    onboarding: InvestorOnboardingType
    updateIndividualInvestmentPopup: (state: IndividualInvestmentPopupStateType) => void
    updateFilterTypesAndSource: (updatedFilterTypes: FilterType[], source: FilterTypeUpdateSource) => void
}

const InvestorDashboardInvestments: FunctionComponent<InvestorDashboardInvestmentsProps> = ({
    dashboard,
    filter,
    onboarding,
    updateIndividualInvestmentPopup,
    updateFilterTypesAndSource
}) => {
    return (
        <InvestorDashboardTile
            title="Portfolio"
            noLayoutMobile
            hideTitleDesktop
        >
            <>
                <InvestorDashboardInvestmentsMobile
                    dashboard={dashboard}
                    onboarding={onboarding}
                    updateIndividualInvestmentPopup={updateIndividualInvestmentPopup}
                />
                <InvestorDashboardInvestmentsDesktop
                    dashboard={dashboard}
                    filter={filter}
                    onboarding={onboarding}
                    updateIndividualInvestmentPopup={updateIndividualInvestmentPopup}
                    updateFilterTypesAndSource={updateFilterTypesAndSource}
                />
            </>
        </InvestorDashboardTile>
    )
}

export default InvestorDashboardInvestments