import React, {FunctionComponent} from "react"
import styles from "./radio.module.sass"

type RadioIconProps = {
    size: number
    active: boolean
}

const RadioIcon: FunctionComponent<RadioIconProps> = ({
    size,
    active
}) => {
    return (
        <div
            className={`${styles.radio} ${active ? styles.active : ""}`}
            style={{
                width: size + "px",
                height: size + "px"
            }}
        />
    )
}

export default RadioIcon