import {QueryFunction, QueryKey as QueryKeyOriginal} from "react-query/types/core/types"

export const QUERY_KEY_PART__LIST = "list"
export const QUERY_KEY_PART__SINGLE = "single"

export type QueryKey = any[]

export type QueryKeyFunction<PARAMETERS> = (parameters: PARAMETERS) => QueryKey

export type QueryKeyWithParameters<PARAMETERS> = {
    keyFunction: QueryKeyFunction<PARAMETERS>
    parameters: PARAMETERS
}

export type QueryType<T> = {
    queryKey: QueryKeyOriginal[]
    queryFn: QueryFunction<T, QueryKeyOriginal[]>
    enabled: boolean
    refetchInterval?: number | false
    onSuccess?: (data: T) => void
}