import React, {createRef, FunctionComponent, useState} from "react"
import CheckIcon from "../../../../../../tech/icon/check.component"
import CopyIcon from "../../../../../../tech/icon/copy.component"
import styles from "./copy.module.sass"

type PendingInvestmentCardPaymentCopyProps = {
    text: string
    value: number | string
}

const PendingInvestmentCardPaymentCopy: FunctionComponent<PendingInvestmentCardPaymentCopyProps> = ({ text, value }) => {
    const inputRef = createRef<HTMLInputElement>()
    const [copied, setCopied] = useState<boolean>(false)

    const doCopy = async () => {
        inputRef.current!.select()
        inputRef.current!.setSelectionRange(0, 99999) // For mobile devices

        await navigator.clipboard.writeText(inputRef.current!.value)

        setCopied(true)
    }

    return (
        <div className={styles.copy}>
            <div>
                {text}
                <input
                    type="text"
                    value={value}
                    ref={inputRef}
                    disabled={true}
                />
            </div>
            <div
                className={styles.icon}
                onClick={doCopy}
            >
                {copied ? (
                    <CheckIcon
                        width={24}
                        height={24}
                        fillClass="fill-icon-success"
                    />
                ) : (
                    <CopyIcon
                        width={24}
                        height={24}
                        fillClass="fill-icon-theme"
                    />
                )}
            </div>
        </div>
    )
}

export default PendingInvestmentCardPaymentCopy