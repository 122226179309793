import React, {FunctionComponent} from "react"
import {DotType} from "./dot.type"
import styles from "./dot.module.sass"

type DotProps = {
    type: DotType
}

const Dot: FunctionComponent<DotProps> = ({ type }) => {
    return (
        <div className={`${styles.dot} ${evaluateClass(type)}`}>
            <div/>
        </div>
    )
}

export default Dot

function evaluateClass(type: DotType): string {
    switch (type) {
        case "ACTIVE": return styles.active
        case "INACTIVE": return styles.inactive
        case "NEUTRAL": return styles.neutral
    }
}