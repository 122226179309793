import React, {FunctionComponent, useState} from "react"
import {IndividualInvestmentType} from "../individual-investment.type"
import Head from "../../../../tech/head/head.component"
import {SubmitHandler, useForm} from "react-hook-form"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import SubmitButton from "../../../../tech/form/submit/submit.component"
import FormRow from "../../../../tech/form/row/row.component"
import TextField from "../../../../tech/form/fields/text/text.component"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import IndividualInvestmentFormError from "./error.component"
import {
    IndividualInvestmentSaveResponse,
    IndividualInvestmentSaveResponseType,
    IndividualInvestmentSaveResponseValidationErrors
} from "../response.type"
import IndividualInvestmentFormValuations from "./valuations/valuations.component"
import SelectField from "../../../../tech/form/fields/select/select.component"
import {CurrencyOpenApi} from "../../../../generated"
import Checkbox from "../../../../tech/form/fields/checkbox/checkbox.component"
import styles from "./form.module.sass"
import {ButtonState} from "../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import Button from "../../../../tech/button/button.component"
import {ButtonSize} from "../../../../tech/button/size.enum"
import {IndividualInvestmentPopupStateType} from "../popup/popup.type"
import {useApp} from "../../../../tech/app/context/app.context"

type State = "LOADING" | "NONE" | "ERROR" | "SUCCESS"

type IndividualInvestmentFormProps = {
    investment?: IndividualInvestmentType
    invalidateDashboardQuery: () => void
    updatePopupState: (newState: IndividualInvestmentPopupStateType) => void
}

const IndividualInvestmentForm: FunctionComponent<IndividualInvestmentFormProps> = ({
    investment,
    invalidateDashboardQuery,
    updatePopupState
}) => {
    const app = useApp()
    const [state, setState] = useState<State>("NONE")
    const [validationErrors, setValidationErrors] = useState<string[]>([])

    const {
        control,
        register,
        handleSubmit,
        formState: {
            errors,
            isDirty,
            isValid
        }
    } = useForm<IndividualInvestmentType>({
        defaultValues: investment
            ? investment
            : { valuations: [{}] },
    })

    const onSubmit: SubmitHandler<IndividualInvestmentType> = async (investmentToSave) => {
        setState("LOADING")
        let response: IndividualInvestmentSaveResponse
        if (investment?.id || state === "SUCCESS") {
            response = await app.fetchClient.individualInvestmentApi.update(investment!.id!, investmentToSave)
        }
        else {
            response = await app.fetchClient.individualInvestmentApi.create(investmentToSave)
            if (response.type === "SUCCESS") {
                updatePopupState({
                    type: "EDIT",
                    investment: response.investment
                })
            }
        }
        setState(mapResponseType(response.type))
        if (response.type === "SUCCESS") invalidateDashboardQuery()
        if (response.type === "VALIDATION_ERROR") setValidationErrors((response as IndividualInvestmentSaveResponseValidationErrors).errors)
    }

    return (
        <div>
            <Head title={investment ? "Edit individual investment" : "Add individual investment"}/>
            {state === "ERROR" && (
                <div className={styles.alert}>
                    <IndividualInvestmentFormError validationErrors={validationErrors}/>
                </div>
            )}
            {state === "SUCCESS" && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.SUCCESS}
                        text="Saving successful."
                    />
                </div>
            )}
            {state === "LOADING" ? (
                <LoadingDots/>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormRow content={{
                        type: "one",
                        element: (
                            <TextField
                                placeholder="Name"
                                errors={errors}
                                reactHookFormRegister={{
                                    name: "name",
                                    register,
                                    options: {
                                        required: "Name is required.",
                                    }
                                }}
                            />
                        )
                    }}/>
                    <FormRow content={{
                        type: "one",
                        element: (
                            <SelectField
                                label="Currency"
                                prefillValue={CurrencyOpenApi.Usd}
                                options={Object.entries(CurrencyOpenApi).map(c => {
                                    return {
                                        label: c[1],
                                        value: c[1]
                                    }
                                })}
                                errors={errors}
                                reactHookFormRegister={{
                                    name: "currency",
                                    register
                                }}
                            />
                        )
                    }}/>
                    <FormRow content={{
                        type: "one",
                        element: (
                            <Checkbox
                                label="Exited?"
                                description="We will use the latest valuation for the exit value."
                                errors={errors}
                                id="individualInvestmentExited"
                                reactHookFormRegister={{
                                    name: "exited",
                                    register
                                }}
                            />
                        )
                    }}/>
                    <IndividualInvestmentFormValuations
                        control={control}
                        errors={errors}
                        register={register}
                    />
                    <div className={`${styles.buttons} ${investment ? styles.withDeleteButton : ""}`}>
                        {investment && (
                            <div className={styles.delete}>
                                <Button
                                    label="Delete"
                                    type="button"
                                    size={ButtonSize.LARGE}
                                    state={ButtonState.ACTIVE}
                                    style={ButtonStyle.SECONDARY}
                                    fullWidth={true}
                                    onClick={() => updatePopupState({
                                        type: "DELETE",
                                        investment
                                    })}
                                />
                            </div>
                        )}
                        <SubmitButton
                            label="Save"
                            disabled={!isValid || !isDirty}
                        />
                    </div>
                </form>
            )}
        </div>
)
}

export default IndividualInvestmentForm

function mapResponseType(type: IndividualInvestmentSaveResponseType): "LOADING" | "NONE" | "ERROR" | "SUCCESS" {
    switch (type) {
        case "SUCCESS":
            return "SUCCESS"
        case "ERROR":
        case "VALIDATION_ERROR":
            return "ERROR"
    }
}