import React, {FunctionComponent, ReactNode} from "react"
import {formatPercent} from "../../../../../tech/format/percent.util"
import Triangle from "../triangle/triangle.component"
import styles from "./percentage.module.sass"
import Tooltip from "../../../../../tech/layout/tooltip/tooltip.component"

type PercentageProps = {
    value: number
    tooltipContent?: ReactNode
}

const Percentage: FunctionComponent<PercentageProps> = ({ value, tooltipContent }) => {
    if (value === 0) {
        return <></>
    }

    return (
        <div className={styles.percentage}>
            <div>
                {formatPercent(value, 1, 0)}
            </div>
            {tooltipContent && (
                <Tooltip
                    padding={{
                        x: 6,
                        y: 2
                    }}
                    position={{
                        top: 16,
                        left: -41,
                        shiftLeft: -220
                    }}
                >
                    {tooltipContent}
                </Tooltip>
            )}
            <div className={styles.indicator}>
                <Triangle
                    type="percentage"
                    value={value}
                />
            </div>
        </div>
    )
}

export default Percentage