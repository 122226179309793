import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import EmailField from "../../../tech/form/fields/email/email.component"
import Checkbox from "../../../tech/form/fields/checkbox/checkbox.component"
import ExclamationMark from "../../../tech/exclamation-mark/mark.component"
import ExclamationMarkInfo from "../../../tech/exclamation-mark/info.component"
import AccreditedInvestorStatusExplanation from "../../../tech/auth/sign-up/ai-explanation.component"
import SubmitButton from "../../../tech/form/submit/submit.component"
import {WaitlistRequestType} from "../waitlist.type"
import FormRow from "../../../tech/form/row/row.component"
import TextField from "../../../tech/form/fields/text/text.component"
import PhoneField from "../../../tech/form/fields/phone/phone.component"
import formStyles from "../../../tech/form/form.module.sass"
import styles from "./form.module.sass"

type WaitlistFormProps = {
    onSubmit: SubmitHandler<WaitlistRequestType>
}

const WaitlistForm: FunctionComponent<WaitlistFormProps> = ({ onSubmit }) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<WaitlistRequestType>()

    return (
        <form
            className={`${formStyles.form} ${styles.actualForm}`}
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormRow content={{
                type: "two",
                firstElement: (
                    <TextField
                        placeholder="First name"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "firstName",
                            register,
                            options: {
                                required: "First name is required.",
                                minLength: { value: 2, message: "First name must be at least 2 characters long." },
                            }
                        }}
                    />
                ),
                secondElement: (
                    <TextField
                        placeholder="Last Name"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "lastName",
                            register,
                            options: {
                                required: "Last name is required.",
                                minLength: { value: 2, message: "Last name must be at least 2 characters long." },
                            }
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <EmailField
                        placeholder="Your Email"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "contact.email",
                            register,
                            options: {
                                required: "Email is required."
                            }
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <PhoneField
                        placeholder="WhatsApp Number"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "contact.phone",
                            register
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <TextField
                        placeholder="LinkedIn Profile URL"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "contact.linkedIn",
                            register,
                            options: {
                                pattern: {
                                    value: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+(\/)?$/,
                                    message: "Please enter a valid LinkedIn profile URL."
                                }
                            }
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <TextField
                        placeholder="Referral Code"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "referralCode",
                            register,
                            options: {
                                pattern: {
                                    value: /^[a-zA-Z0-9]{12}$/,
                                    message: "Please enter a valid referral code consisting of twelve alphanumeric characters."
                                }
                            }
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <Checkbox
                        label="I confirm that I’m an Accredited Investor"
                        id="aiStatusDeclaration"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "aiStatusDeclared",
                            register,
                            options: {
                                required: "You're only allowed to join our waitlist if you are an Accredited Investor."
                            }
                        }}
                        exclamationMark={{
                            mark: <ExclamationMark modalId="aiDeclaration"/>,
                            info: (
                                <ExclamationMarkInfo
                                    modalId="aiDeclaration"
                                    title="Accredited Investor"
                                    content={<AccreditedInvestorStatusExplanation/>}
                                />
                            )
                        }}
                    />
                )
            }}/>
            <SubmitButton label="Join Waitlist"/>
        </form>
    )
}

export default WaitlistForm