import React, {FunctionComponent} from "react"
import {InvestmentWithPaymentDetailsType} from "../../../../investment-with-payment-details.type"
import Alert from "../../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../../tech/alert/type.enum"
import {CurrencyOpenApi} from "../../../../../../generated"
import {formatAmountWithCurrency} from "../../../../../../tech/format/amount.util"
import {InvestmentExchangePreviewType} from "../../../../exchange-rate/exchange-preview.type"
import {formatPercent} from "../../../../../../tech/format/percent.util"
import styles from "./table.module.sass"
import {InvestmentType} from "../../../../investment.type"

type Input = {
    type: "FROM_CURRENCY_SELECTION",
    investment: InvestmentType
    relevantPreview: InvestmentExchangePreviewType | undefined
    selectedCurrency: CurrencyOpenApi
} | {
    type: "INVESTMENT_WITH_PAYMENT_DETAILS"
    investmentWithPaymentDetails: InvestmentWithPaymentDetailsType
}

type PendingInvestmentCardPaymentTableProps = {
    input: Input
}

const PendingInvestmentCardPaymentTable: FunctionComponent<PendingInvestmentCardPaymentTableProps> = ({ input }) => {
    const investment = input.type === "FROM_CURRENCY_SELECTION"
        ? input.investment
        : input.investmentWithPaymentDetails.investment
    const hasDeviatingCurrency = input.type === "FROM_CURRENCY_SELECTION"
        ? investment.deal.currency !== input.selectedCurrency
        : investment.deal.currency !== input.investmentWithPaymentDetails.investment.currency
    const selectedCurrency = input.type === "FROM_CURRENCY_SELECTION"
        ? input.selectedCurrency
        : input.investmentWithPaymentDetails.investment.currency
    const amountAtExchangeRate = input.type === "FROM_CURRENCY_SELECTION"
        ? input.relevantPreview?.getAmountAtExchangeRateFormatted()
        : investment.exchangeRate && formatAmountWithCurrency(investment.amount * investment.exchangeRate.rate, investment.currency!)
    const exchangeFee = input.type === "FROM_CURRENCY_SELECTION"
        ? input.relevantPreview?.fee
        : input.investmentWithPaymentDetails.investment.fees.exchange
    return (
        <div className={styles.table}>
            <div className={styles.amountCommitted}>
                <div>
                    Amount Committed {investment.deal.currency}
                </div>
                <div>
                    {formatAmountWithCurrency(investment.amount, investment.deal._currency)}
                </div>
            </div>
            {hasDeviatingCurrency && amountAtExchangeRate && (
                <div className={styles.amount}>
                    <div>
                        Amount {selectedCurrency}
                    </div>
                    <div>
                        {amountAtExchangeRate}
                    </div>
                </div>
            )}
            <div className={styles.placementFee}>
                <div>
                    Setup Fee {formatPercent(investment.fees.placement.percent)}
                </div>
                <div>
                    {investment.fees.placement.getAmountFormatted(investment.deal._currency)}
                </div>
            </div>
            {hasDeviatingCurrency && exchangeFee && (
                <div className={styles.currencyTransferFee}>
                    <div>
                        Currency Transfer Fee {formatPercent(exchangeFee.percent)}
                    </div>
                    <div>
                        {formatAmountWithCurrency(exchangeFee.amount, investment.deal._currency)}
                    </div>
                </div>
            )}
            {hasDeviatingCurrency && (
                <div className={styles.notice}>
                    <Alert
                        type={AlertType.WARNING}
                        text="An additional 1% buffer is added to cover exchange rate."
                    />
                </div>
            )}
            <div className={styles.total}>
                <div>
                    Total Amount
                </div>
                <div>
                    {getTotalAmount(input)}
                </div>
            </div>
        </div>
    )
}

export default PendingInvestmentCardPaymentTable

function getTotalAmount(input: Input): string {
    if (input.type === "FROM_CURRENCY_SELECTION") {
        return input.relevantPreview
            ? input.relevantPreview.getAmountWithPlacementFeeAndExchangeFeeAtExchangeRateFormatted()
            : formatAmountWithCurrency(input.investment.amountWithPlacementFee, input.investment.deal._currency)
    }
    const investment = input.investmentWithPaymentDetails.investment
    return investment.currency !== investment.deal._currency
        ? investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRateFormatted!
        : investment.amountWithPlacementFeeFormatted
}