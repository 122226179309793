import React, {FunctionComponent} from "react"
import TagLine from "../../../../tech/tag-line/tag-line.component"
import BagIcon from "../../../../tech/icon/bag.component"
import styles from "./investors.module.sass"

type DealInvestorsProps = {
    investors: string[] | undefined
}

const DealInvestors: FunctionComponent<DealInvestorsProps> = ({ investors }) => {
    if (investors && investors.length > 0) {
        return (
            <div className={styles.investors}>
                <div>
                    <BagIcon
                        width={16}
                        height={16}
                        fillClass="fill-icon-theme"
                    />
                </div>
                <div>
                    <TagLine tags={investors.map(i => ({ name: i }))}/>
                </div>
            </div>
        )
    }
    return <div/>
}

export default DealInvestors