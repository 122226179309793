import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const PlusCircleIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
        >
            <path
                className={fillClass}
                d="M10 1.875c-1.607 0-3.178.477-4.514 1.369S3.108 5.406 2.493 6.891s-.776 3.118-.462 4.694 1.087 3.024 2.224 4.16 2.584 1.91 4.16 2.224 3.21.153 4.694-.462 2.754-1.656 3.646-2.992 1.369-2.907 1.369-4.514c-.002-2.154-.859-4.219-2.382-5.743S12.154 1.877 10 1.875zm0 15c-1.36 0-2.689-.403-3.82-1.159s-2.012-1.829-2.532-3.085-.656-2.639-.391-3.972.92-2.559 1.882-3.52 2.187-1.616 3.52-1.882 2.716-.129 3.972.391 2.33 1.402 3.085 2.532 1.159 2.46 1.159 3.82c-.002 1.823-.727 3.57-2.016 4.859S11.823 16.873 10 16.875zM13.75 10c0 .166-.066.325-.183.442s-.276.183-.442.183h-2.5v2.5c0 .166-.066.325-.183.442s-.276.183-.442.183-.325-.066-.442-.183-.183-.276-.183-.442v-2.5h-2.5c-.166 0-.325-.066-.442-.183S6.25 10.166 6.25 10s.066-.325.183-.442.276-.183.442-.183h2.5v-2.5c0-.166.066-.325.183-.442s.276-.183.442-.183.325.066.442.183.183.276.183.442v2.5h2.5c.166 0 .325.066.442.183s.183.276.183.442z"
            />
        </svg>
    )
}

export default PlusCircleIcon