import React, {FunctionComponent} from "react"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import {isPlatformDeal} from "../../deal.util"
import NonPublicPlatformDeal from "./platform-deal/platform-deal.component"
import {InvestorOnboardingType} from "../../../investor/onboarding/onboarding.type"
import DealCardSyndicateBadge from "./syndicate/syndicate.component"
import {getSyndicateOfDeal} from "../../../syndicate/syndicate.util"

type DealCardBadgeProps = {
    deal: DealOnboardingDependentType
    onboarding: InvestorOnboardingType
    pendingInvestment: boolean
}

const DealCardBadge: FunctionComponent<DealCardBadgeProps> = ({
    deal,
    onboarding,
    pendingInvestment
}) => {
    const syndicate = getSyndicateOfDeal(deal, onboarding)
    if (syndicate) {
        return <DealCardSyndicateBadge syndicate={syndicate}/>
    }
    else if (!pendingInvestment && isPlatformDeal(deal)) {
        return <NonPublicPlatformDeal/>
    }
    return <></>
}

export default DealCardBadge