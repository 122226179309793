import React, {FunctionComponent} from "react"
import Timeline from "../../../tech/layout/timeline/timeline.component"
import styles from "./timeline.module.sass"
import {TimelineItemType} from "../../../tech/layout/timeline/item/item.type"
import {InvestmentTimelineItemType} from "./timeline.type"

type InvestmentTimelineProps = {
    items: TimelineItemType<InvestmentTimelineItemType>[]
}

const InvestmentTimeline: FunctionComponent<InvestmentTimelineProps> = ({ items }) => {
    return (
        <>
            <div className={styles.title}>
                Investment Stage
            </div>
            <div className={styles.timeline}>
                <Timeline items={items}/>
            </div>
        </>
    )
}

export default InvestmentTimeline