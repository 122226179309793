import React, {FunctionComponent, ReactNode} from "react"
import styles from "./head.module.sass"

type HeadProps = {
    title: string
    description?: ReactNode
}

const Head: FunctionComponent<HeadProps> = ({ title, description }) => {
    return (
        <>
            <div className={styles.head}>
                {title}
            </div>
            {description && (
                <div className={styles.description}>
                    {description}
                </div>
            )}
        </>
    )
}


export default Head