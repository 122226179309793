import React, {createRef, FormEvent, FunctionComponent, useState} from "react"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import {useParams} from "react-router-dom"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import PaperPlaneIcon from "../../../../tech/icon/paper-plane.component"
import DealEvent from "../event/event.component"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import styles from "./qa.module.sass"
import {DealType} from "../../deal.type"
import {useApp} from "../../../../tech/app/context/app.context"

type DealQaProps = {
    deal: DealOnboardingDependentType
}

const DealQa: FunctionComponent<DealQaProps> = ({ deal }) => {
    let { id } = useParams()
    const app = useApp()
    const [state, setState] = useState<"NONE" | "LOADING" | "SUCCESS" | "ERROR">("NONE")
    const questionRef = createRef<HTMLInputElement>()

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        setState("LOADING")
        try {
            const question: string = questionRef.current?.value!
            await app.fetchClient.dealApi.addQuestion(id!, question)
            setState("SUCCESS")
        }
        catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    if (deal.outcome !== "NONE") {
        return <></>
    }

    if (deal instanceof DealType) {
        return (
            <div className={styles.qa}>
                <h4>Q&amp;A</h4>
                {state === "LOADING" && <LoadingDots/>}
                {state === "NONE" && (
                    <>
                        Got questions about this deal? Submit them below and we will get it to the founders.
                        <form
                            onSubmit={(e) => onSubmit(e)}
                            className={styles.input}
                        >
                            <input
                                type="text"
                                ref={questionRef}
                                placeholder="Ask a question"
                                required={true}
                            />
                            <button type="submit">
                                <PaperPlaneIcon
                                    width={16}
                                    height={16}
                                    fillClass="fill-icon-inactive"
                                />
                            </button>
                        </form>
                        <DealEvent deal={deal}/>
                    </>
                )}
                {state === "SUCCESS" && (
                    <Alert
                        type={AlertType.SUCCESS}
                        text="Successfully saved question"
                    />
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to save question"
                    />
                )}
            </div>
        )
    }
    return <></>
}

export default DealQa