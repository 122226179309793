import {QUERY_KEY_PART__LIST, QueryKey, QueryType} from "../../tech/query/query.type"
import {FetchClient} from "../../tech/fetch/client"
import {InvestmentType} from "./investment.type"

const QUERY_KEY__BASE_INVESTMENT = "investment"
const QUERY_KEY__PENDING = "pending"

export function QUERY_KEY__INVESTMENTS_PENDING(): QueryKey {
    return [
        QUERY_KEY__BASE_INVESTMENT,
        QUERY_KEY_PART__LIST,
        QUERY_KEY__PENDING,
    ]
}

export function QUERY__INVESTMENTS_PENDING(
    fetchClient: FetchClient,
    key: QueryKey,
    enabled: boolean = true
): QueryType<InvestmentType[]> {
    return {
        queryKey: key,
        queryFn: () => fetchClient.investmentApi.getAllPendingForCurrentUser(),
        enabled
    }
}