import {QueryKey, QueryType} from "../../../tech/query/query.type"
import {FetchClient} from "../../../tech/fetch/client"
import {InvestorOnboardingType} from "./onboarding.type"

const QUERY_KEY__BASE = "investorOnboarding"

export function QUERY_KEY__INVESTOR_ONBOARDING(): QueryKey {
    return [QUERY_KEY__BASE]
}

export function QUERY__INVESTOR_ONBOARDING(
    fetchClient: FetchClient,
    key: QueryKey,
    enabled: boolean = true,
    refetchIntervalInMilliseconds?: number
): QueryType<InvestorOnboardingType> {
    return {
        queryKey: key,
        queryFn: () => fetchClient.investorApi.getOnboarding(),
        enabled,
        refetchInterval: refetchIntervalInMilliseconds ? refetchIntervalInMilliseconds : false
    }
}