import {FieldError} from "react-hook-form"

export function hasError(field: string, errors: any): boolean {
    return !!access(field, errors)
}

export function access(field: string, errors: any): FieldError | undefined {
    return field
        .split('.')
        .reduce((o, i) => o !== undefined && o[i], errors)
}