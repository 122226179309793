import {FunctionComponent} from "react"
import LegalWrapper from "../legal.component"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import {DealOnboardingDependentWithInvestmentType} from "../../deal/onboarding-dependent.type"
import {RouteData} from "../../../tech/routing/route.type"
import styles from "../legal.module.sass"
import {Link} from "react-router-dom"
import {PRIVACY_STATEMENT} from "../../../paths"
import Item from "../item/item.component"

type TermsAndConditionsProps = {
    onboarding?: InvestorOnboardingType
    openDeals?: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
}

const TermsAndConditions: FunctionComponent<TermsAndConditionsProps> = ({
    onboarding,
    openDeals,
    relevantRoutes
}) => {
    return (
        <LegalWrapper
            onboarding={onboarding}
            openDeals={openDeals}
            relevantRoutes={relevantRoutes}
        >
            <div className={styles.content}>
                <center>
                    <h2>NonPublic Pte. Ltd.</h2>
                    <h2>Terms of Use</h2>
                    <p>(Last updated: 13 September, 2024)</p>
                </center>
                <p>
                    These Terms of Use govern the registration, access and use of https://www.nonpublic.io and the
                    associated NonPublic platform (collectively, the “<strong>Website</strong>” or the “<strong>Platform</strong>”), including all content (the
                    “Content”), services, features and functions (collectively, the “<strong>Services</strong>”) made available, offered or
                    provided on or through such Platform.
                </p>
                <p>
                    Please read these Terms of Use carefully to fully understand your rights, obligations and potential risks.
                    If you have any concerns with these Terms of Use or any of the documents referenced herein, you
                    should consult a qualified legal professional before completing your registration. If you do not agree to
                    these Terms of Use, please refrain from using the Platform.
                </p>
                <div className={styles.box}>
                    <p>
                        The Platform is owned, operated, administered and managed by <strong>NonPublic Pte. Ltd.</strong> (UEN:
                        202411455N), company incorporated in Singapore with its registered office situated at 20A Tanjong
                        Pagar Road, Singapore 088443 (“<strong>NonPublic</strong>”, or the “<strong>Company</strong>”, and also referred to as “<strong>we</strong>”, “<strong>us</strong>” or “<strong>our</strong>” in these Terms of Use).
                    </p>
                    <p>
                        The Company is a member of the NonPublic Network, comprising the Company and its affiliates and
                        related entities (the “<strong>NonPublic Network</strong>” or the “<strong>NonPublic Group</strong>”).
                    </p>
                    <p>
                        Although parts of this Website might make reference to other entities in the NonPublic Network or to
                        “NonPublic” generally, these Terms of Use only apply to this Website and not to any other website
                        relating to any other entity of the NonPublic Network.
                    </p>
                </div>
                <p>
                    By registering for an account and accessing the Platform, you, the User*:
                </p>
                <ol type="A">
                    <li>
                        agree that these Terms of Use (as may be amended, updated and/or supplemented from time to
                        time) will be a legally binding agreement between you and the Company in relation to your access
                        to and use of the Platform, Content and Services; and
                    </li>
                    <li>
                        confirm, warrant and represent that you are an Accredited Investor as defined under the
                        Securities and Futures Act 2001 of Singapore and under the United States Securities Act of 1933,
                        and a Qualified Purchaser as defined under the United States Investment Company Act of 1940.
                    </li>
                </ol>
                <p>
                    <i>* “User” as used in these Terms of Use means a user who is registered as a member on the Platform.
                    If the User is an entity, then “you” as used in these Terms of Use shall refer to such entity acting through
                    its duly authorised representative.</i>
                </p>
                <p>
                    In addition to these Terms of Use, you may have also entered into a separate agreement with the
                    Company in connection with the Services (the “<strong>Service Agreement</strong>”). If so, you will also be subject to
                    your specific Service Agreement in addition to these Terms of Use. In the event of any conflict or
                    inconsistency between these Terms of Use and your Service Agreement, the Service Agreement shall
                    prevail.
                </p>
                <p>
                    These Terms of Use are intended to be read in conjunction with the <Link to={PRIVACY_STATEMENT}>Privacy Policy </Link>, which is incorporated
                    herein by reference.
                </p>
                <p>
                    These Terms of Use shall take effect with respect to a User upon the successful completion of such
                    User’s registration on the Platform.
                </p>

                <h3>1. Registration Requirements; Access to Restricted Areas</h3>
                <Item bullet="1.1">
                    The Content provided in non-public areas of the Platform that require account registration (the
                    “<strong>Restricted Areas</strong>”) is directed solely at and intended solely for use by individuals, firms,
                    corporations, partnerships, and organisations that meet the following minimum registration
                    requirements:
                    <Item bullet="(a)">
                        the User must be an Accredited Investor as defined under the Securities and Futures Act
                        2001 of Singapore and under the United States Securities Act of 1933, and a Qualified
                        Purchaser as defined under the United States Investment Company Act of 1940;
                    </Item>
                    <Item bullet="(b)">
                        where the User is an individual:
                        <Item bullet="(i)">
                            the User shall be 21 years old and above;
                        </Item>
                        <Item bullet="(ii)">
                            the User must have legal capacity to enter into legally binding agreements; and
                        </Item>
                        <Item bullet="(iii)">
                            the User must not be subject to any sanctions or legal restrictions; and
                        </Item>
                    </Item>
                    <Item bullet="(c)">
                        where the User is an entity:
                        <Item bullet="(i)">
                            the User must be a legally established and validly existing entity in good standing
                            under the laws of its jurisdiction of incorporation or formation;
                        </Item>
                        <Item bullet="(ii)">
                            the User must not be subject to any government sanctions, blacklisting, or
                            restrictions that would prevent it from engaging in transactions or using the Platform;
                            and
                        </Item>
                        <Item bullet="(iii)">
                            the User represents and warrants that the individual(s) using the Platform and
                            Services or otherwise acting on its behalf is duly authorised to do so and has the
                            necessary power and authority to bind the User to these Terms of Use.
                        </Item>
                    </Item>
                </Item>
                <Item bullet="1.2">
                    Access to such Restricted Areas is not available to persons or entities who do not meet the
                    aforementioned criteria or in any jurisdiction where such access would be contrary to applicable
                    laws or regulations.
                </Item>
                <Item bullet="1.3">
                    Additionally, upon the Company’s request, you agree to promptly provide the Company with all
                    necessary documentation and information required for the Company to satisfactorily complete
                    Know Your Customer (KYC) verification. This includes, but is not limited to, personal identification
                    documents, proof of address, and any other relevant information or documentation as requested
                    by the Company. You acknowledge that failure to comply with this requirement may result in
                    restrictions or suspension of your access to the Platform.
                </Item>

                <h3>2. Security of Account; Suspension or Termination</h3>
                <Item bullet="2.1">
                    A User shall not register for more than one account, and where the User is an individual, the User
                    shall not register for an account on behalf of an individual other than himself/herself.
                </Item>
                <Item bullet="2.2">
                    You must take appropriate steps to keep your account and login details confidential to prevent
                    unauthorised use of your account. You must immediately notify us if you have reason to believe
                    that your personal data and/or login details have been compromised. We shall not be liable for
                    any loss or damage arising from any unauthorised use of your account.
                </Item>
                <Item bullet="2.3">
                    We reserve the right to, at our sole discretion, suspend an account or ban a User if we determine,
                    whether based on actual or suspected violations, that any provision of these Terms of Use has been breached. Such action may be taken without prior notice or liability to the User, and we are
                    under no obligation to provide any reason for such suspension or termination.
                </Item>

                <h3>3. Content; Use and Restrictions</h3>
                <Item bullet="3.1">
                    In these Terms of Use, “Content” shall mean all content displayed or made available on the
                    Platform, including without limitation, all text, data, reports, charts, graphs, images, audio, video,
                    materials, presentation decks, documentation and other information.
                </Item>
                <Item bullet="3.2">
                    Unless expressly stated otherwise, and on the condition that you comply with all of your
                    obligations under these Terms of Use and subject to Clause 3.3 below, you are authorised to
                    view, download, copy and print the Content on the Platform, provided that: (a) such use is for
                    your own personal, internal, informational and non-commercial purposes only, and (b) any copy
                    of the Content made by you shall have all accompanying copyright and other proprietary notices
                    intact. Except as set out in the foregoing, your use of the Content and Platform in accordance
                    with these Terms of Use does not confer upon you any right or licence to such Content.
                </Item>
                <Item bullet="3.3">
                    Content provided, owned and managed by third parties, including other Users of the Platform,
                    may be subject to additional restrictions on download, copying, and printing. We disclaim all
                    responsibility for any third-party Content and make no representations regarding its accuracy,
                    legality, or compliance with applicable laws. You are solely responsible for reviewing and adhering
                    to any additional terms or conditions associated with such third-party Content.
                </Item>
                <Item bullet="3.4">
                    You are not authorised to copy or use any software, proprietary processes, or technology
                    embodied or described in the Platform.
                </Item>
                <Item bullet="3.5">
                    You must not:
                    <Item bullet="(a)">
                        distribute, publish, recompile, decompile, disassemble, reverse engineer, distribute and/or
                        modify the Content or any part of the Platform;
                    </Item>
                    <Item bullet="(b)">
                        use any Content to violate any law or regulation, including applicable laws relating to data
                        protection, privacy or information security;
                    </Item>
                    <Item bullet="(c)">
                        purposefully interfere with or disrupt the performance of the Platform; or
                    </Item>
                    <Item bullet="(d)">
                        use the Content or any part of the Platform in a manner that would have the potential to be
                        inconsistent with any individual’s reasonable expectations of privacy.
                    </Item>
                </Item>

                <h3>4. User Content</h3>
                <Item bullet="4.1">
                    In connection with the Services, the Platform may allow Users to post, submit, publish, display or
                    transmit to other Users or other persons Content, messages, documents or materials
                    (collectively, “<strong>User Content</strong>”) on or through the Platform. You acknowledge that we have no
                    control over the User Content, and are under no obligation to screen such User Content.
                </Item>
                <Item bullet="4.2">
                    If you are an owner, poster, provider or publisher of User Content on the Platform:
                    <Item bullet="(a)">
                        You are solely responsible for the User Content or information you publish, display, retain,
                        enter into or on the Platform, or transmit to other Users of the Platform, and you shall hold
                        us harmless from any claims, liabilities, damages, losses, or expenses (including legal fees)
                        arising out of or in connection with your User Content, including but not limited to any claims
                        related to the infringement of intellectual property rights, defamation, privacy violations, or
                        the dissemination of illegal, harmful, or objectionable material.
                    </Item>
                    <Item bullet="(b)">
                        All User Content must comply with these Terms of Use, in particular Clause 10.2 below.
                    </Item>
                    <Item bullet="(c)">
                        Additionally, you agree that we reserve the right to retain an archival record of all User
                        Content, including those deleted or removed by you.
                    </Item>
                </Item>
                <Item bullet="4.3">
                    While we are under no obligation to screen the User Content, we reserve the right to review,
                    monitor, and take action regarding any User Content. If we determine, at our sole discretion, that
                    any User Content is objectionable, illegal, obscene or otherwise harmful, or violates our Terms of
                    Use, we may: (a) at our sole discretion delete or remove such User Content from the Platform,
                    and you acknowledge that we are under no obligation to notify you of such removal, and/or (b)
                    where we in our sole discretion deem necessary or appropriate, make a report to the relevant
                    authorities. By using the Platform, you acknowledge and agree that we may disclose User
                    Content to authorities when necessary to comply with legal obligations or to protect the rights,
                    safety, or property of others.
                </Item>

                <h3>5. Confidentiality</h3>
                <Item bullet="5.1">
                    You shall: (a) keep all Confidential Information strictly confidential and shall not disclose or use
                    such information for any purpose other than as expressly authorised by these Terms of Use or
                    by us in writing, and (b) take all reasonable measures to protect the confidentiality of the
                    Confidential Information and ensure that it is not disclosed to any third parties without our prior
                    written consent or the prior written consent of the relevant disclosing party to whom such
                    Confidential Information belongs.
                </Item>
                <Item bullet="5.2">
                    For the purposes of these Terms of Use, “<strong>Confidential Information</strong>” means (a) any and all
                    Content accessible and made available on the Platform (whether before, on, or after the effective
                    date of these Terms of Use), as well as (b) any information disclosed outside of the Platform
                    (whether in writing, orally, or otherwise, and whether disclosed before, on or after the date of your
                    registration on the Platform) that you may be privy to as a result of your interactions with the
                    Company, that is (i) not generally known to the public, (ii) designated as being confidential, or (iii)
                    ought reasonably to be considered confidential. This shall include, without limitation, business
                    strategies, financial information, personal data, proprietary data, and any and all and materials
                    relating to the Collaborative Opportunities (as defined in Clause 13.1 below) shared by the
                    Company and/or a Collaborating Party (as defined in Clause 13.1 below) (whether through any
                    of their respective representatives or otherwise). For the avoidance of doubt, information shall
                    not be deemed 'generally known to the public' solely because it is known by a limited number of
                    individuals or entities to whom it might be of commercial interest.
                </Item>
                <Item bullet="5.3">
                    Content provided by third parties, including other Users of the Platform, may be subject to
                    additional confidentiality obligations and restrictions imposed by such third parties. In this regard,
                    you must not disclose, distribute, or use such third-party Content for any purpose other than as
                    expressly permitted by the owner of the Content. Any use of third-party Content should be in
                    accordance with any additional terms or conditions provided by the third party, and you are
                    responsible for adhering to those terms.
                </Item>

                <h3>6. Privacy</h3>
                <p>
                    Where you provide any personal information and data (“<strong>Personal Data</strong>”) to us through the
                    Platform, you acknowledge that we may use such Personal Data according to our Privacy
                    Statement.
                </p>

                <h3>7. Intellectual Property Rights</h3>
                <Item bullet="7.1">
                    NonPublic and its affiliates, or the relevant licensors and other third parties (who may or may not
                    be affiliated with NonPublic), own and retain all worldwide rights, titles and interests including but
                    not limited to copyright and related rights, trade marks, business names and domain names,
                    rights in get-up, goodwill and the right to sue for passing off, rights in designs, rights in software,  database rights, rights in confidential information (including know-how), patents, rights to
                    inventions, trade secrets, and all other intellectual property and similar rights which subsist or will
                    subsist now or in the future in any part of the world in and to: (a) the Platform, (b) all hardware,
                    software, and other items used to provide the Services; and all Content, including without
                    limitation, the information, images, databases, data, documents, online graphics, audio and video
                    on the Platform, which contains proprietary and confidential information that is protected by
                    applicable intellectual property and other laws (the “<strong>NP Intellectual Property Rights</strong>”).
                </Item>
                <Item bullet="7.2">
                    You can only use the NP Intellectual Property Rights for the specific purpose of using the Platform
                    and/or Services. These Terms of Use do not convey any proprietary interest in or to the NP
                    Intellectual Property Rights.
                </Item>
                <Item bullet="7.3">
                    You agree not to remove, alter or obscure any copyright, trademark, service mark or other
                    proprietary rights notices incorporated in or accompanying the Platform, Services, or Content.
                </Item>
                <Item bullet="7.4">
                    References to other parties’ trademarks on the Platform are for identification purposes only and
                    do not indicate that such parties have approved the Platform or any of its contents. These Terms
                    of Use do not grant you any right to use the trademarks of other parties.
                </Item>
                <Item bullet="7.5">
                    Except with our prior written approval, you shall not: (a) use the name “NonPublic”, the NonPublic
                    logo, related names, logos, product and service names, designs and slogans of NonPublic, or
                    local language variants of the foregoing either alone or in combination with other words or design
                    elements, or (b) use any of the foregoing names, marks or logos in any press release,
                    advertisement, or other promotional or marketing material or media, whether in written, oral,
                    electronic, visual or any other form.
                </Item>
                <Item bullet="7.6">
                    Except as expressly stated herein, nothing contained on the Platform should be construed as
                    granting, by implication, estoppel, or otherwise, any licence or right to use any Content or
                    trademark displayed on this site without the written permission of our organisation or such other
                    third party that may own the content or trademark displayed on the Platform.
                </Item>
                <Item bullet="7.7">
                    In relation to User Content:
                    <Item bullet="(a)">
                        By providing, submitting, uploading, transmitting, posting or displaying User Content on or
                        through the Platform for any purpose whatsoever, you grant us:
                        <Item bullet="(i)">
                            a perpetual, worldwide, non-exclusive, royalty-free, sub-licensable, transferable right
                            and license to use, access, host, store, cache, reproduce, publish, display, distribute,
                            perform, transmit, modify, adapt, and create derivative works of your User Content,
                            without any further consent, notice and/or compensation to you or others, subject to the
                            relevant legislations and the Privacy Policy and publish such User Content on the
                            Platform for the purpose of displaying, distributing and promoting the Platform or the
                            Services; and
                        </Item>
                        <Item bullet="(ii)">
                            a perpetual, worldwide, non-exclusive, royalty-free, sub-licensable, transferable right
                            and license to use such User Content, including any ideas, inventions, concepts,
                            techniques or know-how disclosed herein, for any purpose, including the developing
                            and/or marketing of Services.
                        </Item>
                    </Item>
                </Item>

                <h3>8. Third-Party Services</h3>
                <Item bullet="8.1">
                    Certain Services may be provided by third parties (the “<strong>Third-Party Services</strong>”), and such Third-
                    Party Services may be integrated into the services we offer. You acknowledge that your use of
                    these Third-Party Services may require you to agree to additional terms and conditions imposed
                    by the relevant provider of such Third-Party Service (the “<strong>Third-Party Service Provider</strong>”). By
                    using any Third-Party Services (whether accessed through links on the Platform or otherwise presented for your use), you agree to comply with, and ensure the acceptance of, the applicable
                    terms of service and privacy policies of such Third-Party Service Providers. This includes, without
                    limitation, any fees charged by these providers for the use of such services.
                </Item>
                <Item bullet="8.2">
                    You acknowledge and agree that we bear no responsibility for the accuracy, reliability,
                    completeness or suitability of the Third-Party Services, and, to the fullest extent permitted by law,
                    we expressly disclaim all liabilities and responsibilities relating to your use of and reliance on the
                    Third-Party Services. Additionally, your use of such Third-Party Services shall constitute your
                    consent to your disclosure of information you provide on the Platform to the providers of the Third-
                    Party Services, and NonPublic bears no responsibility for the usage, retention or disclosure by
                    the Third-Party Service Providers of your information.
                </Item>

                <h3>9. Links to Other Websites, Resources and Tools</h3>
                <Item bullet="9.1">
                    The Platform may contain links to websites, resources or tools maintained by third parties over
                    whom we have no control. Such links are only for your convenience, and we do not recommend
                    or endorse the contents of such third-party websites, resources or tools. You acknowledge and
                    understand that your access to and use of such third-party websites, resources or tools is at your
                    own risk, and you are aware that such third-party websites, resources or tools are not governed
                    by these Terms of Use or any other policies of the NonPublic Network.
                </Item>
                <Item bullet="9.2">
                    The Platform may also contain links to the websites, resources or tools maintained by other
                    entities within the NonPublic Network. As stated above, these Terms of Use only apply specifically
                    to the Platform, and any other websites, resources or tools of ours and those of any
                </Item>

                <h3>10. Conduct, Acceptable and Prohibited Use</h3>
                <Item bullet="10.1">
                    You agree to use the Platform only for lawful purposes, (a) in accordance with these Terms of
                    Use, the Privacy Policy, and any other agreement entered into between yourself and the
                    Comapny in respect of the Services, (b) in a way that does not infringe the rights of, restrict or
                    inhibit anyone else’s use and enjoyment of the Platform, and (c) in compliance with all applicable
                    laws and regulations.
                </Item>
                <Item bullet="10.2">
                    You agree not to use the Platform for the purpose of or in connection with:
                    <Item bullet="(a)">
                        any way or manner other than as authorised and in accordance with these Terms of Use, the
                        Privacy Policy, and any other agreement entered into between yourself and the Company in
                        respect of the Services;
                    </Item>
                    <Item bullet="(b)">
                        pestering, harassing or causing distress or inconvenience to any person, including any issuer
                        or payee with whom you have entered into a share subscription agreement;
                    </Item>
                    <Item bullet="(c)">
                        transmitting obscene, sexually explicit, hateful, threatening or offensive content;
                    </Item>
                    <Item bullet="(d)">
                        harvesting contact information of other users of the Platform for purposes of sending
                        unsolicited communications, offering notes, uploading files that contain viruses, corrupted
                        files, or any other similar software or programs that may damage the operation of another’s
                        computer;
                    </Item>
                    <Item bullet="(e)">
                        impersonating any person or entity;
                    </Item>
                    <Item bullet="(f)">
                        uploading or otherwise transmitting content that infringes any patent, trademark, trade secret,
                        copyright or other proprietary rights of any party;
                    </Item>
                    <Item bullet="(g)">
                        engaging in any unlawful or fraudulent activity or perpetrating a hoax, engaging in money
                        laundering, or engaging in phishing schemes or forgery or other similar falsification or
                        manipulation of data;
                    </Item>
                    <Item bullet="(h)">
                        promoting or providing instructional information about illegal activities, promote physical harm
                        or injury against any group of individual, or promote any act of cruelty to animals;
                    </Item>
                    <Item bullet="(i)">
                        uploading, posting, emailing, transmitting, or otherwise making available any content that you
                        do not have a right to make available under any law or under contractual or fiduciary
                        relationships (such as inside information, proprietary and confidential information learned or
                        disclosed as part of employment relationships or under nondisclosure agreements);
                    </Item>
                    <Item bullet="(j)">
                        uploading, posting, emailing, transmitting, or otherwise making available any unsolicited or
                        unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid
                        schemes," or any other form of solicitation;
                    </Item>
                    <Item bullet="(k)">
                        uploading, posting, emailing, transmitting, or otherwise making available any material that
                        contains software viruses or any other computer code, files or programs designed to
                        interrupt, destroy or limit the functionality of any computer software or hardware or
                        telecommunications equipment;
                    </Item>
                    <Item bullet="(l)">
                        uploading, posting, emailing, transmitting, sharing, or otherwise making available any content
                        that would constitute, encourage or provide instructions for a criminal offence, violate the
                        rights of any party, or that would otherwise create liability or violate any local or international
                        law;
                    </Item>
                    <Item bullet="(m)">
                        an avenue to advertise or offer to sell any goods or services for any commercial purpose, or
                        in any way or attempt to commercially exploit any part of the Platform without our permission,
                        including without limitation, to modify any of the Platform’s content in any way, or copy,
                        reproduce, publicly display, distribute or otherwise use or communicate them for any public
                        or commercial purpose without our permission; and
                    </Item>
                    <Item bullet="(n)">
                        monitoring its availability, performance or functionality, or for any other benchmarking or
                        competitive purposes.
                    </Item>
                </Item>
                <Item bullet="10.3">
                    Additionally, you shall not:
                    <Item bullet="(a)">
                        introduce into the Platform any code, content or other material which contains any virus,
                        trojan horse, worm, time bomb, cancelbot or other computer programming routines that are
                        intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any
                        system, data or personal information; and/or
                    </Item>
                    <Item bullet="(b)">
                        hack into, interfere with, disrupt, disable, over-burden or otherwise interfere with the
                        accessibility or impair the proper working of the Platform, the Services or servers, which shall
                        include but is not limited to denial-of-service attacks, spoof attacks, session hacking, sniffing,
                        tampering, reverse engineering or reprogramming.
                    </Item>
                </Item>

                <h3>11. Changes to the Website or Content</h3>
                <p>
                    We may, at our sole discretion, modify or discontinue any or all of the content, features, functions
                    or services of this Platform, or any portion thereof, at any time with or without notice. It is your
                    responsibility to be aware of any such changes by checking the relevant webpage(s). To the
                    fullest extent permitted by law, we will not be liable if for any reason all or any part of the Platform
                    is unavailable at any time or for any period.
                </p>

                <h3>12. Changes to these Terms of Use and Privacy Policy</h3>
                <p>
                    We may from time to time, at our sole discretion, change, supplement or update these Terms of
                    Use and our Privacy Policy, and the effective date of the revised Terms of Use and Privacy Policy
                    will be the “last updated” date (or as otherwise specified). You should therefore check this page
                    and our Privacy Policy page regularly to ensure that you are kept updated on the latest Terms of
                    Use and Privacy Policy. Your continued use of the Platform following any such change shall
                    constitute your agreement to comply with and be bound by the Terms of Use as revised, and the
                    revised Terms of Use shall apply to all current and past usage by you of this Platform.
                </p>

                <h3>13. Transaction Disclaimers</h3>
                <Item bullet="13.1">
                    In connection with any transaction conducted or facilitated on or through the Platform, you
                    acknowledge that: (a) the Company’s role is limited to facilitating introductions between parties
                    seeking potential joint venture, collaborative, partnership, project or financial backing
                    opportunities (such parties, the “<strong>Collaborating Parties</strong>”, and such opportunities, the
                    “<strong>Collaborative Opportunities</strong>”), and (b) the Platform and the Services simply provide a
                    streamlined process for supporting and facilitating communications related to the Collaborative
                    Opportunities.
                </Item>
                <Item bullet="13.2">
                    You further agree and acknowledge that:
                    <Item bullet="(a)">
                        the Content (and such other information, documents and materials) in respect of a
                        Collaborative Opportunity are provided by the relevant Collaborating Party, and accordingly,
                        we make no representation or warranty as to the accuracy, reliability or validity thereof;
                    </Item>
                    <Item bullet="(b)">
                        the Content made available on the Platform are for general, informational purposes only and
                        should not be construed, regarded or deemed as an offer, solicitation, invitation, advice or
                        recommendation to engage or enter into any Collaborative Opportunities, and accordingly
                        may not be relied upon as such;
                    </Item>
                    <Item bullet="(c)">
                        any performance data or related information expressed on the Platform are in no way an
                        indication of future results, and the Company makes no representation, warranty, guarantee
                        or assurance that (i) that any Collaborative Opportunity will or is likely to achieve profits or
                        losses similar to those achieved in the past, or (ii) significant losses will be avoided;
                    </Item>
                    <Item bullet="(d)">
                        any transaction in respect of a Collaborative Opportunity, whether facilitated through the
                        Platform or otherwise, will only be formalised through a final agreement (or equivalent
                        document) and the related legal documentation (together, the “<strong>Transaction Documents</strong>”),
                        and will be subject to the terms and conditions contained in such Transaction Documents,
                        including the qualifications and/or criteria necessary to participate in the such transaction;
                    </Item>
                    <Item bullet="(e)">
                        any transaction entered into in respect of a Collaborative Opportunity (i) is solely between
                        the relevant Collaborating Parties, and (ii) the Company is not, and will not be, a party to the
                        Transaction Documents; and
                    </Item>
                    <Item bullet="(f)">
                        any transaction that you enter into in respect of a Collaborative Opportunity is entirely at your
                        own risk, and you retain complete control and discretion over your decision to participate in
                        such transaction. Accordingly, you agree to hold the Company, members of the NonPublic
                        Network and their respective members, directors, officers, employees and agents harmless
                        from and against any claims, liabilities, damages, losses, or expenses (including legal fees)
                        of any kind arising out of or in connection with your participation in such transaction.
                    </Item>
                </Item>
                <Item bullet="13.3">
                    To the fullest extent permitted by law, we shall not be liable for any claims, losses, damages, or
                    expenses of any kind or nature arising out of or in connection with (a) the Collaborative
                    Opportunities or the transactions resulting therefrom, (b) the Transaction Documents, (c)
                    inaccuracies or omissions in the information provided by Collaborating Parties, and (d) any issues, delays, or failures related to the execution, performance, or settlement of the relevant
                    transaction.
                </Item>

                <h3>14. General Disclaimers</h3>
                <Item bullet="14.1">
                    The Platform and all content thereof are provided and made available on an “as is” basis without
                    any representation or warranty of any kind, express or implied. No representations, warranties or
                    undertakings (express or implied) are given as to the accuracy or completeness of the information
                    on this Platform. Additionally, we do not guarantee, assure or warrant that the Platform will be
                    secure, error-free, free from viruses or malicious code, or will meet any particular criteria of
                    performance or quality (technical or otherwise).
                </Item>
                <Item bullet="14.2">
                    We are not utilising the Platform to provide investment, financial, accounting, tax, legal or other
                    advice. If you would like investment, accounting, tax, legal or other professional advice, you
                    should consult with your own advisors with respect to your individual circumstances and needs
                    before taking or refraining from taking any action.
                </Item>
                <Item bullet="14.3">
                    NonPublic and the rest of the NonPublic Group expressly disclaim all implied warranties,
                    including, without limitation, warranties of merchantability, title, fitness for a particular purpose,
                    non-infringement, compatibility and security. Your use of the Platform and use of or reliance upon
                    any content or information is solely at your own risk, and none of NonPublic or any other member
                    of the NonPublic Group (including their respective directors, members, employees, partners or
                    agents) shall be liable or responsible for any loss or damage whatsoever arising directly or
                    indirectly in connection with any person relying on this Platform.
                </Item>

                <h3>15. Limitation of Liability</h3>
                <Item bullet="15.1">
                    To the fullest extent permitted by law, the Company, members of the NonPublic Network, and
                    each of their respective directors, officers, employees, representatives and agents shall not be
                    liable for any claim, loss, damages, cost and expense of any kind, including direct, indirect,
                    special, incidental, consequential, compensatory or punitive damages or any other damages
                    whatsoever, including, without limitation, loss of profits, loss of revenue, loss of business, loss of
                    data, loss of goodwill or third-party losses, whether in an action of contract, statute, tort (including,
                    without limitation, negligence) or otherwise, relating to or arising out of:
                    <Item bullet="(a)">
                        your use of (or inability to use) the Platform or Services;
                    </Item>
                    <Item bullet="(b)">
                        any reliance on the Content on the Platform;
                    </Item>
                    <Item bullet="(c)">
                        any transaction or Collaborative Opportunity facilitated through the Platform;
                    </Item>
                    <Item bullet="(d)">
                        the Transaction Documents;
                    </Item>
                    <Item bullet="(e)">
                        any action or inaction of the Company in performing its role of facilitating introductions and
                        communications in connection of Collaborative Opportunities;
                    </Item>
                    <Item bullet="(f)">
                        any delays relating to the Platform or Services;
                    </Item>
                    <Item bullet="(g)">
                        the provision of or failure to provide the Platform or Services; or
                    </Item>
                    <Item bullet="(h)">
                        the provision of or failure to provide the Third-Party Services,
                    </Item>
                    <p>
                        even if the Company knew, or should have known, of the possibility of such damages.
                    </p>
                </Item>
                <Item bullet="15.2">
                    Without prejudice to the other provisions herein, the User hereby agrees to indemnify and hold
                    the Company, its subsidiaries, affiliated companies, related entities and each of their respective directors, officers, agents, partners, and employees (collectively the “Indemnified Parties”),
                    harmless at all times against all actions, proceedings, costs, claims, expenses (including all legal
                    costs on a full indemnity basis), demands, liabilities, losses (whether direct, indirect, or
                    consequential), and damages (whether in tort, contract, or otherwise) whatsoever and howsoever
                    arising in connection with the User’s use of the Platform and/or the Services. This includes,
                    without limitation, claims made by third parties, claims for defamation, infringement of intellectual
                    property rights, breach of confidentiality, unauthorised disclosure of personal data or such other
                    personal data breach, wrongful use of computers, unauthorised or illegal access to computers
                    (including but not limited to hacking), or pecuniary losses, and any regulatory fines or penalties
                    incurred by the Company as a result of the User's breach of his/her/their representations or
                    warranties, including a breach of warranty regarding such User’s Accredited Investor and/or
                    Qualified Purchaser status.
                </Item>

                <h3>16. Survival on Termination</h3>
                <p>
                    On termination of these Terms of Use in respect of a User, any clauses that by their nature are
                    intended to survive such termination, whether expressly stated or implied, shall survive. This
                    includes, but is not limited to, provisions related to confidentiality, disclaimers, limitations of
                    liability, and indemnification.
                </p>

                <h3>17. Severability</h3>
                <p>
                    If any provision in these Terms of Use is found invalid or unenforceable, that provision will be re-
                    construed to the maximum effect permitted by law in order to effect its intent as nearly as possible,
                    and all other provisions in these Terms of Use shall remain in full force and effect.
                </p>

                <h3>18. No Third-Party Rights</h3>
                <p>
                    A person who is not a party to these Terms of Use shall have no right under the Contracts (Rights
                    to Third Parties) Act 2001 of Singapore or under any law, to enforce any provision in these Terms
                    of Use.
                </p>

                <h3>19. Remedies; No Waiver</h3>
                <p>
                    No remedy conferred by any of the provisions of these Terms of Use is intended to be exclusive
                    of any other remedy which is otherwise available at law, in equity, by statute or otherwise and
                    each and every other remedy shall be cumulative and shall be in addition to every other remedy
                    given hereunder or now or hereafter existing at law, in equity, by statute or otherwise. The election
                    of any one or more of such remedies by a party shall not constitute a waiver by such party of the
                    right to pursue other available remedies. No failure on the part of a party hereto to exercise, and
                    no delay in exercising any right under these Terms of Use will operate as a waiver thereof, nor
                    will any single or partial exercise of any right under these Terms of Use preclude any other or
                    further exercise of any right thereof or of the exercise of any other right.
                </p>

                <h3>20. Governing Law and Dispute Resolution</h3>
                <Item bullet="20.1">
                    These Terms of Use shall be governed by and construed in accordance with the laws of
                    Singapore.
                </Item>
                <Item bullet="20.2">
                    In the event of any dispute, claim, or controversy arising out of or in connection with the Platform,
                    the Services or these Terms of Use, the parties agree to first attempt to resolve the matter through
                    good-faith negotiations.
                    <p>
                        If the dispute cannot be resolved through such negotiation within 30 days (or such other period
                        as may be mutually agreed in writing between the parties), the parties shall then proceed to
                        mediation. The mediation process shall be conducted in accordance with the rules of the
                        Singapore Mediation Centre (SMC) or any other mutually agreed-upon mediation organisation.
                    </p>
                    <p>
                        If the dispute remains unresolved after mediation, it shall be referred to and finally resolved by
                        arbitration administered by the Singapore International Arbitration Centre (“<strong>SIAC</strong>”) in accordance
                        with the Arbitration Rules of the Singapore International Arbitration Centre (“<strong>SIAC Rules</strong>”) for the
                        time being in force, which rules are deemed to be incorporated by reference in this clause. The
                        arbitration shall be held in Singapore, and the language of arbitration shall be English. The
                        Tribunal shall consist of one arbitrator to be mutually appointed by the parties. The decision of
                        the arbitrator(s) shall be final and binding upon the parties.
                    </p>
                </Item>
            </div>
        </LegalWrapper>
    )
}

export default TermsAndConditions