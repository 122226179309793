/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomerPortalSessionCreationRequestOpenApi,
  CustomerPortalSessionCreationResponseOpenApi,
  InvestorOnboardingOpenApi,
  SubscribeRequestOpenApi,
  SubscribeResultOpenApi,
  SubscriptionCancellationPreviewOpenApi,
  SubscriptionCancellationRequestOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    CustomerPortalSessionCreationRequestOpenApiFromJSON,
    CustomerPortalSessionCreationRequestOpenApiToJSON,
    CustomerPortalSessionCreationResponseOpenApiFromJSON,
    CustomerPortalSessionCreationResponseOpenApiToJSON,
    InvestorOnboardingOpenApiFromJSON,
    InvestorOnboardingOpenApiToJSON,
    SubscribeRequestOpenApiFromJSON,
    SubscribeRequestOpenApiToJSON,
    SubscribeResultOpenApiFromJSON,
    SubscribeResultOpenApiToJSON,
    SubscriptionCancellationPreviewOpenApiFromJSON,
    SubscriptionCancellationPreviewOpenApiToJSON,
    SubscriptionCancellationRequestOpenApiFromJSON,
    SubscriptionCancellationRequestOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface SubscriptionCancelRequest {
    subscriptionCancellationRequestOpenApi?: SubscriptionCancellationRequestOpenApi;
}

export interface SubscriptionCustomerPortalSessionRequest {
    customerPortalSessionCreationRequestOpenApi?: CustomerPortalSessionCreationRequestOpenApi;
}

export interface SubscriptionSubscribeRequest {
    subscribeRequestOpenApi?: SubscribeRequestOpenApi;
}

/**
 * 
 */
export class SubscriptionApiClient extends runtime.BaseAPI {

    /**
     * Cancel the subscription of the current user
     */
    async subscriptionCancelRaw(requestParameters: SubscriptionCancelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorOnboardingOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/subscription/cancel`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionCancellationRequestOpenApiToJSON(requestParameters.subscriptionCancellationRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorOnboardingOpenApiFromJSON(jsonValue));
    }

    /**
     * Cancel the subscription of the current user
     */
    async subscriptionCancel(requestParameters: SubscriptionCancelRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorOnboardingOpenApi> {
        const response = await this.subscriptionCancelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a Stripe Billing Customer Portal session for the current user
     */
    async subscriptionCustomerPortalSessionRaw(requestParameters: SubscriptionCustomerPortalSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerPortalSessionCreationResponseOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/subscription/customer-portal-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerPortalSessionCreationRequestOpenApiToJSON(requestParameters.customerPortalSessionCreationRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPortalSessionCreationResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Create a Stripe Billing Customer Portal session for the current user
     */
    async subscriptionCustomerPortalSession(requestParameters: SubscriptionCustomerPortalSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerPortalSessionCreationResponseOpenApi> {
        const response = await this.subscriptionCustomerPortalSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview the cancellation of the subscription of the current user
     */
    async subscriptionPreviewCancellationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionCancellationPreviewOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/subscription/cancel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionCancellationPreviewOpenApiFromJSON(jsonValue));
    }

    /**
     * Preview the cancellation of the subscription of the current user
     */
    async subscriptionPreviewCancellation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionCancellationPreviewOpenApi> {
        const response = await this.subscriptionPreviewCancellationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reactivate the subscription of the current user
     */
    async subscriptionReactivateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorOnboardingOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/subscription/reactivate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorOnboardingOpenApiFromJSON(jsonValue));
    }

    /**
     * Reactivate the subscription of the current user
     */
    async subscriptionReactivate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorOnboardingOpenApi> {
        const response = await this.subscriptionReactivateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Subscribe premium membership plan for the current user
     */
    async subscriptionSubscribeRaw(requestParameters: SubscriptionSubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscribeResultOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/subscription/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscribeRequestOpenApiToJSON(requestParameters.subscribeRequestOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscribeResultOpenApiFromJSON(jsonValue));
    }

    /**
     * Subscribe premium membership plan for the current user
     */
    async subscriptionSubscribe(requestParameters: SubscriptionSubscribeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscribeResultOpenApi> {
        const response = await this.subscriptionSubscribeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
