/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InvestorAiDeclarationOpenApi,
  InvestorFiltersOpenApi,
  InvestorKycDocumentsOpenApi,
  InvestorOnboardingOpenApi,
  InvestorOpenApi,
  InvestorPaginatedResponseOpenApi,
  InvestorSortOpenApi,
  InvestorStartOpenApi,
  InvestorWithDetailsOpenApi,
  KycLogOpenApi,
  PersonOpenApi,
  SubscriptionLogOpenApi,
  ValidationResponseOpenApi,
} from '../models';
import {
    InvestorAiDeclarationOpenApiFromJSON,
    InvestorAiDeclarationOpenApiToJSON,
    InvestorFiltersOpenApiFromJSON,
    InvestorFiltersOpenApiToJSON,
    InvestorKycDocumentsOpenApiFromJSON,
    InvestorKycDocumentsOpenApiToJSON,
    InvestorOnboardingOpenApiFromJSON,
    InvestorOnboardingOpenApiToJSON,
    InvestorOpenApiFromJSON,
    InvestorOpenApiToJSON,
    InvestorPaginatedResponseOpenApiFromJSON,
    InvestorPaginatedResponseOpenApiToJSON,
    InvestorSortOpenApiFromJSON,
    InvestorSortOpenApiToJSON,
    InvestorStartOpenApiFromJSON,
    InvestorStartOpenApiToJSON,
    InvestorWithDetailsOpenApiFromJSON,
    InvestorWithDetailsOpenApiToJSON,
    KycLogOpenApiFromJSON,
    KycLogOpenApiToJSON,
    PersonOpenApiFromJSON,
    PersonOpenApiToJSON,
    SubscriptionLogOpenApiFromJSON,
    SubscriptionLogOpenApiToJSON,
    ValidationResponseOpenApiFromJSON,
    ValidationResponseOpenApiToJSON,
} from '../models';

export interface InvestorCreateRequest {
    investorOpenApi?: InvestorOpenApi;
}

export interface InvestorDeleteRequest {
    id: string;
}

export interface InvestorGetRequest {
    id: string;
}

export interface InvestorGetAllPaginatedRequest {
    elementsPerPage?: number;
    page?: number;
    sort?: InvestorSortOpenApi;
    investorFiltersOpenApi?: InvestorFiltersOpenApi;
}

export interface InvestorKycLogsGetAllRequest {
    id: string;
}

export interface InvestorOnboardingSaveGeneralDataRequest {
    personOpenApi?: PersonOpenApi;
}

export interface InvestorOnboardingSaveKycDocumentsRequest {
    investorKycDocumentsOpenApi?: InvestorKycDocumentsOpenApi;
}

export interface InvestorOnboardingStartRequest {
    investorStartOpenApi?: InvestorStartOpenApi;
}

export interface InvestorOnboardingUpdateAccreditedInvestorStatusRequest {
    investorAiDeclarationOpenApi?: InvestorAiDeclarationOpenApi;
}

export interface InvestorSubscriptionLogsGetAllRequest {
    id: string;
}

export interface InvestorUpdateRequest {
    id: string;
    investorOpenApi?: InvestorOpenApi;
}

/**
 * 
 */
export class InvestorApiClient extends runtime.BaseAPI {

    /**
     * Create new investor
     */
    async investorCreateRaw(requestParameters: InvestorCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorWithDetailsOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorOpenApiToJSON(requestParameters.investorOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorWithDetailsOpenApiFromJSON(jsonValue));
    }

    /**
     * Create new investor
     */
    async investorCreate(requestParameters: InvestorCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorWithDetailsOpenApi> {
        const response = await this.investorCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete investor by id
     */
    async investorDeleteRaw(requestParameters: InvestorDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete investor by id
     */
    async investorDelete(requestParameters: InvestorDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.investorDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Get investor by id
     */
    async investorGetRaw(requestParameters: InvestorGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorWithDetailsOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorWithDetailsOpenApiFromJSON(jsonValue));
    }

    /**
     * Get investor by id
     */
    async investorGet(requestParameters: InvestorGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorWithDetailsOpenApi> {
        const response = await this.investorGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all investors
     */
    async investorGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvestorWithDetailsOpenApi>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvestorWithDetailsOpenApiFromJSON));
    }

    /**
     * Get all investors
     */
    async investorGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvestorWithDetailsOpenApi>> {
        const response = await this.investorGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all investors (paginated)
     */
    async investorGetAllPaginatedRaw(requestParameters: InvestorGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorPaginatedResponseOpenApi>> {
        const queryParameters: any = {};

        if (requestParameters.elementsPerPage !== undefined) {
            queryParameters['elementsPerPage'] = requestParameters.elementsPerPage;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorFiltersOpenApiToJSON(requestParameters.investorFiltersOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorPaginatedResponseOpenApiFromJSON(jsonValue));
    }

    /**
     * Get all investors (paginated)
     */
    async investorGetAllPaginated(requestParameters: InvestorGetAllPaginatedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorPaginatedResponseOpenApi> {
        const response = await this.investorGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all kyc logs for investor by id
     */
    async investorKycLogsGetAllRaw(requestParameters: InvestorKycLogsGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KycLogOpenApi>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorKycLogsGetAll.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/{id}/kyc-logs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KycLogOpenApiFromJSON));
    }

    /**
     * Get all kyc logs for investor by id
     */
    async investorKycLogsGetAll(requestParameters: InvestorKycLogsGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KycLogOpenApi>> {
        const response = await this.investorKycLogsGetAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get onboarding state for current user
     */
    async investorOnboardingGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorOnboardingOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorOnboardingOpenApiFromJSON(jsonValue));
    }

    /**
     * Get onboarding state for current user
     */
    async investorOnboardingGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorOnboardingOpenApi> {
        const response = await this.investorOnboardingGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Save general data during investor onboarding
     */
    async investorOnboardingSaveGeneralDataRaw(requestParameters: InvestorOnboardingSaveGeneralDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorOnboardingOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/onboarding/general-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonOpenApiToJSON(requestParameters.personOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorOnboardingOpenApiFromJSON(jsonValue));
    }

    /**
     * Save general data during investor onboarding
     */
    async investorOnboardingSaveGeneralData(requestParameters: InvestorOnboardingSaveGeneralDataRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorOnboardingOpenApi> {
        const response = await this.investorOnboardingSaveGeneralDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save general data during investor onboarding
     */
    async investorOnboardingSaveKycDocumentsRaw(requestParameters: InvestorOnboardingSaveKycDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorOnboardingOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/onboarding/kyc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorKycDocumentsOpenApiToJSON(requestParameters.investorKycDocumentsOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorOnboardingOpenApiFromJSON(jsonValue));
    }

    /**
     * Save general data during investor onboarding
     */
    async investorOnboardingSaveKycDocuments(requestParameters: InvestorOnboardingSaveKycDocumentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorOnboardingOpenApi> {
        const response = await this.investorOnboardingSaveKycDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update started during investor onboarding
     */
    async investorOnboardingStartRaw(requestParameters: InvestorOnboardingStartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorOnboardingOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/onboarding/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorStartOpenApiToJSON(requestParameters.investorStartOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorOnboardingOpenApiFromJSON(jsonValue));
    }

    /**
     * Update started during investor onboarding
     */
    async investorOnboardingStart(requestParameters: InvestorOnboardingStartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorOnboardingOpenApi> {
        const response = await this.investorOnboardingStartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update accredited investor status during investor onboarding
     */
    async investorOnboardingUpdateAccreditedInvestorStatusRaw(requestParameters: InvestorOnboardingUpdateAccreditedInvestorStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorOnboardingOpenApi>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/onboarding/ai-declaration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorAiDeclarationOpenApiToJSON(requestParameters.investorAiDeclarationOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorOnboardingOpenApiFromJSON(jsonValue));
    }

    /**
     * Update accredited investor status during investor onboarding
     */
    async investorOnboardingUpdateAccreditedInvestorStatus(requestParameters: InvestorOnboardingUpdateAccreditedInvestorStatusRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorOnboardingOpenApi> {
        const response = await this.investorOnboardingUpdateAccreditedInvestorStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all subscription logs for investor by id
     */
    async investorSubscriptionLogsGetAllRaw(requestParameters: InvestorSubscriptionLogsGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubscriptionLogOpenApi>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorSubscriptionLogsGetAll.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/{id}/subscription-logs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubscriptionLogOpenApiFromJSON));
    }

    /**
     * Get all subscription logs for investor by id
     */
    async investorSubscriptionLogsGetAll(requestParameters: InvestorSubscriptionLogsGetAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubscriptionLogOpenApi>> {
        const response = await this.investorSubscriptionLogsGetAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update investor
     */
    async investorUpdateRaw(requestParameters: InvestorUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorWithDetailsOpenApi>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling investorUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvestorOpenApiToJSON(requestParameters.investorOpenApi),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorWithDetailsOpenApiFromJSON(jsonValue));
    }

    /**
     * Update investor
     */
    async investorUpdate(requestParameters: InvestorUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorWithDetailsOpenApi> {
        const response = await this.investorUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
