import React, {FunctionComponent} from "react"
import {FilterOptionsType} from "../options/options.type"
import FilterSection from "../options/section/section.component"

type StatusFilterProps = {
    options: FilterOptionsType
    updateOptions: (options: FilterOptionsType) => void
}

const WithInvestmentFilter: FunctionComponent<StatusFilterProps> = ({
    options,
    updateOptions
}) => {
    return (
        <FilterSection
            label="Deal with Investments"
            type="WITH_INVESTMENT"
            options={options}
            updateOptions={updateOptions}
        />
    )
}

export default WithInvestmentFilter