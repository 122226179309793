import {IndividualInvestmentOpenApi, IndividualInvestmentValuationOpenApi} from "../../../generated"
import {IndividualInvestmentType, IndividualInvestmentValuationType} from "./individual-investment.type"

export function mapOpenApiToIndividualInvestment(investment: IndividualInvestmentOpenApi): IndividualInvestmentType {
    return {
        id: investment.id,
        name: investment.name,
        currency: investment.currency,
        exited: investment.exited,
        valuations: investment.valuations.map(mapOpenApiToIndividualInvestmentValuation)
    }
}

export function mapIndividualInvestmentToOpenApi(investment: IndividualInvestmentType): IndividualInvestmentOpenApi {
    return {
        id: investment.id,
        name: investment.name,
        currency: investment.currency,
        exited: investment.exited,
        valuations: investment.valuations.map(mapIndividualInvestmentValuationToOpenApi),
        objectType: "IndividualInvestment"
    }
}

export function mapOpenApiToIndividualInvestmentValuation(valuation: IndividualInvestmentValuationOpenApi): IndividualInvestmentValuationType {
    return {
        id: valuation.id,
        date: valuation.date,
        valuation: valuation.valuation,
        value: valuation.value
    }
}

export function mapIndividualInvestmentValuationToOpenApi(valuation: IndividualInvestmentValuationType): IndividualInvestmentValuationOpenApi {
    return {
        id: valuation.id,
        date: valuation.date,
        valuation: valuation.valuation,
        value: valuation.value
    }
}