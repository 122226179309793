import React, {FunctionComponent, ReactNode} from "react"
import styles from "./button-wrapper.module.sass"

type ButtonWrapperProps = {
    children: ReactNode
    rightOnDesktop: boolean
    hideSpacer?: boolean
}

const ButtonWrapper: FunctionComponent<ButtonWrapperProps> = ({
    children,
    rightOnDesktop,
    hideSpacer
}) => {
    return (
        <>
            {!hideSpacer && (
                <div className={styles.spacer}/>
            )}
            <div className={`${styles.wrapper} ${rightOnDesktop ? styles.rightOnDesktop : "" }`}>
                {children}
            </div>
        </>
    )
}

export default ButtonWrapper