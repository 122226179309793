import {InputFieldProps} from "../props.type"
import React, {FunctionComponent} from "react"
import FormError from "../../error/error.component"
import FieldLabelText from "../label-text.component"
import {Control, Controller} from "react-hook-form"
import styles from "./date.module.sass"

export type DateFieldProps = InputFieldProps & {
    control: Control<any, any>
}

const DateField: FunctionComponent<DateFieldProps> = ({
    label,
    placeholder,
    errors,
    control,
    reactHookFormRegister,
    disabled = false
}) => {
    return (
        <label className={styles.label}>
            <FieldLabelText label={label}/>
            <Controller
                name={reactHookFormRegister.name}
                control={control}
                render={({field: {onChange, value}}) => {
                    const isoDate = value !== undefined ? new Date(value).toISOString().substring(0, 10) : undefined
                    return (
                        <input
                            type="date"
                            defaultValue={isoDate}
                            placeholder={placeholder}
                            disabled={disabled}
                            onChange={e => onChange(transformToDate(e.target.value))}
                        />
                    )
                }}
                rules={{
                    ...reactHookFormRegister.options
                }}
            />
            <FormError
                field={reactHookFormRegister.name}
                errors={errors}
            />
        </label>
    )
}

export default DateField

function transformToDate(d: any): Date | undefined {
    const date = new Date(d)
    // @ts-ignore
    return !isNaN(date)
        ? date
        : undefined
}