import React, {ChangeEvent, FunctionComponent} from "react"
import styles from "./checkbox.module.sass"

type CheckboxOutsideFormProps = {
    id: string
    label: string
    checked: boolean
    onClick: (e: ChangeEvent<HTMLInputElement>) => void
}

const CheckboxOutsideForm: FunctionComponent<CheckboxOutsideFormProps> = ({
    id,
    label,
    checked,
    onClick
}) => {
    const uniqueId = Math.random() + id
    return (
        <div className={styles.checkbox}>
            <div className={styles.wrapper}>
                <div className={styles.input}>
                    <input
                        type="checkbox"
                        id={uniqueId}
                        // @ts-ignore
                        defaultValue={checked}
                        onChange={onClick}
                        checked={checked}
                    />
                </div>
                <div className={styles.label}>
                    <label htmlFor={uniqueId}>
                        {label}
                    </label>
                </div>
            </div>
        </div>
    )
}

export default CheckboxOutsideForm