import React, {FunctionComponent} from "react"
import {Link} from "react-router-dom"
import {RouteType} from "../../paths"
import styles from "./action-line.module.sass"

type ActionLineProps = {
    intro: string
    linkText: string
    route: RouteType
    lineBreak?: boolean
    withDashedTopLine?: boolean
}

const ActionLine: FunctionComponent<ActionLineProps> = ({
    intro,
    linkText,
    route,
    lineBreak,
    withDashedTopLine = true
}) => {
    return (
        <div className={withDashedTopLine ? styles.actionLineWithDashedTopLine : styles.actionLine}>
            {intro}{" "}{lineBreak && <br/>}
            <Link to={route}>
                {linkText}
            </Link>
        </div>
    )
}

export default ActionLine