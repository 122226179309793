import React, {FunctionComponent} from "react"
import TriangleUpIcon from "../../../../../tech/icon/triangle-up.component"
import TriangleDownIcon from "../../../../../tech/icon/triangle-down.component"

type TriangleProps = {
    type: "multiple" | "percentage"
    value: number
}

const Triangle: FunctionComponent<TriangleProps> = (props) => {
    if (isNeutral(props)) {
        return <></>
    }

    if (isPositive(props)) {
        return (
            <TriangleUpIcon
                width={12}
                height={9}
                fillClass="fill-icon-success"
            />
        )
    }

    return (
        <TriangleDownIcon
            width={12}
            height={9}
            fillClass="fill-icon-error"
        />
    )
}

export default Triangle

function isNeutral(props: TriangleProps): boolean {
    return (props.type === "multiple" && props.value === 1)
        || (props.type === "percentage" && props.value === 0)
}

function isPositive(props: TriangleProps): boolean {
    return (props.type === "multiple" && props.value > 1)
        || (props.type === "percentage" && props.value > 0)
}