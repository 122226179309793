import {SyndicateForPresentationOpenApi} from "../../generated"
import {SyndicateType} from "./syndicate.type"
import {mapOpenApiToImage} from "../../tech/image/image.mapper"

export function mapOpenApiToSyndicate(syndicate: SyndicateForPresentationOpenApi): SyndicateType {
    return {
        id: syndicate.id,
        created: syndicate.created,
        modified: syndicate.modified,
        name: syndicate.name,
        logo: mapOpenApiToImage(syndicate.logo),
        investorGroupIds: syndicate.investorGroupIds
    }
}