import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const NineDotsIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M10.125 8.438a1.69 1.69 0 0 1-1.042 1.559c-.308.128-.648.161-.975.096s-.628-.226-.864-.462-.397-.537-.462-.864-.032-.667.096-.975a1.69 1.69 0 0 1 .622-.757c.277-.185.604-.284.938-.284.448 0 .877.178 1.193.494a1.69 1.69 0 0 1 .494 1.193zM18 6.75c-.334 0-.66.099-.937.284s-.494.449-.622.757-.161.648-.096.975a1.69 1.69 0 0 0 .462.864c.236.236.537.397.864.462s.667.032.975-.096a1.69 1.69 0 0 0 1.042-1.559c0-.448-.178-.877-.494-1.193S18.448 6.75 18 6.75zm9.563 3.375a1.69 1.69 0 0 0 .938-.284c.277-.185.494-.449.622-.757s.161-.648.096-.975-.226-.628-.462-.864-.537-.397-.864-.462a1.69 1.69 0 0 0-1.732.718 1.69 1.69 0 0 0-.284.938c0 .448.178.877.494 1.193s.746.494 1.193.494zM8.438 16.313a1.69 1.69 0 0 0-1.559 1.042 1.69 1.69 0 0 0-.096.975c.065.327.226.628.462.864s.537.397.864.462.667.032.975-.096.572-.344.757-.622a1.69 1.69 0 0 0 .284-.937c0-.448-.178-.877-.494-1.193s-.746-.494-1.193-.494zm9.563 0c-.334 0-.66.099-.937.284s-.494.449-.622.757-.161.648-.096.975.226.628.462.864.537.397.864.462.667.032.975-.096.572-.344.757-.622.284-.604.284-.937c0-.448-.178-.877-.494-1.193s-.746-.494-1.193-.494zm9.563 0c-.334 0-.66.099-.937.284s-.494.449-.622.757-.161.648-.096.975.226.628.462.864.537.397.864.462.667.032.975-.096.572-.344.757-.622.284-.604.284-.937c0-.448-.178-.877-.494-1.193s-.746-.494-1.193-.494zM8.438 25.875a1.69 1.69 0 0 0-1.559 1.042 1.69 1.69 0 0 0-.096.975c.065.327.226.628.462.864s.537.397.864.462.667.032.975-.096.572-.344.757-.622a1.69 1.69 0 0 0 .284-.937c0-.448-.178-.877-.494-1.193s-.746-.494-1.193-.494zm9.563 0c-.334 0-.66.099-.937.284s-.494.449-.622.757-.161.648-.096.975.226.628.462.864.537.397.864.462.667.032.975-.096.572-.344.757-.622.284-.604.284-.937c0-.448-.178-.877-.494-1.193s-.746-.494-1.193-.494zm9.563 0c-.334 0-.66.099-.937.284s-.494.449-.622.757-.161.648-.096.975.226.628.462.864.537.397.864.462.667.032.975-.096.572-.344.757-.622.284-.604.284-.937c0-.448-.178-.877-.494-1.193s-.746-.494-1.193-.494z"
            />
        </svg>
    )
}

export default NineDotsIcon