/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestmentFeeWithAmountOpenApi } from './InvestmentFeeWithAmountOpenApi';
import {
    InvestmentFeeWithAmountOpenApiFromJSON,
    InvestmentFeeWithAmountOpenApiFromJSONTyped,
    InvestmentFeeWithAmountOpenApiToJSON,
} from './InvestmentFeeWithAmountOpenApi';

/**
 * 
 * @export
 * @interface InvestmentFeesOpenApi
 */
export interface InvestmentFeesOpenApi {
    /**
     * 
     * @type {InvestmentFeeWithAmountOpenApi}
     * @memberof InvestmentFeesOpenApi
     */
    placement: InvestmentFeeWithAmountOpenApi;
    /**
     * 
     * @type {number}
     * @memberof InvestmentFeesOpenApi
     */
    management: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentFeesOpenApi
     */
    carry: number;
    /**
     * 
     * @type {InvestmentFeeWithAmountOpenApi}
     * @memberof InvestmentFeesOpenApi
     */
    exchange?: InvestmentFeeWithAmountOpenApi;
}

/**
 * Check if a given object implements the InvestmentFeesOpenApi interface.
 */
export function instanceOfInvestmentFeesOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "placement" in value;
    isInstance = isInstance && "management" in value;
    isInstance = isInstance && "carry" in value;

    return isInstance;
}

export function InvestmentFeesOpenApiFromJSON(json: any): InvestmentFeesOpenApi {
    return InvestmentFeesOpenApiFromJSONTyped(json, false);
}

export function InvestmentFeesOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentFeesOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'placement': InvestmentFeeWithAmountOpenApiFromJSON(json['placement']),
        'management': json['management'],
        'carry': json['carry'],
        'exchange': !exists(json, 'exchange') ? undefined : InvestmentFeeWithAmountOpenApiFromJSON(json['exchange']),
    };
}

export function InvestmentFeesOpenApiToJSON(value?: InvestmentFeesOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'placement': InvestmentFeeWithAmountOpenApiToJSON(value.placement),
        'management': value.management,
        'carry': value.carry,
        'exchange': InvestmentFeeWithAmountOpenApiToJSON(value.exchange),
    };
}

