import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const ExclamationMarkIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
        >
            <path
                className={fillClass}
                d="M8 1.5a6.5 6.5 0 0 0-6.005 4.013 6.5 6.5 0 0 0 1.409 7.084 6.5 6.5 0 0 0 7.084 1.409A6.5 6.5 0 0 0 14.5 8c-.002-1.723-.687-3.376-1.906-4.594S9.723 1.502 8 1.5zm0 12a5.5 5.5 0 0 1-5.081-3.395 5.5 5.5 0 0 1 1.192-5.994 5.5 5.5 0 0 1 5.994-1.192A5.5 5.5 0 0 1 13.5 8c-.002 1.458-.582 2.856-1.613 3.887S9.458 13.498 8 13.5zM9 11a.5.5 0 0 1-.5.5 1 1 0 0 1-1-1V8a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5 1 1 0 0 1 1 1v2.5a.5.5 0 0 1 .5.5zM7 5.25a.75.75 0 0 1 .463-.693.75.75 0 0 1 .817.163.75.75 0 0 1 .163.817A.75.75 0 0 1 7.75 6 .75.75 0 0 1 7 5.25z"
            />
        </svg>
    )
}

export default ExclamationMarkIcon


