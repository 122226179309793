import React, {FunctionComponent} from "react"
import TagLine from "../../../../tech/tag-line/tag-line.component"
import {mapDealIndustryToString, mapDealStageToString} from "../../deal.type"
import {DealIndustryOpenApi, DealStageOpenApi} from "../../../../generated"
import {DealGeographyType} from "../../../deal-geography/deal-geography.type"
import styles from "./categorization.module.sass"

type DealCategorizationProps = {
    geography: DealGeographyType | undefined
    industry: DealIndustryOpenApi | undefined
    stage: DealStageOpenApi | undefined
    bright?: boolean
}

const DealCategorization: FunctionComponent<DealCategorizationProps> = ({
    geography,
    industry,
    stage,
    bright
}) => {
    const tags = []
    stage && stage !== DealStageOpenApi.None && tags.push({ name: mapDealStageToString(stage) })
    geography && tags.push({ name: geography.name })
    if (industry) {
        tags.push({ name: mapDealIndustryToString(industry) })
    }
    return (
        <div className={styles.categorization}>
            <TagLine
                tags={tags}
                bright={bright}
            />
        </div>
    )
}

export default DealCategorization