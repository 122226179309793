/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CurrencyOpenApi,
  DealStageOpenApi,
  DealStatusOpenApi,
  InvestorDashboardInvestmentTypeOpenApi,
  InvestorDashboardOpenApi,
} from '../models';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiToJSON,
    DealStageOpenApiFromJSON,
    DealStageOpenApiToJSON,
    DealStatusOpenApiFromJSON,
    DealStatusOpenApiToJSON,
    InvestorDashboardInvestmentTypeOpenApiFromJSON,
    InvestorDashboardInvestmentTypeOpenApiToJSON,
    InvestorDashboardOpenApiFromJSON,
    InvestorDashboardOpenApiToJSON,
} from '../models';

export interface InvestorDashboardGetRequest {
    categoryIds: Array<string>;
    geographyIds: Array<string>;
    investmentTypes: Array<InvestorDashboardInvestmentTypeOpenApi>;
    stages: Array<DealStageOpenApi>;
    status: Array<DealStatusOpenApi>;
    targetCurrency: CurrencyOpenApi;
}

/**
 * 
 */
export class InvestorDashboardApiClient extends runtime.BaseAPI {

    /**
     * Get data for investor dashboard of current user
     */
    async investorDashboardGetRaw(requestParameters: InvestorDashboardGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvestorDashboardOpenApi>> {
        if (requestParameters.categoryIds === null || requestParameters.categoryIds === undefined) {
            throw new runtime.RequiredError('categoryIds','Required parameter requestParameters.categoryIds was null or undefined when calling investorDashboardGet.');
        }

        if (requestParameters.geographyIds === null || requestParameters.geographyIds === undefined) {
            throw new runtime.RequiredError('geographyIds','Required parameter requestParameters.geographyIds was null or undefined when calling investorDashboardGet.');
        }

        if (requestParameters.investmentTypes === null || requestParameters.investmentTypes === undefined) {
            throw new runtime.RequiredError('investmentTypes','Required parameter requestParameters.investmentTypes was null or undefined when calling investorDashboardGet.');
        }

        if (requestParameters.stages === null || requestParameters.stages === undefined) {
            throw new runtime.RequiredError('stages','Required parameter requestParameters.stages was null or undefined when calling investorDashboardGet.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling investorDashboardGet.');
        }

        if (requestParameters.targetCurrency === null || requestParameters.targetCurrency === undefined) {
            throw new runtime.RequiredError('targetCurrency','Required parameter requestParameters.targetCurrency was null or undefined when calling investorDashboardGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.categoryIds) {
            queryParameters['categoryIds'] = requestParameters.categoryIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.geographyIds) {
            queryParameters['geographyIds'] = requestParameters.geographyIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.investmentTypes) {
            queryParameters['investmentTypes'] = requestParameters.investmentTypes.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.stages) {
            queryParameters['stages'] = requestParameters.stages.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.targetCurrency !== undefined) {
            queryParameters['targetCurrency'] = requestParameters.targetCurrency;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/investor-dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestorDashboardOpenApiFromJSON(jsonValue));
    }

    /**
     * Get data for investor dashboard of current user
     */
    async investorDashboardGet(requestParameters: InvestorDashboardGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvestorDashboardOpenApi> {
        const response = await this.investorDashboardGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
