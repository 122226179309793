import React, {FunctionComponent} from "react"
import {Control, FieldErrors, UseFormRegister} from "react-hook-form"
import {IndividualInvestmentType, IndividualInvestmentValuationType} from "../../individual-investment.type"
import FormRow from "../../../../../tech/form/row/row.component"
import NumberField from "../../../../../tech/form/fields/number/number.component"
import MinusCircleIcon from "../../../../../tech/icon/minus-circle.component"
import DateField from "../../../../../tech/form/fields/date/date.component"
import styles from "./valuation.module.sass"

type IndividualInvestmentFormValuationProps = {
    control: Control<IndividualInvestmentType, any>
    errors: FieldErrors<IndividualInvestmentType>
    register: UseFormRegister<IndividualInvestmentType>
    del: (i: number) => void
    index: number
}

const IndividualInvestmentFormValuation: FunctionComponent<IndividualInvestmentFormValuationProps> = ({
    control,
    errors,
    register,
    del,
    index
}) => {
    return (
        <div className={styles.valuation}>
            <input type="hidden" {...register(`valuations.${index}.id`)} />
            <FormRow content={{
                type: "one",
                element: (
                    <DateField
                        label="Date"
                        placeholder="Date"
                        errors={errors}
                        control={control}
                        reactHookFormRegister={{
                            name: `valuations.${index}.date`,
                            register,
                            options: {
                                required: "Date is required.",
                                validate: (value, formValues) => {
                                    if (value !== undefined) {
                                        const allValuationDates = formValues.valuations
                                            .map((v: IndividualInvestmentValuationType) => v.date)
                                            .filter((d: Date | undefined) => d !== undefined)
                                        if (allValuationDates.filter((d: Date) => d.getTime() === value.getTime()).length > 1) {
                                            return "There must not be multiple valuations at the same date."
                                        }
                                    }
                                    return true
                                }
                            }
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <NumberField
                        label="Value"
                        placeholder="Value"
                        errors={errors}
                        control={control}
                        reactHookFormRegister={{
                            name: `valuations.${index}.value`,
                            register,
                            options: {
                                required: "Value is required.",
                                min: {value: 0, message: "Must not be negative."},
                            }
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <NumberField
                        label="Valuation"
                        placeholder="Valuation"
                        errors={errors}
                        control={control}
                        reactHookFormRegister={{
                            name: `valuations.${index}.valuation`,
                            register,
                            options: {
                                required: "Valuation is required.",
                                min: {value: 0, message: "Must not be negative."},
                            }
                        }}
                    />
                )
            }}/>
            {index === 0 ? (
                <div className={styles.removeMargin}/>
            ) : (
                <div
                    className={styles.remove}
                    onClick={() => del(index)}
                >
                    <div>
                        <div>
                            <MinusCircleIcon
                                fillClass="fill-icon-caption"
                                width={16}
                                height={16}
                            />
                        </div>
                        <div>
                            <div>Remove</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default IndividualInvestmentFormValuation