import React, {FunctionComponent} from "react"
import {InvestorDashboardInvestmentType} from "../../../../dashboard.type"
import {formatDate} from "../../../../../../../tech/format/datetime.util"
import {formatAmountWithCurrency} from "../../../../../../../tech/format/amount.util"
import {CurrencyOpenApi} from "../../../../../../../generated"
import InvestorDashboardInvestmentsInvestedOriginal from "../../../invested/invested.component"
import styles from "./invested.module.sass"

type InvestorDashboardInvestmentMobileInvestedProps = {
    investment: InvestorDashboardInvestmentType
    targetCurrency: CurrencyOpenApi
}

const InvestorDashboardInvestmentMobileInvested: FunctionComponent<InvestorDashboardInvestmentMobileInvestedProps> = ({
    investment,
    targetCurrency
}) => {
    return (
        <div className={styles.invested}>
            <div className={styles.amount}>
                Invested: {formatAmountWithCurrency(investment.amount, targetCurrency, 0)}
                <InvestorDashboardInvestmentsInvestedOriginal
                    investment={investment}
                    targetCurrency={targetCurrency}
                />
            </div>
            <div className={styles.date}>
                {formatDate(investment.investment.getInvestmentDate())}
            </div>
        </div>
    )
}

export default InvestorDashboardInvestmentMobileInvested