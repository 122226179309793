/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageOpenApi } from './ImageOpenApi';
import {
    ImageOpenApiFromJSON,
    ImageOpenApiFromJSONTyped,
    ImageOpenApiToJSON,
} from './ImageOpenApi';

/**
 * 
 * @export
 * @interface DealLogosOpenApi
 */
export interface DealLogosOpenApi {
    /**
     * 
     * @type {ImageOpenApi}
     * @memberof DealLogosOpenApi
     */
    normal?: ImageOpenApi;
    /**
     * 
     * @type {ImageOpenApi}
     * @memberof DealLogosOpenApi
     */
    light?: ImageOpenApi;
}

/**
 * Check if a given object implements the DealLogosOpenApi interface.
 */
export function instanceOfDealLogosOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DealLogosOpenApiFromJSON(json: any): DealLogosOpenApi {
    return DealLogosOpenApiFromJSONTyped(json, false);
}

export function DealLogosOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealLogosOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'normal': !exists(json, 'normal') ? undefined : ImageOpenApiFromJSON(json['normal']),
        'light': !exists(json, 'light') ? undefined : ImageOpenApiFromJSON(json['light']),
    };
}

export function DealLogosOpenApiToJSON(value?: DealLogosOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'normal': ImageOpenApiToJSON(value.normal),
        'light': ImageOpenApiToJSON(value.light),
    };
}

