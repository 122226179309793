import {
    PaymentDetailsAccountManualReconciliationOpenApi,
    PaymentDetailsAccountOpenApi,
    PaymentDetailsOpenApi
} from "../../../generated"
import {
    PaymentDetailsAccountManualReconciliationType,
    PaymentDetailsAccountType,
    PaymentDetailsType
} from "./payment-details.type"

export function mapOpenApiToPaymentDetails(paymentDetails: PaymentDetailsOpenApi): PaymentDetailsType {
    return {
        accounts: paymentDetails.accounts.map(mapOpenApiToPaymentDetailsAccount),
        manualReconciliation: paymentDetails.manualReconciliation
    }
}

export function mapOpenApiToPaymentDetailsAccount(paymentDetailsAccount: PaymentDetailsAccountOpenApi): PaymentDetailsAccountType {
    return {
        accountHolder: paymentDetailsAccount.accountHolder,
        bankAddress: paymentDetailsAccount.bankAddress,
        bankName: paymentDetailsAccount.bankName,
        number: paymentDetailsAccount.number,
        manualReconciliation: paymentDetailsAccount.manualReconciliation && mapOpenApiToPaymentDetailsAccountManualReconciliation(paymentDetailsAccount.manualReconciliation)
    }
}

function mapOpenApiToPaymentDetailsAccountManualReconciliation(
    manualReconciliation: PaymentDetailsAccountManualReconciliationOpenApi
): PaymentDetailsAccountManualReconciliationType {
    return {
        transferOrigin: manualReconciliation.transferOrigin,
        swift: manualReconciliation.swift,
        achRoutingNumber: manualReconciliation.achRoutingNumber,
        aba: manualReconciliation.aba
    }
}