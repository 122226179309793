import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const SignOutIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M14 27a1 1 0 0 1-1 1H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h7a1 1 0 0 1 1 1 1 1 0 0 1-1 1H6v20h7a1 1 0 0 1 1 1zm13.708-11.707l-5-5a1 1 0 0 0-1.415 0 1 1 0 0 0 0 1.415L24.586 15H13a1 1 0 0 0-1 1 1 1 0 0 0 1 1h11.586l-3.294 3.293a1 1 0 0 0 0 1.415 1 1 0 0 0 1.415 0l5-5a1 1 0 0 0 .293-.707 1 1 0 0 0-.293-.707z"
            />
        </svg>
    )
}

export default SignOutIcon