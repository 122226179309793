import React, {FunctionComponent} from "react"
import LoadingBar from "../../../../../tech/loading/bar/bar.component"
import styles from "./uploading.module.sass"

type FileUploadingDesktopUploadingProps = {
    uploadFile: (file: File) => void
}

const FileUploadDesktopUploading: FunctionComponent<FileUploadingDesktopUploadingProps> = ({ uploadFile }) => {
    return (
        <div className={styles.uploading}>
            <div className={styles.uploadingBar}>
                <LoadingBar
                    fillClass="background-icon-theme"
                    backgroundClass="background-fill-bg-tertiary"
                />
            </div>
        </div>
    )
}
export default FileUploadDesktopUploading