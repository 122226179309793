import React, {FunctionComponent} from "react"
import {PRIVACY_STATEMENT, TERMS_AND_CONDITIONS} from "../../../../paths"
import styles from "./documents.module.sass"

type DealLegalDocumentsProps = {
    dealId: string
}

const DealLegalDocuments: FunctionComponent<DealLegalDocumentsProps> = ({ dealId }) => {
    return (
        <ul className={styles.list}>
            {dealId === "49068fa3-9fae-4d2a-a3ff-a9e953c72348" && (
                <>
                    <li>
                        <a
                            href="https://production-nonpublic-bac-s3filestoragepublic5374c-mvkpn0n5ts05.s3.ap-southeast-1.amazonaws.com/74741ca8-d502-49d9-a465-d3cf8b9de9c2/79bfb92d-d67c-44c2-bbd7-c21953f12cb4-1726039643227.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Investment Terms
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://production-nonpublic-bac-s3filestoragepublic5374c-mvkpn0n5ts05.s3.ap-southeast-1.amazonaws.com/74741ca8-d502-49d9-a465-d3cf8b9de9c2/1b964d20-080e-45c3-983b-5f09a81a3692-1726039664552.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            SPV Constitution Document
                        </a>
                    </li>
                </>
            )}
            {dealId === "3da40d0b-e0d0-4632-8e4f-bf2fd46706b5" && (
                <>
                    <li>
                        <a
                            href="https://production-nonpublic-bac-s3filestoragepublic5374c-mvkpn0n5ts05.s3.ap-southeast-1.amazonaws.com/74741ca8-d502-49d9-a465-d3cf8b9de9c2/3376ebe5-de04-4085-bdd7-0a519dede9d8-1726009957366.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Investment Terms
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://production-nonpublic-bac-s3filestoragepublic5374c-mvkpn0n5ts05.s3.ap-southeast-1.amazonaws.com/74741ca8-d502-49d9-a465-d3cf8b9de9c2/3e708f8f-732b-4730-aa3f-be66d9abf1b2-1726009973336.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                        SPV Constitution Document
                        </a>
                    </li>
                </>
            )}
            <li>
                <a
                    href={TERMS_AND_CONDITIONS}
                    target="_blank"
                    rel="noreferrer"
                >
                    NonPublic Terms and Conditions
                </a>
            </li>
            <li>
                <a
                    href={PRIVACY_STATEMENT}
                    target="_blank"
                    rel="noreferrer"
                >
                    Privacy Policy
                </a>
            </li>
        </ul>
    )
}

export default DealLegalDocuments