import {Amplify} from "aws-amplify"
import {getEnvironment} from "../environment/environment.util"
import {Environment} from "../environment/environment.enum"

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: getEnvironment() === Environment.PRODUCTION
                ? "ap-southeast-1_gqya9KaV2"
                : "ap-southeast-1_AdM19GD8w",
            userPoolClientId: getEnvironment() === Environment.PRODUCTION
                ? "3bbtl2tkq0vm9m04b5n92jar5t"
                : "4lkq8ok54qp6n8k9p837a1mrng",
            loginWith: {
                oauth: {
                    domain: getEnvironment() === Environment.PRODUCTION
                        ? "nonpublic-production.auth.ap-southeast-1.amazoncognito.com"
                        : "nonpublic-integration.auth.ap-southeast-1.amazoncognito.com",
                    scopes: [
                        "email",
                        "openid",
                        "profile",
                        "aws.cognito.signin.user.admin"
                    ],
                    redirectSignIn: [window.location.origin],
                    redirectSignOut: [window.location.origin],
                    responseType: "token"
                }
            }
        }
    }
})