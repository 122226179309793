export type State = "LOADING"
    | "NONE"
    | ErrorState

export type ErrorState = "ERROR"
    | "ERROR_TOO_MANY_FAILED_ATTEMPTS"
    | "ERROR_USER_NOT_CONFIRMED"
    | "ERROR_USER_DOES_NOT_EXIST"
    | "ERROR_INCORRECT_USERNAME_OR_PASSWORD"
    | "ERROR_PASSWORD_RESET_REQUIRED"

export function isErrorState(state: State): boolean {
    return state === "ERROR"
        || state === "ERROR_TOO_MANY_FAILED_ATTEMPTS"
        || state === "ERROR_USER_NOT_CONFIRMED"
        || state === "ERROR_USER_DOES_NOT_EXIST"
        || state === "ERROR_INCORRECT_USERNAME_OR_PASSWORD"
        || state === "ERROR_PASSWORD_RESET_REQUIRED"
}