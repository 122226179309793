import React, {FunctionComponent, ReactNode} from "react"
import Header from "../header/header.component"
import {RouteData} from "../routing/route.type"
import {DealOnboardingDependentWithInvestmentType} from "../../domain/deal/onboarding-dependent.type"
import styles from "./layout.module.sass"
import {InvestorOnboardingType} from "../../domain/investor/onboarding/onboarding.type"
import Footer from "../footer/footer.component"

type LayoutProps = {
    children: ReactNode
    onboarding: InvestorOnboardingType
    openDeals: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
    whiteBackground: boolean
}

const Layout: FunctionComponent<LayoutProps> = ({
    children,
    onboarding,
    openDeals,
    relevantRoutes,
    whiteBackground
}) => {
    return (
        <>
            <Header
                onboarding={onboarding}
                openDeals={openDeals}
                relevantRoutes={relevantRoutes}
            />
            <div className={`${styles.layout} ${whiteBackground ? styles.whiteBackground : ""}`}>
                <div className={styles.layoutInner}>
                    {children}
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Layout