import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const BookmarkIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
        >
            <path
                className={fillClass}
                d="M11.5 2h-7a1 1 0 0 0-1 1v11a.5.5 0 0 0 .258.437.5.5 0 0 0 .507-.013L8 12.089l3.736 2.334a.5.5 0 0 0 .507.013A.5.5 0 0 0 12.5 14V3a1 1 0 0 0-1-1zm0 1v7.098L8.264 8.076a.5.5 0 0 0-.53 0L4.5 10.098V3h7zm-3.236 8.076a.5.5 0 0 0-.53 0L4.5 13.098v-1.821L8 9.089l3.5 2.188v1.821l-3.236-2.022z"
            />
        </svg>
    )
}

export default BookmarkIcon