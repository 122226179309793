import React, {FunctionComponent} from "react"
import {FilterOptionsType} from "../options/options.type"
import FilterSection from "../options/section/section.component"

type CategoryFilterProps = {
    options: FilterOptionsType
    updateOptions: (options: FilterOptionsType) => void
}

const CategoryFilter: FunctionComponent<CategoryFilterProps> = ({
    options,
    updateOptions
}) => {
    if (options.categories.length > 0) {
        return (
            <FilterSection
                label="Category"
                type="CATEGORY"
                options={options}
                updateOptions={updateOptions}
            />
        )
    }
    return <></>
}

export default CategoryFilter