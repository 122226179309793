/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryOpenApi } from './CountryOpenApi';
import {
    CountryOpenApiFromJSON,
    CountryOpenApiFromJSONTyped,
    CountryOpenApiToJSON,
} from './CountryOpenApi';

/**
 * 
 * @export
 * @interface InvestorFiltersOpenApi
 */
export interface InvestorFiltersOpenApi {
    /**
     * 
     * @type {string}
     * @memberof InvestorFiltersOpenApi
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorFiltersOpenApi
     */
    aiStatusDeclared?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvestorFiltersOpenApi
     */
    city?: string;
    /**
     * 
     * @type {CountryOpenApi}
     * @memberof InvestorFiltersOpenApi
     */
    country?: CountryOpenApi;
    /**
     * 
     * @type {string}
     * @memberof InvestorFiltersOpenApi
     */
    dealGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestorFiltersOpenApi
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestorFiltersOpenApi
     */
    firstName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorFiltersOpenApi
     */
    imported?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvestorFiltersOpenApi
     */
    investorGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvestorFiltersOpenApi
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvestorFiltersOpenApi
     */
    started?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvestorFiltersOpenApi
     */
    userId?: string;
}

/**
 * Check if a given object implements the InvestorFiltersOpenApi interface.
 */
export function instanceOfInvestorFiltersOpenApi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvestorFiltersOpenApiFromJSON(json: any): InvestorFiltersOpenApi {
    return InvestorFiltersOpenApiFromJSONTyped(json, false);
}

export function InvestorFiltersOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestorFiltersOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'aiStatusDeclared': !exists(json, 'aiStatusDeclared') ? undefined : json['aiStatusDeclared'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : CountryOpenApiFromJSON(json['country']),
        'dealGroupName': !exists(json, 'dealGroupName') ? undefined : json['dealGroupName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'imported': !exists(json, 'imported') ? undefined : json['imported'],
        'investorGroupName': !exists(json, 'investorGroupName') ? undefined : json['investorGroupName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'started': !exists(json, 'started') ? undefined : json['started'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function InvestorFiltersOpenApiToJSON(value?: InvestorFiltersOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'aiStatusDeclared': value.aiStatusDeclared,
        'city': value.city,
        'country': CountryOpenApiToJSON(value.country),
        'dealGroupName': value.dealGroupName,
        'email': value.email,
        'firstName': value.firstName,
        'imported': value.imported,
        'investorGroupName': value.investorGroupName,
        'lastName': value.lastName,
        'started': value.started,
        'userId': value.userId,
    };
}

