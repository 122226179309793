import React, {FunctionComponent, ReactElement} from "react"
import styles from "./function.module.sass"

type FileUploadMobileProps = {
    label: string
    labelFor?: string
    icon: ReactElement
    onClick: () => void
}

const FileUploadMobileFunction: FunctionComponent<FileUploadMobileProps> = ({ label, labelFor, icon, onClick }) => {
    return (
        <label
            className={styles.function}
            onClick={onClick}
            htmlFor={labelFor}
        >
            <div>
                <div>
                    {icon}
                </div>
            </div>
            <div className={styles.functionLabel}>
                <div>
                    {label}
                </div>
            </div>
        </label>
    )
}

export default FileUploadMobileFunction