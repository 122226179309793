import React, {FunctionComponent} from "react"
import CheckIcon from "../../../../../tech/icon/check.component"
import {format} from "../format.util"
import {CurrencyOpenApi, DealOutcomeOpenApi} from "../../../../../generated"
import styles from "./note.module.sass"
import Alert from "../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../tech/alert/type.enum"

type DealCommitmentNoteProps = {
    dealCurrency: CurrencyOpenApi
    investedAmount: number
    minTarget: number
    maxTarget: number
    outcome: DealOutcomeOpenApi
    hasInvestment: boolean
}

const DealCommitmentNote: FunctionComponent<DealCommitmentNoteProps> = ({
    dealCurrency,
    investedAmount,
    minTarget,
    maxTarget,
    outcome,
    hasInvestment
}) => {
    const isLive = investedAmount >= minTarget
    return (
        <div className={styles.note}>
            {isLive && (
                <div className={styles.live}>
                    <div className={styles.dealIsLive}>
                        {outcome === "NONE" && (
                            <>
                                <div>
                                    <CheckIcon
                                        width={16}
                                        height={16}
                                        fillClass="fill-icon-success"
                                    />
                                </div>
                                <div>
                                    Deal is live
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.max}>
                        <div>
                            {format(maxTarget, dealCurrency)} Max
                        </div>
                    </div>
                </div>
            )}
            {!isLive && !hasInvestment && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.INFO}
                        text={`Deal will go live when ${format(minTarget, dealCurrency)} is committed`}
                        size="SMALL"
                    />
                </div>
            )}
        </div>
    )
}

export default DealCommitmentNote