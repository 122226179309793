import React, {FunctionComponent} from "react"
import ProfileTile from "../tile/tile.component"
import {InvestorOnboardingType} from "../../onboarding/onboarding.type"
import {ButtonSize} from "../../../../tech/button/size.enum"
import Button from "../../../../tech/button/button.component"
import {ButtonState} from "../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import {useNavigate} from "react-router-dom"
import {ONBOARDING} from "../../../../paths"
import type {InvestorOnboardingStageTypeOpenApi} from "../../../../generated"
import ProfileOnboardingStatus from "./status/status.component"
import styles from "./onboarding.module.sass"
import ProfileOnboardingChecklist from "./checklist/checklist.component"

type ProfileOnboardingProps = {
    onboarding: InvestorOnboardingType
}

const ProfileOnboarding: FunctionComponent<ProfileOnboardingProps> = ({ onboarding }) => {
    const navigate = useNavigate()
    return (
        <ProfileTile
            title="Onboarding"
            collapsible={false}
            children={
                <>
                    <div className={styles.description}>
                        {evaluateDescription(onboarding.stage.type)}
                    </div>
                    <ProfileOnboardingStatus onboarding={onboarding}/>
                    <ProfileOnboardingChecklist onboarding={onboarding}/>
                    {showButton(onboarding.stage.type) && (
                        <div className={styles.button}>
                            <Button
                                label="Complete Onboarding"
                                type="button"
                                size={ButtonSize.SMALL}
                                state={ButtonState.ACTIVE}
                                style={ButtonStyle.PRIMARY}
                                onClick={() => navigate(ONBOARDING)}
                                withArrow={true}
                            />
                        </div>
                    )}
                </>
            }
        />
    )
}

export default ProfileOnboarding

function showButton(stageType: InvestorOnboardingStageTypeOpenApi): boolean {
    return stageType === "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED"
        || stageType === "GENERAL_DATA"
        || stageType === "KYC_DOCUMENT_UPLOAD_NECESSARY"
}

function evaluateDescription(stageType: InvestorOnboardingStageTypeOpenApi): string {
    switch (stageType) {
        case "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED":
            return "Please confirm that you are an accredited investor."
        case "GENERAL_DATA":
            return "Please provide us your name, address and contact details."
        case "KYC_DOCUMENT_UPLOAD_NECESSARY":
            return "Your KYC documents are not complete at the moment or we need you to upload an updated version of some documents."
        case "KYC_WAITING_FOR_REVIEW":
            return "We are currently reviewing your documents."
        case "ACKNOWLEDGE_SUCCESS":
        case "NONE":
            return "We have received all the information needed for your onboarding. Thank you!"
    }
}