import {UseQueryResult} from "react-query"

export function allDataAvailable(queryResults: UseQueryResult[]): boolean {
    return queryResults.every(r => r.data !== undefined)
}

export function isErroneous(queryResult: UseQueryResult | UseQueryResult[]) {
    return Array.isArray(queryResult)
        ? queryResult.some(r => r.error)
        : queryResult.error
}

export function showLoadingAnimation(queryResult: UseQueryResult | UseQueryResult[]): boolean {
    return Array.isArray(queryResult)
        ? showLoadingAnimationArr(queryResult)
        : queryResult.data === undefined && queryResult.isFetching && !queryResult.error
}

function showLoadingAnimationArr(queryResults: UseQueryResult[]): boolean {
    return !allDataAvailable(queryResults)
        && queryResults.map(r => r.isFetching).some(fetching => fetching)
        && queryResults.every(r => !r.error)
}