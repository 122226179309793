import React, {FunctionComponent, useState} from "react"
import {FilterType} from "../../../tech/filter/filter.type"
import {DealSortType} from "../sort/sort.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import DealList from "../list.component"
import {useQuery, useQueryClient} from "react-query"
import {QUERY__DEALS} from "../deal.query"
import {isErroneous, showLoadingAnimation} from "../../../tech/query/query.util"
import {QUERY__EXCLUSIVE_DEALS__DEALS} from "./exclusive-deals.config"
import {useApp} from "../../../tech/app/context/app.context"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"

type ExclusiveDealsProps = {
    onboarding: InvestorOnboardingType
}

const ExclusiveDeals: FunctionComponent<ExclusiveDealsProps> = ({ onboarding }) => {
    const app = useApp()
    const queryClient = useQueryClient()
    const [filterTypes, setFilterTypes] = useState<FilterType[]>([])
    const [sortType, setSortType] = useState<DealSortType>()

    const updateFilterTypes = (updatedFilterTypes: FilterType[]) => setFilterTypes(updatedFilterTypes)
    const updateSortType = (updatedSortType: DealSortType) => setSortType(updatedSortType)

    const result = useQuery(QUERY__DEALS(
        app.fetchClient,
        queryClient,
        QUERY__EXCLUSIVE_DEALS__DEALS
    ))

    return (
        <>
            {showLoadingAnimation(result) && <LoadingDots/>}
            {result.data && (
                <DealList
                    dealsWithInvestment={result.data}
                    title="Exclusive Deals"
                    filterTypes={filterTypes}
                    updateFilterTypes={updateFilterTypes}
                    sortType={sortType}
                    updateSortType={updateSortType}
                    emptyState={{
                        imageSrc: "/empty-state/empty-state-exclusive-deals.svg",
                        textLine1: "No exclusive deals available",
                        textLine2: "Browse non-exclusive deals"
                    }}
                    onboarding={onboarding}
                />
            )}
            {isErroneous(result) && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load deals."
                />
            )}
        </>
    )
}

export default ExclusiveDeals