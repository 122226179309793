import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const PaperPlaneIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M13.992 7.125l-10.5-5.993a1 1 0 0 0-1.131.105 1 1 0 0 0-.302 1.095l1.938 5.654-1.937 5.679a1 1 0 0 0 .126.911A1 1 0 0 0 3 15a1.01 1.01 0 0 0 .495-.131l10.494-6.003a1 1 0 0 0 .511-.871 1 1 0 0 0-.508-.872l-.001.003zM3 14v-.006L4.884 8.5H8.5A.5.5 0 0 0 9 8a.5.5 0 0 0-.5-.5H4.889L3.004 2.007 3 2l10.5 5.989L3 14z"
            />
        </svg>
    )
}

export default PaperPlaneIcon