import {matchPath} from "react-router-dom"
import {InvestorOnboardingType} from "../../../domain/investor/onboarding/onboarding.type"
import {ONBOARDING, PREMIUM_MEMBERSHIP, PROFILE} from "../../../paths"

export function shouldRedirectToOnboarding(
    onboarding: InvestorOnboardingType,
    pathname: string
): boolean {
    return (onboarding.stage.type === "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED" || onboarding.stage.type === "ACKNOWLEDGE_SUCCESS")
        && !pathname.includes(ONBOARDING.replaceAll("/", ""))
        && pathname !== PROFILE
}

export function isWhiteBackground(pathname: string): boolean {
    return matchPath(
        { path: PREMIUM_MEMBERSHIP },
        pathname
    ) !== null
}