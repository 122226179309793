import {FunctionComponent} from "react"
import {FormRowType} from "./row.type"
import styles from "./row.module.sass"

type FormRowProps = {
    content: FormRowType
}

const FormRow: FunctionComponent<FormRowProps> = ({ content }) => {
    switch (content.type) {
        case "one":
            return (
                <div className={styles.row}>
                    {content.element}
                </div>
            )
        case "two":
            return (
                <div className={styles.rowTwoColumns}>
                    <div>
                        {content.firstElement}
                    </div>
                    <div>
                        {content.secondElement}
                    </div>
                </div>
            )
    }
}

export default FormRow