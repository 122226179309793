import React, {FunctionComponent} from "react"
import Button from "../../button/button.component"
import {ButtonState} from "../../button/state.enum"
import {ButtonSize} from "../../button/size.enum"
import {ButtonStyle} from "../../button/style.enum"
import styles from "./submit.module.sass"

type SubmitButtonProps = {
    label: string,
    disabled?: boolean
    position?: "FULL_WIDTH" | "RIGHT"
    size?: ButtonSize
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
    label,
    disabled,
    position = "FULL_WIDTH",
    size = ButtonSize.LARGE
}) => {
    return (
        <div className={`${styles.submitButton} ${position === "RIGHT" ? styles.right : ""}`}>
            <Button
                label={label}
                type="submit"
                size={size}
                state={disabled ? ButtonState.INACTIVE : ButtonState.ACTIVE}
                style={ButtonStyle.PRIMARY}
                withArrow={true}
                fullWidth={true}
                disabled={disabled}
            />
        </div>
    )
}

export default SubmitButton