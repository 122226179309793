import React, {FunctionComponent} from "react"
import {DealSortType} from "./sort.type"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import SortAscendingIcon from "../../../tech/icon/sort-ascending.component"
import {ButtonSize} from "../../../tech/button/size.enum"
import {ButtonState} from "../../../tech/button/state.enum"
import DealSortOptions from "./options/options.component"
import {getStringRepresentation} from "./sort.util"
import styles from "./sort.module.sass"

type SortProps = {
    sortType?: DealSortType
    updateSortType: (updatedSortType: DealSortType) => void
    opened: boolean
    updateOpened: (opened: boolean) => void
    closeFilter: () => void
}

const DealSort: FunctionComponent<SortProps> = ({
    sortType,
    updateSortType,
    opened,
    updateOpened,
    closeFilter
}) => {
    const toggleOpened = () => {
        if (!opened) closeFilter()
        updateOpened(!opened)
    }

    const updateSortTypeInternal = (type: DealSortType) => {
        toggleOpened()
        updateSortType(type)
    }

    return (
        <div className={styles.sort}>
            <Button
                label={sortType ? `Sorted by ${getStringRepresentation(sortType)}` : "Sort"}
                type="button"
                size={ButtonSize.SMALL}
                state={sortType ? ButtonState.ACTIVE : ButtonState.INACTIVE}
                style={sortType ? ButtonStyle.PRIMARY : ButtonStyle.SECONDARY}
                icon={
                    <SortAscendingIcon
                        width={16}
                        height={16}
                        fillClass="fill-icon-inactive"
                    />
                }
                onClick={toggleOpened}
            />
            <DealSortOptions
                currentSortType={sortType}
                updateSortType={updateSortTypeInternal}
                opened={opened}
                close={() => updateOpened(false)}
            />
        </div>
    )
}

export default DealSort