import React, {createRef, FunctionComponent, ReactElement} from "react"
import FileUploadMobileFunction from "../../function.component"
import styles from "./input.module.sass"

type FileUploadInputFunctionProps = {
    label: string
    icon: ReactElement
    type: "FILE" | "IMAGE"
    uploadFile: (file: File) => void
}

const FileUploadInput: FunctionComponent<FileUploadInputFunctionProps> = ({ label, icon, type, uploadFile }) => {
    const inputRef = createRef<HTMLInputElement>()

    const id = Math.floor(Math.random() * 99999) + "-" + type

    const onUpload = () => {
        uploadFile(inputRef.current!.files![0])
    }

    return (
        <>
            <FileUploadMobileFunction
                label={label}
                icon={icon}
                onClick={() => {}}
                labelFor={id}
            />
            <div className={styles.input}>
                <input
                    type="file"
                    id={id}
                    accept={getAccept(type)}
                    ref={inputRef}
                    onInput={onUpload}
                />
            </div>
        </>
    )
}

export default FileUploadInput

function getAccept(type: "FILE" | "IMAGE"): string {
    switch (type) {
        case "FILE":
            return "image/png,image/jpeg,application/pdf"
        case "IMAGE":
            return "image/png,image/jpeg"
    }
}