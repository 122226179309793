import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const FunnelIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M14.413 3.096A.99.99 0 0 0 13.5 2.5h-11a1 1 0 0 0-.913.596 1 1 0 0 0 .175 1.076l.005.006L6 8.698V13.5a1 1 0 0 0 .528.882 1 1 0 0 0 1.027-.049l2-1.334a1 1 0 0 0 .446-.832V8.698l4.234-4.52.005-.006c.132-.143.218-.321.249-.512a.99.99 0 0 0-.075-.565zM9.271 8.017c-.174.184-.271.428-.271.681v3.468L7 13.5V8.698c.001-.254-.096-.499-.27-.684L2.5 3.5h11L9.271 8.017z"
            />
        </svg>
    )
}

export default FunnelIcon