import React, {FunctionComponent, useState} from "react"
import {useNavigate} from "react-router-dom"
import XIcon from "../icon/x.component"
import LogoText from "../logo/text.component"
import Menu from "./menu/menu.component"
import NineDotsIcon from "../icon/nine-dots.component"
import DesktopMenu from "./menu/desktop/desktop-menu.component"
import {RouteData} from "../routing/route.type"
import {DealOnboardingDependentWithInvestmentType} from "../../domain/deal/onboarding-dependent.type"
import {InvestorOnboardingType} from "../../domain/investor/onboarding/onboarding.type"
import {evaluateEntryPath} from "../routing/routing.util"
import styles from "./header.module.sass"

type HeaderProps = {
    onboarding: InvestorOnboardingType
    openDeals: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
}

const Header: FunctionComponent<HeaderProps> = ({
    onboarding,
    openDeals,
    relevantRoutes
}) => {
    const navigate = useNavigate()
    const [showMenu, setShowMenu] = useState<boolean>(false)
    return (
        <header className={styles.header}>
            <div className={styles.headerInner}>
                <div className={styles.logo}>
                    <div onClick={() => {
                        navigate(evaluateEntryPath(openDeals, onboarding))
                        setShowMenu(false)
                    }}>
                        <div className={styles.defaultLogo}>
                            <LogoText fillColor="#050724"/>
                        </div>
                    </div>
                </div>
                <div className={showMenu ? styles.menu : styles.menuHidden}>
                <Menu
                        closeMenu={() => setShowMenu(false)}
                        openDeals={openDeals}
                        relevantRoutes={relevantRoutes}
                    />
                </div>
                <div
                    className={styles.toggle}
                    onClick={() => setShowMenu(!showMenu)}
                >
                    {showMenu ? (
                        <XIcon
                            width={35}
                            height={35}
                            fillClass="fill-icon-theme"
                        />
                    ) : (
                        <NineDotsIcon
                            width={35}
                            height={35}
                            fillClass="fill-icon-theme"
                        />
                    )}
                </div>
                <DesktopMenu/>
            </div>
        </header>
    )
}

export default Header