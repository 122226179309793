import React, {FunctionComponent, useState} from "react"
import {IndividualInvestmentType} from "../individual-investment.type"
import {IndividualInvestmentPopupStateType} from "../popup/popup.type"
import Head from "../../../../tech/head/head.component"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import Button from "../../../../tech/button/button.component"
import {ButtonState} from "../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import {ButtonSize} from "../../../../tech/button/size.enum"
import styles from "./delete.module.sass"
import {useApp} from "../../../../tech/app/context/app.context"

type IndividualInvestmentDeleteProps = {
    investment: IndividualInvestmentType
    invalidateDashboardQuery: () => void
    updatePopupState: (newState: IndividualInvestmentPopupStateType) => void
}

const IndividualInvestmentDelete: FunctionComponent<IndividualInvestmentDeleteProps> = ({
    investment,
    invalidateDashboardQuery,
    updatePopupState
}) => {
    const app = useApp()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR">("NONE")

    const deleteIndividualInvestment = async () => {
        setState("LOADING")
        try {
            await app.fetchClient.individualInvestmentApi.delete(investment.id!)
            invalidateDashboardQuery()
            updatePopupState({ type: "CLOSED" })
        }
        catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <div>
            <Head title="Delete individual investment"/>
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Deletion failed."
                />
            )}
            {state === "LOADING" ? (
                <LoadingDots/>
            ) : (
                <div className={styles.delete}>
                    <div className={styles.text}>
                        Do you really want to delete the individual investment <strong>{investment.name}</strong> from your investor dashboard permanently?
                    </div>
                    <div className={styles.buttons}>
                        <div>
                            <Button
                                label="Abort"
                                type="button"
                                size={ButtonSize.LARGE}
                                state={ButtonState.ACTIVE}
                                style={ButtonStyle.SECONDARY}
                                fullWidth={true}
                                onClick={() => updatePopupState({
                                    type: "EDIT",
                                    investment
                                })}
                            />
                        </div>
                        <div>
                            <Button
                                label="Delete permanently"
                                type="button"
                                size={ButtonSize.LARGE}
                                state={ButtonState.ACTIVE}
                                style={ButtonStyle.PRIMARY}
                                fullWidth={true}
                                onClick={deleteIndividualInvestment}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default IndividualInvestmentDelete