/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StripePaymentIntentStatusOpenApi = {
    Canceled: 'CANCELED',
    Processing: 'PROCESSING',
    RequiresAction: 'REQUIRES_ACTION',
    RequiresCapture: 'REQUIRES_CAPTURE',
    RequiresConfirmation: 'REQUIRES_CONFIRMATION',
    RequiresPaymentMethod: 'REQUIRES_PAYMENT_METHOD',
    Succeeded: 'SUCCEEDED'
} as const;
export type StripePaymentIntentStatusOpenApi = typeof StripePaymentIntentStatusOpenApi[keyof typeof StripePaymentIntentStatusOpenApi];


export function StripePaymentIntentStatusOpenApiFromJSON(json: any): StripePaymentIntentStatusOpenApi {
    return StripePaymentIntentStatusOpenApiFromJSONTyped(json, false);
}

export function StripePaymentIntentStatusOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripePaymentIntentStatusOpenApi {
    return json as StripePaymentIntentStatusOpenApi;
}

export function StripePaymentIntentStatusOpenApiToJSON(value?: StripePaymentIntentStatusOpenApi | null): any {
    return value as any;
}

