import React, {FunctionComponent} from "react"
import {useNavigate} from "react-router-dom"
import {DEAL_INVEST, ONBOARDING} from "../../../../paths"
import {DealType} from "../../deal.type"
import {InvestmentType} from "../../../investment/investment.type"
import Button from "../../../../tech/button/button.component"
import {ButtonSize} from "../../../../tech/button/size.enum"
import {ButtonState} from "../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import {evaluateState} from "./invest-button.util"
import ButtonWrapper from "../../../../tech/button/wrapper/button-wrapper.component"
import styles from "./invest-button.module.sass"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import {InvestorOnboardingType} from "../../../investor/onboarding/onboarding.type"

type DealInvestButtonProps = {
    deal: DealOnboardingDependentType
    investment?: InvestmentType
    onboarding?: InvestorOnboardingType
    position: "MOBILE_ACTION" | "SUMMARY"
}

const DealInvestButton: FunctionComponent<DealInvestButtonProps> = ({
    deal,
    investment,
    onboarding,
    position
}) => {
    if (!(deal instanceof DealType)) {
        return <></>
    }

    const positionClass = getPositionClass(position)
    const state = evaluateState(deal, investment)

    if (state === "INVEST") {
        return (
            <div className={`${styles.invest} ${positionClass}`}>
                <div className={styles.mobile}>
                    <ButtonWrapper rightOnDesktop={false}>
                        <InvestButton
                            dealId={deal.id!}
                            onboarding={onboarding}
                        />
                    </ButtonWrapper>
                </div>
                <div className={styles.desktop}>
                    <InvestButton
                        dealId={deal.id!}
                        onboarding={onboarding}
                    />
                </div>
            </div>
        )
    }

    if (state === "CLOSED") {
        return (
            <div className={`${styles.closed} ${positionClass}`}>
                <Alert
                    type={AlertType.WARNING}
                    text="This deal is closed for further investments."
                />
            </div>
        )
    }

    return <></>
}

export default DealInvestButton

type InvestButtonProps = {
    dealId: string
    onboarding?: InvestorOnboardingType
}

const InvestButton: FunctionComponent<InvestButtonProps> = ({ dealId, onboarding }) => {
    const navigate = useNavigate()
    return (
        <Button
            label={onboarding?.blocked ? "COMPLETE ONBOARDING TO INVEST" : "INVEST"}
            type="button"
            size={ButtonSize.LARGE}
            state={ButtonState.ACTIVE}
            style={ButtonStyle.PRIMARY}
            onClick={() => onboarding?.blocked ? navigate(ONBOARDING) : navigate(DEAL_INVEST(dealId))}
            fullWidth={true}
            withArrow={true}
            withWordWrap={true}
        />
    )
}

function getPositionClass(position: "MOBILE_ACTION" | "SUMMARY"): string {
    switch (position) {
        case "MOBILE_ACTION":
            return styles.positionMobileAction
        case "SUMMARY":
            return styles.positionSummary
    }
}