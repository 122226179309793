import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const SortAscendingIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <path
                className={fillClass}
                d="M8 8a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h4.5A.5.5 0 0 1 8 8zM3 4.5h8.5A.5.5 0 0 0 12 4a.5.5 0 0 0-.5-.5H3a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5zm3.5 7H3a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5h3.5A.5.5 0 0 0 7 12a.5.5 0 0 0-.5-.5zm7.854-1.354c-.046-.046-.102-.083-.162-.109a.5.5 0 0 0-.383 0c-.061.025-.116.062-.162.109L12 11.793V7a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5v4.793l-1.646-1.647a.5.5 0 0 0-.707 0 .5.5 0 0 0 0 .708l2.5 2.5c.046.046.102.083.162.109a.5.5 0 0 0 .383 0c.061-.025.116-.062.162-.109l2.5-2.5c.046-.046.083-.102.109-.162a.5.5 0 0 0 0-.383c-.025-.061-.062-.116-.109-.162z"
            />
        </svg>
    )
}

export default SortAscendingIcon