import React, {FunctionComponent} from "react"
import styles from "./head.module.sass"

type HeadProps = {
    title: string
}

const AuthHead: FunctionComponent<HeadProps> = ({ title }) => {
    return (
        <div className={styles.head}>
            {title}
        </div>
    )
}

export default AuthHead