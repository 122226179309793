import {SubscriptionOpenApi} from "../../generated"
import {SubscriptionType} from "./subscription.type"
import {mapOpenApiToCancellation} from "./cancellation/cancellation.mapper"

export function mapOpenApiToSubscription(subscription: SubscriptionOpenApi): SubscriptionType {
    return {
        type: subscription.type,
        price: subscription.price,
        paymentInterval: subscription.paymentInterval,
        cancellation: subscription.cancellation && mapOpenApiToCancellation(subscription.cancellation)
    }
}