import React, {FunctionComponent} from "react"
import {DealType} from "../../deal/deal.type"
import DealHead from "../../deal/single/head/head.component"
import DealCommitment from "../../deal/card/commitment/commitment.component"
import {formatAmountWithCurrency} from "../../../tech/format/amount.util"
import DealFees from "../../deal/single/summary/fees/fees.component"
import {InvestmentType} from "../investment.type"
import styles from "./summary.module.sass"

type InvestmentDealSummaryProps = {
    amountCommitting: number
    deal: DealType
    investment?: InvestmentType
}

const InvestmentDealSummary: FunctionComponent<InvestmentDealSummaryProps> = ({
    amountCommitting,
    deal,
    investment
}) => {
    return (
        <div className={styles.summary}>
            <DealHead deal={deal}/>
            <div className={styles.middle}>
                <DealCommitment
                    deal={deal}
                    investment={investment}
                    blurredPosition="SINGLE"
                />
            </div>
            <div className={styles.fees}>
                <DealFees deal={deal}/>
            </div>
            <div className={styles.bottom}>
                <div>
                    Amount Committing
                </div>
                <div>
                    {formatAmountWithCurrency(amountCommitting, deal._currency) || "-"}
                </div>
            </div>
        </div>
    )
}

export default InvestmentDealSummary