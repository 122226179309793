import {PrefetchType} from "./prefetch.type"
import {DEALS, EXCLUSIVE_DEALS, FAVOURITE_DEALS, INVESTOR_DASHBOARD, PENDING_INVESTMENTS} from "../../paths"
import {QUERY__DEAL_OVERVIEW__DEALS, QUERY__DEAL_OVERVIEW__INVESTOR_DASHBOARD} from "../../domain/deal/overview.config"
import {QUERY__DEALS} from "../../domain/deal/deal.query"
import {QUERY__INVESTMENTS_PENDING} from "../../domain/investment/investment.query"
import {QUERY__PENDING_INVESTMENT_OVERVIEW__PENDING_INVESTMENTS} from "../../domain/investment/pending/overview.config"
import {QUERY__INVESTOR_DASHBOARD} from "../../domain/investor/dashboard/dashboard.query"
import {QUERY__INVESTOR_DASHBOARD__INVESTOR_DASHBOARD} from "../../domain/investor/dashboard/dashboard.config"
import {QUERY__EXCLUSIVE_DEALS__DEALS} from "../../domain/deal/exclusive/exclusive-deals.config"
import {QUERY__FAVOURITE_DEALS__DEALS} from "../../domain/deal/favourite/favourite-deals.config"

export const prefetchConfig: PrefetchType<any>[] = [
    {
        route: DEALS,
        queries: [
            {
                func: {
                    type: "WITH",
                    func: QUERY__DEALS
                },
                keyWithParameters: QUERY__DEAL_OVERVIEW__DEALS
            },
            {
                func: {
                    type: "WITHOUT",
                    func: QUERY__INVESTOR_DASHBOARD
                },
                keyWithParameters: QUERY__DEAL_OVERVIEW__INVESTOR_DASHBOARD
            },
        ]
    },
    {
        route: EXCLUSIVE_DEALS,
        queries: [
            {
                func: {
                    type: "WITH",
                    func: QUERY__DEALS
                },
                keyWithParameters: QUERY__EXCLUSIVE_DEALS__DEALS
            }
        ]
    },
    {
        route: FAVOURITE_DEALS,
        queries: [
            {
                func: {
                    type: "WITH",
                    func: QUERY__DEALS
                },
                keyWithParameters: QUERY__FAVOURITE_DEALS__DEALS
            }
        ]
    },
    {
        route: INVESTOR_DASHBOARD,
        queries: [
            {
                func: {
                    type: "WITHOUT",
                    func: QUERY__INVESTOR_DASHBOARD
                },
                keyWithParameters: QUERY__INVESTOR_DASHBOARD__INVESTOR_DASHBOARD
            },
        ]
    },
    {
        route: PENDING_INVESTMENTS,
        queries: [
            {
                func: {
                    type: "WITHOUT",
                    func: QUERY__INVESTMENTS_PENDING
                },
                key: QUERY__PENDING_INVESTMENT_OVERVIEW__PENDING_INVESTMENTS
            },
        ]
    },
]