import React, {FunctionComponent} from "react"
import {NavLink} from "react-router-dom"
import MenuPersonIcon from "../person-icon/person-icon.component"
import {HELP, PROFILE} from "../../../../paths"
import styles from "./desktop-menu.module.sass"
import QuestionCircleIcon from "../../../icon/question-circle.component"

const DesktopMenu: FunctionComponent = () => {
    return (
        <div className={styles.menu}>
            <NavLink
                to={HELP}
                className={({ isActive }) => getHelpClassName(isActive)}
            >
                <div className={styles.help}>
                    <div>
                        <QuestionCircleIcon
                            width={24}
                            height={24}
                            fillClass="fill-icon-inactive"
                        />
                    </div>
                    <div>
                        Help
                    </div>
                </div>
            </NavLink>
            <NavLink to={PROFILE}>
                <MenuPersonIcon/>
            </NavLink>
        </div>
    )
}

export default DesktopMenu

function getHelpClassName(active: boolean): string {
    return active
        ? `${styles.helpLink} ${styles.active}`
        : styles.helpLink
}