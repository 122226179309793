import {CalendarEvent} from "./event.type"

export function isSafariMobile(): boolean {
    const userAgent = window.navigator.userAgent
    const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
    const webkit = !!userAgent.match(/WebKit/i);

    return iOS && webkit && !userAgent.match(/CriOS/i);
}

export function buildICalUrl(event: CalendarEvent, useDataURL: boolean): string {
    const body: string[] = []

    body.push(`DTSTART:${formatDate(event.start)}`)
    body.push(`SUMMARY:${event.title}`)

    event.url && body.push(`URL:${event.url}`)
    event.end && body.push(`DTEND:${formatDate(event.end)}`)
    event.location && body.push(`LOCATION:${event.location}`)

    const url = [
        "BEGIN:VCALENDAR",
        "VERSION:2.0",
        "BEGIN:VEVENT",
        body.join("\n"),
        "END:VEVENT",
        "END:VCALENDAR"
    ].join("\n")

    return useDataURL
        ? encodeURI(`data:text/calendar;charset=utf8,${url}`)
        : url
}

function formatDate(date: Date): string {
    return [
        date.getUTCFullYear(),
        pad(date.getUTCMonth() + 1),
        pad(date.getUTCDate()),
        "T",
        pad(date.getUTCHours()),
        pad(date.getUTCMinutes()) + "00Z"
    ].join("")
}

function pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`
}