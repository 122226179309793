import {InputFieldProps} from "../props.type"
import React, {FunctionComponent} from "react"
import FormError from "../../error/error.component"
import {hasError} from "../../error/error.util"
import FieldLabelText from "../label-text.component"
import styles from "./email.module.sass"

type EmailFieldProps = InputFieldProps & {
    prefill?: string
}

const EmailField: FunctionComponent<EmailFieldProps> = ({
    placeholder,
    errors,
    autoComplete,
    prefill,
    reactHookFormRegister,
    label,
    disabled = false
}) => {
    return (
        <label className={styles.label}>
            <FieldLabelText label={label}/>
            <input
                type="email"
                autoComplete={autoComplete}
                placeholder={placeholder}
                defaultValue={prefill}
                disabled={disabled}
                className={hasError(reactHookFormRegister.name, errors) ? styles.withError : undefined}
                {...reactHookFormRegister.register(
                    reactHookFormRegister.name,
                    {
                        // @ts-ignore
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email."
                        },
                        ...reactHookFormRegister.options
                    }
                )}
            />
            <FormError
                field={reactHookFormRegister.name}
                errors={errors}
            />
        </label>
    )
}

export default EmailField