import React, {FunctionComponent} from "react"
import {TooltipDataPointType} from "./tooltip.type"
import {formatDate} from "../../../format/datetime.util"
import styles from "./tooltip.module.sass"
import {formatAmountWithCurrency} from "../../../format/amount.util"
import {CurrencyOpenApi} from "../../../../generated"

type LineChartTooltipProps = {
    currency: CurrencyOpenApi
    dataPoints: TooltipDataPointType[]
}

const LineChartTooltip: FunctionComponent<LineChartTooltipProps> = ({
    currency,
    dataPoints
}) => {
    const rand = Math.random()
    return (
        <div className={styles.tooltip}>
            <strong className={styles.date}>
                {formatDate(dataPoints[0].dataPoint.date.value)}
            </strong>
            {dataPoints.map(p => (
                <div
                    className={styles.line}
                    key={Math.random()}
                >
                    <strong style={{ color: p.color }}>
                        {p.label}:
                    </strong>
                    {formatAmountWithCurrency(p.dataPoint.value, currency, 0)}
                </div>
            ))}
            {dataPoints[0].dataPoint.description.length > 0 && (
                <ul className={styles.description}>
                    {dataPoints[0].dataPoint.description.map((d, index) => (
                        <li key={`tooltip-description-${rand}-${index}`}>
                            {d}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default LineChartTooltip