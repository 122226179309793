import React, {FunctionComponent} from "react"
import CaretRightIcon from "../../../../../../../tech/icon/caret-right.component"
import DealCategorization from "../../../../../../deal/card/categorization/categorization.component"
import styles from "./head.module.sass"
import InvestorDashboardInvestmentsStatus from "../../../status/status.component"
import InvestorDashboardInvestmentLogo from "../../../logo/logo.component"
import {InvestorDashboardUnderlyingInvestmentType} from "../../../investment.type"

type InvestorDashboardInvestmentMobileHeadProps = {
    investment: InvestorDashboardUnderlyingInvestmentType
}

const InvestorDashboardInvestmentMobileHead: FunctionComponent<InvestorDashboardInvestmentMobileHeadProps> = ({
    investment
}) => {
    return (
        <div className={styles.head}>
            <div>
                <div className={`${styles.logoAndStatus} ${investment.investment.type === "REGULAR" ? styles.marginBottom : ""}`}>
                    <InvestorDashboardInvestmentLogo investment={investment}/>
                    <InvestorDashboardInvestmentsStatus investment={investment}/>
                </div>
                {investment.investment.type === "REGULAR" && (
                    <div className={styles.categorization}>
                        <DealCategorization
                            geography={investment.investment.value.deal.geography}
                            industry={investment.investment.value.deal.industry}
                            stage={investment.investment.value.deal.stage}
                            bright={false}
                        />
                    </div>
                )}
            </div>
            <div>
                <CaretRightIcon
                    width={16}
                    height={16}
                    fillClass="fill-icon-theme"
                />
            </div>
        </div>
    )
}

export default InvestorDashboardInvestmentMobileHead