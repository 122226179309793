import {FunctionComponent} from "react"
import styles from "./head.module.sass"

type FilterSectionHeadProps = {
    label: string
}

const FilterSectionHead: FunctionComponent<FilterSectionHeadProps> = ({
    label
}) => {
    return (
        <div className={styles.head}>
            {label}
        </div>
    )
}

export default FilterSectionHead