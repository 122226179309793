import React, {FunctionComponent, ReactNode} from "react"
import Modal from "../modal.component"
import styles from "./confirmation.module.sass"
import CrossCircleIcon from "../../../icon/cross-circle.component"

type ConfirmationModalProps = {
    children: ReactNode
    close: () => void
    confirm: ReactNode
    decline: ReactNode
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
    children,
    close,
    confirm,
    decline
}) => {
    return (
        <Modal close={close}>
            <div className={styles.modal}>
                <div className={styles.content}>
                    <div>
                        {children}
                    </div>
                    <div className={styles.close}>
                        <div onClick={close}>
                            <CrossCircleIcon
                                width={32}
                                height={32}
                                fillClass="fill-icon-theme"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.actions}>
                    <div className={styles.decline}>
                        {decline}
                    </div>
                    <div className={styles.confirm}>
                        {confirm}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationModal