import React, {FunctionComponent} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import DealHead from "./head/head.component"
import DealCommitment from "./commitment/commitment.component"
import DealCategorization from "./categorization/categorization.component"
import DealDescription from "./description/description.component"
import DealInvestors from "./investors/investors.component"
import DealTags from "./tags/tags.component"
import DealTitle from "./title/title.component"
import {DEAL} from "../../../paths"
import {DealOnboardingDependentType} from "../onboarding-dependent.type"
import {DealType} from "../deal.type"
import {InvestmentType} from "../../investment/investment.type"
import styles from "./card.module.sass"
import DealCardBadge from "./badge/badge.component"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"

type DealCardProps = {
    deal: DealOnboardingDependentType
    investment?: InvestmentType
    onboarding: InvestorOnboardingType
    pendingInvestment?: boolean
}

const DealCard: FunctionComponent<DealCardProps> = ({
    deal,
    investment,
    onboarding,
    pendingInvestment = false
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <div
            className={styles.card}
            onClick={() => navigate(
                DEAL(deal.id!),
                { state: { previousLocationPathname: location.pathname } }
            )}
        >
            <div className={styles.parts}>
                <div
                    className={styles.part1}
                    style={deal.image
                        ? { background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 4.05%, #000 68.52%), url(${deal.image.url}?auto=format&w=1024), lightgray 50% / cover no-repeat` }
                        : {}
                    }
                >
                    <DealHead
                        deal={deal}
                        showBookmark={!pendingInvestment}
                    />
                    <div className={styles.categorization}>
                        <DealCategorization
                            geography={deal.geography}
                            industry={deal.industry}
                            stage={deal.stage}
                            bright={true}
                        />
                    </div>
                </div>
                <div className={styles.part2}>
                    <DealTitle
                        deal={deal}
                        showBookmark={!pendingInvestment}
                    />
                    <DealDescription deal={deal}/>
                    <DealInvestors investors={deal.investors}/>
                    <DealTags termsTags={deal.termsTags}/>
                </div>
                <div className={`${styles.part3} ${deal instanceof DealType ? styles.withContent : ""}`}>
                    <DealCommitment
                        deal={deal}
                        investment={investment}
                        blurredPosition="OVERVIEW"
                    />
                </div>
            </div>
            <DealCardBadge
                deal={deal}
                onboarding={onboarding}
                pendingInvestment={pendingInvestment}
            />
        </div>
    )
}

export default DealCard