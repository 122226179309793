import {
    FilterOptionCategoryType,
    FilterOptionGeographyType,
    FilterOptionInvestmentTypeType,
    FilterOptionStageType,
    FilterOptionStatusType,
    FilterOptionsType,
    FilterOptionType,
    FilterOptionWithInvestmentType
} from "./options.type"
import {FilterType} from "../filter.type"
import {DealStageOpenApi, DealStatusOpenApi, InvestorDashboardInvestmentTypeOpenApi} from "../../../generated"
import {
    getCategoryIdsFromFilterTypes,
    getGeographyIdsFromFilterTypes,
    getInvestmentTypesFromFilterTypes,
    getStageFromFilterTypes,
    getStatusFromFilterTypes,
    getWithInvestmentFilterTypes
} from "../filter.util"
import {mapDealStageToString} from "../../../domain/deal/deal.type"
import {DealGeographyType} from "../../../domain/deal-geography/deal-geography.type"
import {compareStrings} from "../../sort/sort.util"
import {DealOnboardingDependentType} from "../../../domain/deal/onboarding-dependent.type"

export function toggleFilterOption(id: string, options: FilterOptionsType): FilterOptionsType {
    return {
      ...options,
        categories: options.categories.map(option => toggleFilterOptionInternal(id, option)),
        geographies: options.geographies.map(option => toggleFilterOptionInternal(id, option)),
        investmentTypes: options.investmentTypes?.map(option => toggleFilterOptionInternal(id, option)),
        stages: options.stages.map(option => toggleFilterOptionInternal(id, option)),
        statuses: options.statuses?.map(option => toggleFilterOptionInternal(id, option)),
        withInvestmentOptions: options.withInvestmentOptions?.map(option => toggleFilterOptionInternal(id, option)),
    }
}

function toggleFilterOptionInternal<T>(
    id: string,
    option: FilterOptionType<T>
): FilterOptionType<T> {
    if (option.id === id) {
        return {
            ...option,
            selected: !option.selected
        }
    }
    return option
}

export function sortFilterOptions(options: FilterOptionsType): FilterOptionsType {
    return {
        categories: options.categories.sort((a, b) => compareStrings(a.label, b.label, "ASCENDING")),
        geographies: options.geographies.sort((a, b) => compareStrings(a.label, b.label, "ASCENDING")),
        investmentTypes: options.investmentTypes?.sort((a, b) => compareStrings(a.label, b.label, "ASCENDING")),
        stages: options.stages.sort((a, b) => compareStrings(a.label, b.label, "ASCENDING")),
        statuses: options.statuses?.sort((a, b) => compareStrings(a.label, b.label, "ASCENDING")),
        withInvestmentOptions: options.withInvestmentOptions?.sort((a, b) => compareStrings(a.label, b.label, "DESCENDING")),
    }
}

export function initializeFilterOptions(
    deals: DealOnboardingDependentType[],
    currentFilterTypes: FilterType[],
    showInvestmentTypeFilter: boolean,
    showStatusFilter: boolean,
    showWithInvestmentFilter: boolean,
): FilterOptionsType {
    return sortFilterOptions({
        categories: initializeCategories(deals, currentFilterTypes),
        geographies: initializeGeographies(deals, currentFilterTypes),
        investmentTypes: showInvestmentTypeFilter ? initializeInvestmentTypes(currentFilterTypes) : undefined,
        stages: initializeStages(deals, currentFilterTypes),
        statuses: showStatusFilter ? initializeStatuses(currentFilterTypes) : undefined,
        withInvestmentOptions: showWithInvestmentFilter ? initializeWithInvestmentFilter(currentFilterTypes) : undefined,
    })
}

function initializeCategories(
    deals: DealOnboardingDependentType[],
    currentFilterTypes: FilterType[]
): FilterOptionCategoryType[] {
    const selectedCategoryIds = getCategoryIdsFromFilterTypes(currentFilterTypes)
    const categories = deals
        .flatMap(d => d.categories)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(category => ({
            label: category.name,
            id: "filter-category-" + category.id,
            value: category,
            selected: selectedCategoryIds.includes(category.id!)
        }))
    return removeDuplicates(categories)
}

function initializeGeographies(
    deals: DealOnboardingDependentType[],
    currentFilterTypes: FilterType[]
): FilterOptionGeographyType[] {
    const selectedGeographyIds = getGeographyIdsFromFilterTypes(currentFilterTypes)
    const geographies = deals
        .map(d => d.geography)
        .filter(g => g !== undefined)
        .map(g => g as DealGeographyType)
        .filter((v, i, a) => a.map(x => x.id).indexOf(v.id) === i)
        .map(geography => ({
            label: geography.name,
            id: "filter-geography-" + geography.id,
            value: geography,
            selected: selectedGeographyIds.includes(geography.id!)
        }))
    return removeDuplicates(geographies)
}

function initializeInvestmentTypes(currentFilterTypes: FilterType[]): FilterOptionInvestmentTypeType[] {
    const currentOptions = getInvestmentTypesFromFilterTypes(currentFilterTypes)
    const options: FilterOptionInvestmentTypeType[] = []
    options.push({
        label: "Individual Investments",
        id: "filter-investment-type-individual",
        value: InvestorDashboardInvestmentTypeOpenApi.IndividualInvestments,
        selected: currentOptions.includes(InvestorDashboardInvestmentTypeOpenApi.IndividualInvestments)
    })
    options.push({
        label: "Regular Investments",
        id: "filter-investment-type-regular",
        value: InvestorDashboardInvestmentTypeOpenApi.RegularInvestments,
        selected: currentOptions.includes( InvestorDashboardInvestmentTypeOpenApi.RegularInvestments)
    })
    return options
}

function initializeStages(
    deals: DealOnboardingDependentType[],
    currentFilterTypes: FilterType[]
): FilterOptionStageType[] {
    const selectedStages = getStageFromFilterTypes(currentFilterTypes)
    const stages = deals
        .map(d => d._stage)
        .filter(stage => stage !== DealStageOpenApi.None)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(stage => ({
            label: mapDealStageToString(stage),
            id: "filter-stage-" + stage,
            value: stage,
            selected: selectedStages.includes(stage)
        }))
    return removeDuplicates(stages)
}

function initializeStatuses(currentFilterTypes: FilterType[]): FilterOptionStatusType[] {
    const selectedStatuses = getStatusFromFilterTypes(currentFilterTypes)
    const statuses = []
    statuses.push({
        label: "Exited Deals",
        id: "filter-status-exited",
        value: DealStatusOpenApi.Exited,
        selected: selectedStatuses.includes(DealStatusOpenApi.Exited)
    })
    statuses.push({
        label: "Live Deals",
        id: "filter-status-live",
        value: DealStatusOpenApi.Live,
        selected: selectedStatuses.includes(DealStatusOpenApi.Live)
    })
    return statuses
}

function initializeWithInvestmentFilter(currentFilterTypes: FilterType[]): FilterOptionWithInvestmentType[] {
    const currentOptions = getWithInvestmentFilterTypes(currentFilterTypes)
    const options: FilterOptionWithInvestmentType[] = []
    options.push({
        label: "With",
        id: "filter-with-investment-with",
        value: "WITH",
        selected: currentOptions.includes("WITH")
    })
    options.push({
        label: "Without",
        id: "filter-with-investment-without",
        value: "WITHOUT",
        selected: currentOptions.includes("WITHOUT")
    })
    return options
}

function removeDuplicates(arr: FilterOptionType<any>[]): FilterOptionType<any>[] {
    return arr.filter((value, index, self) => {
        return self.findIndex((t) => value.id === t.id) === index
    })
}