import {InvestorKycDocumentsOpenApi} from "../../../../generated"
import {InvestorKycDocumentsType} from "./kyc.type"
import {mapFileToOpenApi} from "../../../file/file.mapper"

export function mapInvestorKycDocumentsToOpenApi(documents: InvestorKycDocumentsType): InvestorKycDocumentsOpenApi {
    return {
        passportFiles: documents.passportFiles
            && documents.passportFiles.filter(f => f !== undefined).map(f => mapFileToOpenApi(f)),
        proofOfAddressFiles: documents.proofOfAddressFiles
            && documents.proofOfAddressFiles.filter(f => f !== undefined).map(f => mapFileToOpenApi(f)),
        proofOfAiStatusFiles: documents.proofOfAiStatusFiles
            && documents.proofOfAiStatusFiles.filter(f => f !== undefined).map(f => mapFileToOpenApi(f))
    }
}