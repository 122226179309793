import React, {FunctionComponent} from "react"
import InvestorDashboardSummaryOverview from "./overview/overview.component"
import InvestorDashboardSummaryDistribution from "./distribution/distribution.component"
import InvestorDashboardSummaryValue from "./value/value.component"
import {DashboardType} from "../dashboard.type"
import {InvestorOnboardingType} from "../../onboarding/onboarding.type"
import {hasAnySubscription} from "../../../subscription/subscription.util"
import styles from "./summary.module.sass"

type InvestorDashboardSummaryProps = {
    dashboard: DashboardType
    onboarding: InvestorOnboardingType
}

const InvestorDashboardSummary: FunctionComponent<InvestorDashboardSummaryProps> = ({
    dashboard,
    onboarding
}) => {
    return (
        <div className={styles.summary}>
            <InvestorDashboardSummaryOverview
                dashboard={dashboard}
                onboarding={onboarding}
                withBox
            />
            {hasAnySubscription(onboarding) && dashboard.investments.length > 0 && (
                <>
                    <InvestorDashboardSummaryValue dashboard={dashboard}/>
                    <InvestorDashboardSummaryDistribution dashboard={dashboard}/>
                </>
            )}
        </div>
    )
}

export default InvestorDashboardSummary
