import React, {FunctionComponent} from "react"
import {TimelineItemType} from "./item/item.type"
import styles from "./timeline.module.sass"
import TimelineItem from "./item/item.component"

type TimelineProps<T> = {
    items: TimelineItemType<T>[]
}

const Timeline: FunctionComponent<TimelineProps<any>> = ({ items }) => {
    return (
        <div className={styles.timeline}>
            {items.map((item, index) => (
                <TimelineItem
                    item={item}
                    key={`timeline-item-${index}`}
                />
            ))}
        </div>
    )
}

export default Timeline