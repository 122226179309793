import {FunctionComponent} from "react"
import {InvestorOnboardingType} from "../../../onboarding/onboarding.type"
import {InvestorOnboardingStageTypeOpenApi} from "../../../../../generated"
import styles from "./status.module.sass"

type ProfileOnboardingStatusProps = {
    onboarding: InvestorOnboardingType
}

const ProfileOnboardingStatus: FunctionComponent<ProfileOnboardingStatusProps> = ({ onboarding }) => {
    return (
        <div className={`${styles.status} ${evaluateClass(onboarding.stage.type)}`}>
            <div>
                <div className={styles.dot}/>
            </div>
            <div>
                <div className={styles.statusText}>
                    Status:
                </div>
            </div>
            <div>
                <div className={styles.text}>
                    {evaluateText(onboarding.stage.type)}
                </div>
            </div>
        </div>
    )
}

export default ProfileOnboardingStatus

function evaluateClass(stageType: InvestorOnboardingStageTypeOpenApi): string {
    if (stageType === "NONE" || stageType === "ACKNOWLEDGE_SUCCESS") return styles.green
    if (stageType === "KYC_WAITING_FOR_REVIEW") return styles.yellow
    return styles.red
}

function evaluateText(stageType: InvestorOnboardingStageTypeOpenApi): string {
    switch (stageType) {
        case "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED":
        case "GENERAL_DATA":
        case "KYC_DOCUMENT_UPLOAD_NECESSARY":
            return "Incomplete"
        case "KYC_WAITING_FOR_REVIEW":
            return "Waiting for document review"
        case "ACKNOWLEDGE_SUCCESS":
        case "NONE":
            return "Complete"
    }
}