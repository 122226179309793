import React from "react"
import styles from "./contact.module.sass"

const HelpContact = () => {
    return (
        <>
            <div className={styles.paragraph}>
                Feel free to reach out to us anytime. We are here and happy to help with any questions, concerns, or feedback you may have. Your satisfaction is our priority, and we look forward to assisting you!
            </div>
            <div className={styles.paragraph}>
                <a href="mailto:info@nonpublic.io">info@nonpublic.io</a>
            </div>
        </>
    )
}

export default HelpContact