import {DealQuestionOpenApi} from "../../../generated"
import {DealQuestionType} from "./question.type"

export function mapOpenApiToDealQuestion(question: DealQuestionOpenApi): DealQuestionType {
    return {
        id: question.id,
        question: question.question,
        resolved: question.resolved,
        dealId: question.dealId
    }
}