import React, {FunctionComponent} from "react"
import {formatDate} from "../../../../../tech/format/datetime.util"
import DashboardInfo from "../../info/info.component"
import styles from "./last-updated.module.sass"

type InvestorDashboardInvestmentLastUpdatedProps = {
    lastUpdated: Date
    showOnDesktop?: boolean
}

const InvestorDashboardInvestmentLastUpdated: FunctionComponent<InvestorDashboardInvestmentLastUpdatedProps> = ({
    lastUpdated,
    showOnDesktop = false
}) => {
    return (
        <div className={`${styles.lastUpdated} ${showOnDesktop ? styles.showOnDesktop : ""}`}>
            <DashboardInfo
                text={`Data last updated on ${formatDate(lastUpdated)}`}
                noBackgroundMobile={true}
                tooltip={{
                    content: (
                        <>
                            Refers to the date on which either one of your <strong>investments was finalised</strong> or a <strong>valuation was added / changed</strong>.
                        </>
                    ),
                    halveMaximumWidth: true
                }}
            />
        </div>
    )
}

export default InvestorDashboardInvestmentLastUpdated