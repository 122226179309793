import {FunctionComponent, ReactNode} from "react"
import {useApp} from "../../tech/app/context/app.context"
import {InvestorOnboardingType} from "../investor/onboarding/onboarding.type"
import {DealOnboardingDependentWithInvestmentType} from "../deal/onboarding-dependent.type"
import {RouteData} from "../../tech/routing/route.type"
import Layout from "../../tech/layout/layout.component"
import AuthWideLayoutWrapper from "../../tech/auth/wide-wrapper.component"
import AuthLayoutWrapper from "../../tech/auth/layout-wrapper.component"
import styles from "./legal.module.sass"

type TermsAndConditionsProps = {
    onboarding?: InvestorOnboardingType
    openDeals?: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
    children: ReactNode
}

const LegalWrapper: FunctionComponent<TermsAndConditionsProps> = ({
    onboarding,
    openDeals,
    relevantRoutes,
    children
}) => {
    const app = useApp()

    if (app.authenticated && onboarding && openDeals) {
        return (
            <Layout
                onboarding={onboarding}
                openDeals={openDeals}
                relevantRoutes={relevantRoutes}
                whiteBackground={false}
            >
                {children}
            </Layout>
        )
    }

    return (
        <AuthLayoutWrapper>
            <AuthWideLayoutWrapper>
                <div className={styles.notAuthenticated}>
                    {children}
                </div>
            </AuthWideLayoutWrapper>
        </AuthLayoutWrapper>
    )
}

export default LegalWrapper