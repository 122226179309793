/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealStatusOpenApi = {
    Exited: 'EXITED',
    Live: 'LIVE'
} as const;
export type DealStatusOpenApi = typeof DealStatusOpenApi[keyof typeof DealStatusOpenApi];


export function DealStatusOpenApiFromJSON(json: any): DealStatusOpenApi {
    return DealStatusOpenApiFromJSONTyped(json, false);
}

export function DealStatusOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealStatusOpenApi {
    return json as DealStatusOpenApi;
}

export function DealStatusOpenApiToJSON(value?: DealStatusOpenApi | null): any {
    return value as any;
}

