import React, {FunctionComponent, useEffect, useRef, useState} from "react"
import {FilterType} from "../../tech/filter/filter.type"
import {DealSortType} from "./sort/sort.type"
import DealFilter from "../../tech/filter/filter.component"
import DealSort from "./sort/sort.component"
import {filterByFilterTypes} from "../../tech/filter/filter.util"
import DealCard from "./card/card.component"
import FilterSelection from "../../tech/filter/selection/selection.component"
import {compareByDealSortType} from "./sort/sort.util"
import EmptyState, {EmptyStateStateProps} from "../../tech/layout/empty/empty.component"
import {DealOnboardingDependentWithInvestmentType} from "./onboarding-dependent.type"
import styles from "./list.module.sass"
import {InvestorOnboardingType} from "../investor/onboarding/onboarding.type"

type DealListProps = {
    dealsWithInvestment: DealOnboardingDependentWithInvestmentType[]
    title: string
    filterTypes: FilterType[]
    updateFilterTypes: (updatedFilterTypes: FilterType[]) => void
    sortType?: DealSortType
    updateSortType: (updatedSortType: DealSortType) => void
    emptyState: EmptyStateStateProps
    onboarding: InvestorOnboardingType
}

const DealList: FunctionComponent<DealListProps> = ({
    dealsWithInvestment,
    title,
    filterTypes,
    updateFilterTypes,
    sortType,
    updateSortType,
    emptyState,
    onboarding
}) => {
    const [filterOpened, setFilterOpened] = useState<boolean>(false)
    const [sortOpened, setSortOpened] = useState<boolean>(false)
    const [sticky, setSticky] = useState<boolean>(false)
    const positionRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const onScroll = () => {
            if (positionRef.current !== null) {
                const headerHeightCorrection = window.innerWidth >= 1024 ? 104 : 25
                if (positionRef.current.getBoundingClientRect().top - headerHeightCorrection <= 0) {
                    setSticky(true)
                }
                else {
                    setSticky(false)
                }
            }
        }
        window.removeEventListener("scroll", onScroll)
        window.addEventListener("scroll", onScroll, { passive: true })
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    return (
        <>
            <div ref={positionRef}/>
            <div className={`${styles.head} ${sticky ? styles.sticky : ""}`}>
                <div>
                    <div className={styles.headline}>
                        {title}
                    </div>
                    <div>
                        <div className={styles.actions}>
                            <FilterSelection
                                filterTypes={filterTypes}
                                updateFilterTypes={updateFilterTypes}
                            />
                            <DealFilter
                                deals={dealsWithInvestment.map(d => d.deal)}
                                filterTypes={filterTypes}
                                updateFilterTypes={updateFilterTypes}
                                opened={filterOpened}
                                updateOpened={(opened: boolean) => setFilterOpened(opened)}
                                closeSort={() => setSortOpened(false)}
                                showWithInvestmentFilter
                            />
                            <DealSort
                                sortType={sortType}
                                updateSortType={updateSortType}
                                opened={sortOpened}
                                updateOpened={(opened: boolean) => setSortOpened(opened)}
                                closeFilter={() => setFilterOpened(false)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {sticky && <div className={styles.headStickySpacer}/>}
            <div className={styles.dealList}>
                <div className={styles.deals}>
                    {dealsWithInvestment.length > 0 ? (
                        <>
                            {[...dealsWithInvestment]
                                .filter(dealWithInvestment => filterByFilterTypes(dealWithInvestment, filterTypes))
                                .sort(compareByDealSortType(sortType || "FINAL_DEADLINE"))
                                .map(dealWithInvestment => (
                                    <DealCard
                                        deal={dealWithInvestment.deal}
                                        investment={dealWithInvestment.investmentWithPaymentDetails?.investment}
                                        onboarding={onboarding}
                                        key={dealWithInvestment.deal.id}
                                    />
                                ))}
                        </>
                    ) : (
                        <EmptyState
                            imageSrc={emptyState.imageSrc}
                            textLine1={emptyState.textLine1}
                            textLine2={emptyState.textLine2}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default DealList