import {FAQEntryApiClient, FaqEntryOpenApi} from "../../../../generated"
import {mapOpenApiToFaqEntry} from "./faq-entry.mapper"
import {FaqEntryType} from "./faq-entry.type"

export class FaqEntryApi {

    constructor(private apiClient: FAQEntryApiClient) {
    }

    public async getAll(): Promise<FaqEntryType[]> {
        return (await this.apiClient.faqEntryGetAll())
            .map((faqEntry: FaqEntryOpenApi) => mapOpenApiToFaqEntry(faqEntry))
    }

    public async getAllPublic(): Promise<FaqEntryType[]> {
        return (await this.apiClient.faqEntryGetAllPublic())
            .map((faqEntry: FaqEntryOpenApi) => mapOpenApiToFaqEntry(faqEntry))
    }

}