/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DealIndustryOpenApi = {
    Others: 'OTHERS',
    AgriculturalTech: 'AGRICULTURAL_TECH',
    Commerce: 'COMMERCE',
    Communications: 'COMMUNICATIONS',
    Consumer: 'CONSUMER',
    Entertainment: 'ENTERTAINMENT',
    Fintech: 'FINTECH',
    FoodTech: 'FOOD_TECH',
    Fund: 'FUND',
    Gaming: 'GAMING',
    Saas: 'SAAS',
    FoodBeverage: 'FOOD_BEVERAGE',
    VentureCapital: 'VENTURE_CAPITAL',
    Hospitality: 'HOSPITALITY',
    ClimateTech: 'CLIMATE_TECH',
    Ai: 'AI',
    Recruitment: 'RECRUITMENT'
} as const;
export type DealIndustryOpenApi = typeof DealIndustryOpenApi[keyof typeof DealIndustryOpenApi];


export function DealIndustryOpenApiFromJSON(json: any): DealIndustryOpenApi {
    return DealIndustryOpenApiFromJSONTyped(json, false);
}

export function DealIndustryOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealIndustryOpenApi {
    return json as DealIndustryOpenApi;
}

export function DealIndustryOpenApiToJSON(value?: DealIndustryOpenApi | null): any {
    return value as any;
}

