import React, {FunctionComponent} from "react"
import commonStyles from "../common.module.sass"

const PendingInvestmentCardWaitForDealClosing: FunctionComponent = () => {
    return (
        <>
            <div className={commonStyles.title}>
                We've received your payment. Thank you!
            </div>
            <div className={commonStyles.paragraph}>
                As soon as we have reached the investment deadline given by our partners and have received the funds necessary to complete the investment, you will be notified.
            </div>
            <div className={commonStyles.paragraph}>
                We will finalize the deal, ACRA files will be shared with you and you are then officially invested.
            </div>
        </>
    )
}

export default PendingInvestmentCardWaitForDealClosing