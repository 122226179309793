import React, {FunctionComponent} from "react"
import {IndividualInvestmentPopupStateType} from "../../../investment/individual/popup/popup.type"
import styles from "./head-no-investments.module.sass"
import InvestorDashboardIndividualInvestmentButton from "../investments/individual/button/button.component"
import Head from "../../../../tech/head/head.component"

type InvestorDashboardHeadNoInvestmentsProps = {
    title: string
    updateIndividualInvestmentPopup: (state: IndividualInvestmentPopupStateType) => void
}

const InvestorDashboardHeadNoInvestments: FunctionComponent<InvestorDashboardHeadNoInvestmentsProps> = ({
    title,
    updateIndividualInvestmentPopup
}) => {
    return (
        <div className={styles.head}>
            <div className={styles.title}>
                <div className={styles.headElement}>
                    <Head title={title}/>
                </div>
            </div>
            <div>
                <div className={styles.buttonElement}>
                    <InvestorDashboardIndividualInvestmentButton openPopup={() => updateIndividualInvestmentPopup({ type: "NEW" })}/>
                </div>
            </div>
        </div>
    )
}

export default InvestorDashboardHeadNoInvestments