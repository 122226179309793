import {InvestmentType} from "../../../investment.type"
import {CurrencyOpenApi} from "../../../../../generated"

export function getAmountPotentiallyExchanged(
    investment: InvestmentType,
    currency: CurrencyOpenApi,
    amountWithPlacementFeeAndExchangeFeeAtExchangeRate: number
) {
    return investment.deal.currency !== currency
        ? amountWithPlacementFeeAndExchangeFeeAtExchangeRate
        : investment.amountWithPlacementFee
}

export function needsToSelectCurrency(investment: InvestmentType) {
    return !investment.currency
}