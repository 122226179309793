import React, {FunctionComponent, ReactNode} from "react"
import styles from "./tile.module.sass"

type HelpTileProps = {
    title: string
    children: ReactNode
    authenticated: boolean
}

const HelpTile: FunctionComponent<HelpTileProps> = ({
    title,
    children,
    authenticated
}) => {
    return (
        <div className={`${styles.tile} ${authenticated ? styles.authenticated : styles.unauthenticated}`}>
            <div className={styles.title}>
                {title}
            </div>
            {children}
        </div>
    )
}

export default HelpTile