import {SyndicateType} from "../../syndicate/syndicate.type"
import {CompanyType} from "../../company/company.type"
import {DealCategoryType} from "../../deal-category/deal-category.type"
import {DealGeographyType} from "../../deal-geography/deal-geography.type"
import {
    CurrencyOpenApi,
    DealIndustryOpenApi,
    DealOutcomeOpenApi,
    DealStageOpenApi,
    DealWaitingStateOpenApi
} from "../../../generated"
import {DealLogosType, DealSlideDeckType} from "../deal.type"
import {DealTileType} from "../tile/tile.type"
import {DealInterface} from "../deal.interface"

export type DealWithSyndicatePublicType = {
    deal: DealPublicType
    syndicate: SyndicateType
}

export class DealPublicType implements DealInterface {
    public id: string
    public _name: string | undefined
    public _description: string | undefined
    public company: CompanyType | undefined
    public categories: DealCategoryType[] | undefined
    public _geography: DealGeographyType | undefined
    public _stage: DealStageOpenApi | undefined
    public _outcome: DealOutcomeOpenApi | undefined
    public waitingState: DealWaitingStateOpenApi | undefined
    public _industry: DealIndustryOpenApi | undefined
    public _finalDeadline: Date | undefined
    public _minTarget: number | undefined
    public _maxTarget: number | undefined
    public minTicket: number | undefined
    public _currency: CurrencyOpenApi | undefined
    public _investedAmount: number | undefined
    public _logos: DealLogosType | undefined
    public tiles: DealTileType[] | undefined
    public slideDeck: DealSlideDeckType | undefined
    public _investors: string[] | undefined
    public _termsTags: string[] | undefined

    constructor({
        id,
        name,
        description,
        company,
        categories,
        geography,
        stage,
        outcome,
        waitingState,
        industry,
        finalDeadline,
        minTarget,
        maxTarget,
        minTicket,
        currency,
        investedAmount,
        logos,
        tiles,
        slideDeck,
        investors,
        termsTags
    } : {
        id: string
        name: string | undefined
        description: string | undefined
        company: CompanyType | undefined
        categories: DealCategoryType[] | undefined
        geography: DealGeographyType | undefined
        stage: DealStageOpenApi | undefined
        outcome: DealOutcomeOpenApi | undefined
        waitingState: DealWaitingStateOpenApi | undefined
        industry: DealIndustryOpenApi | undefined
        finalDeadline: Date | undefined
        minTarget: number | undefined
        maxTarget: number | undefined
        minTicket: number | undefined
        currency: CurrencyOpenApi | undefined
        investedAmount: number | undefined
        logos: DealLogosType | undefined
        tiles: DealTileType[] | undefined
        slideDeck: DealSlideDeckType | undefined
        investors: string[] | undefined
        termsTags: string[] | undefined
    }) {
        this.id = id
        this._name = name
        this._description = description
        this.company = company
        this.categories = categories
        this._geography = geography
        this._stage = stage
        this._outcome = outcome
        this.waitingState = waitingState
        this._industry = industry
        this._finalDeadline = finalDeadline
        this._minTarget = minTarget
        this._maxTarget = maxTarget
        this.minTicket = minTicket
        this._currency = currency
        this._investedAmount = investedAmount
        this._logos = logos
        this.tiles = tiles
        this.slideDeck = slideDeck
        this._investors = investors
        this._termsTags = termsTags
    }

    get name(): string | undefined {
        return this._name
    }

    get description(): string | undefined {
        return this._description
    }

    get currency(): CurrencyOpenApi | undefined {
        return this._currency
    }

    get finalDeadline(): Date | undefined {
        return this._finalDeadline
    }

    get geography(): DealGeographyType | undefined {
        return this._geography
    }

    get industry(): DealIndustryOpenApi | undefined {
        return this._industry
    }

    get investedAmount(): number | undefined {
        return this._investedAmount
    }

    get investors(): string[] | undefined {
        return this._investors
    }

    get logos(): DealLogosType | undefined {
        return this._logos
    }

    get minTarget(): number | undefined {
        return this._minTarget
    }

    get maxTarget(): number | undefined {
        return this._maxTarget
    }

    get outcome(): DealOutcomeOpenApi | undefined {
        return this._outcome
    }

    get stage(): DealStageOpenApi | undefined {
        return this._stage
    }

    get termsTags(): string[] | undefined {
        return this._termsTags
    }

    isPublic(): boolean {
        return true
    }
}