import React, {FunctionComponent} from "react"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import styles from "./syndication.module.sass"
import {Link} from "react-router-dom"
import {SYNDICATE} from "../../../paths"

type SyndicationProps = {
    onboarding: InvestorOnboardingType
}

const Syndication: FunctionComponent<SyndicationProps> = ({ onboarding }) => {
    const syndicateMemberships = onboarding.investorWithDetails?.syndication.memberships || []

    if (syndicateMemberships.length < 1) {
        return <>You don't belong to a syndicate yet.</>
    }

    return (
        <>
            You're member of the following syndicates:
            <ul className={styles.list}>
                {syndicateMemberships.map((syndicate) => (
                    <li key={syndicate.id}>
                        <Link to={SYNDICATE(syndicate.id)}>
                            {syndicate.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Syndication