export function addQueryParameter(
    basePath: string,
    parameter: {
        key: string
        value: string
    }
): string {
    const url = new URL(constructBaseUrl(basePath))
    url.searchParams.append(parameter.key, parameter.value)
    return removeOrigin(url)
}

export function addQueryParameters(
    basePath: string,
    parameters: ({
        key: string
        value: string
    })[]
): string {
    const url = new URL(constructBaseUrl(basePath))
    for (const parameter of parameters) {
        url.searchParams.append(parameter.key, parameter.value)
    }
    return removeOrigin(url)
}

export function removeQueryParameters(
    searchParameters: URLSearchParams,
    parametersToRemove: string[]
): URLSearchParams {
    for (const parameterToRemove of parametersToRemove) {
        searchParameters.delete(parameterToRemove)
    }
    return searchParameters
}

function constructBaseUrl(path: string): string {
    const firstCharacterInPathIsNotSlash = path.length > 0
        && Array.from(path)[0] !== "/"
    const fullUrl = window.location.origin
        + (window.location.origin.slice(-1) !== "/" && firstCharacterInPathIsNotSlash ? "/" : "")
        + path
    return fullUrl.slice(-1) === "/"
        ? fullUrl.slice(0, -1)
        : fullUrl
}

function removeOrigin(fullUrl: URL): string {
    return fullUrl.toString().replace(window.location.origin, "")
}