export function normalizeValues(obj: any) {
    if (obj !== null && obj !== undefined) {
        Object.keys(obj).forEach(k => {
            if (typeof obj[k] == "object") {
                obj[k] = normalizeValues(obj[k])
            }
            else if (obj[k] === "") {
                obj[k] = undefined
            }
        })
    }
    return obj
}