import React, {FunctionComponent} from "react"
import {InvestorDashboardInvestmentType} from "../../../../dashboard.type"
import {formatPercent} from "../../../../../../../tech/format/percent.util"
import {CurrencyOpenApi} from "../../../../../../../generated"
import {formatMultiple} from "../../../../../../../tech/format/multiple.util"
import ColoredCurrentValue from "../../../current-value/current-value.component"
import styles from "./stats.module.sass"
import Percentage from "../../../percentage/percentage.component"
import Triangle from "../../../triangle/triangle.component"
import {calculateNetValueChangePercentage} from "../../../percentage/percentage.util"
import {InvestorOnboardingType} from "../../../../../onboarding/onboarding.type"
import {hasAnySubscription} from "../../../../../../subscription/subscription.util"
import CurrentValueTooltip from "../../../../summary/overview/tooltip/current-value/current-value.component"
import Tooltip from "../../../../../../../tech/layout/tooltip/tooltip.component"
import IrrTooltip from "../../../../summary/overview/tooltip/irr/irr.component"
import MoicTooltip from "../../../../summary/overview/tooltip/moic/moic.component"

type InvestorDashboardInvestmentMobileStatsProps = {
    investment: InvestorDashboardInvestmentType
    targetCurrency: CurrencyOpenApi
    onboarding: InvestorOnboardingType
}

const InvestorDashboardInvestmentMobileStats: FunctionComponent<InvestorDashboardInvestmentMobileStatsProps> = ({
    investment,
    targetCurrency,
    onboarding
}) => {
    if (investment.amount === investment.currentValue) {
        return <></>
    }

    return (
        <div className={styles.stats}>
            <div className={styles.netValue}>
                <div>
                    <strong>Current Value:{" "}</strong>
                    <ColoredCurrentValue
                        investment={investment}
                        targetCurrency={targetCurrency}
                    />
                </div>
                <Percentage
                    value={calculateNetValueChangePercentage(investment)}
                    tooltipContent={<CurrentValueTooltip/>}
                />
            </div>
            {hasAnySubscription(onboarding) && (
                <>
                    <div className={styles.irr}>
                        <div>
                            <div>
                                <strong>IRR:</strong> {formatPercent(investment.irr, 1, 0)}
                            </div>
                        </div>
                        <div>
                            <Tooltip
                                padding={{
                                    x: 6,
                                    y: 2
                                }}
                                position={{
                                    top: 16,
                                    left: -41,
                                    shiftLeft: -220
                                }}
                            >
                                <IrrTooltip/>
                            </Tooltip>
                        </div>
                        <div className={styles.triangle}>
                            <Triangle
                                type="percentage"
                                value={investment.irr}
                            />
                        </div>
                    </div>
                    <div className={styles.moic}>
                        <div>
                            <div>
                                <strong>MOIC:</strong> {formatMultiple(investment.moic, 1, 0)}
                            </div>
                        </div>
                        <div>
                            <Tooltip
                                padding={{
                                    x: 6,
                                    y: 2
                                }}
                                position={{
                                    top: 16,
                                    left: -41,
                                    shiftLeft: -220
                                }}
                            >
                                <MoicTooltip/>
                            </Tooltip>
                        </div>
                        <div className={styles.triangle}>
                            <Triangle
                                type="multiple"
                                value={investment.moic}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default InvestorDashboardInvestmentMobileStats