import React, {FunctionComponent} from "react"
import {FilterOptionsType} from "../options/options.type"
import FilterSection from "../options/section/section.component"

type GeographyFilterProps = {
    options: FilterOptionsType
    updateOptions: (options: FilterOptionsType) => void
}

const StageFilter: FunctionComponent<GeographyFilterProps> = ({
    options,
    updateOptions
}) => {
    if (options.stages.length > 0) {
        return (
            <FilterSection
                label="Stage"
                type="STAGE"
                options={options}
                updateOptions={updateOptions}
            />
        )
    }
    return <></>
}

export default StageFilter