import React, {FunctionComponent, useEffect} from "react"
import {Outlet, useLocation, useNavigate} from "react-router-dom"
import {useApp} from "../../app/context/app.context"
import {ONBOARDING} from "../../../paths"
import Layout from "../../layout/layout.component"
import {InvestorOnboardingType} from "../../../domain/investor/onboarding/onboarding.type"
import {RouteData} from "../route.type"
import {DealOnboardingDependentWithInvestmentType} from "../../../domain/deal/onboarding-dependent.type"
import {redirectToSignInAndStoreCurrentUrl} from "../../redirect/redirect.util"
import {isWhiteBackground, shouldRedirectToOnboarding} from "./authenticated.util"

type AuthenticatedRouteProps = {
    onboarding: InvestorOnboardingType
    openDeals: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
}

const AuthenticatedRoute: FunctionComponent<AuthenticatedRouteProps> = ({
    onboarding,
    openDeals,
    relevantRoutes
}) => {
    const app = useApp()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!app.authenticated) {
            redirectToSignInAndStoreCurrentUrl(
                location.pathname,
                navigate
            )
        }
    }, [app.authenticated, location.pathname, navigate])

    useEffect(() => {
        if (shouldRedirectToOnboarding(onboarding!, location.pathname)) {
            navigate(ONBOARDING)
        }
    }, [onboarding, location.pathname, navigate])

    return (
        <Layout
            onboarding={onboarding!}
            openDeals={openDeals!}
            relevantRoutes={relevantRoutes}
            whiteBackground={isWhiteBackground(location.pathname)}
        >
            <Outlet/>
        </Layout>
    )
}

export default AuthenticatedRoute