import React, {FunctionComponent} from "react"

const AccreditedInvestorStatusExplanation: FunctionComponent = () => {
    return (
        <div>
            MAS defines an Accredited Investor as someone who:
            <ul>
                <li>Has an annual income in the preceding 12 months of not less than S$300,000 (or its equivalent in foreign currency), OR</li>
                <li>Has net financial assets (net of any related liabilities) exceeding S$1,000,000 (or its equivalent in foreign currency), OR</li>
                <li>Has net personal assets exceeding S$2,000,000 (or its equivalent in foreign currency), of which net equity of the individual’s primary residence is no more than S$1,000,000</li>
            </ul>
        </div>
    )
}

export default AccreditedInvestorStatusExplanation