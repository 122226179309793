import React, {FunctionComponent, ReactNode, useState} from "react"
import styles from "./tile.module.sass"
import CaretCircleUpIcon from "../../../../tech/icon/caret-circle-up.component"
import CaretCircleDownIcon from "../../../../tech/icon/caret-circle-down.component"

type ProfileTileProps = {
    title: string
    collapsible: boolean
    children: ReactNode
}

const ProfileTile: FunctionComponent<ProfileTileProps> = ({
    title,
    collapsible,
    children
}) => {
    const [opened, setOpened] = useState<boolean>(false)
    return (
        <div className={styles.tile}>
            <div
                className={styles.head}
                onClick={() => setOpened(!opened)}
            >
                <div className={styles.title}>
                    <div>{title}</div>
                </div>
                {collapsible && (
                    <div className={styles.toggle}>
                        <div>
                            {opened ? (
                                <CaretCircleUpIcon
                                    width={24}
                                    height={24}
                                    fillClass="fill-icon-theme"
                                />
                            ) : (
                                <CaretCircleDownIcon
                                    width={24}
                                    height={24}
                                    fillClass="fill-icon-theme"
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className={!collapsible || (collapsible && opened) ? styles.bodyOpened : styles.body}>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ProfileTile