import React, {FunctionComponent, ReactNode} from "react"
import styles from "./wide-wrapper.module.sass"

const AuthWideLayoutWrapper: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default AuthWideLayoutWrapper