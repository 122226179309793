import React, {FunctionComponent} from "react"
import {InvestorOnboardingType} from "../../investor/onboarding/onboarding.type"
import {DealOnboardingDependentWithInvestmentType} from "../onboarding-dependent.type"
import {RouteData} from "../../../tech/routing/route.type"
import DealSingleAuthenticated from "./authenticated.component"
import DealSingleNotAuthenticated from "./not-authenticated.component"
import {useApp} from "../../../tech/app/context/app.context"

type DealSingleProps = {
    onboarding?: InvestorOnboardingType
    openDeals: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
}

const DealSingle: FunctionComponent<DealSingleProps> = ({
    onboarding,
    openDeals,
    relevantRoutes
}) => {
    const app = useApp()

    if (app.authenticated) {
        return (
            <DealSingleAuthenticated
                onboarding={onboarding!}
                openDeals={openDeals}
                relevantRoutes={relevantRoutes}
            />
        )
    }

    return <DealSingleNotAuthenticated/>
}

export default DealSingle