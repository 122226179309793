import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const ArrowsInIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
        >
            <path
                className={fillClass}
                d="M18 13V8a1 1 0 0 1 1-1 1 1 0 0 1 1 1v2.586l5.293-5.294a1 1 0 0 1 1.415 0 1 1 0 0 1 0 1.415L21.414 12H24a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1zm-5 5H8a1 1 0 0 0-1 1 1 1 0 0 0 1 1h2.586l-5.294 5.293a1 1 0 0 0 0 1.415 1 1 0 0 0 1.415 0L12 21.414V24a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1zm8.414 2H24a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-2.586l5.293 5.294a1 1 0 0 0 .707.293 1 1 0 0 0 .707-.293 1 1 0 0 0 .293-.707 1 1 0 0 0-.293-.707L21.414 20zM13 7a1 1 0 0 0-1 1v2.586L6.708 5.293a1 1 0 0 0-1.415 0 1 1 0 0 0 0 1.415L10.586 12H8a1 1 0 0 0-1 1 1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1z"
            />
        </svg>
    )
}

export default ArrowsInIcon