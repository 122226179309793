import {FunctionComponent} from "react"
import styles from "./current-value.module.sass"

const CurrentValueTooltip: FunctionComponent = () => {
    return (
        <div className={styles.currentValue}>
            <div>
                The <strong>current value</strong> is calculated by the value you initially invested, adjusted by the latest available valuation of the company.
            </div>
            <div>
                As usual in private equity, this is only a <strong>rough estimate</strong> and not an accurate value.
            </div>
        </div>
    )
}

export default CurrentValueTooltip