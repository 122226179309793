import React, {FunctionComponent, ReactNode} from "react"
import {NavLink} from "react-router-dom"
import styles from "./item.module.sass"

type MenuItemProps = {
    icon: ReactNode
    text: string
    route: string
    closeMenu: () => void,
    hideOnDesktop?: boolean
}

const MenuItem: FunctionComponent<MenuItemProps> = ({
    icon,
    text,
    route,
    closeMenu,
    hideOnDesktop = false
}) => {
    return (
        <NavLink
            to={route}
            className={({ isActive }) => evaluateClassName(isActive, hideOnDesktop)}
            onClick={closeMenu}
        >
            <div className={styles.icon}>
                <div>
                    {icon}
                </div>
            </div>
            <div className={styles.text}>
                <div>
                    {text}
                </div>
            </div>
        </NavLink>
    )
}

export default MenuItem

function evaluateClassName(active: boolean, hideOnDesktop: boolean) {
    if (active) {
        return hideOnDesktop ? styles.itemActiveHideOnDesktop : styles.itemActive
    }
    return hideOnDesktop ? styles.itemHideOnDesktop : styles.item
}