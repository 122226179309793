import React, {FunctionComponent, ReactNode} from "react"
import SortIcon from "../../../../../../../tech/icon/sort.component"
import {InvestmentDashboardDesktopSort, InvestmentDashboardDesktopSortType} from "./sort.type"
import styles from "./sort.module.sass"
import Tooltip from "../../../../../../../tech/layout/tooltip/tooltip.component"

type InvestorDashboardInvestmentsDesktopTableHeadSortProps = {
    title: string
    sort: InvestmentDashboardDesktopSort
    sortType: InvestmentDashboardDesktopSortType
    updateSort: (sort: InvestmentDashboardDesktopSort) => void
    tooltip?: {
        content: ReactNode
        maxWidth: number
        shiftLeft?: number
    }
}

const InvestorDashboardInvestmentsDesktopTableHeadSort: FunctionComponent<InvestorDashboardInvestmentsDesktopTableHeadSortProps> = ({
    title,
    sort,
    sortType,
    updateSort,
    tooltip
}) => {
    return (
        <div
            className={styles.sort}
            onClick={() => updateSort(determineSortOnClick(sort, sortType))}
        >
            <div className={styles.title}>
                <div>
                    {title}
                </div>
            </div>
            <div className={styles.sortIcon}>
                <SortIcon
                    width={16}
                    height={16}
                    fillClass="fill-icon-inactive"
                    fillClassActive="fill-icon-active"
                    mode={determineMode(sort, sortType)}
                />
            </div>
            {tooltip && (
                <div>
                    <Tooltip
                        padding={{ x: 4, y: 4 }}
                        position={{
                            top: 14,
                            left: -43,
                            shiftLeft: tooltip.shiftLeft
                        }}
                        maxWidth={tooltip.maxWidth}
                    >
                        {tooltip.content}
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

export default InvestorDashboardInvestmentsDesktopTableHeadSort

function determineMode(
    sort: InvestmentDashboardDesktopSort,
    sortType: InvestmentDashboardDesktopSortType
) {
    return sort.type === sortType
        ? sort.direction
        : "NONE"
}

function determineSortOnClick(
    sort: InvestmentDashboardDesktopSort,
    sortType: InvestmentDashboardDesktopSortType
): InvestmentDashboardDesktopSort {
    if (sort.type === sortType) {
        return {
            type: sort.type,
            direction: sort.direction === "ASCENDING" ? "DESCENDING" : "ASCENDING"
        }
    }
    return {
        type: sortType,
        direction: "ASCENDING"
    }
}