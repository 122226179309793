import {FunctionComponent, ReactNode} from "react"
import styles from "./item.module.sass"

type ItemProps = {
    bullet: string
    children: ReactNode
}

const Item: FunctionComponent<ItemProps> = ({
    bullet,
    children
}) => {
    return (
        <div className={styles.item}>
            <div className={styles.bullet}>
                {bullet}
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default Item