import React, {FunctionComponent, useState} from "react"
import {InputFieldProps} from "../props.type"
import EyeIcon from "../../../icon/eye.component"
import EyeSlashIcon from "../../../icon/eye-slash.component"
import FormError from "../../error/error.component"
import styles from "./password.module.sass"
import FieldLabelText from "../label-text.component"

type PasswordFieldProps = InputFieldProps & {
}

const PasswordField: FunctionComponent<PasswordFieldProps> = ({
    placeholder,
    errors,
    reactHookFormRegister,
    autoComplete,
    label,
    disabled = false
}) => {
    const [visibility, setVisibility] = useState<"text" | "password">("password")

    const toggleVisibility = () => {
        if (visibility === "text") {
            setVisibility("password")
        }
        else {
            setVisibility("text")
        }
    }

    return (
        <label className={styles.label}>
            <FieldLabelText label={label}/>
            <div className={styles.toggleWrapper}>
                <input
                    type={visibility}
                    autoComplete={autoComplete}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...reactHookFormRegister.register(
                        reactHookFormRegister.name,
                        reactHookFormRegister.options
                    )}
                />
                <div
                    className={styles.toggle}
                    onClick={toggleVisibility}
                >
                    {visibility === "password" ? (
                        <EyeIcon
                            fillClass="fill-icon-black"
                            width={26}
                            height={26}
                        />
                    ) : (
                        <EyeSlashIcon
                            fillClass="fill-icon-black"
                            width={26}
                            height={26}
                        />
                    )}
                </div>
            </div>
            <FormError
                field={reactHookFormRegister.name}
                errors={errors}
            />
        </label>
    )
}

export default PasswordField