import {FunctionComponent} from "react"
import styles from "./option.module.sass"
import {FilterOptionsType, FilterOptionType} from "../options.type"
import CheckboxOutsideForm from "../../../form/fields/checkbox/checkbox-outside-form.component"
import {toggleFilterOption} from "../options.util"

type FilterOptionProps = {
    option: FilterOptionType<any>
    options: FilterOptionsType
    updateOptions: (options: FilterOptionsType) => void
}

const FilterOption: FunctionComponent<FilterOptionProps> = ({
    option,
    options,
    updateOptions
}) => {
    return (
        <div className={styles.option}>
            <CheckboxOutsideForm
                id={option.id}
                label={option.label}
                checked={option.selected}
                onClick={() => updateOptions(toggleFilterOption(option.id, options))}
            />
        </div>
    )
}

export default FilterOption
