import {FaqEntryOpenApi} from "../../../../generated"
import {FaqEntryType} from "./faq-entry.type"

export function mapOpenApiToFaqEntry(faqEntry: FaqEntryOpenApi): FaqEntryType {
    return {
        id: faqEntry.id,
        category: faqEntry.category,
        title: faqEntry.title,
        text: faqEntry.text,
        order: faqEntry.order,
        visibility: faqEntry.visibility,
    }
}