import React, {FunctionComponent} from "react"
import ExclamationMarkIcon from "../icon/exclamation-mark.component"
import styles from "./mark.module.sass"

type ExclamationMarkProps = {
    modalId: string
}

const ExclamationMark: FunctionComponent<ExclamationMarkProps> = ({ modalId }) => {
    return (
        <div
            className={styles.mark}
            onClick={() => toggleInfo(modalId)}
        >
            <ExclamationMarkIcon
                fillClass="fill-theme"
                width={16}
                height={16}
            />
        </div>
    )
}

export default ExclamationMark

export function toggleInfo(modalId: string): void {
    const elem = document.getElementById(modalId)
    if (elem) {
        if (elem.style.display === 'none') {
            elem.style.display = 'flex'
        }
        else {
            elem.style.display = 'none'
        }
    }
}