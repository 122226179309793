import {CurrencyOpenApi, InvestmentApiClient, InvestmentOpenApi} from "../../generated"
import {InvestmentType} from "./investment.type"
import {mapOpenApiToInvestment} from "./investment.mapper"
import {mapOpenApiToInvestmentExchangePreview} from "./exchange-rate/exchange-preview.mapper"
import {InvestmentExchangePreviewType} from "./exchange-rate/exchange-preview.type"
import {mapOpenApiToDealOnboardingDependentWithInvestment} from "../deal/onboarding-dependent.mapper"
import {DealOnboardingDependentWithInvestmentType} from "../deal/onboarding-dependent.type"

export class InvestmentApi {

    constructor(private apiClient: InvestmentApiClient) {
    }

    public async getAllPendingForCurrentUser(): Promise<InvestmentType[]> {
        return (await this.apiClient.investmentGetAllPendingForCurrentUser())
            .map((investment: InvestmentOpenApi) => mapOpenApiToInvestment(investment))
    }

    public async getExchangeRatePreviews(id: string): Promise<InvestmentExchangePreviewType[]> {
        return (await this.apiClient.investmentExchangeRatePreviewGet({ id }))
            .map(preview => mapOpenApiToInvestmentExchangePreview(preview))
    }

    public async selectCurrency(id: string, currency: CurrencyOpenApi, exchangePreviewId?: string): Promise<DealOnboardingDependentWithInvestmentType | "GONE" | "ERROR"> {
        const response = await this.apiClient.investmentExchangeRatePutRaw({
            id,
            investmentSelectCurrencyRequestOpenApi: {
                currency,
                exchangePreviewId
            }
        })
        if (response.raw.ok) {
            return mapOpenApiToDealOnboardingDependentWithInvestment(await response.value())
        }
        return response.raw.status === 410
            ? "GONE"
            : "ERROR"
    }

}