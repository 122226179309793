import React, {FunctionComponent} from "react"
import {format} from "../format.util"
import Dot from "../../../../../tech/dot/dot.component"
import styles from "./status.module.sass"
import {CurrencyOpenApi} from "../../../../../generated"

type DealCommitmentStatusProps = {
    dealCurrency: CurrencyOpenApi
    investedAmount: number
    minTarget: number
}

const DealCommitmentStatus: FunctionComponent<DealCommitmentStatusProps> = ({
    dealCurrency,
    investedAmount,
    minTarget,
}) => {
    const live = investedAmount >= minTarget
    return (
        <div className={styles.status}>
            <Dot type={live ? "ACTIVE" : "NEUTRAL"}/>
            <div className={styles.textStatus}>
                Status:
            </div>
            <div className={styles.textAmount}>
                {format(investedAmount, dealCurrency)} committed total
            </div>
        </div>
    )
}

export default DealCommitmentStatus