import React, {FunctionComponent} from "react"
import Button from "../../../../../../tech/button/button.component"
import {ButtonSize} from "../../../../../../tech/button/size.enum"
import {ButtonState} from "../../../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../../../tech/button/style.enum"
import styles from "./button.module.sass"
import PlusCircleIcon from "../../../../../../tech/icon/plus-circle.component"

type InvestorDashboardIndividualInvestmentButtonProps = {
    openPopup: () => void
}

const InvestorDashboardIndividualInvestmentButton: FunctionComponent<InvestorDashboardIndividualInvestmentButtonProps> = ({ openPopup }) => {
    return (
        <div className={styles.addOwnInvestment}>
            <Button
                label="Add own investment"
                type="button"
                size={ButtonSize.SMALL}
                state={ButtonState.INACTIVE}
                style={ButtonStyle.SECONDARY}
                icon={
                    <PlusCircleIcon
                        width={16}
                        height={16}
                        fillClass="fill-icon-inactive"
                    />
                }
                onClick={openPopup}
            />
        </div>
    )
}

export default InvestorDashboardIndividualInvestmentButton