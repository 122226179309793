/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealFeeStructureOpenApi } from './DealFeeStructureOpenApi';
import {
    DealFeeStructureOpenApiFromJSON,
    DealFeeStructureOpenApiFromJSONTyped,
    DealFeeStructureOpenApiToJSON,
} from './DealFeeStructureOpenApi';

/**
 * 
 * @export
 * @interface DealInvestorGroupOpenApi
 */
export interface DealInvestorGroupOpenApi {
    /**
     * 
     * @type {string}
     * @memberof DealInvestorGroupOpenApi
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DealInvestorGroupOpenApi
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DealInvestorGroupOpenApi
     */
    description?: string;
    /**
     * 
     * @type {DealFeeStructureOpenApi}
     * @memberof DealInvestorGroupOpenApi
     */
    feeStructure?: DealFeeStructureOpenApi;
}

/**
 * Check if a given object implements the DealInvestorGroupOpenApi interface.
 */
export function instanceOfDealInvestorGroupOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DealInvestorGroupOpenApiFromJSON(json: any): DealInvestorGroupOpenApi {
    return DealInvestorGroupOpenApiFromJSONTyped(json, false);
}

export function DealInvestorGroupOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealInvestorGroupOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'feeStructure': !exists(json, 'feeStructure') ? undefined : DealFeeStructureOpenApiFromJSON(json['feeStructure']),
    };
}

export function DealInvestorGroupOpenApiToJSON(value?: DealInvestorGroupOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'feeStructure': DealFeeStructureOpenApiToJSON(value.feeStructure),
    };
}

