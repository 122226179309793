import React, {FunctionComponent, ReactNode} from "react"
import styles from "./description.module.sass"

type InvestorOnboardingDescriptionProps = {
    content: (string | ReactNode)[]
}

const InvestorOnboardingDescription: FunctionComponent<InvestorOnboardingDescriptionProps> = ({ content }) => {
    if (content.length === 0) {
        return <></>
    }
    return (
        <div className={styles.description}>
            {content.map((c, index) => (
                <div key={`onboarding-content-${index}`}>
                    {c}
                </div>
            ))}
        </div>
    )
}

export default InvestorOnboardingDescription